import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Input, Container, Row, Col, Modal } from 'reactstrap';
import axios from 'axios';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import ManagerHeader from './ManagerHeader';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import moment from 'moment';
const moment = require("moment");

class ManagerQC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for image
      file: '',
      imagePreviewUrl: '',

      // Progress
      ShowProcedure: false,
      ShowBar: false,

      // redirect
      RedirectManagerKolList: false,

      // emp_id : 'CHCUI0035',
      // SrNO: this.props.location.state.SrNO,
      // RID: this.props.location.state.RID,
      // resercher_name: this.props.location.state.resercher_name,
      // project_id: this.props.location.state.project_id,
      // Project_Name: this.props.location.state.Project_Name,
      // kol_unique_id: this.props.location.state.kol_unique_id,
      // manager_name: this.props.location.state.manager_name,
      // manager_id: this.props.location.state.manager_id,
      // project_id: this.props.location.state.project_id,
      // p_name: this.props.location.state.p_name,
      kol_unique_id: '',
      manager_name: '',
      manager_id: '',
      project_id: '',
      p_name: '',


      //Flag to show form of selected drawer item
      PersonalInformationFlag: true,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,

      Language: [
        { id: 'Mandarin Chinese', language: 'Mandarin Chinese' },
        { id: 'Spanish', language: 'Spanish' },
        { id: 'English', language: 'English' },
        { id: 'Hindi (Sanskritised Hindustani)[9]', language: 'Hindi (Sanskritised Hindustani)[9]' },
        { id: 'Bengali', language: 'Bengali' },
        { id: 'Portuguese', language: 'Portuguese' },
        { id: 'Russian', language: 'Russian' },
        { id: 'Japanese', language: 'Japanese' },
        { id: 'Western Punjabi[10]', language: 'Western Punjabi[10]' },
        { id: 'Marathi', language: 'Marathi' },
        { id: 'Telugu', language: 'Telugu' },
        { id: 'Wu Chinese', language: 'Wu Chinese' },
        { id: 'Turkish', language: 'Turkish' },
        { id: 'Korean', language: 'Korean' },
        { id: 'French', language: 'French' },
        { id: 'German', language: 'German' },
        { id: 'Vietnamese', language: 'Vietnamese' },
        { id: 'Tamil', language: 'Tamil' },
        { id: 'Yue Chinese', language: 'Yue Chinese' },
        { id: 'Urdu (Persianised Hindustani)[9]', language: 'Urdu (Persianised Hindustani)[9]' },
        { id: 'Javanese', language: 'Javanese' },
        { id: 'Italian', language: 'Italian' },
        { id: 'Egyptian Arabic', language: 'Egyptian Arabic' },
        { id: 'Gujarati', language: 'Gujarati' },
        { id: 'Iranian Persian', language: 'Iranian Persian' },
        { id: 'Bhojpuri', language: 'Bhojpuri' },
        { id: 'Min Nan Chinese', language: 'Min Nan Chinese' },
        { id: 'Hakka Chinese', language: 'Hakka Chinese' },
        { id: 'Jin Chinese', language: 'Jin Chinese' },
        { id: 'Hausa', language: 'Hausa' },
        { id: 'Kannada', language: 'Kannada' },
        { id: 'Indonesian (Indonesian Malay)', language: 'Indonesian (Indonesian Malay)' },
        { id: 'Polish', language: 'Polish' },
        { id: 'Yoruba', language: 'Yoruba' },
        { id: 'Xiang Chinese', language: 'Xiang Chinese' },
        { id: 'Malayalam', language: 'Malayalam' },
        { id: 'Odia', language: 'Odia' },
        { id: 'Maithili', language: 'Maithili' },
        { id: 'Burmese', language: 'Burmese' },
        { id: 'Eastern Punjabi[10]', language: 'Eastern Punjabi[10]' },
        { id: 'Sunda', language: 'Sunda' },
        { id: 'Sudanese Arabic', language: 'Sudanese Arabic' },
        { id: 'Algerian Arabic', language: 'Algerian Arabic' },
        { id: 'Moroccan Arabic', language: 'Moroccan Arabic' },
        { id: 'Ukrainian', language: 'Ukrainian' },
        { id: 'Igbo', language: 'Igbo' },
        { id: 'Northern Uzbek', language: 'Northern Uzbek' },
        { id: 'Sindhi', language: 'Sindhi' },
        { id: 'North Levantine Arabic', language: 'North Levantine Arabic' },
        { id: 'Romanian', language: 'Romanian' },
        { id: 'Tagalog', language: 'Tagalog' },
        { id: 'Dutch', language: 'Dutch' },
        { id: 'Saʽidi Arabic', language: 'Saʽidi Arabic' },
        { id: 'Gan Chinese', language: 'Gan Chinese' },
        { id: 'Amharic', language: 'Amharic' },
        { id: 'Northern Pashto', language: 'Northern Pashto' },
        { id: 'Magahi', language: 'Magahi' },
        { id: 'Thai', language: 'Thai' },
        { id: 'Saraiki', language: 'Saraiki' },
        { id: 'Khmer', language: 'Khmer' },
        { id: 'Chhattisgarhi', language: 'Chhattisgarhi' },
        { id: 'Somali', language: 'Somali' },
        { id: 'Malay (Malaysian Malay)', language: 'Malay (Malaysian Malay)' },
        { id: 'Cebuano', language: 'Cebuano' },
        { id: 'Nepali', language: 'Nepali' },
        { id: 'Mesopotamian Arabic', language: 'Mesopotamian Arabic' },
        { id: 'Assamese', language: 'Assamese' },
        { id: 'Sinhalese', language: 'Sinhalese' },
        { id: 'Northern Kurdish', language: 'Northern Kurdish' },
        { id: 'Hejazi Arabic', language: 'Hejazi Arabic' },
        { id: 'Nigerian Fulfulde', language: 'Nigerian Fulfulde' },
        { id: 'Bavarian', language: 'Bavarian' },
        { id: 'South Azerbaijani', language: 'South Azerbaijani' },
        { id: 'Greek', language: 'Greek' },
        { id: 'Chittagonian', language: 'Chittagonian' },
        { id: 'Kazakh', language: 'Kazakh' },
        { id: 'Deccan', language: 'Deccan' },
        { id: 'Hungarian', language: 'Hungarian' },
        { id: 'Kinyarwanda', language: 'Kinyarwanda' },
        { id: 'Zulu', language: 'Zulu' },
        { id: 'South Levantine Arabic', language: 'South Levantine Arabic' },
        { id: 'Tunisian Arabic', language: 'Tunisian Arabic' },
        { id: 'Sanaani Spoken Arabic', language: 'Sanaani Spoken Arabic' },
        { id: 'Min Bei Chinese', language: 'Min Bei Chinese' },
        { id: 'Southern Pashto', language: 'Southern Pashto' },
        { id: 'Czech', language: 'Czech' },
        { id: 'Taʽizzi-Adeni Arabic', language: 'Taʽizzi-Adeni Arabic' },
        { id: 'Min Dong Chinese', language: 'Min Dong Chinese' },
        { id: 'Sylheti', language: 'Sylheti' },
      ],

      CitizenshipdDrop: [
        { id: 'Afghan', citizenship: 'Afghan' },
        { id: 'Albanian', citizenship: 'Albanian' },
        { id: 'Algerian', citizenship: 'Algerian' },
        { id: 'American Samoa', citizenship: 'American Samoa' },
        { id: 'Andorran', citizenship: 'Andorran' },
        { id: 'Angolan', citizenship: 'Angolan' },
        { id: 'Anguilla', citizenship: 'Anguilla' },
        { id: 'Antarctica', citizenship: 'Antarctica' },
        { id: 'Antigua and Barbuda', citizenship: 'Antigua and Barbuda' },
        { id: 'Argentinian', citizenship: 'Argentinian' },
        { id: 'Armenian', citizenship: 'Armenian' },
        { id: 'Aruba', citizenship: 'Aruba' },
        { id: 'Australian', citizenship: 'Australian' },
        { id: 'Austrian', citizenship: 'Austrian' },
        { id: 'Azerbaijani', citizenship: 'Azerbaijani' },
        { id: 'Bahamian', citizenship: 'Bahamian' },
        { id: 'Bahraini', citizenship: 'Bahraini' },
        { id: 'Bangladeshi', citizenship: 'Bangladeshi' },
        { id: 'Barbadian', citizenship: 'Barbadian' },
        { id: 'Belarusian or Belarusan', citizenship: 'Belarusian or Belarusan' },
        // { id: 'Basques', citizenship: 'Basques' },
        { id: 'Belgian', citizenship: 'Belgian' },
        { id: 'Belizean', citizenship: 'Belizean' },
        { id: 'Benines', citizenship: 'Benines' },
        { id: 'Bermuda', citizenship: 'Bermuda' },
        { id: 'Bhutanese', citizenship: 'Bhutanese' },
        { id: 'Bolivian', citizenship: 'Bolivian' },
        { id: 'Bosnian', citizenship: 'Bosnian' },
        { id: 'Tswana', citizenship: 'Tswana' },
        // { id: 'Bosnians and Herzegovinians', citizenship: 'Bosnians and Herzegovinians' },
        { id: 'Brazilian', citizenship: 'Brazilian' },
        // { id: 'Bretons', citizenship: 'Bretons' },
        { id: 'British Indian Ocean Territory', citizenship: 'British Indian Ocean Territory' },
        { id: 'British Virgin Islands', citizenship: 'British Virgin Islands' },
        { id: 'Bruneian', citizenship: 'Bruneian' },
        { id: 'Bulgarian', citizenship: 'Bulgarian' },
        // { id: 'Macedonian Bulgarians', citizenship: 'Macedonian Bulgarians' },
        { id: 'Burkinese', citizenship: 'Burkinese' },
        // { id: 'Burmese', citizenship: 'Burmese' },
        { id: 'Burundian', citizenship: 'Burundian' },
        { id: 'Cambodian', citizenship: 'Cambodian' },
        { id: 'Cameroonian', citizenship: 'Cameroonian' },
        { id: 'Canadian', citizenship: 'Canadian' },
        // { id: 'Catalans', citizenship: 'Catalans' },
        { id: 'Cape Verdean', citizenship: 'Cape Verdean' },
        { id: 'Cayman Islands', citizenship: 'Cayman Islands' },
        { id: 'Central African Republic', citizenship: 'Central African Republic' },
        { id: 'Chadian', citizenship: 'Chadian' },
        { id: 'Chilean', citizenship: 'Chilean' },
        { id: 'Chinese', citizenship: 'Chinese' },
        { id: 'Christmas Island', citizenship: 'Christmas Island' },
        { id: 'Cocos Islands', citizenship: 'Cocos Islands' },
        { id: 'Colombian', citizenship: 'Colombian' },
        { id: 'Comoros', citizenship: 'Comoros' },
        { id: 'Cook Islands', citizenship: 'Cook Islands' },
        { id: 'CostRican', citizenship: 'CostRican' },
        { id: 'Croat or Croatian', citizenship: 'Croat or Croatian' },
        // { id: 'Congolese (RotC)', citizenship: 'Congolese (RotC)' },
        // { id: 'Costa Ricans', citizenship: 'Costa Ricans' },
        // { id: 'Croats', citizenship: 'Croats' },
        { id: 'Cuban', citizenship: 'Cuban' },
        { id: 'Curacao', citizenship: 'Curacao' },
        { id: 'Cypriot', citizenship: 'Cypriot' },
        { id: 'Czech', citizenship: 'Czech' },
        { id: 'Congolese', citizenship: 'Congolese' },
        { id: 'Dane', citizenship: 'Dane' },
        // { id: 'Greenlanders', citizenship: 'Greenlanders' },
        { id: 'Djiboutian', citizenship: 'Djiboutian' },
        { id: 'Dominican', citizenship: 'Dominican' },
        { id: 'Dominican (Republic)', citizenship: 'Dominican (Republic)' },
        // { id: 'Dutch', citizenship: 'Dutch' },
        { id: 'East Timor', citizenship: 'East Timor' },
        { id: 'Ecuadorean', citizenship: 'Ecuadorean' },
        // { id: 'Ecuadorians', citizenship: 'Ecuadorians' },
        { id: 'Egyptian', citizenship: 'Egyptian' },
        { id: 'Salvadorean', citizenship: 'Salvadorean' },
        { id: 'Equatorial Guinea', citizenship: 'Equatorial Guinea' },
        // { id: 'English', citizenship: 'English' },
        // { id: 'Equatoguineans', citizenship: 'Equatoguineans' },
        { id: 'Eritrean', citizenship: 'Eritrean' },
        { id: 'Estonian', citizenship: 'Estonian' },
        { id: 'Ethiopian', citizenship: 'Ethiopian' },
        { id: 'Falkland Islands', citizenship: 'Falkland Islands' },
        { id: 'Faroe Islands', citizenship: 'Faroe Islands' },
        { id: 'Fijian', citizenship: 'Fijian' },
        { id: 'Finn', citizenship: 'Finn' },
        { id: 'Frenchman, Frenchwoman', citizenship: 'Frenchman, Frenchwoman' },
        // { id: 'Finnish Swedish', citizenship: 'Finnish Swedish' },
        { id: 'French Polynesia', citizenship: 'French Polynesia' },
        { id: 'Gabonese', citizenship: 'Gabonese' },
        { id: 'Gambian', citizenship: 'Gambian' },
        { id: 'Georgian', citizenship: 'Georgian' },
        { id: 'German', citizenship: 'German' },
        // { id: 'Baltic Germans', citizenship: 'Baltic Germans' },
        { id: 'Ghanaian', citizenship: 'Ghanaian' },
        { id: 'Gibraltar', citizenship: 'Gibraltar' },
        { id: 'Greek', citizenship: 'Greek' },
        { id: 'Greenland', citizenship: 'Greenland' },
        // { id: 'Greek Macedonians', citizenship: 'Greek Macedonians' },
        { id: 'Grenadian', citizenship: 'Grenadian' },
        { id: 'Guam', citizenship: 'Guam' },
        { id: 'Guatemalan', citizenship: 'Guatemalan' },
        { id: 'Guernsey', citizenship: 'Guernsey' },
        // { id: 'Guianese (French)', citizenship: 'Guianese (French)' },
        { id: 'Guinean', citizenship: 'Guinean' },
        { id: 'Guinea-Bissau', citizenship: 'Guinea-Bissau' },
        { id: 'Guyanese', citizenship: 'Guyanese' },
        { id: 'Haitian', citizenship: 'Haitian' },
        { id: 'Honduran', citizenship: 'Honduran' },
        { id: 'Hong Kong', citizenship: 'Hong Kong' },
        { id: 'Hungarian', citizenship: 'Hungarian' },
        { id: 'Icelander', citizenship: 'Icelander' },
        // { id: 'I-Kiribati', citizenship: 'I-Kiribati' },
        { id: 'Indian', citizenship: 'Indian' },
        { id: 'Indonesian', citizenship: 'Indonesian' },
        { id: 'Iranian', citizenship: 'Iranian' },
        { id: 'Iraqi', citizenship: 'Iraqi' },
        { id: 'Irishman, Irishwoman', citizenship: 'Irishman, Irishwoman' },
        { id: 'Isle of Man', citizenship: 'Isle of Man' },
        // { id: 'Irish', citizenship: 'Irish' },
        { id: 'Israel', citizenship: 'Israel' },
        { id: 'Italian', citizenship: 'Italian' },
        { id: 'Ivory Coast', citizenship: 'Ivory Coast' },
        // { id: 'Ivoirians', citizenship: 'Ivoirians' },
        { id: 'Jamaican', citizenship: 'Jamaican' },
        { id: 'Japanese', citizenship: 'Japanese' },
        { id: 'Jersey', citizenship: 'Jersey' },
        { id: 'Jordanian', citizenship: 'Jordanian' },
        { id: 'Kazakh', citizenship: 'Kazakh' },
        { id: 'Kenyan', citizenship: 'Kenyan' },
        { id: 'Kiribati', citizenship: 'Kiribati' },
        // { id: 'Koreans', citizenship: 'Koreans' },
        { id: 'Kosovo', citizenship: 'Kosovo' },
        { id: 'Kuwaiti', citizenship: 'Kuwaiti' },
        { id: 'Kyrgyzstan', citizenship: 'Kyrgyzstan' },
        { id: 'Laotian', citizenship: 'Laotian' },
        { id: 'Latvian', citizenship: 'Latvian' },
        { id: 'Lebanese', citizenship: 'Lebanese' },
        { id: 'Lesotho', citizenship: 'Lesotho' },
        { id: 'Liberian', citizenship: 'Liberian' },
        { id: 'Libyan', citizenship: 'Libyan' },
        { id: 'Liechtensteine', citizenship: 'Liechtensteiner' },
        { id: 'Lithuanian', citizenship: 'Lithuanian' },
        { id: 'Luxembourger', citizenship: 'Luxembourger' },
        { id: 'Macau', citizenship: 'Macau' },
        { id: 'Macedonia', citizenship: 'Macedonia' },
        { id: 'Malagasy or Madagascan', citizenship: 'Malagasy or Madagascan' },
        { id: 'Malawian', citizenship: 'Malawian' },
        { id: 'Malaysian', citizenship: 'Malaysian' },
        { id: 'Maldivian', citizenship: 'Maldivian' },
        { id: 'Malian', citizenship: 'Malian' },
        { id: 'Maltese', citizenship: 'Maltese' },
        { id: 'Marshall Islands', citizenship: 'Marshall Islands' },
        // { id: 'Manx', citizenship: 'Manx' },
        // { id: 'Marshallese', citizenship: 'Marshallese' },
        { id: 'Mauritanian', citizenship: 'Mauritanian' },
        { id: 'Mauritian', citizenship: 'Mauritian' },
        { id: 'Mayotte', citizenship: 'Mayotte' },
        { id: 'Mexican', citizenship: 'Mexican' },
        { id: 'Micronesia', citizenship: 'Micronesia' },
        { id: 'Moldovan', citizenship: 'Moldovan' },
        { id: 'Monegasque or Monacan', citizenship: 'Monegasque or Monacan' },
        { id: 'Mongolian', citizenship: 'Mongolian' },
        { id: 'Montenegrin', citizenship: 'Montenegrin' },
        { id: 'Montserrat', citizenship: 'Montserrat' },
        { id: 'Moroccan', citizenship: 'Moroccan' },
        { id: 'Mozambican', citizenship: 'Mozambican' },
        { id: 'Namibian', citizenship: 'Namibian' },
        { id: 'Nauru', citizenship: 'Nauru' },
        { id: 'Nepalese', citizenship: 'Nepalese' },
        { id: 'Dutch', citizenship: 'Dutch' },
        { id: 'Netherlands Antilles', citizenship: 'Netherlands Antilles' },
        { id: 'New Caledonia', citizenship: 'New Caledonia' },
        { id: 'New Zealander', citizenship: 'New Zealander' },
        { id: 'Nicaraguan', citizenship: 'Nicaraguan' },
        { id: 'Nigerien', citizenship: 'Nigerien' },
        { id: 'Nigerian', citizenship: 'Nigerian' },
        { id: 'Niue', citizenship: 'Niue' },
        { id: 'North Korean', citizenship: 'North Korean' },
        { id: 'Northern Mariana Islands', citizenship: 'Northern Mariana Islands' },
        { id: 'Norwegian', citizenship: 'Norwegian' },
        { id: 'Omani', citizenship: 'Omani' },
        { id: 'Pakistani', citizenship: 'Pakistani' },
        { id: 'Palau', citizenship: 'Palau' },
        { id: 'Palestine', citizenship: 'Palestine' },
        // { id: 'Palestinians', citizenship: 'Palestinians' },
        { id: 'Panamanian', citizenship: 'Panamanian' },
        { id: 'PapuNew Guinean or Guinean', citizenship: 'PapuNew Guinean or Guinean' },
        { id: 'Paraguayan', citizenship: 'Paraguayan' },
        { id: 'Peruvian', citizenship: 'Peruvian' },
        { id: 'Filipino', citizenship: 'Filipino' },
        { id: 'Pitcairn', citizenship: 'Pitcairn' },
        { id: 'Pole', citizenship: 'Pole' },
        { id: 'Portuguese', citizenship: 'Portuguese' },
        { id: 'Puerto Rico', citizenship: 'Puerto Rico' },
        { id: 'Qatari', citizenship: 'Qatari' },
        { id: 'Republic of the Congo', citizenship: 'Republic of the Congo' },
        { id: 'Reunion', citizenship: 'Reunion' },
        // { id: 'Quebecers', citizenship: 'Quebecers' },
        // { id: 'Réunionnais', citizenship: 'Réunionnais' },
        { id: 'Romanian', citizenship: 'Romanian' },
        { id: 'Russian', citizenship: 'Russian' },
        // { id: 'Baltic Russians', citizenship: 'Baltic Russians' },
        { id: 'Rwandan', citizenship: 'Rwandan' },
        { id: 'Saint Barthelemy', citizenship: 'Saint Barthelemy' },
        { id: 'Saint Helena', citizenship: 'Saint Helena' },
        { id: 'Saint Kitts and Nevis', citizenship: 'Saint Kitts and Nevis' },
        { id: 'Saint Lucia', citizenship: 'Saint Lucia' },
        { id: 'Saint Martin', citizenship: 'Saint Martin' },
        { id: 'Saint Pierre and Miquelon', citizenship: 'Saint Pierre and Miquelon' },
        { id: 'Saint Vincent and the Grenadines', citizenship: 'Saint Vincent and the Grenadines' },
        // { id: 'Salvadorans', citizenship: 'Salvadorans' },
        // { id: 'Sammarinese', citizenship: 'Sammarinese' },
        { id: 'Samoan', citizenship: 'Samoan' },
        { id: 'San Marino', citizenship: 'San Marino' },
        { id: 'Sao Tome and Principe', citizenship: 'Sao Tome and Principe' },
        { id: 'Saudi Arabian or Saudi', citizenship: 'Saudi Arabian or Saudi' },
        // { id: 'São Tomé and Príncipe', citizenship: 'São Tomé and Príncipe' },
        // { id: 'Saudis', citizenship: 'Saudis' },
        // { id: 'Scots', citizenship: 'Scots' },
        { id: 'Senegalese', citizenship: 'Senegalese' },
        { id: 'Serb or Serbian', citizenship: 'Serb or Serbian' },
        { id: 'Seychellois', citizenship: 'Seychellois' },
        { id: 'SierrLeonian', citizenship: 'SierrLeonian' },
        // { id: 'Sierra Leoneans', citizenship: 'Sierra Leoneans' },
        { id: 'Singaporean', citizenship: 'Singaporean' },
        { id: 'Sint Maarten', citizenship: 'Sint Maarten' },
        { id: 'Slovak', citizenship: 'Slovak' },
        { id: 'Slovene or Slovenian', citizenship: 'Slovene or Slovenian' },
        { id: 'Solomon Islander', citizenship: 'Solomon Islander' },
        { id: 'Somali', citizenship: 'Somali' },
        // { id: 'Somalilanders', citizenship: 'Somalilanders' },
        // { id: 'Sotho', citizenship: 'Sotho' },
        { id: 'South African', citizenship: 'South African' },
        { id: 'South Korean', citizenship: 'South Korean' },
        { id: 'South Sudan', citizenship: 'South Sudan' },
        { id: 'Spaniard', citizenship: 'Spaniard' },
        { id: 'Sri Lankan', citizenship: 'Sri Lankan' },
        { id: 'Sudanese', citizenship: 'Sudanese' },
        { id: 'Surinamer or Surinamese', citizenship: 'Surinamer or Surinamese' },
        { id: 'Svalbard and Jan Mayen', citizenship: 'Svalbard and Jan Mayen' },
        { id: 'Swazi', citizenship: 'Swazi' },
        { id: 'Swede', citizenship: 'Swede' },
        { id: 'Swiss', citizenship: 'Swiss' },
        // { id: 'Syriacs', citizenship: 'Syriacs' },
        { id: 'Syrian', citizenship: 'Syrian' },
        { id: 'Taiwanese', citizenship: 'Taiwanese' },
        // { id: 'Tamils', citizenship: 'Tamils' },
        { id: 'Tajik or Tadjik', citizenship: 'Tajik or Tadjik' },
        { id: 'Tanzanian', citizenship: 'Tanzanian' },
        { id: 'Thai', citizenship: 'Thai' },
        // { id: 'Tibetans', citizenship: 'Tibetans' },
        // { id: 'Tobagonians', citizenship: 'Tobagonians' },
        { id: 'Togolese', citizenship: 'Togolese' },
        { id: 'Tokelau', citizenship: 'Tokelau' },
        { id: 'Tonga', citizenship: 'Tongan' },
        { id: 'Trinidadian, Tobagan, Tobagonian', citizenship: 'Trinidadian, Tobagan, Tobagonian' },
        { id: 'Tunisian', citizenship: 'Tunisian' },
        { id: 'Turk', citizenship: 'Turk' },
        { id: 'Turkmen or Turkoman', citizenship: 'Turkmen or Turkoman' },
        { id: 'Turks and Caicos Islands', citizenship: 'Turks and Caicos Islands' },
        { id: 'Tuvaluan', citizenship: 'Tuvaluan' },
        { id: 'U.S. Virgin Islands', citizenship: 'U.S. Virgin Islands' },
        { id: 'Ugandan', citizenship: 'Ugandan' },
        { id: 'Emirati', citizenship: 'Emirati' },
        { id: 'British', citizenship: 'British' },
        { id: 'American', citizenship: 'American' },
        { id: 'Uruguayan', citizenship: 'Uruguayan' },
        { id: 'Uzbek', citizenship: 'Uzbek' },
        { id: 'Vanuatuan', citizenship: 'Vanuatuan' },
        { id: 'Vatican', citizenship: 'Vatican' },
        { id: 'Venezuelan', citizenship: 'Venezuelan' },
        { id: 'Vietnamese', citizenship: 'Vietnamese' },
        { id: 'Wallis and Futuna', citizenship: 'Wallis and Futuna' },
        { id: 'Western Sahara', citizenship: 'Western Sahara' },
        // { id: 'Vincentians', citizenship: 'Vincentians' },
        { id: 'Yemeni', citizenship: 'Yemeni' },
        { id: 'Zambian', citizenship: 'Zambian' },
        { id: 'Zimbabwean', citizenship: 'Zimbabwean' },

        // { id: 'Angamis', citizenship: 'Angamis' },
        // { id: 'Aromanians', citizenship: 'Aromanians' },
        // { id: 'Assyrians', citizenship: 'Assyrians' },
        // // { id: 'Basques', citizenship: 'Basques' },
        // { id: 'Bengalis', citizenship: 'Bengalis' },
        // { id: 'Berbers', citizenship: 'Berbers' },
        // { id: 'Boers', citizenship: 'Boers' },
        // { id: 'Buryats', citizenship: 'Buryats' },
        // { id: 'Cajuns', citizenship: 'Cajuns' },
        // // { id: 'Catalans', citizenship: 'Catalans' },
        // { id: 'Celts', citizenship: 'Celts' },
        // { id: 'Chuvash', citizenship: 'Chuvash' },
        // { id: 'Han Chinese', citizenship: 'Han Chinese' },
        // { id: 'Hispanic and Latino Americans', citizenship: 'Hispanic and Latino Americans' },
        // { id: 'Hutus', citizenship: 'Hutus' },
        // { id: 'Igbo', citizenship: 'Igbo' },
        // { id: 'Indigenous Australians', citizenship: 'Indigenous Australians' },
        // { id: 'Indigenous people of the Americas', citizenship: 'Indigenous people of the Americas' },
        // { id: 'First Nations', citizenship: 'First Nations' },
        // { id: 'Huaorani people', citizenship: 'Huaorani people' },
        // { id: 'Inuit', citizenship: 'Inuit' },
        // { id: 'Canadian Inuit', citizenship: 'Canadian Inuit' },
        // { id: 'Greenlandic Inuit', citizenship: 'Greenlandic Inuit' },
        // { id: 'Métis people', citizenship: 'Métis people' },
        // { id: 'Native Americans', citizenship: 'Native Americans' },
        // { id: 'Malayali', citizenship: 'Malayali' },
        // { id: 'Jews', citizenship: 'Jews' },
        // { id: 'Kannadigas', citizenship: 'Kannadigas' },
        // { id: 'Karen', citizenship: 'Karen' },
        // { id: 'Kodavas', citizenship: 'Kodavas' },
        // { id: 'Kurds', citizenship: 'Kurds' },
        // // { id: 'Macedonians', citizenship: 'Macedonians' },
        // { id: 'Malays', citizenship: 'Malays' },
        // { id: 'Nagas', citizenship: 'Nagas' },
        // { id: 'Roma', citizenship: 'Roma' },
        // { id: 'Samis', citizenship: 'Samis' },
        // { id: 'Silesians', citizenship: 'Silesians' },
        // { id: 'Sindhis', citizenship: 'Sindhis' },
        // { id: 'Sinhalese', citizenship: 'Sinhalese' },
        // // { id: 'Syriacs', citizenship: 'Syriacs' },
        // // { id: 'Tamils', citizenship: 'Tamils' },
        // { id: 'Tatars', citizenship: 'Tatars' },
        // { id: 'Tutsis', citizenship: 'Tutsis' },
        // { id: 'Zulus', citizenship: 'Zulus' },
      ],

      // Store values from API
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialitydata: [],
      ProcedureDataForAdvBoard: [],
      SubSpecialitydataForAdvBoard: [],
      ProcedureDataForClinicalTrial: [],
      SubSpecialitydataClinicalTrial: [],
      ProcedureDataForEventDetails: [],
      SubSpecialitydataEventDetails: [],
      ProcedureDataForGuidelines: [],
      SubSpecialitydataGuidelines: [],
      ProcedureDataForPublicationDetails: [],
      SubSpecialitydataPublicationDetails: [],

      CountryListData: [],
      StateData: [],
      // CityData: [],
      EmployeeListData: [],

      // to recall the procedure and subspeciality multiple dropdowns
      showProcedureSubSpecialityDropdown: false,
      showProcedureSubSpecialityDropdownAdvBoard: false,
      showProcedureSubSpecialityDropdownClinicalTrial: false,
      showProcedureSubSpecialityDropdownEventDetails: false,
      showProcedureSubSpecialityDropdownGuidelines: false,
      showProcedureSubSpecialityDropdownPublicationDetails: false,

      // set procedre and sub speciality 
      ProcedureType: [],
      SubSpecialityType: [],
      SpecilityTypeEducation: [],
      ProcedureTypeAdvBoard: [],
      SubSpecialityTypeAdvBoard: [],
      ProcedureTypeClinicalTrial: [],
      SubSpecialityTypeClinicalTrial: [],
      ProcedureTypeEventDetails: [],
      SubSpecialityTypeEventDetails: [],
      ProcedureTypeGuidelines: [],
      SubSpecialityTypeGuidelines: [],
      ProcedureTypePublicationDetails: [],
      SubSpecialityTypePublicationDetails: [],

      // to get values filled in form
      // kol type: '',
      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',

      ProcedureTypeDefaultValues: [],
      SubSpecialityTypeDefaultValues: [],

      StateDataForHospitalAffiliations: [],
      // CityDataForHospitalAffiliations: [],

      procedure_potential: '',
      procedure_potential_links: '',


      PersonalInformation: {},
      WorkDetails: [],
      KOl_Identifier: {},
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],


      // OrganizationSourceUrl:[{ OrgSourceUrl:'' }],
      // for adding new source multiple
      items: [],
      value: "",

      // for adding new source multiple peronal info
      valuePersonalInfoAddSource: '',
      itemsDoctorFullName: [],

      // for adding new source multiple peronal info
      valuePersonalInfoAddSource: '',

      itemsSalutation: [],
      itemsDoctorFullName: [],
      itemsFirstName: [],
      itemsMiddleName: [],
      itemsLastName: [],
      itemsSuffix: [],
      itemsQualification: [],
      itemsExperience: [],
      itemsMedicalSchool: [],
      itemsMedicalRegisterNo: [],
      itemsNpiNo: [],
      itemsPlaceWork: [],
      itemsDesignation: [],
      itemsBrand: [],
      itemsDepartment: [],
      itemSpeciality: [],
      itemSpeciality2: [],
      itemsProcedure: [],
      itemsSubSpeciality: [],
      itemsDoctorContact: [],
      itemsDoctorEmail: [],
      itemsDoctorEmail2: [],
      itemsFax: [],
      itemsLinkedIn: [],
      itemsTwitter: [],
      itemsAssistanceContact: [],
      itemsAssistanceEmail: [],
      itemsWebsite: [],
      itemsCountry: [],
      itemsState: [],
      itemsCity: [],
      itemsAddress1: [],
      itemsAddress2: [],
      itemsProcedurePotential: [],

      // for adding new source multiple Biography
      valueBiographyAddSource: '',

      itemsCitizenship: [],
      itemsLicensedRegions: [],
      itemsAreasOfInterest: [],
      itemsexpertise: [],
      itemsLanguageSpoken: [],
      itemsWorkPhone: [],
      itemsWorkEmail: [],
      itemsLinkedInFolllowers: [],
      itemsTwitterFollowers: [],
      itemsBiography: [],

      // variables for error
      // personal info
      koltypeErr: '',
      salutationErr: '',
      doctorfullnameErr: '',
      firstnameErr: '',
      lastnameErr: '',
      // qualificationErr: '',
      experienceErr: '',
      medicalschoolErr: '',
      placeofworkErr: '',
      designationErr: '',
      departmentErr: '',
      specialityErr: '',
      proceduretypeErr: '',
      subspecialitytypeErr: '',
      doctorcontactnoErr: '',
      doctoremailErr: '',
      countryErr: '',
      stateErr: '',
      address1Err: '',
      address2Err: '',
      zipcodeErr: '',
      // lastupdateddateErr: '',

      // biography
      // citizenshipErr: '',
      // licensedregionsErr: '',
      areasofinterestErr: '',
      expertiseErr: '',
      // workemailErr: '',
      biographyErr: '',
      // Professional Membership
      organizationErr: '',
      // organizationtypeErr: '',
      // sourcelinkErr: '',
      positionErr: '',
      // tenureErr: '',
      //Education
      institutenameErr: '',
      // institutetypeErr: '',
      sourcelinkEducationErr: '',
      degreesErr: '',
      specialityEducationErr: '',
      // yearsattendedfromErr: '',
      // yearsattendedtoErr: '',
      // Advisory Board
      specialityAdvBoardErr: '',
      subspecialityAdvBoardErr: '',
      procedureAdvBoardErr: '',
      yearErr: '',
      companyAdvBoardErr: '',
      typeofCompanyErr: '',
      // advisoryboardErr: '',
      topicErr: '',
      roleAdvBoardErr: '',
      affiliationtypeAdvBoardErr: '',
      committeestatusAdvBoardErr: '',

      
      manualAdvBoardErr: '',
      // Awards and honours
      yearAwardsErr: '',
      awardsnhonourErr: '',
      // Books Monographs
      yearBooksErr: '',
      titleErr: '',
      authorErr: '',
      publicationErr: '',
      editorsErr: '',
      // Commitees
      yearCommiteeErr: '',
      commiteeErr: '',
      roleErr: '',
      // Clinical Trial Details
      specialityClinicalErr: '',
      subSpecialityClinicalErr: '',
      procedureClinicalErr: '',
      coverageErr: '',
      noStudyLocationErr: '',
      studydetailsErr: '',
      sourcelinkClinicalErr: '',
      startdateErr: '',
      enddateErr: '',
      phaseErr: '',
      roleClinicalErr: '',
      // roleClinicalErr: '',
      manualClinicaldErr: '',
      // Event Details
      specialityEventErr: '',
      subSpecialityEventErr: '',
      procedureEventErr: '',
      eventNameErr: '',
      eventTypeErr: '',
      // eventdescriptionErr: '',
      eventtopicErr: '',
      sourcelinkEventErr: '',
      // sessiontypeErr: '',
      roleEventsErr: '',
      manualAdvEventDetails: '',
      // Grants
      companyErr: '',
      titleGrantsErr: '',
      yearGrantsErr: '',
      // Guidelines
      specialityGuidelinesErr: '',
      subSpecialityGuidelinesErr: '',
      procedureGuidelinesErr: '',
      companyGuidelinesErr: '',
      typeOfCompanyGuidelinesErr: '',
      yearGuidelinesErr: '',
      titleGuidelinesErr: '',
      manualGuidelines: '',
      // Hospital Affiliations
      hospitalErr: '',
      countryHospitalErr: '',
      // Interactions
      dateInteractionErr: '',
      opinionleaderErr: '',
      enteredbyErr: '',
      producttopicErr: '',
      typeInteractionErr: '',
      modeInteractionErr: '',
      timeInteractionErr: '',
      // Press Item
      titlePressErr: '',
      sourcelinkPressErr: '',
      datePressErr: '',
      // Presentation
      titlePresentationErr: '',
      typePresentationErr: '',
      // sponsorPresentationErr: '',
      locationPresentationErr: '',
      // datePresentationErr: '',
      // Publication Details
      specialityPublicationErr: '',
      subSpecialityPublicationErr: '',
      procedurePublicationErr: '',
      typeOfJournelErr: '',
      journalErr: '',
      publicationtitleErr: '',
      datePublicationErr: '',
      sourcelinkPublicationErr: '',
      positionPublicationErr: '',
      coauthorPublicationErr: '',
      keywordsErr: '',
      manualPublication: '',
      // Sunshine Payment
      paymenttypeErr: '',
      specialitytypeSunshineErr: '',
      // Procedure potential
      procedurepotentialErr: '',

      // 
      // varialblesForCheckboxErr
      salutationCheck: '',
      doctorFullNameCheck: '',
      firstNameCheck: '',
      // middaleNameCheck: '',
      lastNameCheck: '',
      // qualificationChech: '',
      experienceCheck: '',
      // mediSchoolCheck: '',
      // mediRegiNoCheck: '',
      // NpiCheck: '',
      brancdCheck: '',
      procedureCheck: '',
      specialityCheck: '',
      subSpecialityCheck: '',
      doctorContactCheck: '',
      doctorEmailCheck: '',
      doctorEmail2Check: '',
      // linkedinProfileCheck: '',
      // twitterHandleCheck: '',
      // assistanceContactCheck: '',
      // assistaceEmailCheck: '',
      // websiteCheck: '',
      addressCheck: '',
      address2Check: '',
      // faxCheck: '',
      // stateCheck: '',
      counterCheck: '',
      procedurePotentialCheck: '',
      zipCheck: '',
      // contractCheck: '',

      // workditails
      placeofWorkCheck: '',
      designantionCheck: '',
      // departmentCheck: '',
      // fromYearWDCheck: '',
      // toYearWDCheck: '',

      // Biography
      // citizenshipCheck: '',
      // licensedCheck: '',
      areaInterestCheck: '',
      // languageCheck: '',
      // workPhoneCheck: '',
      // workEmailCheck: '',
      // linkedinFollowersCheck: '',
      // twitterFollowersCheck: '',
      biographyCheck: '',
      expertiseCheck: '',
      // resumeCheck: '',

      // Professional Membership
      organizationCheck: '',
      // organizationTypeCheck: '',
      // sourceLinkCheck: '',
      // locationCheck: '',
      positionCheck: '',
      // tenureCheck: '',
      // tenureToCheck: '',
      // organizationWebsiteCheck: '',

      // Education
      instituteNameCheck: '',
      // InstituteTypeCheck: '',
      sourceLinkEducationCheck: '',
      degreesCheck: '',
      specialityEducationCheck: '',
      // yearsAttendedCheck: '',

      // Advisory Board
      // yearCheck: '',
      // advisoryBoardCheck: '',
      // topicCheck: '',
      // specialityAdvisoryCheck: '',
      // subSpecialityAdvisoryCheck: '',
      // procedureTypeAdvisoryCheck: '',
      companyCheck: '',
      typeOfCompanyCheck: '',
      roleCheck: '',
      // manualWeigAdvBoardCheck: '',

      // Awards N Honours
      // yearAwardsCheck: '',
      // yearToAwardsCheck: '',
      awardHonoursCheck: '',
      // typesCheck: '',

      // Books Monographs
      yearBooksCheck: '',
      titleCheck: '',
      authorCheck: '',
      publicationCheck: '',
      // editionCheck: '',
      // pagesCheck: '',
      // editordCheck: '',

      // Commitees
      // yearCommiteeCheck: '',
      // commiteeCheck: '',
      bodyOrgNameCommCheck: '',
      roleCommiteeCheck: '',

      // Clinical Trial Details
      studyDetailsCheck: '',
      sourceLinkClinicalCheck: '',
      startDateCheck: '',
      endDateCheck: '',
      typeClinicalCheck: '',
      phaseCheck: '',
      roleClinicalCheck: '',
      // conditionCheck: '',
      // treatmentCheck: '',
      // primaryOutcomeCheck: '',
      // secondaryOutcomeCheck: '',
      // sponsorCheck: '',
      // collaboratorsCheck: '',
      // specilityClinicalCheck: '',
      // subSpecialityClinicalCheck: '',
      // procedureClinicalCheck: '',
      studyLocationCheck: '',
      coverageCheck: '',
      // manualWeigClinicalTrialCheck: '',

      // Event Details
      // eventDescCheck: '',
      eventTopicCheck: '',
      sourceLinkEventCheck: '',
      // conditionEventCheck: '',
      // interventationCheck: '',
      // dateCheck: '',
      // sessionTypeCheck: '',
      roleEventCheck: '',
      // eventSponsorCheck: '',
      // totalAttendeesCheck: '',
      // countryCheck: '',
      // specialityEventCheck: '',
      // subSpecialityEventCheck: '',
      // procedureEventCheck: '',
      eventCheck: '',
      eventTypeCheck: '',
      // manualWeigEventDetailsCheck: '',

      // Grants
      companyGrantsCheck: '',
      // titleGrantCheck: '',
      // yearGrantCheck: '',
      // amountCheck: '',
      // coInvestigatorCheck: '',

      // Guidelines
      yearGuidelinesCheck: '',
      comanyGuidelinesCheck: '',
      titleGuidelinesCheck: '',
      // specialityGuidelinesCheck: '',
      // subSpecialityGuidelinesCheck: '',
      // procedureGuidelinesCheck: '',
      organizationGuidelinesCheck: '',
      typeOrgnizationCheck: '',
      roleGuidelineCheck: '',
      GuidelinesaffiliationtypeGuidelineCheck:'',
      // manualWeighGuidelinesCheck: '',

      // Hospital Affiliations
      hospitalCheck: '',
      // cityCheck: '',
      // stateHospitalCheck: '',
      countryHospitalCheck: '',
      roleHospitalCheck: '',
      // fromYearHospitalCheck: '',
      // toYearHospitalCheck: '',

      // Interactions
      dateInteractionCheck: '',
      // opinionLeaderCheck: '',
      // enteredByCheck: '',
      // productCheck: '',
      // typeInteractionCheck: '',
      // modeCheck: '',
      timeCheck: '',
      timeZoneInteractionCheck: '',
      incentiveModeInteractionCheck: '',
      // activityCheck: '',
      // commentsCheck: '',

      // Press Item
      titlePressCheck: '',
      sourceLinkPressCheck: '',
      datePressCheck: '',
      // quotePressCheck: '',

      // Presentation
      titlePresentation: '',
      typePresentationCheck: '',
      // sponsorPresentationCheck: '',
      locationPresentationCheck: '',
      // datePresentationCheck: '',
      eventNamePresentationCheck: '',

      // Publication Details
      journalCheck: '',
      publicationTitleCheck: '',
      datePublicationCheck: '',
      sourceLinkPublicationCheck: '',
      positionPublicationCheck: '',
      coAutorCheck: '',
      keyWordsCheck: '',
      // specialityPublicationCheck: '',
      // subSpecialityPublicationCheck: '',
      // procedurePublicationCheck: '',
      typeOfJournelCheck: '',
      // manualWeighPublicationCheck: '',

      // SunshinePayment
      // dateSunshineCheck: '',
      // typeSunshineCheck: '',
      // deviceCheck: '',
      // locationSunshineCheck: '',
      // amountSunshineCheck: '',
      paymentTypeCheck: '',
      // OLAddressCheck: '',
      // specialityTypeCheck: '',
      // npiSunshineCheck: '',
      // 
      // 

      // Variables for add source
      SalutationAddSource: true,
      DFullNameAddSource: true,
      FirstNameAddSource: true,
      MiddleNameAddSource: true,
      LastNameAddSource: true,
      SuffixAddSource: true,
      QualificationAddSource: true,
      ExperienceAddSource: true,
      MedicalSchoolsAddSource: true,
      MedicalRegistrationNoAddSource: true,
      NPINoAddSource: true,
      PlaceOfWorkAddSourceFun: true,
      DesignationAddSource: true,
      BrandAddSource: true,
      DepartmentAddSource: true,
      FromYearAddSource: true,
      ToYearAddSource: true,
      SpecialityAddSource: true,
      Speciality2AddSource: true,
      ProcedureTypeAddSource: true,
      SubSpecialityTypeAddSource: true,
      DoctorContactNoAddSource: true,
      DoctorEmailAddSource: true,
      DoctorEmail2AddSource: true,
      FaxAddSource: true,
      LinkedInProfileAddSource: true,
      TwitterHandleAddSource: true,
      AssistanceContactNoAddSource: true,
      AssistanceEmailAddSource: true,
      WebsiteAddSource: true,
      CountryAddSource: true,
      StateAddSource: true,
      Address1AddSource: true,
      CityPEAddSource: true,
      Address2AddSource: true,

      // Biography
      CitizenshipAddSource: true,
      LicensedRegionsAddSource: true,
      AreasofInterestAddSource: true,
      expertiseAddSource: true,
      LanguageSpokenAddSource: true,
      WorkPhoneAddSource: true,
      WorkEmailAddSource: true,
      LinkedInFollowersAddSource: true,
      TwitterFollowersAddSource: true,
      BiographyFieldAddSource: true,

      // Professional Membership
      OrganizationAddSource: true,
      OrganizationTypeAddSource: true,
      SourceLinkAddSource: true,
      LocationAddSource: true,
      PositionAddSource: true,
      TenureAddSource: true,
      OrganizationWebsiteAddSource: true,
      DepartmentProfMemberAddSource: true,
      ProfileCountAddSource: true,

      // Education
      InstituteNameAddSource: true,
      InstituteTypeAddSource: true,
      SourceLinkEducationAddSource: true,
      DegreesAddSource: true,
      SpecialityEducationAddSource: true,
      YearsAttendedAddSource: true,

      // Advisory Borad
      SpecialityAdvBoardAddSource: true,
      SubSpecialityAdvBoardAddSource: true,
      ProcedureAdvBoardAddSource: true,
      YearAddSource: true,
      ToYearAdvBoardAddSource: true,
      CompanyOrgAddSource: true,
      TypeOfCompanyOrgAddSource: true,
      AdvisoryBoardSource: true,
      TopicSource: true,
      RoleAdvBoardAddSource: true,
      affiliationtypeAdvBoardAddSource: true,
      committeestatusAdvBoardAddSource: true,

      // Awards honours
      YearAwardSource: true,
      ToYearAwardSource: true,
      AwardsHonoursSource: true,
      AwardingOrganisationSource: true,
      TypesSource: true,

      // Books monographs
      YearBooksSource: true,
      titleSource: true,
      AuthorSource: true,
      PublicationSource: true,
      EditionSource: true,
      PagesSource: true,
      EditorsSource: true,

      // Commitees
      YearCommiteesSource: true,
      ToYearCommiteesSource: true,
      CommiteeSource: true,
      bodyOrgNameSource: true,
      RoleSource: true,

      // Clinical trial details
      SpecialityClinicalSource: true,
      SubSpecialityClinicalSource: true,
      ProcedureClinicalSource: true,
      StudyDetailsSource: true,
      SourceLinkClinicalTrialSource: true,
      StartDateClinicalTrialSource: true,
      EndDateClinicalTrialSource: true,
      TypeSource: true,
      PhaseSource: true,
      RoleClinicalTrialSource: true,
      CoverageClinicalTrialSource: true,
      NoStudyLocationClinicalTrialSource: true,
      conditionsSource: true,
      TreatmentInterventionSource: true,
      PrimaryOutcomeSource: true,
      SecondaryOutcomesSource: true,
      SponsorSource: true,
      CollaboratorsSource: true,

      // Event Details
      SpecialityEventSource: true,
      EventDescriptionSource: true,
      SubSpecialityEventSource: true,
      ProceduereEventSource: true,
      EventNameSource: true,
      EventTypeEventsSource: true,
      EventTopicSource: true,
      SourcelinEventkSource: true,
      ConditionsEventsSource: true,
      InterventionEventsSource: true,
      DateEventsSource: true,
      SessionTypeSource: true,
      RoleEventsSource: true,
      EventSponsorSource: true,
      TotalEventAttendeesSource: true,
      CountryEventsSource: true,

      // Grants
      CompanySource: true,
      TitleGrantsSource: true,
      YearGrantsSource: true,
      ToYearGrantsSource: true,
      AmountGrantsSource: true,
      CoInvestigatorSource: true,

      // Guidelines
      SpecialityGuidlinesSource: true,
      SubSpecialityGuidlinesSource: true,
      ProcedureGuidlinesSource: true,
      YearGuidlinesSource: true,
      OrganizationGuidlinesSource: true,
      CompanyOrganizationGuidlinesSource: true,
      TypeOfOrganizationGuidlinesSource: true,
      TitleGuidlinesSource: true,
      RoleGuidlinesSource: true,
      GuidelinesaffiliationtypeGuidlinesSource: true,

      // Hospital Affiliations
      HospitalSource: true,
      HospitalRoleSource: true,
      HospitalFromYearSource: true,
      HospitalToYearSource: true,
      CountryHospitalAffSource: true,
      StateHospitalAffSource: true,
      CityHospitalAffSource: true,
      DepartmentHospitalSource: true,

      // Interactions
      DateInteractionsSource: true,
      OpinionLeaderSource: true,
      EnteredBySource: true,
      ProductTopicSource: true,
      TypeInteractionSource: true,
      ModeInteractionSource: true,
      TimeInteractionSource: true,
      ActivityToolUsedSource: true,
      CommentsKeywordsSource: true,

      // Press Item
      TitlePressItemSource: true,
      QuotePressItemSource: true,
      SourceLinkPressItemSource: true,
      DatePressItemSource: true,

      // Presentation
      TitlePresentationSource: true,
      EventPresentationSource: true,
      TypePresentationSource: true,
      SponsorPresentationSource: true,
      LocationPresentationSource: true,
      DatePresentationSource: true,

      // Publication Details
      SpecialityPublicationSource: true,
      SubSpecialityPublicationSource: true,
      ProcedurePublicationSource: true,
      JournalSource: true,
      TypeOfJournelPublicationSource: true,
      PublicationTitleSource: true,
      DatePublicationSource: true,
      SourceLinkPublicationSource: true,
      PositionPublicationSource: true,
      CoAuthorPublicationSource: true,
      KeyWordsPublicationSource: true,

      // Sunshine Payment
      DateSunshineSource: true,
      TypeSunshineSource: true,
      DrugDevicesSource: true,
      LocationSunshineSource: true,
      AmountSunshineSource: true,
      PaymentTypeSunshineSource: true,
      OLAddressSunshineSource: true,
      SpecialitySunshineSource: true,
      NPISunshineSource: true,

      // Procedure Potential
      ProcedurePotentialSource: true,

      // vriales for error
      // doctoremailError: '',
    };
  }

  componentDidMount = async () => {
    let getSession_Manager_ID = await sessionStorage.getItem('id')
    if (getSession_Manager_ID !== null) {
      await this.setState({
        manager_id: getSession_Manager_ID,
      })
    }

    let getSession_Manager_Name = await sessionStorage.getItem('name')
    if (getSession_Manager_Name !== null) {
      await this.setState({
        manager_name: getSession_Manager_Name,
      })
    }

    let getSession_Project_Id = await sessionStorage.getItem('project_id')
    if (getSession_Project_Id !== null) {
      await this.setState({
        project_id: getSession_Project_Id,
      })
    }

    let getSession_Project_Name = await sessionStorage.getItem('p_name')
    if (getSession_Project_Name !== null) {
      await this.setState({
        p_name: getSession_Project_Name,
      })
    }

    let getSession_Kol_Unique_Id = await sessionStorage.getItem('kol_unique_id')
    if (getSession_Kol_Unique_Id !== null) {
      await this.setState({
        kol_unique_id: getSession_Kol_Unique_Id,
      })
    }

    console.log("getSession_Manager_ID ====", getSession_Manager_ID)
    console.log("getSession_Manager_Name ====", getSession_Manager_Name)
    console.log("getSession_Project_Id ====", getSession_Project_Id)
    console.log("getSession_Project_Name ====", getSession_Project_Name)
    console.log("getSession_Kol_Unique_Id ====", getSession_Kol_Unique_Id)

    this.setState({
      ShowBar: true,
    })

    const options = {
      method: 'POST',
    }

    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
        })
      })

    // Country List
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var TestCountryList = findresponse.Country_Details;
        // var TestKolIddata= findresponse.kol_system_id;
        console.log("TestCountryList", JSON.stringify(TestCountryList));
        // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
        this.setState({
          CountryListData: findresponse.Country_Details,
        })
      })

    // employee list
    fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var TestEmployeeData = findresponse.Employee_Details;
        console.log("TestEmployeeData", JSON.stringify(TestEmployeeData));
        this.setState({
          EmployeeListData: findresponse.Employee_Details,
        })
      })


    // const url = global.websiteUrl+'/service/Researcher/get_ResearcherList';
    const url = global.websiteUrl+'/service/KOL/get_KOLList';
    let formData = new FormData();
    // formData.append('kol_id','CHCUI0026');
    // formData.append('kol_id',this.props.location.state.kol_unique_id);
    formData.append('kol_id', getSession_Kol_Unique_Id);
    formData.append('project_id', getSession_Project_Id);
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata=====", JSON.stringify(lgdata));
        var KOL_Personal_Info = response.data.KOL_Personal_Info;
        console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
        var KOL_Work_Details = response.data.KOL_Work_Details;
        console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
        this.setState({
          PersonalInformation: KOL_Personal_Info,
          WorkDetails: response.data.KOL_Work_Details,
          KOl_Identifier: response.data.KOL_Identifiers,
          Biography: response.data.KOL_Biography,
          ProfessionalMemberships: response.data.KOL_Professional_Memberships,
          Education: response.data.KOL_Education,
          AdvisoryBoard: response.data.KOL_Advisory_Board,
          AwardsHonours: response.data.KOL_Awards_Honours,
          BooksMonographs: response.data.KOL_Books_Monographs,
          Commitees: response.data.KOL_Commitees,
          ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
          EventsDetails: response.data.KOL_Events_Details,
          Grants: response.data.KOL_Grants,
          Guidelines: response.data.KOL_Guidelines,
          HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
          Interactions: response.data.KOL_Interactions,
          PressItem: response.data.KOL_Press_Item,
          Presentation: response.data.KOL_Presentations,
          PublicationDetails: response.data.KOL_Publication_Details,
          SunshinePayment: response.data.KOL_Sunshine_Payment,

          profile_photo_for_edit: response.data.KOL_Profile_Photo,
          BiographyResume_edit: response.data.KOL_Resume,
          BiographyResumeFileName: response.data.KOL_Resume,

          profile_photo: response.data.KOL_Profile_Photo,
          // BiographyResume: response.data.KOL_Resume, 

        })
        this.setState({
          ShowBar: false,
        })
        console.log("PersonalInformation", this.state.PersonalInformation.doctor_full_name);
        console.log("Education", this.state.Education);
        console.log("WorkDetails===============", this.state.WorkDetails);
        console.log("BiographyResume========", this.state.BiographyResume);
        console.log("BiographyResumeFileName========", this.state.BiographyResumeFileName);

        // biography file name last only
        if (this.state.BiographyResumeFileName !== null) {
          console.log("in resume name ")
          var resumename = this.state.BiographyResumeFileName
          console.log('resumename::::::::::::::', resumename);
          var finalResumeName = resumename.substr(resumename.lastIndexOf('/') + 1);
          this.setState({
            FinalResumeName: finalResumeName,
          })
          console.log("finalResumeName", finalResumeName)
        }

        if (this.state.PersonalInformation.salutation_links != '') {
          this.setState({
            itemsSalutation: this.state.PersonalInformation.salutation_links.split(','),
          })
        }

        if (this.state.PersonalInformation.doctor_full_name_links != '') {
          this.setState({
            itemsDoctorFullName: this.state.PersonalInformation.doctor_full_name_links.split(','),
          })
        }

        if (this.state.PersonalInformation.first_name_links != '') {
          this.setState({
            itemsFirstName: this.state.PersonalInformation.first_name_links.split(','),
          })
        }

        if (this.state.PersonalInformation.middle_name_links != '') {
          this.setState({
            itemsMiddleName: this.state.PersonalInformation.middle_name_links.split(','),
          })
        }

        if (this.state.PersonalInformation.last_name_links != '') {
          this.setState({
            itemsLastName: this.state.PersonalInformation.last_name_links.split(','),
          })
        }

        if (this.state.PersonalInformation.suffix_links != '') {
          this.setState({
            itemsSuffix: this.state.PersonalInformation.suffix_links.split(','),
          })
        }

        if (this.state.PersonalInformation.qualification_links != '') {
          this.setState({
            itemsQualification: this.state.PersonalInformation.qualification_links.split(','),
          })
        }

        if (this.state.PersonalInformation.experience_links != '') {
          this.setState({
            itemsExperience: this.state.PersonalInformation.experience_links.split(','),
          })
        }

        if (this.state.PersonalInformation.medical_schools_links != '') {
          this.setState({
            itemsMedicalSchool: this.state.PersonalInformation.medical_schools_links.split(','),
          })
        }

        if (this.state.PersonalInformation.medical_registration_no_links != '') {
          this.setState({
            itemsMedicalRegisterNo: this.state.PersonalInformation.medical_registration_no_links.split(','),
          })
        }

        if (this.state.PersonalInformation.NPI_no_links != '') {
          this.setState({
            itemsNpiNo: this.state.PersonalInformation.NPI_no_links.split(','),
          })
        }

        // if( this.state.PersonalInformation.place_of_work_links != '' ){
        //     this.setState({
        //         itemsPlaceWork: this.state.PersonalInformation.place_of_work_links.split(','),
        //     })
        // }

        // if( this.state.PersonalInformation.designation_links != '' ){
        //     this.setState({
        //         itemsDesignation: this.state.PersonalInformation.designation_links.split(','),
        //     })
        // }

        if (this.state.PersonalInformation.brand_links != '') {
          this.setState({
            itemsBrand: this.state.PersonalInformation.brand_links.split(','),
          })
        }

        // if( this.state.PersonalInformation.department_links != '' ){
        //     this.setState({
        //         itemsDepartment: this.state.PersonalInformation.department_links.split(','),
        //     })
        // }

        if (this.state.PersonalInformation.specialty_links != '') {
          this.setState({
            itemSpeciality: this.state.PersonalInformation.specialty_links.split(','),
          })
        }

        if (this.state.PersonalInformation.specialty2_links != '') {
          this.setState({
            itemSpeciality2: this.state.PersonalInformation.specialty2_links.split(','),
          })
        }


        if (this.state.PersonalInformation.procedure_type_links != '') {
          this.setState({
            itemsProcedure: this.state.PersonalInformation.procedure_type_links.split(','),
          })
        }

        if (this.state.PersonalInformation.sub_specialty_links != '') {
          this.setState({
            itemsSubSpeciality: this.state.PersonalInformation.sub_specialty_links.split(','),
          })
        }

        if (this.state.PersonalInformation.doctor_contact_links != '') {
          this.setState({
            itemsDoctorContact: this.state.PersonalInformation.doctor_contact_links.split(','),
          })
        }

        if (this.state.PersonalInformation.doctor_email_links != '') {
          this.setState({
            itemsDoctorEmail: this.state.PersonalInformation.doctor_email_links.split(','),
          })
        }

        if (this.state.PersonalInformation.doctor_email2_links != '') {
          this.setState({
            itemsDoctorEmail2: this.state.PersonalInformation.doctor_email2_links.split(','),
          })
        }

        if (this.state.PersonalInformation.fax_links != '') {
          this.setState({
            itemsFax: this.state.PersonalInformation.fax_links.split(','),
          })
        }

        if (this.state.PersonalInformation.linked_in_profile_links != '') {
          this.setState({
            itemsLinkedIn: this.state.PersonalInformation.linked_in_profile_links.split(','),
          })
        }

        if (this.state.PersonalInformation.twitter_handle_links != '') {
          this.setState({
            itemsTwitter: this.state.PersonalInformation.twitter_handle_links.split(','),
          })
        }

        if (this.state.PersonalInformation.assistance_contact_no_links != '') {
          this.setState({
            itemsAssistanceContact: this.state.PersonalInformation.assistance_contact_no_links.split(','),
          })
        }

        if (this.state.PersonalInformation.assistance_email_links != '') {
          this.setState({
            itemsAssistanceEmail: this.state.PersonalInformation.assistance_email_links.split(','),
          })
        }

        if (this.state.PersonalInformation.website_links != '') {
          this.setState({
            itemsWebsite: this.state.PersonalInformation.website_links.split(','),
          })
        }

        if (this.state.PersonalInformation.country_links != '') {
          this.setState({
            itemsCountry: this.state.PersonalInformation.country_links.split(','),
          })
        }

        if (this.state.PersonalInformation.state_links != '') {
          this.setState({
            itemsState: this.state.PersonalInformation.state_links.split(','),
          })
        }

        if (this.state.PersonalInformation.city_links != '') {
          this.setState({
            itemsCity: this.state.PersonalInformation.city_links.split(','),
          })
        }

        if (this.state.PersonalInformation.address_links != '') {
          this.setState({
            itemsAddress1: this.state.PersonalInformation.address_links.split(','),
          })
        }

        if (this.state.PersonalInformation.address_2links != '') {
          this.setState({
            itemsAddress2: this.state.PersonalInformation.address_2links.split(','),
          })
        }


        if (this.state.PersonalInformation.procedure_potential_links != '') {
          this.setState({
            itemsProcedurePotential: this.state.PersonalInformation.procedure_potential_links.split(','),
          })
        }

        if (this.state.Biography.citizenship_links != '') {
          this.setState({
            itemsCitizenship: this.state.Biography.citizenship_links.split(','),
          })
        }

        if (this.state.Biography.licensed_regions_links != '') {
          this.setState({
            itemsLicensedRegions: this.state.Biography.licensed_regions_links.split(','),
          })
        }

        if (this.state.Biography.areas_of_interest_links != '') {
          this.setState({
            itemsAreasOfInterest: this.state.Biography.areas_of_interest_links.split(','),
          })
        }

       
        if (this.state.PersonalInformation.expertise_links != '') {
          this.setState({
            itemsexpertise: this.state.PersonalInformation.expertise_links.split(','),
          })
        }

        
        
        if (this.state.Biography.language_spoken_links != '') {
          this.setState({
            itemsLanguageSpoken: this.state.Biography.language_spoken_links.split(','),
          })
        }

        if (this.state.Biography.work_phone_links != '') {
          this.setState({
            itemsWorkPhone: this.state.Biography.work_phone_links.split(','),
          })
        }

        if (this.state.Biography.work_email_links != '') {
          this.setState({
            itemsWorkEmail: this.state.Biography.work_email_links.split(','),
          })
        }

        if (this.state.Biography.linkedin_followers_links != '') {
          this.setState({
            itemsLinkedInFolllowers: this.state.Biography.linkedin_followers_links.split(','),
          })
        }

        if (this.state.Biography.twitter_followers_links != '') {
          this.setState({
            itemsTwitterFollowers: this.state.Biography.twitter_followers_links.split(','),
          })
        }

        if (this.state.Biography.biography_links != '') {
          this.setState({
            itemsBiography: this.state.Biography.biography_links.split(','),
          })
        }

        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const url = global.websiteUrl+'/service/Location/get_StateList';
            let formData = new FormData();
            formData.append('country_id', this.state.PersonalInformation.country_id);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
              .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                this.setState({
                  StateData: response.data.Country_Details,
                  StateDataForHospitalAffiliations: response.data.Country_Details,
                })
                console.log("StateData======", this.state.StateData)
              })
              .catch(error => {
                console.log(error);
              });
          }
        }

        // if (KOL_Personal_Info.country_id) {
        //   if (KOL_Personal_Info.country_id !== '0') {
        //     const url = global.websiteUrl+'/service/Location/get_CityList';
        //     let formData = new FormData();
        //     formData.append('country_id', this.state.PersonalInformation.country_id);

        //     const config = {
        //       //   headers: { 'content-type': 'multipart/form-data' }
        //     }
        //     axios.post(url, formData, config)
        //       .then(response => {
        //         console.log(response);
        //         var testCityData = response.data.Country_Details;
        //         console.log("testCityData", JSON.stringify(testCityData));
        //         this.setState({
        //           CityData: response.data.Country_Details,
        //           CityDataForHospitalAffiliations: response.data.Country_Details,
        //         })
        //         console.log("CityData======", this.state.CityData)
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       });
        //   }
        // }
        //alert(this.state.PersonalInformation.specialty_id);
        if (KOL_Personal_Info.specialty_id) {
          console.log("in Procedure list");
          console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


          const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
          let formData = new FormData();
          formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
          formData.append('search_type', "Procedure");

          const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
          }
          axios.post(url, formData, config)
            .then(response => {
              console.log(response);
              var proceduredata = response.data;
              console.log("proceduredata", JSON.stringify(proceduredata));
              this.setState({
                ProcedureData: response.data.data,
                showProcedureSubSpecialityDropdown: false,
                showProcedureSubSpecialityDropdownAdvBoard: false,
              })
              console.log("ProcedureData", this.state.ProcedureData)
              this.setState({
                showProcedureSubSpecialityDropdown: true,
                showProcedureSubSpecialityDropdownAdvBoard: true,
              })
            })
            .catch(error => {
              console.log(error);
            });
        }

        if (KOL_Personal_Info.specialty_id) {
          console.log("in sub speciality list");
          const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
          let formData = new FormData();
          formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
          formData.append('search_type', "Sub Speciality");

          const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
          }
          axios.post(url, formData, config)
            .then(response => {
              console.log(response);
              var SubSpecialitydata = response.data;
              console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
              this.setState({
                SubSpecialitydata: response.data.data,
                showProcedureSubSpecialityDropdown: false,
              })
              console.log("SubSpecialitydata", this.state.SubSpecialitydata)
              this.setState({
                showProcedureSubSpecialityDropdown: true,
              })
            })
            .catch(error => {
              console.log(error);
            });
        }

        // to convert procedure id strings into array
        var procedureDefault = KOL_Personal_Info.procedure_type_id
        console.log("procedureDefault", procedureDefault);
        var arrayProcedureDefault = procedureDefault.split(',');
        console.log("arrayProcedureDefault", arrayProcedureDefault);

        arrayProcedureDefault.map(async (data) => {
          if (data !== "") {
            var joined = this.state.ProcedureType.concat(data);
            await this.setState({
              ProcedureType: joined
            });
            console.log("ProcedureTypeDefaultValues", this.state.ProcedureTypeDefaultValues)
          }
        })

        // to convert sub speciality id strings into array
        var subSpecialityDefault = KOL_Personal_Info.sub_specialty_id
        console.log("subSpecialityDefault", subSpecialityDefault);
        var arraysubSpecialityDefault = subSpecialityDefault.split(',');
        console.log("arraysubSpecialityDefault", arraysubSpecialityDefault);

        arraysubSpecialityDefault.map(async (data) => {
          if (data !== "") {
            var joined = this.state.SubSpecialityType.concat(data);
            await this.setState({
              SubSpecialityType: joined
            });
            console.log("SubSpecialityTypeDefaultValues", this.state.SubSpecialityTypeDefaultValues)
          }
        })


      })

  }

  validateCheckAll = () => {
    let salutationCheck = '';
    let doctorFullNameCheck = '';
    let firstNameCheck = '';
    // let middaleNameCheck= '';
    let lastNameCheck = '';
    let suffixCheck = '';
    // let qualificationChech= '';
    let experienceCheck = '';
    // let mediSchoolCheck= '';
    // let mediRegiNoCheck= '';
    // let NpiCheck= '';
    // let brancdCheck= '';
    let procedureCheck = '';
    let specialityCheck = '';
    let subSpecialityCheck = '';
    let doctorContactCheck = '';
    let doctorEmailCheck = '';
    let doctorEmail2Check = '';
    // let linkedinProfileCheck= '';
    // let twitterHandleCheck= '';
    // let assistanceContactCheck= '';
    // let assistaceEmailCheck= '';
    // let websiteCheck= '';
    let addressCheck = '';
    let address2Check = '';
    // let faxCheck= '';
    // let stateCheck = '';
    let counterCheck = '';
    let procedurePotentialCheck = '';
    let zipCheck = '';
    // let contractCheck= '';
    // workditails
    let placeofWorkCheck = '';
    let designantionCheck = '';
    // let departmentCheck = '';
    // let fromYearWDCheck  = '';
    // let toYearWDCheck = '';
    // Biography
    // let citizenshipCheck= '';
    // let licensedCheck= '';
    let areaInterestCheck = '';
    // let languageCheck= '';
    // let workPhoneCheck= '';
    // let workEmailCheck= '';
    // let linkedinFollowersCheck= '';
    // let twitterFollowersCheck= '';
    let biographyCheck = '';
    let expertiseCheck = '';
    // let resumeCheck= '';
    // Professional Membership
    let organizationCheck = '';

    // let organizationTypeCheck= '';
    // let sourceLinkCheck= '';
    // let locationCheck= '';
    let positionCheck = '';
    // let tenureCheck= '';
    // let tenureToCheck= '';
    // let organizationWebsiteCheck= '';

    // Education
    let instituteNameCheck = '';
    // let InstituteTypeCheck= '';
    let sourceLinkEducationCheck = '';
    let degreesCheck = '';
    let specialityEducationCheck = '';
    // let yearsAttendedCheck= '';

    // Advisory Board
    // let yearCheck= '';
    // let advisoryBoardCheck= '';
    // let topicCheck= '';
    // let specialityAdvisoryCheck = '';
    // let subSpecialityAdvisoryCheck = '';
    // let procedureTypeAdvisoryCheck = '';
    let companyCheck = '';
    let typeOfCompanyCheck = '';
    let roleCheck = '';
    // let manualWeigAdvBoardCheck = '';

    // Awards N Honours
    // let yearAwardsCheck = '';
    // let yearToAwardsCheck = '';
    let awardHonoursCheck = '';
    // let typesCheck= '';

    // Books Monographs
    let yearBooksCheck = '';
    let titleCheck = '';
    let authorCheck = '';
    let publicationCheck = '';
    // let editionCheck= '';
    // let pagesCheck= '';
    // let editordCheck= '';

    // Commitees
    // let yearCommiteeCheck= '';
    // let commiteeCheck = '';
    let bodyOrgNameCommCheck = '';
    let roleCommiteeCheck = '';

    // Clinical Trial Details
    let studyDetailsCheck = '';
    let sourceLinkClinicalCheck = '';
    let startDateCheck = '';
    let endDateCheck = '';
    let typeClinicalCheck = '';
    let phaseCheck = '';
    let roleClinicalCheck = '';
    // let conditionCheck= '';
    // let treatmentCheck= '';
    // let primaryOutcomeCheck= '';
    // let secondaryOutcomeCheck= '';
    // let sponsorCheck= '';
    // let collaboratorsCheck= '';
    // let specilityClinicalCheck = '';
    // let subSpecialityClinicalCheck = '';
    // let procedureClinicalCheck = '';
    let studyLocationCheck = '';
    let coverageCheck = '';
    // let manualWeigClinicalTrialCheck = '';

    // Event Details
    // let eventDescChec= '';
    let eventTopicCheck = '';
    let sourceLinkEventCheck = '';
    // let conditionEventCheck= '';
    // let interventationCheck= '';
    // let dateCheck= '';
    // let sessionTypeCheck= '';
    let roleEventCheck = '';
    // let eventSponsorCheck= '';
    // let totalAttendeesCheck= '';
    // let countryCheck= '';
    // let specialityEventCheck = '';
    // let subSpecialityEventCheck = '';
    // let procedureEventCheck = '';
    let eventCheck = '';
    let eventTypeCheck = '';
    // let manualWeigEventDetailsCheck = '';

    // Grants
    let companyGrantsCheck = '';
    // let titleGrantCheck = '';
    // let yearGrantCheck = '';
    // let amountCheck= '';
    // let coInvestigatorCheck= '';

    // Guidelines
    let yearGuidelinesCheck = '';
    let comanyGuidelinesCheck = '';
    let titleGuidelinesCheck = '';
    // let specialityGuidelinesCheck = '';
    // let subSpecialityGuidelinesCheck = '';
    // let procedureGuidelinesCheck = '';
    let organizationGuidelinesCheck = '';
    let typeOrgnizationCheck = '';
    let roleGuidelineCheck = '';

    let GuidelinesaffiliationtypeGuidelineCheck = '';

    // Hospital Affiliations
    let hospitalCheck = '';
    // let cityCheck= '';
    // let stateHospitalCheck= '';
    let countryHospitalCheck = '';
    let roleHospitalCheck = '';
    // let fromYearHospitalCheck = '';
    // let toYearHospitalCheck = '';

    // Interactions
    let dateInteractionCheck = '';
    // let opinionLeaderCheck= '';
    // let enteredByCheck= '';
    // let productCheck= '';
    // let typeInteractionCheck= '';
    // let modeCheck= '';
    let timeCheck = '';
    let timeZoneInteractionCheck = '';
    let incentiveModeInteractionCheck = '';
    // let activityCheck= '';
    // let commentsCheck= '';

    // Press Item
    let titlePressCheck = '';
    let sourceLinkPressCheck = '';
    let datePressCheck = '';
    // let quotePressCheck = '';

    // Presentation
    let titlePresentation = '';
    let typePresentationCheck = '';
    // let sponsorPresentationCheck= '';
    let locationPresentationCheck = '';
    // let datePresentationCheck= '';
    let eventNamePresentationCheck = '';

    // Publication Details
    let journalCheck = '';
    let publicationTitleCheck = '';
    let datePublicationCheck = '';
    let sourceLinkPublicationCheck = '';
    let positionPublicationCheck = '';
    let coAutorCheck = '';
    let keyWordsCheck = '';
    // let specialityPublicationCheck = '';
    // let subSpecialityPublicationCheck = '';
    // let procedurePublicationCheck = '';
    let typeOfJournelCheck = '';
    // let manualWeighPublicationCheck = '';

    // SunshinePayment
    // let dateSunshineCheck= '';
    // let typeSunshineCheck= '';
    // let deviceCheck= '';
    // let locationSunshineCheck= '';
    // let amountSunshineCheck= '';
    let paymentTypeCheck = '';
    // let OLAddressCheck= '';
    // let specialityTypeCheck = '';
    // let npiSunshineCheck= '';

    // Personal Information
    console.log("this.state.PersonalInformation.salutation_status==============", this.state.PersonalInformation.salutation_status)
    if (this.state.PersonalInformation.salutation_status !== true && this.state.PersonalInformation.salutation_status !== 'true') {
      salutationCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.doctor_full_name_status !== true && this.state.PersonalInformation.doctor_full_name_status !== 'true') {
      doctorFullNameCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.first_name_status !== true && this.state.PersonalInformation.first_name_status !== 'true') {
      firstNameCheck = 'Not Checked';
    }
    // if(this.state.PersonalInformation.middle_name_status !== true && this.state.PersonalInformation.middle_name_status !== 'true'){
    //     middaleNameCheck = 'Not Checked';
    // }
    if (this.state.PersonalInformation.last_name_status !== true && this.state.PersonalInformation.last_name_status !== 'true') {
      lastNameCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.suffix_status !== true && this.state.PersonalInformation.suffix_status !== 'true') {
      suffixCheck = 'Not Checked';
    }
    // if(this.state.PersonalInformation.qualification_status !== true && this.state.PersonalInformation.qualification_status !== 'true'){
    //     qualificationChech = 'Not Checked';
    // }
    if (this.state.PersonalInformation.experience_status !== true && this.state.PersonalInformation.experience_status !== 'true') {
      experienceCheck = 'Not Checked';
    }
    // if(this.state.PersonalInformation.medical_schools_status !== true && this.state.PersonalInformation.medical_schools_status !== 'true'){
    //     mediSchoolCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.medical_registration_no_status !== true && this.state.PersonalInformation.medical_registration_no_status !== 'true'){
    //     mediRegiNoCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.NPI_no_status !== true && this.state.PersonalInformation.NPI_no_status !== 'true'){
    //     NpiCheck = 'Not Checked';
    // }
    if (this.state.PersonalInformation.procedure_type_status !== true && this.state.PersonalInformation.procedure_type_status !== 'true') {
      procedureCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.specialty_status !== true && this.state.PersonalInformation.specialty_status !== 'true') {
      specialityCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.sub_specialty_status !== true && this.state.PersonalInformation.sub_specialty_status !== 'true') {
      subSpecialityCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.doctor_contact_status !== true && this.state.PersonalInformation.doctor_contact_status !== 'true') {
      doctorContactCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.doctor_email_status !== true && this.state.PersonalInformation.doctor_email_status !== 'true') {
      doctorEmailCheck = 'Not Checked';
    }

    
    if (this.state.PersonalInformation.doctor_email2_status !== true && this.state.PersonalInformation.doctor_email2_status !== 'true') {
      doctorEmail2Check = 'Not Checked';
    }
    // if(this.state.PersonalInformation.linked_in_profile_status !== true && this.state.PersonalInformation.linked_in_profile_status !== 'true'){
    //     linkedinProfileCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.twitter_handle_status !== true && this.state.PersonalInformation.twitter_handle_status !== 'true'){
    //     twitterHandleCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.assistance_contact_no_status !== true && this.state.PersonalInformation.assistance_contact_no_status !== 'true'){
    //     assistanceContactCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.assistance_email_status !== true && this.state.PersonalInformation.assistance_email_status !== 'true'){
    //     assistaceEmailCheck = 'Not Checked';
    // }
    // if(this.state.PersonalInformation.website_status !== true && this.state.PersonalInformation.website_status !== 'true'){
    //     websiteCheck = 'Not Checked';
    // }
    if (this.state.PersonalInformation.address_status !== true && this.state.PersonalInformation.address_status !== 'true') {
      addressCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.address_2status !== true && this.state.PersonalInformation.address_2status !== 'true') {
      address2Check = 'Not Checked';
    }
    // if(this.state.PersonalInformation.fax_status !== true && this.state.PersonalInformation.fax_status !== 'true'){
    //     faxCheck = 'Not Checked';
    // }
    // if (this.state.PersonalInformation.state_status !== true && this.state.PersonalInformation.state_status !== 'true') {
    //   stateCheck = 'Not Checked';
    // }
    if (this.state.PersonalInformation.country_status !== true && this.state.PersonalInformation.country_status !== 'true') {
      counterCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.procedure_potential_status !== true && this.state.PersonalInformation.procedure_potential_status !== 'true') {
      procedurePotentialCheck = 'Not Checked';
    }
    if (this.state.PersonalInformation.zip_code_status !== true && this.state.PersonalInformation.zip_code_status !== 'true') {
      zipCheck = 'Not Checked';
    }
    // if(this.state.PersonalInformation.contract_status !== true && this.state.PersonalInformation.contract_status !== 'true'){
    //     contractCheck = 'Not Checked';
    // }
    // // Work Details
    {
      this.state.WorkDetails.map((el, i) => {

        if (el.current_place_of_work_status !== true && el.current_place_of_work_status !== 'true') {
          placeofWorkCheck = 'Not Checked';
        }
        if (el.designation_status !== true && el.designation_status !== 'true') {
          designantionCheck = 'Not Checked';
        }
        // if (el.department_status !== true && el.department_status !== 'true') {
        //   departmentCheck = 'Not Checked';
        // }
        // if (el.from_year_status !== true && el.from_year_status !== 'true') {
        //   fromYearWDCheck = 'Not Checked';
        // }
        // if (el.to_year_status !== true && el.to_year_status !== 'true') {
        //   toYearWDCheck  = 'Not Checked';
        // }
        console.log("WorkDetailsWorkDetailsWorkDetails", this.state.WorkDetails)
      })
    }
    // Biography
    // if(this.state.Biography.citizenship_status !== true && this.state.Biography.citizenship_status !== 'true'){
    //     citizenshipCheck = 'Not Checked';
    // }
    if (this.state.Biography.areas_of_interest_status !== true && this.state.Biography.areas_of_interest_status !== 'true') {
      areaInterestCheck = 'Not Checked';
    }
    // if(this.state.Biography.language_spoken_status !== true && this.state.Biography.language_spoken_status !== 'true'){
    //     languageCheck = 'Not Checked';
    // }
    // if(this.state.Biography.linkedin_followers_status !== true && this.state.Biography.linkedin_followers_status !== 'true'){
    //     linkedinFollowersCheck = 'Not Checked';
    // }
    // if(this.state.Biography.twitter_followers_status !== true && this.state.Biography.twitter_followers_status !== 'true'){
    //     twitterFollowersCheck = 'Not Checked';
    // }
    if (this.state.Biography.biography_status !== true && this.state.Biography.biography_status !== 'true') {
      biographyCheck = 'Not Checked';
    }

    if (this.state.PersonalInformation.expertise_status !== true && this.state.PersonalInformation.expertise_status !== 'true') {
      expertiseCheck = 'Not Checked';
    }

    // if(this.state.Biography.resume_status !== true && this.state.Biography.resume_status !== 'true'){
    //     resumeCheck = 'Not Checked';
    // }
    // ProfessionalMemberships
    {
      this.state.ProfessionalMemberships.map((el, i) => {
        if (el.organization_status !== 'true' && el.organization_status !== true) {
          organizationCheck = 'Not Checked';
        }
        // if(el.organization_type_status !== 'true' && el.organization_type_status !== true ){
        //     organizationTypeCheck = 'Not Checked';
        // }
        // if(el.source_link_status !== 'true' && el.source_link_status !== true){
        //     sourceLinkCheck = 'Not Checked';
        // }
        // if(el.location_status !== 'true' && el.location_status !== true){
        //     locationCheck = 'Not Checked';
        // }
        if (el.position_status !== 'true' && el.position_status !== true) {
          positionCheck = 'Not Checked';
        }
        // if(el.tenure_status !== 'true' && el.tenure_status !== true){
        //     tenureCheck = 'Not Checked';
        // }
        // if(el.tenure1_status !== 'true' && el.tenure1_status !== true){
        //     tenureToCheck = 'Not Checked';
        // }
        // if(el.organization_website_status !== 'true' && el.organization_website_status !== true){
        //     organizationWebsiteCheck = 'Not Checked';
        // }   
      })
    }
    // Education
    {
      this.state.Education.map((el, i) => {
        if (el.institute_name_status !== true && el.institute_name_status !== 'true') {
          instituteNameCheck = 'Not Checked';
        }
        // if(el.institute_type_status !== true && el.institute_type_status !== 'true'){
        //     InstituteTypeCheck = 'Not Checked';
        // } 
        if (el.source_link_status !== true && el.source_link_status !== 'true') {
          sourceLinkEducationCheck = 'Not Checked';
        }
        if (el.degrees_status !== true && el.degrees_status !== 'true') {
          degreesCheck = 'Not Checked';
        }
        if (el.speciality_status !== true && el.speciality_status !== 'true') {
          specialityEducationCheck = 'Not Checked';
        }
        // if(el.years_attended_staus !== true && el.years_attended_staus !== 'true'){
        //     yearsAttendedCheck = 'Not Checked';
        // } 
      })
    }
    // AdvisoryBoard
    {
      this.state.AdvisoryBoard.map((el, i) => {
        // if(el.year_status !== true  && el.year_status !== 'true'){
        //     yearCheck = 'Not Checked';
        // } 
        // if(el.advisory_board_status !== true && el.advisory_board_status !== 'true'){
        //     advisoryBoardCheck = 'Not Checked';
        // } 
        // if(el.topic_status !== true && el.topic_status !== 'true'){
        //     topicCheck = 'Not Checked';
        // } 
        // if (el.speciality_status !== true && el.speciality_status !== 'true') {
        //   specialityAdvisoryCheck = 'Not Checked';
        // }
        // if (el.sub_speciality_status !== true && el.sub_speciality_status !== 'true') {
        //   subSpecialityAdvisoryCheck = 'Not Checked';
        // }
        // if (el.procedure_type_status !== true && el.procedure_type_status !== 'true') {
        //   procedureTypeAdvisoryCheck = 'Not Checked';
        // }
        if (el.company_status !== true && el.company_status !== 'true') {
          companyCheck = 'Not Checked';
        }
        if (el.type_of_company_status !== true && el.type_of_company_status !== 'true') {
          typeOfCompanyCheck = 'Not Checked';
        }
        if (el.role_status !== true && el.role_status !== 'true') {
          roleCheck = 'Not Checked';
        }
        // if (el.manual_weightage_status !== true && el.manual_weightage_status !== 'true') {
        //   manualWeigAdvBoardCheck = 'Not Checked';
        // }
      })
    }
    // AwardsHonours
    {
      this.state.AwardsHonours.map((el, i) => {
        // if (el.year_status !== true && el.year_status !== 'true') {
        //   yearAwardsCheck = 'Not Checked';
        // }
        // if (el.to_year_status !== true && el.to_year_status !== 'true') {
        //   yearToAwardsCheck = 'Not Checked';
        // }
        if (el.awards_honours_status !== true && el.awards_honours_status !== 'true') {
          awardHonoursCheck = 'Not Checked';
        }
        // if(el.types_status !== true && el.types_status !== 'true'){
        //     typesCheck = 'Not Checked';
        // } 
      })
    }
    // BooksMonographs
    {
      this.state.BooksMonographs.map((el, i) => {
        if (el.year_status !== true && el.year_status !== 'true') {
          yearBooksCheck = 'Not Checked';
        }
        if (el.title_status !== true && el.title_status !== 'true') {
          titleCheck = 'Not Checked';
        }
        if (el.author_status !== true && el.author_status !== 'true') {
          authorCheck = 'Not Checked';
        }
        if (el.publication_status !== true && el.publication_status !== 'true') {
          publicationCheck = 'Not Checked';
        }
        // if(el.edition_status !== true && el.edition_status !== 'true'){
        //     editionCheck = 'Not Checked';
        // }
        // if(el.pages_status !== true && el.pages_status !== 'true'){
        //     pagesCheck = 'Not Checked';
        // }
        // if(el.editors_status !== true && el.editors_status !== 'true'){
        //     editordCheck = 'Not Checked';
        // }
      })
    }
    // Commitees
    {
      this.state.Commitees.map((el, i) => {
        // if(el.year_status !== true && el.year_status !== 'true'){
        //     yearCommiteeCheck = 'Not Checked';
        // }
        // if (el.committee_status !== true && el.committee_status !== 'true') {
        //   commiteeCheck = 'Not Checked';
        // }
        if (el.body_org_name_status !== true && el.body_org_name_status !== 'true') {
          bodyOrgNameCommCheck = 'Not Checked';
        }
        if (el.role_status !== true && el.role_status !== 'true') {
          roleCommiteeCheck = 'Not Checked';
        }
      })
    }
    // ClinicalTrialDetails
    {
      // if (this.state.KOLType !== 'Hospital Procurement') {
      if (this.state.PersonalInformation.kol_type !== 'Hospital Procurement') {
        this.state.ClinicalTrialDetails.map((el, i) => {
          if (el.study_details_status !== true && el.study_details_status !== 'true') {
            studyDetailsCheck = 'Not Checked';
          }
          if (el.source_link_status !== true && el.source_link_status !== 'true') {
            sourceLinkClinicalCheck = 'Not Checked';
          }
          if (el.start_date_status !== true && el.start_date_status !== 'true') {
            startDateCheck = 'Not Checked';
          }
          if (el.end_date_status !== true && el.end_date_status !== 'true') {
            endDateCheck = 'Not Checked';
          }
          if (el.type_status !== true && el.type_status !== 'true') {
            typeClinicalCheck = 'Not Checked';
          }
          if (el.phase_status !== true && el.phase_status !== 'true') {
            phaseCheck = 'Not Checked';
          }
          if (el.role_status !== true && el.role_status !== 'true') {
            roleClinicalCheck = 'Not Checked';
          }
          // if(el.conditions_status !== true && el.conditions_status !== 'true'){
          //     conditionCheck = 'Not Checked';
          // }
          // if(el.treatment_intervention_status !== true && el.treatment_intervention_status !== 'true'){
          //     treatmentCheck = 'Not Checked';
          // }
          // if(el.primary_outcomes_status !== true && el.primary_outcomes_status !== 'true'){
          //     primaryOutcomeCheck = 'Not Checked';
          // }
          // if(el.secondary_outcomes_status !== true && el.secondary_outcomes_status !== 'true' ){
          //     secondaryOutcomeCheck = 'Not Checked';
          // }
          // if(el.sponsors_status !== true && el.sponsors_status !== 'true'){
          //     sponsorCheck = 'Not Checked';
          // }
          // if(el.collaborators_status !== true && el.collaborators_status !== 'true'){
          //     collaboratorsCheck = 'Not Checked';
          // }
          // if (el.speciality_status !== true && el.speciality_status !== 'true') {
          //   specilityClinicalCheck = 'Not Checked';
          // }
          // if (el.sub_speciality_status !== true && el.sub_speciality_status !== 'true') {
          //   subSpecialityClinicalCheck = 'Not Checked';
          // }
          // if (el.procedure_type_status !== true && el.procedure_type_status !== 'true') {
          //   procedureClinicalCheck = 'Not Checked';
          // }
          if (el.coverage_status !== true && el.coverage_status !== 'true') {
            coverageCheck = 'Not Checked';
          }
          if (el.no_of_study_locations_status !== true && el.no_of_study_locations_status !== 'true') {
            studyLocationCheck = 'Not Checked';
          }
          // if (el.manual_weightage_status !== true && el.manual_weightage_status !== 'true') {
          //   manualWeigClinicalTrialCheck = 'Not Checked';
          // }
        })
      }

    }
    // EventsDetails
    {
      this.state.EventsDetails.map((el, i) => {
        // if(el.event_desc_status !== true && el.event_desc_status !== 'true'){
        //     eventDescChec = 'Not Checked';
        // }
        if (el.event_topic_status !== true && el.event_topic_status !== 'true') {
          eventTopicCheck = 'Not Checked';
        }
        if (el.source_link_status !== true && el.source_link_status !== 'true') {
          sourceLinkEventCheck = 'Not Checked';
        }
        // if(el.conditions_status !== true && el.conditions_status !== 'true'){
        //     conditionEventCheck = 'Not Checked';
        // }
        // if(el.intervention_status !== true && el.intervention_status !== 'true'){
        //     interventationCheck = 'Not Checked';
        // }
        // if(el.date_status !== true && el.date_status !== 'true'){
        //     dateCheck = 'Not Checked';
        // }
        // if(el.session_type_status !== true && el.session_type_status !== 'true'){
        //     sessionTypeCheck = 'Not Checked';
        // }
        if (el.role_status !== true && el.role_status !== 'true') {
          roleEventCheck = 'Not Checked';
        }
        // if(el.event_sponsor_status !== true && el.event_sponsor_status !== 'true'){
        //     eventSponsorCheck = 'Not Checked';
        // }
        // if(el.total_event_attendees_status !== true &&  el.total_event_attendees_status !== 'true'){
        //     totalAttendeesCheck = 'Not Checked';
        // }
        // if(el.country_status !== true && el.country_status !== 'true'){
        //     countryCheck = 'Not Checked';
        // }
        // if (el.speciality_status !== true && el.speciality_status !== 'true') {
        //   specialityEventCheck = 'Not Checked';
        // }
        // if (el.sub_speciality_status !== true && el.sub_speciality_status !== 'true') {
        //   subSpecialityEventCheck = 'Not Checked';
        // }
        // if (el.procedure_type_status !== true && el.procedure_type_status !== 'true') {
        //   procedureEventCheck = 'Not Checked';
        // }
        if (el.events_status !== true && el.events_status !== 'true') {
          eventCheck = 'Not Checked';
        }
        if (el.event_type_status !== true && el.event_type_status !== 'true') {
          eventTypeCheck = 'Not Checked';
        }
        // if (el.manual_weightage_status !== true && el.manual_weightage_status !== 'true') {
        //   manualWeigEventDetailsCheck = 'Not Checked';
        // }
      })
    }
    // Grants
    {
      this.state.Grants.map((el, i) => {
        if (el.company_status !== true && el.company_status !== 'true') {
          companyGrantsCheck = 'Not Checked';
        }
        // if (el.title_status !== true && el.title_status !== 'true') {
        //   titleGrantCheck = 'Not Checked';
        // }
        // if (el.year_status !== true && el.year_status !== 'true') {
        //   yearGrantCheck = 'Not Checked';
        // }
        // if(el.amount_status !== true && el.amount_status !== 'true'){
        //     amountCheck = 'Not Checked';
        // }
        // if(el.co_investigator_status !== true && el.co_investigator_status !== 'true'){
        //     coInvestigatorCheck = 'Not Checked';
        // }
      })
    }
    // Guidelines
    {
      // if (this.state.KOLType !== 'Hospital Procurement') {
      if (this.state.PersonalInformation.kol_type !== 'Hospital Procurement') {
        this.state.Guidelines.map((el, i) => {
          if (el.year_status !== true && el.year_status !== 'true') {
            yearGuidelinesCheck = 'Not Checked';
          }
          // if(el.company_status !== true && el.company_status !== 'true'){
          //     comanyGuidelinesCheck = 'Not Checked';
          // }
          if (el.title_status !== true && el.title_status !== 'true') {
            titleGuidelinesCheck = 'Not Checked';
          }
          // if (el.speciality_status !== true && el.speciality_status !== 'true') {
          //   specialityGuidelinesCheck = 'Not Checked';
          // }
          // if (el.sub_speciality_status !== true && el.sub_speciality_status !== 'true') {
          //   subSpecialityGuidelinesCheck = 'Not Checked';
          // }
          // if (el.procedure_type_status !== true && el.procedure_type_status !== 'true') {
          //   procedureGuidelinesCheck = 'Not Checked';
          // }
          if (el.organization_status !== true && el.organization_status !== 'true') {
            organizationGuidelinesCheck = 'Not Checked';
          }
          if (el.type_of_organization_status !== true && el.type_of_organization_status !== 'true') {
            typeOrgnizationCheck = 'Not Checked';
          }
          if (el.role_status !== true && el.role_status !== 'true') {
            roleGuidelineCheck = 'Not Checked';
          }

          if (el.Guidelinesaffiliationtype_status !== true && el.Guidelinesaffiliationtype_status !== 'true') {
            GuidelinesaffiliationtypeGuidelineCheck = 'Not Checked';
          }
          // if (el.manual_weightage_status !== true && el.manual_weightage_status !== 'true') {
          //   manualWeighGuidelinesCheck = 'Not Checked';
          // }
        })
      }
    }
    // HospitalAffiliations
    {
      this.state.HospitalAffiliations.map((el, i) => {
        if (el.hospital_status !== true && el.hospital_status !== 'true') {
          hospitalCheck = 'Not Checked';
        }
        // if(el.city_status !== true && el.city_status !== 'true'){
        //     cityCheck = 'Not Checked';
        // }
        // if(el.state_status !== true && el.state_status !== 'true'){
        //     stateHospitalCheck = 'Not Checked';
        // }
        if (el.country_status !== true && el.country_status !== 'true') {
          countryHospitalCheck = 'Not Checked';
        }
        if (el.role_status !== true && el.role_status !== 'true') {
          roleHospitalCheck  = 'Not Checked';
        }
        // if (el.from_year_status !== true && el.from_year_status !== 'true') {
        //   fromYearHospitalCheck   = 'Not Checked';
        // }
        // if (el.to_year_status !== true && el.to_year_status !== 'true') {
        //   toYearHospitalCheck    = 'Not Checked';
        // }
      })
    }
    // Interactions
    {
      this.state.Interactions.map((el, i) => {
        if (el.date_status !== true && el.date_status !== 'true') {
          dateInteractionCheck = 'Not Checked';
        }
        // if(el.opinion_leader_status !== true && el.opinion_leader_status !== 'true'){
        //     opinionLeaderCheck = 'Not Checked';
        // }
        // if(el.entered_by_status !== true && el.entered_by_status !== 'true'){
        //     enteredByCheck = 'Not Checked';
        // }
        // if(el.product_status !== true && el.product_status !== 'true'){
        //     productCheck = 'Not Checked';
        // }
        // if(el.type_status !== true && el.type_status !== 'true'){
        //     typeInteractionCheck = 'Not Checked';
        // }
        // if(el.mode_status !== true && el.mode_status !== 'true'){
        //     modeCheck = 'Not Checked';
        // }
        if (el.time_status !== true && el.time_status !== 'true') {
          timeCheck = 'Not Checked';
        }
        if (el.timezone_status !== true && el.timezone_status !== 'true') {
          timeZoneInteractionCheck = 'Not Checked';
        }
        if (el.incentive_mode_status !== true && el.incentive_mode_status !== 'true') {
          incentiveModeInteractionCheck = 'Not Checked';
        }
        // if(el.activity_or_tool_used_status !== true && el.activity_or_tool_used_status !== 'true' ){
        //     activityCheck = 'Not Checked';
        // }
        // if(el.comments_status !== true && el.comments_status !== 'true'){
        //     commentsCheck = 'Not Checked';
        // }
      })
    }
    // PressItem
    {
      if (this.state.PersonalInformation.kol_type == 'KOL Community' || 
        this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
        this.state.PersonalInformation.kol_type == 'Advocacy Group') {
        this.state.PressItem.map((el, i) => {
          console.log(" in press item check ")
          if (el.title_status !== true && el.title_status !== 'true') {
            titlePressCheck = 'Not Checked';
          }
          if (el.source_link_status !== true && el.source_link_status !== 'true') {
            sourceLinkPressCheck = 'Not Checked';
          }
          if (el.date_status !== true && el.date_status !== 'true') {
            datePressCheck = 'Not Checked';
          }
          // if (el.quote_status !== true && el.quote_status !== 'true') {
          //   quotePressCheck = 'Not Checked';
          // }
          
        })
      }
    }
    // Presentation
    {
      if (this.state.PersonalInformation.kol_type == 'KOL Community') {
        console.log(" in Presentation item check ")
        this.state.Presentation.map((el, i) => {
          if (el.title_status !== true && el.title_status !== 'true') {
            titlePresentation = 'Not Checked';
          }
          if (el.type_status !== true && el.type_status !== 'true') {
            typePresentationCheck = 'Not Checked';
          }
          // if(el.sponsor_status !== true && el.sponsor_status !== 'true'){
          //     sponsorPresentationCheck = 'Not Checked';
          // }
          if (el.location_status !== true && el.location_status !== 'true') {
            locationPresentationCheck = 'Not Checked';
          }
          // if(el.date_status !== true && el.date_status !== 'true'){
          //     datePresentationCheck = 'Not Checked';
          // }
          if (el.event_name_status !== true && el.event_name_status !== 'true') {
            eventNamePresentationCheck = 'Not Checked';
          }
        })
      }
    }
    // PublicationDetails
    {
      this.state.PublicationDetails.map((el, i) => {
        if (el.journal_status !== true && el.journal_status !== 'true') {
          journalCheck = 'Not Checked';
        }
        if (el.publication_title_status !== true && el.publication_title_status !== 'true') {
          publicationTitleCheck = 'Not Checked';
        }
        if (el.date_status !== true && el.date_status !== 'true') {
          datePublicationCheck = 'Not Checked';
        }
        if (el.source_link_status !== true && el.source_link_status !== 'true') {
          sourceLinkPublicationCheck = 'Not Checked';
        }
        if (el.position_status !== true && el.position_status !== 'true') {
          positionPublicationCheck = 'Not Checked';
        }
        if (el.co_author_status !== true && el.co_author_status !== 'true') {
          coAutorCheck = 'Not Checked';
        }
        if (el.key_words_status !== true && el.key_words_status !== 'true') {
          keyWordsCheck = 'Not Checked';
        }
        // if (el.speciality_status !== true && el.speciality_status !== 'true') {
        //   specialityPublicationCheck = 'Not Checked';
        // }
        // if (el.sub_speciality_status !== true && el.sub_speciality_status !== 'true') {
        //   subSpecialityPublicationCheck = 'Not Checked';
        // }
        // if (el.procedure_type_status !== true && el.procedure_type_status !== 'true') {
        //   procedurePublicationCheck = 'Not Checked';
        // }
        if (el.type_of_journal_status !== true && el.type_of_journal_status !== 'true') {
          typeOfJournelCheck = 'Not Checked';
        }
        // if (el.manual_weightage_status !== true && el.manual_weightage_status !== 'true') {
        //   manualWeighPublicationCheck = 'Not Checked';
        // }
      })
    }
    // SunshinePayment
    {
      this.state.SunshinePayment.map((el, i) => {
        // if(el.date_status !== true && el.date_status !== 'true'){
        //     dateSunshineCheck = 'Not Checked';
        // }
        // if(el.type_status !== true && el.type_status !== 'true'){
        //     typeSunshineCheck = 'Not Checked';
        // }
        // if(el.devices_status !== true && el.devices_status !== 'true'){
        //     deviceCheck = 'Not Checked';
        // }
        // if(el.location_status !== true && el.location_status !== 'true'){
        //     locationSunshineCheck = 'Not Checked';
        // }
        // if(el.amount_status !== true && el.amount_status !== 'true' ){
        //     amountSunshineCheck = 'Not Checked';
        // }
        if (el.payment_type_status !== true && el.payment_type_status !== 'true') {
          paymentTypeCheck = 'Not Checked';
        }
        // if(el.OL_address_status !== true && el.OL_address_status !== 'true' ){
        //     OLAddressCheck = 'Not Checked';
        // }
        // if (el.speciality_type_status !== true && el.speciality_type_status !== 'true') {
        //   specialityTypeCheck = 'Not Checked';
        // }
        // if(el.NPI_status !== true && el.NPI_status !== 'true'){
        //     npiSunshineCheck = 'Not Checked';
        // }
      })
    }

    if (salutationCheck || doctorFullNameCheck || firstNameCheck || lastNameCheck || suffixCheck ||
      experienceCheck || procedureCheck || specialityCheck ||
      subSpecialityCheck || doctorContactCheck || doctorEmailCheck || doctorEmail2Check ||
      addressCheck || address2Check ||
      counterCheck || procedurePotentialCheck || zipCheck ||
      placeofWorkCheck || designantionCheck ||
      areaInterestCheck || biographyCheck || expertiseCheck ||
      organizationCheck || positionCheck ||
      instituteNameCheck || sourceLinkEducationCheck || degreesCheck || specialityEducationCheck ||
      companyCheck ||
      typeOfCompanyCheck || roleCheck ||
      awardHonoursCheck ||
      yearBooksCheck || titleCheck || authorCheck || publicationCheck ||
      bodyOrgNameCommCheck || roleCommiteeCheck ||
      studyDetailsCheck || sourceLinkClinicalCheck || startDateCheck || endDateCheck || typeClinicalCheck || phaseCheck || roleClinicalCheck ||
      studyLocationCheck || 
      coverageCheck ||
      eventTopicCheck || sourceLinkEventCheck ||
      roleEventCheck || eventCheck || eventTypeCheck ||
      companyGrantsCheck ||
      yearGuidelinesCheck || comanyGuidelinesCheck || titleGuidelinesCheck ||
      organizationGuidelinesCheck || typeOrgnizationCheck || roleGuidelineCheck || GuidelinesaffiliationtypeGuidelineCheck ||
      hospitalCheck || countryHospitalCheck || roleHospitalCheck ||
      dateInteractionCheck ||
      timeCheck || timeZoneInteractionCheck || incentiveModeInteractionCheck ||
      titlePressCheck || sourceLinkPressCheck || datePressCheck || 
      titlePresentation || typePresentationCheck || locationPresentationCheck || eventNamePresentationCheck ||
      journalCheck || publicationTitleCheck || datePublicationCheck || sourceLinkPublicationCheck || positionPublicationCheck ||
      coAutorCheck || keyWordsCheck ||
      typeOfJournelCheck ||
      paymentTypeCheck
    ) {
      this.setState({
        salutationCheck, doctorFullNameCheck, firstNameCheck, lastNameCheck, suffixCheck,
        experienceCheck, procedureCheck, specialityCheck,
        subSpecialityCheck, doctorContactCheck, doctorEmailCheck,doctorEmail2Check,
        addressCheck, address2Check,
        counterCheck, procedurePotentialCheck, zipCheck,
        placeofWorkCheck, designantionCheck,
        areaInterestCheck, biographyCheck, expertiseCheck,
        organizationCheck, positionCheck,
        instituteNameCheck, sourceLinkEducationCheck, degreesCheck, specialityEducationCheck,
        companyCheck,
        typeOfCompanyCheck, roleCheck,
        awardHonoursCheck,
        yearBooksCheck, titleCheck, authorCheck, publicationCheck,
        bodyOrgNameCommCheck, roleCommiteeCheck,
        studyDetailsCheck, sourceLinkClinicalCheck, startDateCheck, endDateCheck, typeClinicalCheck, phaseCheck, roleClinicalCheck,
        studyLocationCheck, 
        coverageCheck,
        eventTopicCheck, sourceLinkEventCheck,
        roleEventCheck, eventCheck, eventTypeCheck,
        companyGrantsCheck,
        yearGuidelinesCheck, comanyGuidelinesCheck, titleGuidelinesCheck,
        organizationGuidelinesCheck, typeOrgnizationCheck, roleGuidelineCheck,GuidelinesaffiliationtypeGuidelineCheck,
        hospitalCheck, countryHospitalCheck, roleHospitalCheck,
        dateInteractionCheck,
        timeCheck, timeZoneInteractionCheck, incentiveModeInteractionCheck,
        titlePressCheck, sourceLinkPressCheck, datePressCheck,
        titlePresentation, typePresentationCheck, locationPresentationCheck, eventNamePresentationCheck,
        journalCheck, publicationTitleCheck, datePublicationCheck, sourceLinkPublicationCheck, positionPublicationCheck,
        coAutorCheck, keyWordsCheck,
        typeOfJournelCheck,
        paymentTypeCheck
      });
      return false;
    }
    return true;


  }

  getPhoto = event => {
    event.preventDefault();
    console.log("photo event", event.target.files[0])

    if (event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];
      // this.setState({file: e.target.files[0],})
      console.log("file my", this.state.profile_photo);
      reader.onloadend = () => {
        this.setState({
          profile_photo: file,
          imagePreviewUrl: reader.result,
          profile_photo_for_edit: '',
        });
      }
      reader.readAsDataURL(file);
    }


  }

  // Function for drawer side selection
  PersonalInformationFun = async () => {
    await this.setState({
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      PersonalInformationFlag: true,
    })
  }

  BiographyFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      BiographyFlag: true,
    })
  }

  ProfessionalMembershipFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      ProfessionalMembershipFlag: true,
    })
  }

  EducationFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      EducationFlag: true,
    })
  }

  AdvisoryBoardFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      AdvisoryBoardFlag: true,
    })
  }

  AwardsAndHonoursFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      AwardsAndHonoursFlag: true,
    })
  }

  BooksAndMonographFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      BooksAndMonographFlag: true,
    })
  }

  CommiteesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      CommiteesFlag: true,
    })
  }

  ClinicalTrialDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      ClinicalTrialDetailsFlag: true,
    })
  }

  EventsDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      EventsDetailsFlag: true,
    })
  }

  GrantsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      GrantsFlag: true,
    })
  }

  GuidelinesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      GuidelinesFlag: true,
    })
  }

  HospitalAffiliationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      HospitalAffiliationsFlag: true,
    })
  }

  InteractionsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      InteractionsFlag: true,
    })
  }

  PressItemFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      PressItemFlag: true,
    })
  }

  PresentationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      PresentationsFlag: true,
    })
  }

  PublicationDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: false,
      PublicationDetailsFlag: true,
    })
  }

  SunshinePaymentFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      ProcedurePotentialFlag: false,
      SunshinePaymentFlag: true,
      ContractFlag: false,
    })
  }

  ContractFunc = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      ProcedurePotentialFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: true,
    })
  }

  ProcedurePotentialFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ContractFlag: false,
      ProcedurePotentialFlag: true,
    })
  }

  // Functions for add source variable toggle
  SalutaionAddSourceFun = async () => {
    await this.setState({
      SalutationAddSource: !this.state.SalutationAddSource,
    })
  }

  DFullNameAddSourceFun = async () => {
    await this.setState({
      DFullNameAddSource: !this.state.DFullNameAddSource,
    })
  }

  FirstNameAddSourceFun = async () => {
    await this.setState({
      FirstNameAddSource: !this.state.FirstNameAddSource,
    })
  }

  MiddleNameAddSourceFun = async () => {
    await this.setState({
      MiddleNameAddSource: !this.state.MiddleNameAddSource,
    })
  }

  LastNameAddSourceFun = async () => {
    await this.setState({
      LastNameAddSource: !this.state.LastNameAddSource,
    })
  }

  SuffixAddSourceFun = async () => {
    await this.setState({
      SuffixAddSource: !this.state.SuffixAddSource,
    })
  }

  QualificationAddSourceFun = async () => {
    await this.setState({
      QualificationAddSource: !this.state.QualificationAddSource,
    })
  }

  ExperienceAddSourceFun = async () => {
    await this.setState({
      ExperienceAddSource: !this.state.ExperienceAddSource,
    })
  }

  MedicalSchoolsAddSourceFun = async () => {
    await this.setState({
      MedicalSchoolsAddSource: !this.state.MedicalSchoolsAddSource,
    })
  }

  MedicalRegistrationNoAddSourceFun = async () => {
    await this.setState({
      MedicalRegistrationNoAddSource: !this.state.MedicalRegistrationNoAddSource,
    })
  }

  NPINoAddSourceFun = async () => {
    await this.setState({
      NPINoAddSource: !this.state.NPINoAddSource,
    })
  }

  PlaceOfWorkAddSourceFun = async () => {
    await this.setState({
      PlaceOfWorkAddSource: !this.state.PlaceOfWorkAddSourceFun,
    })
  }

  DesignationAddSourceFun = async () => {
    await this.setState({
      DesignationAddSource: !this.state.DesignationAddSource,
    })
  }

  BrandAddSourceFun = async () => {
    await this.setState({
      BrandAddSource: !this.state.BrandAddSource,
    })
  }

  DepartmentAddSourceFun = async () => {
    await this.setState({
      DepartmentAddSource: !this.state.DepartmentAddSource,
    })
  }

  FromYearAddSourceFun = async () => {
    await this.setState({
      FromYearAddSource: !this.state.FromYearAddSource,
    })
  }

  ToYearAddSourceFun = async () => {
    await this.setState({
      ToYearAddSource: !this.state.ToYearAddSource,
    })
  }

  SpecialityAddSourceFun = async () => {
    await this.setState({
      SpecialityAddSource: !this.state.SpecialityAddSource,
    })
  }


  Speciality2AddSourceFun = async () => {
    await this.setState({
      Speciality2AddSource: !this.state.Speciality2AddSource,
    })
  }

  ProcedureTypeAddSourceFun = async () => {
    await this.setState({
      ProcedureTypeAddSource: !this.state.ProcedureTypeAddSource,
    })
  }

  SubSpecialityTypeAddSourceFun = async () => {
    await this.setState({
      SubSpecialityTypeAddSource: !this.state.SubSpecialityTypeAddSource,
    })
  }

  DoctorContactNoAddSourceFunc = async () => {
    await this.setState({
      DoctorContactNoAddSource: !this.state.DoctorContactNoAddSource,
    })
  }

  DoctorEmailAddSourceFun = async () => {
    await this.setState({
      DoctorEmailAddSource: !this.state.DoctorEmailAddSource,
    })
  }


  DoctorEmail2AddSourceFun = async () => {
    await this.setState({
      DoctorEmail2AddSource: !this.state.DoctorEmail2AddSource,
    })
  }
  FaxAddSourceFun = async () => {
    await this.setState({
      FaxAddSource: !this.state.FaxAddSource,
    })
  }

  LinkedInProfileAddSourceFun = async () => {
    await this.setState({
      LinkedInProfileAddSource: !this.state.LinkedInProfileAddSource,
    })
  }

  TwitterHandleAddSourceFun = async () => {
    await this.setState({
      TwitterHandleAddSource: !this.state.TwitterHandleAddSource,
    })
  }

  AssistanceContactNoAddSourceFunc = async () => {
    await this.setState({
      AssistanceContactNoAddSource: !this.state.AssistanceContactNoAddSource,
    })
  }

  AssistanceEmailAddSourceFun = async () => {
    await this.setState({
      AssistanceEmailAddSource: !this.state.AssistanceEmailAddSource,
    })
  }

  WebsiteAddSourceFun = async () => {
    await this.setState({
      WebsiteAddSource: !this.state.WebsiteAddSource,
    })
  }

  CountryAddSourceFun = async () => {
    await this.setState({
      CountryAddSource: !this.state.CountryAddSource,
    })
  }

  StateAddSourceFun = async () => {
    await this.setState({
      StateAddSource: !this.state.StateAddSource,
    })
  }

  CityPEAddSourceFun = async () => {
    await this.setState({
      CityPEAddSource: !this.state.CityPEAddSource,
    })
  }

  Address1AddSourceFun = async () => {
    await this.setState({
      Address1AddSource: !this.state.Address1AddSource,
    })
  }

  Address2AddSourceFun = async () => {
    await this.setState({
      Address2AddSource: !this.state.Address2AddSource,
    })
  }

  CitizenshipAddSourceFun = async () => {
    await this.setState({
      CitizenshipAddSource: !this.state.CitizenshipAddSource,
    })
  }

  LicensedRegionsAddSourceFun = async () => {
    await this.setState({
      LicensedRegionsAddSource: !this.state.LicensedRegionsAddSource,
    })
  }

  AreasofInterestAddSourceFun = async () => {
    await this.setState({
      AreasofInterestAddSource: !this.state.AreasofInterestAddSource,
    })
  }

  LanguageSpokenAddSourceFun = async () => {
    await this.setState({
      LanguageSpokenAddSource: !this.state.LanguageSpokenAddSource,
    })
  }

  WorkPhoneAddSourceFun = async () => {
    await this.setState({
      WorkPhoneAddSource: !this.state.WorkPhoneAddSource,
    })
  }

  WorkEmailAddSourceFun = async () => {
    await this.setState({
      WorkEmailAddSource: !this.state.WorkEmailAddSource,
    })
  }

  LinkedInFollowersAddSourceFun = async () => {
    await this.setState({
      LinkedInFollowersAddSource: !this.state.LinkedInFollowersAddSource,
    })
  }

  TwitterFollowersAddSourceFun = async () => {
    await this.setState({
      TwitterFollowersAddSource: !this.state.TwitterFollowersAddSource,
    })
  }

  BiographyFieldAddSourceFun = async () => {
    await this.setState({
      BiographyFieldAddSource: !this.state.BiographyFieldAddSource,
    })
  }

  // Professional membership
  OrganizationAddSourceFun = async () => {
    await this.setState({
      OrganizationAddSource: !this.state.OrganizationAddSource,
    })
  }

  OrganizationTypeAddSourceFun = async () => {
    await this.setState({
      OrganizationTypeAddSource: !this.state.OrganizationTypeAddSource,
    })
  }

  SourceLinkAddSourceFun = async () => {
    await this.setState({
      SourceLinkAddSource: !this.state.SourceLinkAddSource,
    })
  }

  LocationAddSourceFun = async () => {
    await this.setState({
      LocationAddSource: !this.state.LocationAddSource,
    })
  }

  PositionAddSourceFun = async () => {
    await this.setState({
      PositionAddSource: !this.state.PositionAddSource,
    })
  }

  TenureAddSourceFun = async () => {
    await this.setState({
      TenureAddSource: !this.state.TenureAddSource,
    })
  }

  OrganizationWebsiteAddSourceFun = async () => {
    await this.setState({
      OrganizationWebsiteAddSource: !this.state.OrganizationWebsiteAddSource,
    })
  }

  DepartmentProfMemberAddSourceFun = async () => {
    await this.setState({
      DepartmentProfMemberAddSource: !this.state.DepartmentProfMemberAddSource,
    })
  }

  ProfileCountAddSourceFun = async () => {
    await this.setState({
      ProfileCountAddSource: !this.state.ProfileCountAddSource,
    })
  }

  // Education
  InstituteNameAddSourceFun = async () => {
    await this.setState({
      InstituteNameAddSource: !this.state.InstituteNameAddSource,
    })
  }

  InstituteTypeAddSourceFun = async () => {
    await this.setState({
      InstituteTypeAddSource: !this.state.InstituteTypeAddSource,
    })
  }

  SourceLinkEducationAddSourceFun = async () => {
    await this.setState({
      SourceLinkEducationAddSource: !this.state.SourceLinkEducationAddSource,
    })
  }

  DegreesAddSourceFun = async () => {
    await this.setState({
      DegreesAddSource: !this.state.DegreesAddSource,
    })
  }

  SpecialityEducationAddSourceFun = async () => {
    await this.setState({
      SpecialityEducationAddSource: !this.state.SpecialityEducationAddSource,
    })
  }

  YearsAttendedAddSourceFun = async () => {
    await this.setState({
      YearsAttendedAddSource: !this.state.YearsAttendedAddSource,
    })
  }

  // Advisory board
  SpecialityAdvBoardAddSourceFun = async () => {
    await this.setState({
      SpecialityAdvBoardAddSource: !this.state.SpecialityAdvBoardAddSource,
    })
  }

  SubSpecialityAdvBoardAddSourceFun = async () => {
    await this.setState({
      SubSpecialityAdvBoardAddSource: !this.state.SubSpecialityAdvBoardAddSource,
    })
  }

  ProcedureAdvBoardAddSourceFun = async () => {
    await this.setState({
      ProcedureAdvBoardAddSource: !this.state.ProcedureAdvBoardAddSource,
    })
  }

  YearAddSourceFun = async () => {
    await this.setState({
      YearAddSource: !this.state.YearAddSource,
    })
  }

  ToYearAdvBoardAddSourceFun = async () => {
    await this.setState({
      ToYearAdvBoardAddSource: !this.state.ToYearAdvBoardAddSource,
    })
  }

  CompanyOrgAddSourceFun = async () => {
    await this.setState({
      CompanyOrgAddSource: !this.state.CompanyOrgAddSource,
    })
  }

  TypeOfCompanyOrgAddSourceFun = async () => {
    await this.setState({
      TypeOfCompanyOrgAddSource: !this.state.TypeOfCompanyOrgAddSource,
    })
  }

  AdvisoryBoardSourceFun = async () => {
    await this.setState({
      AdvisoryBoardSource: !this.state.AdvisoryBoardSource,
    })
  }

  TopicSourceFun = async () => {
    await this.setState({
      TopicSource: !this.state.TopicSource,
    })
  }

  RoleAdvBoardAddSourceFun = async () => {
    await this.setState({
      RoleAdvBoardAddSource: !this.state.RoleAdvBoardAddSource,
    })
  }

  affiliationtypeAdvBoardAddSourceFun = async () => {
    await this.setState({
      affiliationtypeAdvBoardAddSource: !this.state.affiliationtypeAdvBoardAddSource,
    })
  }


  committeestatusAdvBoardAddSourceFun = async () => {
    await this.setState({
      committeestatusAdvBoardAddSource: !this.state.committeestatusAdvBoardAddSource,
    })
  }

  

  // Awards and honours
  YearAwardSourceFun = async () => {
    await this.setState({
      YearAwardSource: !this.state.YearAwardSource,
    })
  }

  ToYearAwardSourceFun = async () => {
    await this.setState({
      ToYearAwardSource: !this.state.ToYearAwardSource,
    })
  }

  AwardsHonoursSourceFun = async () => {
    await this.setState({
      AwardsHonoursSource: !this.state.AwardsHonoursSource,
    })
  }

  AwardingOrganisationSourceFun = async () => {
    await this.setState({
      AwardingOrganisationSource: !this.state.AwardingOrganisationSource,
    })
  }

  TypesSourceFun = async () => {
    await this.setState({
      TypesSource: !this.state.TypesSource,
    })
  }

  // Books monographs
  YearBooksSourceFun = async () => {
    await this.setState({
      YearBooksSource: !this.state.YearBooksSource,
    })
  }

  titleSourceFun = async () => {
    await this.setState({
      titleSource: !this.state.titleSource,
    })
  }

  AuthorSourceFun = async () => {
    await this.setState({
      AuthorSource: !this.state.AuthorSource,
    })
  }

  PublicationSourceFun = async () => {
    await this.setState({
      PublicationSource: !this.state.PublicationSource,
    })
  }

  EditionSourceFun = async () => {
    await this.setState({
      EditionSource: !this.state.EditionSource,
    })
  }

  PagesSourceFun = async () => {
    await this.setState({
      PagesSource: !this.state.PagesSource,
    })
  }

  EditorsSourceFun = async () => {
    await this.setState({
      EditorsSource: !this.state.EditorsSource,
    })
  }

  // Commitees
  YearCommiteesSourceFun = async () => {
    await this.setState({
      YearCommiteesSource: !this.state.YearCommiteesSource,
    })
  }

  ToYearCommiteesSourceFun = async () => {
    await this.setState({
      ToYearCommiteesSource: !this.state.ToYearCommiteesSource,
    })
  }

  bodyOrgNameSourceFun = async () => {
    await this.setState({
      bodyOrgNameSource: !this.state.bodyOrgNameSource,
    })
  }

  CommiteeSourceFun = async () => {
    await this.setState({
      CommiteeSource: !this.state.CommiteeSource,
    })
  }

  RoleSourceFun = async () => {
    await this.setState({
      RoleSource: !this.state.RoleSource,
    })
  }

  // Clinical Trial Details
  SpecialityClinicalSourceFun = async () => {
    await this.setState({
      SpecialityClinicalSource: !this.state.SpecialityClinicalSource,
    })
  }

  SubSpecialityClinicalSourceFun = async () => {
    await this.setState({
      SubSpecialityClinicalSource: !this.state.SubSpecialityClinicalSource,
    })
  }

  ProcedureClinicalSourceFun = async () => {
    await this.setState({
      ProcedureClinicalSource: !this.state.ProcedureClinicalSource,
    })
  }

  StudyDetailsSourceFun = async () => {
    await this.setState({
      StudyDetailsSource: !this.state.StudyDetailsSource,
    })
  }

  SourceLinkClinicalTrialSourceFun = async () => {
    await this.setState({
      SourceLinkClinicalTrialSource: !this.state.SourceLinkClinicalTrialSource,
    })
  }

  StartDateClinicalTrialSourceFun = async () => {
    await this.setState({
      StartDateClinicalTrialSource: !this.state.StartDateClinicalTrialSource,
    })
  }

  EndDateClinicalTrialSourceFun = async () => {
    await this.setState({
      EndDateClinicalTrialSource: !this.state.EndDateClinicalTrialSource,
    })
  }

  TypeSourceFun = async () => {
    await this.setState({
      TypeSource: !this.state.TypeSource,
    })
  }

  PhaseSourceFun = async () => {
    await this.setState({
      PhaseSource: !this.state.PhaseSource,
    })
  }

  RoleClinicalTrialSourceFun = async () => {
    await this.setState({
      RoleClinicalTrialSource: !this.state.RoleClinicalTrialSource,
    })
  }

  CoverageClinicalTrialSourceFun = async () => {
    await this.setState({
      CoverageClinicalTrialSource: !this.state.CoverageClinicalTrialSource,
    })
  }

  NoStudyLocationClinicalTrialSourceFun = async () => {
    await this.setState({
      NoStudyLocationClinicalTrialSource: !this.state.NoStudyLocationClinicalTrialSource,
    })
  }

  conditionsSourceFun = async () => {
    await this.setState({
      conditionsSource: !this.state.conditionsSource,
    })
  }

  TreatmentInterventionSourceFun = async () => {
    await this.setState({
      TreatmentInterventionSource: !this.state.TreatmentInterventionSource,
    })
  }

  PrimaryOutcomeSourceFun = async () => {
    await this.setState({
      PrimaryOutcomeSource: !this.state.PrimaryOutcomeSource,
    })
  }

  SecondaryOutcomesSourceFun = async () => {
    await this.setState({
      SecondaryOutcomesSource: !this.state.SecondaryOutcomesSource,
    })
  }

  SponsorSourceFun = async () => {
    await this.setState({
      SponsorSource: !this.state.SponsorSource,
    })
  }

  CollaboratorsSourceFun = async () => {
    await this.setState({
      CollaboratorsSource: !this.state.CollaboratorsSource,
    })
  }

  // Event Details
  SpecialityEventSourceFun = async () => {
    await this.setState({
      SpecialityEventSource: !this.state.SpecialityEventSource,
    })
  }

  SubSpecialityEventSourceFun = async () => {
    await this.setState({
      SubSpecialityEventSource: !this.state.SubSpecialityEventSource,
    })
  }

  ProceduereEventSourceFun = async () => {
    await this.setState({
      ProceduereEventSource: !this.state.ProceduereEventSource,
    })
  }

  EventNameSourceFun = async () => {
    await this.setState({
      EventNameSource: !this.state.EventNameSource,
    })
  }

  EventTypeEventsSourceFun = async () => {
    await this.setState({
      EventTypeEventsSource: !this.state.EventTypeEventsSource,
    })
  }

  EventDescriptionSourceFun = async () => {
    await this.setState({
      EventDescriptionSource: !this.state.EventDescriptionSource,
    })
  }

  EventTopicSourceFun = async () => {
    await this.setState({
      EventTopicSource: !this.state.EventTopicSource,
    })
  }

  SourcelinEventkSourceFun = async () => {
    await this.setState({
      SourcelinEventkSource: !this.state.SourcelinEventkSource,
    })
  }

  ConditionsEventsSourceFun = async () => {
    await this.setState({
      ConditionsEventsSource: !this.state.ConditionsEventsSource,
    })
  }

  InterventionEventsSourceFun = async () => {
    await this.setState({
      InterventionEventsSource: !this.state.InterventionEventsSource,
    })
  }

  DateEventsSourceFun = async () => {
    await this.setState({
      DateEventsSource: !this.state.DateEventsSource,
    })
  }

  SessionTypeSourceFun = async () => {
    await this.setState({
      SessionTypeSource: !this.state.SessionTypeSource,
    })
  }

  RoleEventsSourceFun = async () => {
    await this.setState({
      RoleEventsSource: !this.state.RoleEventsSource,
    })
  }

  EventSponsorSourceFun = async () => {
    await this.setState({
      EventSponsorSource: !this.state.EventSponsorSource,
    })
  }

  TotalEventAttendeesSourceFun = async () => {
    await this.setState({
      TotalEventAttendeesSource: !this.state.TotalEventAttendeesSource,
    })
  }

  CountryEventsSourceFun = async () => {
    await this.setState({
      CountryEventsSource: !this.state.CountryEventsSource,
    })
  }

  // Grants
  CompanySourceFun = async () => {
    await this.setState({
      CompanySource: !this.state.CompanySource,
    })
  }

  TitleGrantsSourceFun = async () => {
    await this.setState({
      TitleGrantsSource: !this.state.TitleGrantsSource,
    })
  }

  YearGrantsSourceFun = async () => {
    await this.setState({
      YearGrantsSource: !this.state.YearGrantsSource,
    })
  }

  ToYearGrantsSourceFun = async () => {
    await this.setState({
      ToYearGrantsSource: !this.state.ToYearGrantsSource,
    })
  }

  AmountGrantsSourceFun = async () => {
    await this.setState({
      AmountGrantsSource: !this.state.AmountGrantsSource,
    })
  }

  CoInvestigatorSourceFun = async () => {
    await this.setState({
      CoInvestigatorSource: !this.state.CoInvestigatorSource,
    })
  }

  // Guidelines
  SpecialityGuidlinesSourceFun = async () => {
    await this.setState({
      SpecialityGuidlinesSource: !this.state.SpecialityGuidlinesSource,
    })
  }

  SubSpecialityGuidlinesSourceFun = async () => {
    await this.setState({
      SubSpecialityGuidlinesSource: !this.state.SubSpecialityGuidlinesSource,
    })
  }

  ProcedureGuidlinesSourceFun = async () => {
    await this.setState({
      ProcedureGuidlinesSource: !this.state.ProcedureGuidlinesSource,
    })
  }

  YearGuidlinesSourceFun = async () => {
    await this.setState({
      YearGuidlinesSource: !this.state.YearGuidlinesSource,
    })
  }

  OrganizationGuidlinesSourceFun = async () => {
    await this.setState({
      OrganizationGuidlinesSource: !this.state.OrganizationGuidlinesSource,
    })
  }

  TypeOfOrganizationGuidlinesSourceFun = async () => {
    await this.setState({
      TypeOfOrganizationGuidlinesSource: !this.state.TypeOfOrganizationGuidlinesSource,
    })
  }

  CompanyOrganizationGuidlinesSourceFun = async () => {
    await this.setState({
      CompanyOrganizationGuidlinesSource: !this.state.CompanyOrganizationGuidlinesSource,
    })
  }

  TitleGuidlinesSourceFun = async () => {
    await this.setState({
      TitleGuidlinesSource: !this.state.TitleGuidlinesSource,
    })
  }
  RoleGuidlinesSourceFun = async () => {
    await this.setState({
      RoleGuidlinesSource: !this.state.RoleGuidlinesSource,
    })
  }
  GuidelinesaffiliationtypeGuidlinesSourceFun = async () => {
    await this.setState({
      GuidelinesaffiliationtypeGuidlinesSource: !this.state.GuidelinesaffiliationtypeGuidlinesSource,
    })
  }


  
  // Hospital Affiliations
  HospitalSourceFun = async () => {
    await this.setState({
      HospitalSource: !this.state.HospitalSource,
    })
  }

  HospitalRoleSourceFun = async () => {
    await this.setState({
      HospitalRoleSource: !this.state.HospitalRoleSource,
    })
  }

  HospitalFromYearSourceFun = async () => {
    await this.setState({
      HospitalFromYearSource: !this.state.HospitalFromYearSource,
    })
  }

  HospitalToYearSourceFun = async () => {
    await this.setState({
      HospitalToYearSource: !this.state.HospitalToYearSource,
    })
  }

  CountryHospitalAffSourceFun = async () => {
    await this.setState({
      CountryHospitalAffSource: !this.state.CountryHospitalAffSource,
    })
  }

  StateHospitalAffSourceFun = async () => {
    await this.setState({
      StateHospitalAffSource: !this.state.StateHospitalAffSource,
    })
  }

  CityHospitalAffSourceFun = async () => {
    await this.setState({
      CityHospitalAffSource: !this.state.CityHospitalAffSource,
    })
  }

  DepartmentHospitalSourceFun = async () => {
    await this.setState({
      DepartmentHospitalSource: !this.state.DepartmentHospitalSource,
    })
  }

  // Interactions
  DateInteractionsSourceFun = async () => {
    await this.setState({
      DateInteractionsSource: !this.state.DateInteractionsSource,
    })
  }

  OpinionLeaderSourceFun = async () => {
    await this.setState({
      OpinionLeaderSource: !this.state.OpinionLeaderSource,
    })
  }

  EnteredBySourceFun = async () => {
    await this.setState({
      EnteredBySource: !this.state.EnteredBySource,
    })
  }

  ProductTopicSourceFun = async () => {
    await this.setState({
      ProductTopicSource: !this.state.ProductTopicSource,
    })
  }

  TypeInteractionSourceFun = async () => {
    await this.setState({
      TypeInteractionSource: !this.state.TypeInteractionSource,
    })
  }

  ModeInteractionSourceFun = async () => {
    await this.setState({
      ModeInteractionSource: !this.state.ModeInteractionSource,
    })
  }

  TimeInteractionSourceFun = async () => {
    await this.setState({
      TimeInteractionSource: !this.state.TimeInteractionSource,
    })
  }

  ActivityToolUsedSourceFun = async () => {
    await this.setState({
      ActivityToolUsedSource: !this.state.ActivityToolUsedSource,
    })
  }

  CommentsKeywordsSourceFun = async () => {
    await this.setState({
      CommentsKeywordsSource: !this.state.CommentsKeywordsSource,
    })
  }

  // Press Item
  TitlePressItemSourceFun = async () => {
    await this.setState({
      TitlePressItemSource: !this.state.TitlePressItemSource,
    })
  }

  QuotePressItemSourceFun = async () => {
    await this.setState({
      QuotePressItemSource: !this.state.QuotePressItemSource,
    })
  }

  SourceLinkPressItemSourceFun = async () => {
    await this.setState({
      SourceLinkPressItemSource: !this.state.SourceLinkPressItemSource,
    })
  }

  DatePressItemSourceFun = async () => {
    await this.setState({
      DatePressItemSource: !this.state.DatePressItemSource,
    })
  }

  // Presentation
  TitlePresentationSourceFun = async () => {
    await this.setState({
      TitlePresentationSource: !this.state.TitlePresentationSource,
    })
  }

  EventPresentationSourceFun = async () => {
    await this.setState({
      EventPresentationSource: !this.state.EventPresentationSource,
    })
  }

  TypePresentationSourceFun = async () => {
    await this.setState({
      TypePresentationSource: !this.state.TypePresentationSource,
    })
  }

  SponsorPresentationSourceFun = async () => {
    await this.setState({
      SponsorPresentationSource: !this.state.SponsorPresentationSource,
    })
  }

  LocationPresentationSourceFun = async () => {
    await this.setState({
      LocationPresentationSource: !this.state.LocationPresentationSource,
    })
  }

  DatePresentationSourceFun = async () => {
    await this.setState({
      DatePresentationSource: !this.state.DatePresentationSource,
    })
  }

  // Publication Details
  SpecialityPublicationSourceFun = async () => {
    await this.setState({
      SpecialityPublicationSource: !this.state.SpecialityPublicationSource,
    })
  }

  SubSpecialityPublicationSourceFun = async () => {
    await this.setState({
      SubSpecialityPublicationSource: !this.state.SubSpecialityPublicationSource,
    })
  }

  ProcedurePublicationSourceFun = async () => {
    await this.setState({
      ProcedurePublicationSource: !this.state.ProcedurePublicationSource,
    })
  }

  JournalSourceFun = async () => {
    await this.setState({
      JournalSource: !this.state.JournalSource,
    })
  }

  TypeOfJournelPublicationSourceFun = async () => {
    await this.setState({
      TypeOfJournelPublicationSource: !this.state.TypeOfJournelPublicationSource,
    })
  }

  PublicationTitleSourceFun = async () => {
    await this.setState({
      PublicationTitleSource: !this.state.PublicationTitleSource,
    })
  }

  DatePublicationSourceFun = async () => {
    await this.setState({
      DatePublicationSource: !this.state.DatePublicationSource,
    })
  }

  SourceLinkPublicationSourceFun = async () => {
    await this.setState({
      SourceLinkPublicationSource: !this.state.SourceLinkPublicationSource,
    })
  }

  PositionPublicationSourceFun = async () => {
    await this.setState({
      PositionPublicationSource: !this.state.PositionPublicationSource,
    })
  }

  CoAuthorPublicationSourceFun = async () => {
    await this.setState({
      CoAuthorPublicationSource: !this.state.CoAuthorPublicationSource,
    })
  }

  KeyWordsPublicationSourceFun = async () => {
    await this.setState({
      KeyWordsPublicationSource: !this.state.KeyWordsPublicationSource,
    })
  }

  // Sunshine Payment
  DateSunshineSourceFun = async () => {
    await this.setState({
      DateSunshineSource: !this.state.DateSunshineSource,
    })
  }

  TypeSunshineSourceFun = async () => {
    await this.setState({
      TypeSunshineSource: !this.state.TypeSunshineSource,
    })
  }

  DrugDevicesSourceFun = async () => {
    await this.setState({
      DrugDevicesSource: !this.state.DrugDevicesSource,
    })
  }

  LocationSunshineSourceFun = async () => {
    await this.setState({
      LocationSunshineSource: !this.state.LocationSunshineSource,
    })
  }

  AmountSunshineSourceFun = async () => {
    await this.setState({
      AmountSunshineSource: !this.state.AmountSunshineSource,
    })
  }

  PaymentTypeSunshineSourceFun = async () => {
    await this.setState({
      PaymentTypeSunshineSource: !this.state.PaymentTypeSunshineSource,
    })
  }

  OLAddressSunshineSourceFun = async () => {
    await this.setState({
      OLAddressSunshineSource: !this.state.OLAddressSunshineSource,
    })
  }

  SpecialitySunshineSourceFun = async () => {
    await this.setState({
      SpecialitySunshineSource: !this.state.SpecialitySunshineSource,
    })
  }

  NPISunshineSourceFun = async () => {
    await this.setState({
      NPISunshineSource: !this.state.NPISunshineSource,
    })
  }

  // Procedure Potential
  ProcedurePotentialSourceFun = async () => {
    await this.setState({
      ProcedurePotentialSource: !this.state.ProcedurePotentialSource,
    })
  }

  //Biography upload resume function
  // onUploadResume = event => {
  //     this.setState({ BiographyResume: event.target.files[0] , BiographyResume_edit: ''});
  // }
  onUploadResume = async (event) => {
    await this.setState({
      BiographyResume: event.target.files[0],
      BiographyResumeFileName: event.target.files[0].name,
      FinalResumeName: event.target.files[0].name,
    });
    console.log("event of resume BiographyResumeFileName", this.state.BiographyResumeFileName)
  }

  // To get procedure 
  getProcedureList = () => {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        this.setState({
          ProcedureData: response.data.data,
        })
        console.log("ProcedureData", this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });

  }

  // To get sub speciality list 
  getSubSpecialityList = () => {
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydata = response.data;
        console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
        this.setState({
          SubSpecialitydata: response.data.data,
        })
        console.log("SubSpecialitydata", this.state.SubSpecialitydata)
      })
      .catch(error => {
        console.log(error);
      });
  }

  //  To get procedure  list for adv board
  getProcedureListAdvBoard = (spe_id) => {
    console.log(spe_id)
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        this.setState({
          ProcedureDataForAdvBoard: response.data.data,
        })
        console.log("ProcedureDataForAdvBoard", this.state.ProcedureDataForAdvBoard)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get sub speciality list adv board
  getSubSpecialityListAdvBoard = (spe_id) => {
    console.log(spe_id)
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydata = response.data;
        console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
        this.setState({
          SubSpecialitydataForAdvBoard: response.data.data,
        })
        console.log("SubSpecialitydataForAdvBoard", this.state.SubSpecialitydataForAdvBoard)
      })
      .catch(error => {
        console.log(error);
      });
  }

  //  To get procedure  list for Clinical trial
  getProcedureListClinicalTrial = (spe_id) => {
    console.log(spe_id)
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var ProcedureDataForClinicalTrial = response.data;
        console.log("ProcedureDataForClinicalTrial", JSON.stringify(ProcedureDataForClinicalTrial));
        this.setState({
          ProcedureDataForClinicalTrial: response.data.data,
        })
        console.log("ProcedureDataForClinicalTrial", this.state.ProcedureDataForClinicalTrial)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get sub speciality list for clinical trial
  getSubSpecialityListClinicalTrial = (spe_id) => {
    console.log(spe_id)
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydataClinicalTrial = response.data;
        console.log("SubSpecialitydataClinicalTrial", JSON.stringify(SubSpecialitydataClinicalTrial));
        this.setState({
          SubSpecialitydataClinicalTrial: response.data.data,
        })
        console.log("SubSpecialitydataClinicalTrial", this.state.SubSpecialitydataClinicalTrial)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get state list
  getStateList = () => {
    console.log("in state list");
    console.log("country id", this.state.PersonalInformation.country_id)

    const url = global.websiteUrl+'/service/Location/get_StateList';
    let formData = new FormData();
    formData.append('country_id', this.state.PersonalInformation.country_id);

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var testStateData = response.data.Country_Details;
        console.log("testStateData", JSON.stringify(testStateData));
        this.setState({
          StateData: response.data.Country_Details,
        })
        console.log("StateData======", this.state.StateData)
      })
      .catch(error => {
        console.log(error);
      });
  }

  //  To get procedure  list for Event Details
  getProcedureListEventsDetails = (spe_id) => {
    console.log(spe_id)
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var ProcedureDataForEventDetails = response.data;
        console.log("ProcedureDataForEventDetails", JSON.stringify(ProcedureDataForEventDetails));
        this.setState({
          ProcedureDataForEventDetails: response.data.data,
        })
        console.log("ProcedureDataForEventDetails", this.state.ProcedureDataForEventDetails)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get sub speciality list for Event Details
  getSubSpecialityListEventsDetails = (spe_id) => {
    console.log(spe_id)
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydataEventDetails = response.data;
        console.log("SubSpecialitydataEventDetails", JSON.stringify(SubSpecialitydataEventDetails));
        this.setState({
          SubSpecialitydataEventDetails: response.data.data,
        })
        console.log("SubSpecialitydataEventDetails", this.state.SubSpecialitydataEventDetails)
      })
      .catch(error => {
        console.log(error);
      });
  }

  //  To get procedure  list for Guidelines
  getProcedureListGuidelines = (spe_id) => {
    console.log(spe_id)
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var ProcedureDataForGuidelines = response.data;
        console.log("ProcedureDataForGuidelines", JSON.stringify(ProcedureDataForGuidelines));
        this.setState({
          ProcedureDataForGuidelines: response.data.data,
        })
        console.log("ProcedureDataForGuidelines", this.state.ProcedureDataForGuidelines)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get sub speciality list for Guidelines
  getSubSpecialityListGuidelines = (spe_id) => {
    console.log(spe_id)
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydataGuidelines = response.data;
        console.log("SubSpecialitydataGuidelines", JSON.stringify(SubSpecialitydataGuidelines));
        this.setState({
          SubSpecialitydataGuidelines: response.data.data,
        })
        console.log("SubSpecialitydataGuidelines", this.state.SubSpecialitydataGuidelines)
      })
      .catch(error => {
        console.log(error);
      });
  }

  //  To get procedure  list for Publication Details
  getProcedureListPublicationDetails = (spe_id) => {
    console.log(spe_id)
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var ProcedureDataForPublicationDetails = response.data;
        console.log("ProcedureDataForPublicationDetails", JSON.stringify(ProcedureDataForPublicationDetails));
        this.setState({
          ProcedureDataForPublicationDetails: response.data.data,
        })
        console.log("ProcedureDataForPublicationDetails", this.state.ProcedureDataForPublicationDetails)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get sub speciality list for Publication Details
  getSubSpecialityListPublicationDetails = (spe_id) => {
    console.log(spe_id)
    console.log("in sub speciality list");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', spe_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialitydataPublicationDetails = response.data;
        console.log("SubSpecialitydataPublicationDetails", JSON.stringify(SubSpecialitydataPublicationDetails));
        this.setState({
          SubSpecialitydataPublicationDetails: response.data.data,
        })
        console.log("SubSpecialitydataPublicationDetails", this.state.SubSpecialitydataPublicationDetails)
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To get City list
  // getCityList = () => {
  //   console.log("in city list");
  //   console.log("country id", this.state.PersonalInformation.country_id)

  //   const url = global.websiteUrl+'/service/Location/get_CityList';
  //   let formData = new FormData();
  //   formData.append('country_id', this.state.PersonalInformation.country_id);

  //   const config = {
  //     //   headers: { 'content-type': 'multipart/form-data' }
  //   }
  //   axios.post(url, formData, config)
  //     .then(response => {
  //       console.log(response);
  //       var testCityData = response.data.Country_Details;
  //       console.log("testCityData", JSON.stringify(testCityData));
  //       this.setState({
  //         CityData: response.data.Country_Details,
  //       })
  //       console.log("CityData======", this.state.CityData)
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  // 
  handleChangeBiography(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Biography = [...this.state.Biography];
    Biography[i] = { ...Biography[i], [name]: value };
    this.setState({ Biography });
  }

  // Personal Information Add Source Funs starts here
  handleChangePersonalInfoAddSource = (evt) => {
    this.setState({
      valuePersonalInfoAddSource: evt.target.value,
      error: null
    });
  };

  handleKeyDownSalutation = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsSalutation: [...this.state.itemsSalutation, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsSalutation.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.salutation_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteSalutation = async (item) => {
    await this.setState({
      itemsSalutation: this.state.itemsSalutation.filter((i) => i !== item)
    });
    var output = this.state.itemsSalutation.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.salutation_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownDoctorFullName = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsDoctorFullName: [...this.state.itemsDoctorFullName, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsDoctorFullName.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_full_name_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteDoctorFullName = async (item) => {
    await this.setState({
      itemsDoctorFullName: this.state.itemsDoctorFullName.filter((i) => i !== item)
    });
    var output = this.state.itemsDoctorFullName.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.doctor_full_name_links = output.join(",");
    await this.setState({ PersonalInformation });

    console.log("check this", this.state.PersonalInformation.doctor_full_name_links)
    console.log(output.join(","))
    console.log("items", this.state.items)
  };

  handleKeyDownFirstName = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsFirstName: [...this.state.itemsFirstName, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsFirstName.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.first_name_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteFirstName = async (item) => {
    await this.setState({
      itemsFirstName: this.state.itemsFirstName.filter((i) => i !== item)
    });
    var output = this.state.itemsFirstName.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.first_name_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownMiddleName = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsMiddleName: [...this.state.itemsMiddleName, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsMiddleName.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.middle_name_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteMiddleName = async (item) => {
    await this.setState({
      itemsMiddleName: this.state.itemsMiddleName.filter((i) => i !== item)
    });
    var output = this.state.itemsMiddleName.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.middle_name_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownLastName = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsLastName: [...this.state.itemsLastName, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsLastName.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.last_name_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteLastName = async (item) => {
    await this.setState({
      itemsLastName: this.state.itemsLastName.filter((i) => i !== item)
    });
    var output = this.state.itemsLastName.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.last_name_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownQualification = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsQualification: [...this.state.itemsQualification, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsQualification.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.qualification_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteQualification = async (item) => {
    await this.setState({
      itemsQualification: this.state.itemsQualification.filter((i) => i !== item)
    });
    var output = this.state.itemsQualification.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.qualification_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownExperience = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsExperience: [...this.state.itemsExperience, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsExperience.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.experience_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteExperience = async (item) => {
    await this.setState({
      itemsExperience: this.state.itemsExperience.filter((i) => i !== item)
    });
    var output = this.state.itemsExperience.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.experience_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownMedicalSchool = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsMedicalSchool: [...this.state.itemsMedicalSchool, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsMedicalSchool.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_schools_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteMedicalSchool = async (item) => {
    await this.setState({
      itemsMedicalSchool: this.state.itemsMedicalSchool.filter((i) => i !== item)
    });
    var output = this.state.itemsMedicalSchool.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.medical_schools_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownMedicalMedicalRegister = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsMedicalRegisterNo: [...this.state.itemsMedicalRegisterNo, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsMedicalRegisterNo.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_registration_no_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteMedicalRegister = async (item) => {
    await this.setState({
      itemsMedicalRegisterNo: this.state.itemsMedicalRegisterNo.filter((i) => i !== item)
    });
    var output = this.state.itemsMedicalRegisterNo.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.medical_registration_no_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownNpiNo = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsNpiNo: [...this.state.itemsNpiNo, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsNpiNo.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.NPI_no_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteNpiNo = async (item) => {
    await this.setState({
      itemsNpiNo: this.state.itemsNpiNo.filter((i) => i !== item)
    });
    var output = this.state.itemsNpiNo.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.NPI_no_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownPlaceOfWork = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsPlaceWork: [...this.state.itemsPlaceWork, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsPlaceWork.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.place_of_work_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeletePlaceOfWork = async (item) => {
    await this.setState({
      itemsPlaceWork: this.state.itemsPlaceWork.filter((i) => i !== item)
    });
    var output = this.state.itemsPlaceWork.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.place_of_work_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownDesignation = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsDesignation: [...this.state.itemsDesignation, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsDesignation.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.designation_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteDesignation = async (item) => {
    await this.setState({
      itemsDesignation: this.state.itemsDesignation.filter((i) => i !== item)
    });
    var output = this.state.itemsDesignation.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.designation_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownBrand = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsBrand: [...this.state.itemsBrand, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsBrand.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.brand_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteBrand = async (item) => {
    await this.setState({
      itemsBrand: this.state.itemsBrand.filter((i) => i !== item)
    });
    var output = this.state.itemsBrand.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.brand_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownDepartment = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsDepartment: [...this.state.itemsDepartment, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsDepartment.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.department_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteDepartment = async (item) => {
    await this.setState({
      itemsDepartment: this.state.itemsDepartment.filter((i) => i !== item)
    });
    var output = this.state.itemsDepartment.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.department_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownSpeciality = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemSpeciality: [...this.state.itemSpeciality, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemSpeciality.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };



  handleKeyDownSpeciality2 = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemSpeciality2: [...this.state.itemSpeciality2, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemSpeciality2.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.specialty2_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };


  handleDeleteSpeciality = async (item) => {
    await this.setState({
      itemSpeciality: this.state.itemSpeciality.filter((i) => i !== item)
    });
    var output = this.state.itemSpeciality.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.specialty_links = output.join(",");
    await this.setState({ PersonalInformation });
  };



  handleDeleteSpeciality2 = async (item) => {
    await this.setState({
      itemSpeciality2: this.state.itemSpeciality2.filter((i) => i !== item)
    });
    var output = this.state.itemSpeciality2.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.specialty2_links = output.join(",");
    await this.setState({ PersonalInformation });
  };



  handleKeyDownProcedure = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsProcedure: [...this.state.itemsProcedure, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsProcedure.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_type_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteProcedure = async (item) => {
    await this.setState({
      itemsProcedure: this.state.itemsProcedure.filter((i) => i !== item)
    });
    var output = this.state.itemsProcedure.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.procedure_type_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownSubSpeciality = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsSubSpeciality: [...this.state.itemsSubSpeciality, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsSubSpeciality.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.sub_specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteSubspeciality = async (item) => {
    await this.setState({
      itemsSubSpeciality: this.state.itemsSubSpeciality.filter((i) => i !== item)
    });
    var output = this.state.itemsSubSpeciality.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.sub_specialty_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownDoctorsContact = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsDoctorContact: [...this.state.itemsDoctorContact, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsDoctorContact.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_contact_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteDoctorsContact = async (item) => {
    await this.setState({
      itemsDoctorContact: this.state.itemsDoctorContact.filter((i) => i !== item)
    });
    var output = this.state.itemsDoctorContact.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.doctor_contact_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownDoctorsEmail = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsDoctorEmail: [...this.state.itemsDoctorEmail, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsDoctorEmail.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_email_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };

  handleDeleteDoctorsEmail = async (item) => {
    await this.setState({
      itemsDoctorEmail: this.state.itemsDoctorEmail.filter((i) => i !== item)
    });
    var output = this.state.itemsDoctorEmail.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.doctor_email_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownFax = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsFax: [...this.state.itemsFax, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsFax.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.fax_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteFax = async (item) => {
    await this.setState({
      itemsFax: this.state.itemsFax.filter((i) => i !== item)
    });
    var output = this.state.itemsFax.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.fax_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownLinkedIn = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsLinkedIn: [...this.state.itemsLinkedIn, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsLinkedIn.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.linked_in_profile_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteLinkedIn = async (item) => {
    await this.setState({
      itemsLinkedIn: this.state.itemsLinkedIn.filter((i) => i !== item)
    });
    var output = this.state.itemsLinkedIn.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.linked_in_profile_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownTwitter = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsTwitter: [...this.state.itemsTwitter, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsTwitter.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.twitter_handle_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteTwitter = async (item) => {
    await this.setState({
      itemsTwitter: this.state.itemsTwitter.filter((i) => i !== item)
    });
    var output = this.state.itemsTwitter.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.twitter_handle_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownAssisContact = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsAssistanceContact: [...this.state.itemsAssistanceContact, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsAssistanceContact.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_contact_no_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteAssisContact = async (item) => {
    await this.setState({
      itemsAssistanceContact: this.state.itemsAssistanceContact.filter((i) => i !== item)
    });
    var output = this.state.itemsAssistanceContact.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.assistance_contact_no_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownAssisEmail = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsAssistanceEmail: [...this.state.itemsAssistanceEmail, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsAssistanceEmail.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_email_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteAssisEmail = async (item) => {
    await this.setState({
      itemsAssistanceEmail: this.state.itemsAssistanceEmail.filter((i) => i !== item)
    });
    var output = this.state.itemsAssistanceEmail.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.assistance_email_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownWebsite = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsWebsite: [...this.state.itemsWebsite, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsWebsite.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.website_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteWebsite = async (item) => {
    await this.setState({
      itemsWebsite: this.state.itemsWebsite.filter((i) => i !== item)
    });
    var output = this.state.itemsWebsite.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.website_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownCountry = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsCountry: [...this.state.itemsCountry, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsCountry.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.country_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteCountry = async (item) => {
    await this.setState({
      itemsCountry: this.state.itemsCountry.filter((i) => i !== item)
    });
    var output = this.state.itemsCountry.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.country_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownState = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsState: [...this.state.itemsState, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsState.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.state_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteState = async (item) => {
    await this.setState({
      itemsState: this.state.itemsState.filter((i) => i !== item)
    });
    var output = this.state.itemsState.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.state_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownCityPE = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsCity: [...this.state.itemsCity, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsCity.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.city_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteCityPe  = async (item) => {
    await this.setState({
      itemsCity: this.state.itemsCity.filter((i) => i !== item)
    });
    var output = this.state.itemsCity.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.city_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  handleKeyDownAddress1 = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsAddress1: [...this.state.itemsAddress1, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsAddress1.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.address_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteAddress1 = async (item) => {
    await this.setState({
      itemsAddress1: this.state.itemsAddress1.filter((i) => i !== item)
    });
    var output = this.state.itemsAddress1.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.address_links = output.join(",");
    await this.setState({ PersonalInformation });
  };


  handleKeyDownAddress2 = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsAddress2: [...this.state.itemsAddress2, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsAddress2.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.address_2links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteAddress2 = async (item) => {
    await this.setState({
      itemsAddress2: this.state.itemsAddress2.filter((i) => i !== item)
    });
    var output = this.state.itemsAddress2.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.address_2links = output.join(",");
    await this.setState({ PersonalInformation });
  };









  handleKeyDownProcedurePotential = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInfoAddSource.trim();
      if (this.state.valuePersonalInfoAddSource !== '') {
        await this.setState({
          itemsProcedurePotential: [...this.state.itemsProcedurePotential, this.state.valuePersonalInfoAddSource],
          valuePersonalInfoAddSource: ""
        });

        var output = this.state.itemsProcedurePotential.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_potential_links = output.join(",");
        await this.setState({ PersonalInformation });
      }
    }
  };
  handleDeleteProcedurePotential = async (item) => {
    await this.setState({
      itemsProcedurePotential: this.state.itemsProcedurePotential.filter((i) => i !== item)
    });
    var output = this.state.itemsProcedurePotential.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.procedure_potential_links = output.join(",");
    await this.setState({ PersonalInformation });
  };

  // Biography Add Source Funs starts here
  handleChangeBiographyAddSource = (evt) => {
    this.setState({
      valueBiographyAddSource: evt.target.value,
      error: null
    });
  };

  handleKeyDownCitzenship = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsCitizenship: [...this.state.itemsCitizenship, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsCitizenship.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.citizenship_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteCitizenship = async (item) => {
    await this.setState({
      itemsCitizenship: this.state.itemsCitizenship.filter((i) => i !== item)
    });
    var output = this.state.itemsCitizenship.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.citizenship_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownLicenseRegions = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsLicensedRegions: [...this.state.itemsLicensedRegions, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsLicensedRegions.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.licensed_regions_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteLicenseRegions = async (item) => {
    await this.setState({
      itemsLicensedRegions: this.state.itemsLicensedRegions.filter((i) => i !== item)
    });
    var output = this.state.itemsLicensedRegions.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.licensed_regions_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownAreasOfInterest = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsAreasOfInterest: [...this.state.itemsAreasOfInterest, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsAreasOfInterest.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.areas_of_interest_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteAreasOfInterest = async (item) => {
    await this.setState({
      itemsAreasOfInterest: this.state.itemsAreasOfInterest.filter((i) => i !== item)
    });
    var output = this.state.itemsAreasOfInterest.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.areas_of_interest_links = output.join(",");
    await this.setState({ Biography });
  };


  handleKeyDownexpertise = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valuePersonalInformationAddSource.trim();
      if (this.state.valuePersonalInformationAddSource !== '') {
        await this.setState({
          itemsexpertise: [...this.state.itemsexpertise, this.state.valuePersonalInformationAddSource],
          valuePersonalInformationAddSource: ""
        });

        var output = this.state.itemsexpertise.map(function (item) {
          return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.expertise_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteexpertise = async (item) => {
    await this.setState({
      itemsexpertise: this.state.itemsexpertise.filter((i) => i !== item)
    });
    var output = this.state.itemsexpertise.map(function (item) {
      return item;
    });

    let { PersonalInformation } = this.state;
    PersonalInformation.expertise_links = output.join(",");
    await this.setState({ PersonalInformation });
  };



  handleKeyDownLanguage = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsLanguageSpoken: [...this.state.itemsLanguageSpoken, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsLanguageSpoken.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.language_spoken_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteLanguage = async (item) => {
    await this.setState({
      itemsLanguageSpoken: this.state.itemsLanguageSpoken.filter((i) => i !== item)
    });
    var output = this.state.itemsLanguageSpoken.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.language_spoken_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownWorkPhone = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsWorkPhone: [...this.state.itemsWorkPhone, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsWorkPhone.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.work_phone_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteWorkPhone = async (item) => {
    await this.setState({
      itemsWorkPhone: this.state.itemsWorkPhone.filter((i) => i !== item)
    });
    var output = this.state.itemsWorkPhone.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.work_phone_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownWorkEmail = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsWorkEmail: [...this.state.itemsWorkEmail, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsWorkEmail.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.work_email_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteWorkEmail = async (item) => {
    await this.setState({
      itemsWorkEmail: this.state.itemsWorkEmail.filter((i) => i !== item)
    });
    var output = this.state.itemsWorkEmail.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.work_email_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownLinkedInFollowers = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsLinkedInFolllowers: [...this.state.itemsLinkedInFolllowers, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsLinkedInFolllowers.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.linkedin_followers_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteLinkedInFollowers = async (item) => {
    await this.setState({
      itemsLinkedInFolllowers: this.state.itemsLinkedInFolllowers.filter((i) => i !== item)
    });
    var output = this.state.itemsLinkedInFolllowers.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.linkedin_followers_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownTwitterFollowers = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsTwitterFollowers: [...this.state.itemsTwitterFollowers, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsTwitterFollowers.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.twitter_followers_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteTwitterFollowers = async (item) => {
    await this.setState({
      itemsTwitterFollowers: this.state.itemsTwitterFollowers.filter((i) => i !== item)
    });
    var output = this.state.itemsTwitterFollowers.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.twitter_followers_links = output.join(",");
    await this.setState({ Biography });
  };

  handleKeyDownBiography = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.valueBiographyAddSource.trim();
      if (this.state.valueBiographyAddSource !== '') {
        await this.setState({
          itemsBiography: [...this.state.itemsBiography, this.state.valueBiographyAddSource],
          valueBiographyAddSource: ""
        });

        var output = this.state.itemsBiography.map(function (item) {
          return item;
        });

        let { Biography } = this.state;
        Biography.biography_links = output.join(",");
        await this.setState({ Biography });

        console.log("check this", this.state.Biography)
        console.log(output.join(","))
      }
    }
  };
  handleDeleteBiography = async (item) => {
    await this.setState({
      itemsBiography: this.state.itemsBiography.filter((i) => i !== item)
    });
    var output = this.state.itemsBiography.map(function (item) {
      return item;
    });

    let { Biography } = this.state;
    Biography.biography_links = output.join(",");
    await this.setState({ Biography });
  };

  // to set values of Work Details array
  handleChangeWorkDetails(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let WorkDetails = [...this.state.WorkDetails];
    WorkDetails[i] = { ...WorkDetails[i], [name]: value };
    this.setState({ WorkDetails });
  }

  removeClickWorkDetails(i) {
    let WorkDetails = [...this.state.WorkDetails];
    WorkDetails.splice(i, 1);
    this.setState({ WorkDetails });
  }

  addClickWorkDetails = async () => {
    await this.setState(prevState => ({
      WorkDetails: [...prevState.WorkDetails, {
        kol_id: this.state.kol_unique_id,
        id: '',
        current_place_of_work: '',
        current_place_of_work_links: '',
        current_place_of_work_comment: '',
        current_place_of_work_status: '',
        designation: '',
        designation_links: '',
        designation_comment: '',
        designation_status: '',
        department: '',
        department_links: '',
        department_comment: '',
        department_status: '',
      }]
    }))

    this.setState({ items: [] })
  }

  handleChangeCheckBoxWorkDetails = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let WorkDetails = [...this.state.WorkDetails];
    WorkDetails[i] = { ...WorkDetails[i], [name]: checked };
    await this.setState({ WorkDetails });
  }

  handleChangeWorkingDetailsAddSource = (evt) => {
    // console.log("hdl : "+evt.target.value)
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownWorkingDetails = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.WorkDetails[index].current_place_of_work_links))
        // organization_type_links
        //    if (value && this.isValid(value)) {
        if (name == 'current_place_of_work_links') {
          await this.setState({
            items: [...this.state.WorkDetails[index].current_place_of_work_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'designation_links') {
          await this.setState({
            items: [...this.state.WorkDetails[index].designation_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'department_links') {
          await this.setState({
            items: [...this.state.WorkDetails[index].department_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.WorkDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ WorkDetails: list });
      }
    }
  }

  handleDeleteWorkingDetails = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.WorkDetails];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ WorkDetails: list });
  }

  // to set values of Professional Membership array
  handleChangeProfessionalMembership(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
    ProfessionalMemberships[i] = { ...ProfessionalMemberships[i], [name]: value };
    this.setState({ ProfessionalMemberships });
  }

  removeClickProfessionalMembership(i) {
    let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
    ProfessionalMemberships.splice(i, 1);
    this.setState({ ProfessionalMemberships });
  }

  addClickProfessionalMembership() {
    this.setState(prevState => ({
      ProfessionalMemberships: [...prevState.ProfessionalMemberships, {
        kol_id: this.state.kol_unique_id,
        id: '',
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        organization: '',
        organization_links: '',
        organization_comment: '',
        organization_status: '',
        organization_type: '',
        organization_type_links: '',
        organization_type_comment: '',
        organization_type_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        location: '',
        location_links: '',
        location_comment: '',
        location_status: '',
        position: '',
        position_links: '',
        position_comment: '',
        position_status: '',
        tenure: '',
        tenure_link: '',
        tenure_comment: '',
        tenure_status: '',
        organization_website: '',
        organization_website_links: '',
        organization_website_comment: '',
        organization_website_status: '',
      }]
    }))

    this.setState({ items: [] })
  }

  handleChangeCheckBoxProfessionalMem = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
    ProfessionalMemberships[i] = { ...ProfessionalMemberships[i], [name]: checked };
    await this.setState({ ProfessionalMemberships });
  }

  handleChangeProfAddSource = (evt) => {
    // console.log("hdl : "+evt.target.value)
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownProfAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.ProfessionalMemberships[index].organization_links))
        // organization_type_links
        //    if (value && this.isValid(value)) {
        if (name == 'organization_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].organization_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'organization_type_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].organization_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }

        else if (name == 'location_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].location_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'position_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].position_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'tenure_link') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].tenure_link.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'organization_website_links') {
          await this.setState({
            items: [...this.state.ProfessionalMemberships[index].organization_website_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.ProfessionalMemberships];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ ProfessionalMemberships: list });
      }
    }
  }

  handleDeleteProfAddSource = async (arrayOrganization, item, index, name) => {
    console.log('in del : ' + JSON.stringify(arrayOrganization));
    await this.setState({
      items: arrayOrganization.flat().filter((i) => i !== item)
    });
    let list = [...this.state.ProfessionalMemberships];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ ProfessionalMemberships: list });
  }

  handleSubmitProfessional = (event) => {
    // alert('Professional Membership: ' + JSON.stringify(this.state.ProfessionalMembership));
    // alert('OrganizationSourceUrl: ' + JSON.stringify(this.state.OrganizationSourceUrl));

    console.log("Professional Membership:", this.state.ProfessionalMemberships)

    event.preventDefault();
  }

  // to set values of Education array
  handleChangeEducation(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Education = [...this.state.Education];
    Education[i] = { ...Education[i], [name]: value };
    this.setState({ Education });
  }

  removeClickEducation(i) {
    let Education = [...this.state.Education];
    Education.splice(i, 1);
    this.setState({ Education });
  }

  addClickEducation() {
    this.setState(prevState => ({
      Education: [...prevState.Education, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        institute_name: '',
        institute_name_links: '',
        institute_name_comment: '',
        institute_name_status: '',
        institute_type: '',
        institute_type_links: '',
        institute_type_comment: '',
        institute_type_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        degrees: '',
        degrees_links: '',
        degrees_comment: '',
        degrees_status: '',
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        years_attended_from: '',
        years_attended_to: '',
        years_attended_links: '',
        years_attended_comment: '',
        years_attended_staus: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxEducation = async (i, e) => {
    console.log(i);
    console.log(e.target);
    console.log('e.target.checked', e.target.checked);
    console.log('e.target.name', e.target.name);
    const { name, checked } = e.target;
    let Education = [...this.state.Education];
    Education[i] = { ...Education[i], [name]: checked };
    await this.setState({ Education });
  }

  handleChangeEducationAddSource = (evt) => {
    // console.log("hdl : "+evt.target.value)
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownEducationAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.Education[index].institute_name_links))

        if (name == 'institute_name_links') {
          await this.setState({
            items: [...this.state.Education[index].institute_name_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'institute_type_links') {
          await this.setState({
            items: [...this.state.Education[index].institute_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.Education[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'degrees_links') {
          await this.setState({
            items: [...this.state.Education[index].degrees_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.Education[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'years_attended_links') {
          await this.setState({
            items: [...this.state.Education[index].years_attended_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.Education];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ Education: list });
      }
    }
  }

  handleDeleteEducationAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.Education];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ Education: list });
  }

  handleSubmitEducation = (event) => {
    console.log("Education:", this.state.Education)
    event.preventDefault();
  }

  // to set values of advisory board array
  handleChangeAdvisoryBoard(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let AdvisoryBoard = [...this.state.AdvisoryBoard];
    AdvisoryBoard[i] = { ...AdvisoryBoard[i], [name]: value };
    this.setState({ AdvisoryBoard });
  }

  removeClickAdvisoryBoard(i) {
    let AdvisoryBoard = [...this.state.AdvisoryBoard];
    AdvisoryBoard.splice(i, 1);
    this.setState({ AdvisoryBoard });
  }

  addClickAdvisoryBoard() {
    console.log("kol id", this.state.kol_unique_id)
    this.setState(prevState => ({
      AdvisoryBoard: [...prevState.AdvisoryBoard, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        advisory_board: '',
        advisory_board_links: '',
        affiliationtype_links: '',
        advisory_board_comment: '',
        advisory_board_status: '',
        topic: '',
        topic_links: '',
        topic_comment: '',
        topic_status: '',
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        sub_specialty_id: '',
        sub_speciality_links: '',
        sub_speciality_comment: '',
        sub_speciality_status: '',
        procedure_type_id: '',
        procedure_type_links: '',
        procedure_type_comment: '',
        procedure_type_status: '',
        company: '',
        company_links: '',
        company_comment: '',
        company_status: '',
        type_of_company: '',
        type_of_company_links: '',
        type_of_company_comment: '',
        type_of_company_status: '',
        role_id: '',
        role_links: '',
        role_comment: '',
        role_status: '',
        manual_weightage: '',
      }]
    }))
  }

  handleChangeCheckBoxAdvisoryBoard = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let AdvisoryBoard = [...this.state.AdvisoryBoard];
    AdvisoryBoard[i] = { ...AdvisoryBoard[i], [name]: checked };
    await this.setState({ AdvisoryBoard });
  }

  handleChangeAdvBoardAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownAdvBoardAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.AdvisoryBoard[index].speciality_links))

        if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sub_speciality_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].sub_speciality_links.split(","), this.state.value],
            value: ""
          });
        }

        else if (name == 'procedure_type_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].procedure_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'year_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].year_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'company_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].company_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'type_of_company_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].type_of_company_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'advisory_board_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].advisory_board_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'topic_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].topic_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'role_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].role_links.split(","), this.state.value],
            value: ""
          });
        }

        else if (name == 'affiliationtype_links') {
          await this.setState({
            items: [...this.state.AdvisoryBoard[index].affiliationtype_links.split(","), this.state.value],
            value: ""
          });
        }

        

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.AdvisoryBoard];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ AdvisoryBoard: list });
      }
    }
  }

  handleDeleteAdvBoardAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.AdvisoryBoard];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ AdvisoryBoard: list });
  }

  handleSubmitAdvisoryBoard = (event) => {
    console.log("AdvisoryBoard:", this.state.AdvisoryBoard)
    event.preventDefault();
  }

  // awards and honours
  handleChangeAwardsHonours(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let AwardsHonours = [...this.state.AwardsHonours];
    AwardsHonours[i] = { ...AwardsHonours[i], [name]: value };
    this.setState({ AwardsHonours });
  }

  removeClickAwardsHonours(i) {
    let AwardsHonours = [...this.state.AwardsHonours];
    AwardsHonours.splice(i, 1);
    this.setState({ AwardsHonours });
  }

  addClickAwardsHonours() {
    console.log("kol id", this.state.PersonalInformation.kol_unique_id)
    this.setState(prevState => ({
      AwardsHonours: [...prevState.AwardsHonours, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        awards_honours: '',
        awards_honours_links: '',
        awards_honours_commen: '',
        awards_honours_status: '',

        Awarding_Organisation: '',
        Awarding_Organisation_links: '',
        Awarding_Organisation_commen: '',
        Awarding_Organisation_status: '',

        types: '',
        types_links: '',
        types_comment: '',
        types_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxAwardsHonours = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let AwardsHonours = [...this.state.AwardsHonours];
    AwardsHonours[i] = { ...AwardsHonours[i], [name]: checked };
    await this.setState({ AwardsHonours });
  }

  handleSubmitAwardsHonours = (event) => {
    console.log("AwardsHonours:", this.state.AwardsHonours)
    event.preventDefault();
  }

  // Books monographs
  handleChangeBooksMonographs(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let BooksMonographs = [...this.state.BooksMonographs];
    BooksMonographs[i] = { ...BooksMonographs[i], [name]: value };
    this.setState({ BooksMonographs });
  }

  removeClickBooksMonographs(i) {
    let BooksMonographs = [...this.state.BooksMonographs];
    BooksMonographs.splice(i, 1);
    this.setState({ BooksMonographs });
  }

  addClickBooksMonographs() {
    this.setState(prevState => ({
      BooksMonographs: [...prevState.BooksMonographs, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        title: '',
        title_links: '',
        title_comment: '',
        title_status: '',
        author: '',
        author_links: '',
        author_comment: '',
        author_status: '',
        publication: '',
        publication_links: '',
        publication_comment: '',
        publication_status: '',
        edition: '',
        edition_links: '',
        edition_comment: '',
        edition_status: '',
        pages: '',
        pages_links: '',
        pages_comment: '',
        pages_status: '',
        editors: '',
        editors_links: '',
        editors_comment: '',
        editors_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxBooksMonographs = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let BooksMonographs = [...this.state.BooksMonographs];
    BooksMonographs[i] = { ...BooksMonographs[i], [name]: checked };
    await this.setState({ BooksMonographs });
  }

  handleSubmitBooksMonographs = (event) => {
    console.log("BooksMonographs:", this.state.BooksMonographs)
    event.preventDefault();
  }

  // Commitees
  handleChangeCommitees(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Commitees = [...this.state.Commitees];
    Commitees[i] = { ...Commitees[i], [name]: value };
    this.setState({ Commitees });
  }

  removeClickCommitees(i) {
    let Commitees = [...this.state.Commitees];
    Commitees.splice(i, 1);
    this.setState({ Commitees });
  }

  addClickCommitees() {
    this.setState(prevState => ({
      Commitees: [...prevState.Commitees, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        committee: '',
        committee_links: '',
        committee_comment: '',
        committee_status: '',
        role_id: '',
        role_links: '',
        role_comment: '',
        role_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxCommitees = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let Commitees = [...this.state.Commitees];
    Commitees[i] = { ...Commitees[i], [name]: checked };
    await this.setState({ Commitees });
  }

  handleSubmitCommitees = (event) => {
    console.log("Commitees:", this.state.Commitees)
    event.preventDefault();
  }

  // Clinical trial details 
  handleChangeClinicalTrialDetails(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
    ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: value };
    this.setState({ ClinicalTrialDetails });
  }

  removeClickClinicalTrialDetails(i) {
    let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
    ClinicalTrialDetails.splice(i, 1);
    this.setState({ ClinicalTrialDetails });
  }

  addClickClinicalTrialDetails() {
    this.setState(prevState => ({
      ClinicalTrialDetails: [...prevState.ClinicalTrialDetails, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        sub_speciality: '',
        sub_speciality_links: '',
        sub_speciality_comment: '',
        sub_speciality_status: '',
        procedure_type: '',
        procedure_type_links: '',
        procedure_type_comment: '',
        procedure_type_status: '',
        study_details: '',
        study_details_links: '',
        study_details_comment: '',
        study_details_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        start_date: '',
        start_date_links: '',
        start_date_comment: '',
        start_date_status: '',
        end_date: '',
        end_date_links: '',
        end_date_comment: '',
        end_date_status: '',
        type: '',
        type_links: '',
        type_comment: '',
        type_status: '',
        phase: '',
        phase_links: '',
        phase_comment: '',
        phase_status: '',
        role_id: '',
        role_links: '',
        role_comment: '',
        role_status: '',
        coverage: '',
        coverage_links: '',
        coverage_comment: '',
        coverage_status: '',
        no_of_study_locations: '',
        no_of_study_locations_links: '',
        no_of_study_locations_comment: '',
        no_of_study_locations_status: '',
        conditions: '',
        conditions_links: '',
        conditions_comment: '',
        conditions_status: '',
        treatment_intervention: '',
        treatment_intervention_links: '',
        treatment_intervention_comment: '',
        treatment_intervention_status: '',
        primary_outcomes: '',
        primary_outcomes_links: '',
        primary_outcomes_comment: '',
        primary_outcomes_status: '',
        secondary_outcomes: '',
        secondary_outcomes_links: '',
        secondary_outcomes_comment: '',
        secondary_outcomes_status: '',
        sponsors: '',
        sponsors_links: '',
        sponsors_comment: '',
        sponsors_status: '',
        collaborators: '',
        collaborators_links: '',
        collaborators_comment: '',
        collaborators_status: '',
        manual_weightage: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxClinicalTrialDetails = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
    ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: checked };
    await this.setState({ ClinicalTrialDetails });
  }

  handleChangeClinicalAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownClinicalAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.ClinicalTrialDetails[index].speciality_links))

        if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sub_speciality_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].sub_speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'procedure_type_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].procedure_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'study_details_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].study_details_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'start_date_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].start_date_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'end_date_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].end_date_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'type_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'phase_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].phase_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'role_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].role_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'coverage_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].coverage_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'conditions_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].conditions_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'treatment_intervention_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].treatment_intervention_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'primary_outcomes_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].primary_outcomes_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'secondary_outcomes_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].secondary_outcomes_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sponsors_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].sponsors_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'collaborators_links') {
          await this.setState({
            items: [...this.state.ClinicalTrialDetails[index].collaborators_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.ClinicalTrialDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ ClinicalTrialDetails: list });
      }
    }
  }

  handleDeleteClinicalAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.ClinicalTrialDetails];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ ClinicalTrialDetails: list });
  }

  handleSubmitClinicalTrialDetails = (event) => {
    console.log("ClinicalTrialDetails:", this.state.ClinicalTrialDetails)
    event.preventDefault();
  }

  // Event details
  handleChangeEventsDetails(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let EventsDetails = [...this.state.EventsDetails];
    EventsDetails[i] = { ...EventsDetails[i], [name]: value };
    this.setState({ EventsDetails });
  }

  removeClickEventsDetails(i) {
    let EventsDetails = [...this.state.EventsDetails];
    EventsDetails.splice(i, 1);
    this.setState({ EventsDetails });
  }

  addClickEventsDetails() {
    this.setState(prevState => ({
      EventsDetails: [...prevState.EventsDetails, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        sub_speciality: '',
        sub_speciality_links: '',
        sub_speciality_comment: '',
        sub_speciality_status: '',
        procedure_type: '',
        procedure_type_links: '',
        procedure_type_comment: '',
        procedure_type_status: '',
        events: '',
        events_links: '',
        events_comment: '',
        events_status: '',
        event_type: '',
        event_type_links: '',
        event_type_comment: '',
        event_type_status: '',
        event_desc: '',
        event_desc_links: '',
        event_desc_comment: '',
        event_desc_status: '',
        event_topic: '',
        event_topic_links: '',
        event_topic_comment: '',
        event_topic_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        conditions: '',
        conditions_links: '',
        conditions_comment: '',
        conditions_status: '',
        intervention: '',
        intervention_links: '',
        intervention_comment: '',
        intervention_status: '',
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
        session_type: '',
        session_type_links: '',
        session_type_comment: '',
        session_type_status: '',
        role_id: '',
        role_links: '',
        role_comment: '',
        role_status: '',
        event_sponsor: '',
        event_sponsor_links: '',
        event_sponsor_comment: '',
        event_sponsor_status: '',
        total_event_attendees: '',
        total_event_attendees_links: '',
        total_event_attendees_comment: '',
        total_event_attendees_status: '',
        country_id: '',
        country_links: '',
        country_comment: '',
        country_status: '',
        manual_weightage: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxEventDetails = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let EventsDetails = [...this.state.EventsDetails];
    EventsDetails[i] = { ...EventsDetails[i], [name]: checked };
    await this.setState({ EventsDetails });
  }

  handleChangeEventAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownEventAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.EventsDetails[index].speciality_links))

        if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sub_speciality_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].sub_speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'procedure_type_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].procedure_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'events_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].events_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'event_type_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].event_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'event_desc_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].event_desc_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'event_topic_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].event_topic_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'conditions_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].conditions_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'intervention_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].intervention_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'date_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].date_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'session_type_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].session_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'role_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].role_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'event_sponsor_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].event_sponsor_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'total_event_attendees_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].total_event_attendees_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'country_links') {
          await this.setState({
            items: [...this.state.EventsDetails[index].country_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.EventsDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ EventsDetails: list });
      }
    }
  }

  handleDeleteEventAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.EventsDetails];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ EventsDetails: list });
  }

  handleSubmitEventsDetails = (event) => {
    console.log("EventsDetails:", this.state.EventsDetails)
    event.preventDefault();
  }

  // Grants
  handleChangeGrants(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Grants = [...this.state.Grants];
    Grants[i] = { ...Grants[i], [name]: value };
    this.setState({ Grants });
  }

  removeClickGrants(i) {
    let Grants = [...this.state.Grants];
    Grants.splice(i, 1);
    this.setState({ Grants });
  }

  addClickGrants() {
    this.setState(prevState => ({
      Grants: [...prevState.Grants, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        company: '',
        company_links: '',
        company_comment: '',
        company_status: '',
        title: '',
        title_links: '',
        title_comment: '',
        title_status: '',
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        amount: '',
        amount_links: '',
        amount_comment: '',
        amount_status: '',
        co_investigator: '',
        co_investigator_links: '',
        co_investigator_comment: '',
        co_investigator_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxGrants = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let Grants = [...this.state.Grants];
    Grants[i] = { ...Grants[i], [name]: checked };
    await this.setState({ Grants });
  }

  handleChangeGrantsAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownGrantsAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.Grants[index].company_links))

        if (name == 'company_links') {
          await this.setState({
            items: [...this.state.Grants[index].company_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'title_links') {
          await this.setState({
            items: [...this.state.Grants[index].title_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'year_links') {
          await this.setState({
            items: [...this.state.Grants[index].year_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'amount_links') {
          await this.setState({
            items: [...this.state.Grants[index].amount_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'co_investigator_links') {
          await this.setState({
            items: [...this.state.Grants[index].co_investigator_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.Grants];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ Grants: list });
      }
    }
  }


  handleDeleteGrantsAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.Grants];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ Grants: list });
  }

  handleSubmitGrants = (event) => {
    console.log("Grants:", this.state.Grants)
    event.preventDefault();
  }

  // Guidelines
  handleChangeGuidelines(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Guidelines = [...this.state.Guidelines];
    Guidelines[i] = { ...Guidelines[i], [name]: value };
    this.setState({ Guidelines });
  }

  removeClickGuidelines(i) {
    let Guidelines = [...this.state.Guidelines];
    Guidelines.splice(i, 1);
    this.setState({ Guidelines });
  }

  addClickGuidelines() {
    this.setState(prevState => ({
      Guidelines: [...prevState.Guidelines, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        sub_speciality: '',
        sub_speciality_links: '',
        sub_speciality_comment: '',
        sub_speciality_status: '',
        procedure_type: '',
        procedure_type_links: '',
        procedure_type_comment: '',
        procedure_type_status: '',
        year: '',
        year_links: '',
        year_comment: '',
        year_status: '',
        organization: '',
        organization_links: '',
        organization_comment: '',
        organization_status: '',
        type_of_organization: '',
        type_of_organization_links: '',
        type_of_organization_comment: '',
        type_of_organization_status: '',
        company: '',
        company_links: '',
        company_comment: '',
        company_status: '',
        title: '',
        title_links: '',
        title_comment: '',
        title_status: '',
        manual_weightage: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxGuidelines = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let Guidelines = [...this.state.Guidelines];
    Guidelines[i] = { ...Guidelines[i], [name]: checked };
    await this.setState({ Guidelines });
  }

  handleChangeGuidelinesAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownGuidelinesAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.Guidelines[index].speciality_links))

        if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sub_speciality_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].sub_speciality_links.split(","), this.state.value],
            value: ""
          });
        }

        else if (name == 'procedure_type_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].procedure_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'year_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].year_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'organization_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].organization_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'type_of_organization_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].type_of_organization_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'title_links') {
          await this.setState({
            items: [...this.state.Guidelines[index].title_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.Guidelines];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ Guidelines: list });
      }
    }
  }

  handleDeleteGuidelinessAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.Guidelines];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ Guidelines: list });
  }

  handleSubmitGuidelines = (event) => {
    console.log("Guidelines:", this.state.Guidelines)
    event.preventDefault();
  }

  // HospitalAffiliations
  handleChangeHospitalAffiliations(i, e) {
    console.log(i);
    console.log(e.target);
    console.log(e.target.name);
    console.log(e.target.value);
    const { name, value } = e.target;
    let HospitalAffiliations = [...this.state.HospitalAffiliations];
    HospitalAffiliations[i] = { ...HospitalAffiliations[i], [name]: value };
    this.setState({ HospitalAffiliations });

    if (e.target.name == "country_id") {
      console.log("yes this is country id")

      const url = global.websiteUrl+'/service/Location/get_StateList';
      let formData = new FormData();
      formData.append('country_id', e.target.value);

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var testStateData = response.data.Country_Details;
          console.log("testStateData", JSON.stringify(testStateData));
          if (response.data.status == true) {
            this.setState({
              StateDataForHospitalAffiliations: response.data.Country_Details,
            })
          }
          else if (response.data.status == false) {
            this.setState({
              StateDataForHospitalAffiliations: [],
            })
          }
          console.log("StateDataForHospitalAffiliations===", this.state.StateDataForHospitalAffiliations)
        })
        .catch(error => {
          console.log(error);
        });
    }

    // if (e.target.name == "country_id") {
    //   console.log("yes this is country id 2")

    //   const url = global.websiteUrl+'/service/Location/get_CityList';
    //   let formData = new FormData();
    //   formData.append('country_id', e.target.value);

    //   const config = {
    //     //   headers: { 'content-type': 'multipart/form-data' }
    //   }
    //   axios.post(url, formData, config)
    //     .then(response => {
    //       console.log(response);
    //       var testCityData = response.data.Country_Details;
    //       console.log("testCityData", JSON.stringify(testCityData));
    //       // this.setState({
    //       //   CityDataForHospitalAffiliations: response.data.Country_Details,
    //       // })
    //       if (response.data.status == true) {
    //         this.setState({
    //           CityDataForHospitalAffiliations: response.data.Country_Details,
    //         })
    //       }
    //       else if (response.data.status == false) {
    //         this.setState({
    //           CityDataForHospitalAffiliations: [],
    //         })
    //       }
    //       console.log("CityDataForHospitalAffiliations====", this.state.CityDataForHospitalAffiliations)
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });

    // }
  }

  removeClickHospitalAffiliations(i) {
    let HospitalAffiliations = [...this.state.HospitalAffiliations];
    HospitalAffiliations.splice(i, 1);
    this.setState({ HospitalAffiliations });
  }

  addClickHospitalAffiliations() {
    this.setState(prevState => ({
      HospitalAffiliations: [...prevState.HospitalAffiliations, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        hospital: '',
        hospital_links: '',
        hospital_comment: '',
        hospital_status: '',
        city_id: '',
        city_links: '',
        city_comment: '',
        city_status: '',
        state_id: '',
        state_links: '',
        state_comment: '',
        state_status: '',
        country_id: '',
        country_links: '',
        country_comment: '',
        country_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxHospitalAffiliations = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let HospitalAffiliations = [...this.state.HospitalAffiliations];
    HospitalAffiliations[i] = { ...HospitalAffiliations[i], [name]: checked };
    await this.setState({ HospitalAffiliations });
  }

  handleSubmitHospitalAffiliations = (event) => {
    console.log("HospitalAffiliations:", this.state.HospitalAffiliations)
    event.preventDefault();
  }

  // Interactions
  handleChangeInteractions(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Interactions = [...this.state.Interactions];
    Interactions[i] = { ...Interactions[i], [name]: value };
    this.setState({ Interactions });
  }

  removeClickInteractions(i) {
    let Interactions = [...this.state.Interactions];
    Interactions.splice(i, 1);
    this.setState({ Interactions });
  }

  addClickInteractions() {
    this.setState(prevState => ({
      Interactions: [...prevState.Interactions, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
        opinion_leader: '',
        opinion_leader_links: '',
        opinion_leader_comment: '',
        opinion_leader_status: '',
        entered_by: '',
        entered_by_links: '',
        entered_by_comment: '',
        entered_by_status: '',
        product: '',
        product_links: '',
        product_comment: '',
        product_status: '',
        type: '',
        type_links: '',
        type_comment: '',
        type_status: '',
        mode: '',
        mode_links: '',
        mode_comment: '',
        mode_status: '',
        time: '',
        time_links: '',
        time_comment: '',
        time_status: '',
        activity_or_tool_used: '',
        activity_or_tool_used_links: '',
        activity_or_tool_used_comment: '',
        activity_or_tool_used_status: '',
        comments: '',
        comments_links: '',
        comments_comment: '',
        comments_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxInteractions = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let Interactions = [...this.state.Interactions];
    Interactions[i] = { ...Interactions[i], [name]: checked };
    await this.setState({ Interactions });
  }

  handleSubmitInteractions = (event) => {
    console.log("Interactions:", this.state.Interactions)
    event.preventDefault();
  }

  // Press Item
  handleChangePressItem(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let PressItem = [...this.state.PressItem];
    PressItem[i] = { ...PressItem[i], [name]: value };
    this.setState({ PressItem });
  }

  removeClickPressItem(i) {
    let PressItem = [...this.state.PressItem];
    PressItem.splice(i, 1);
    this.setState({ PressItem });
  }

  addClickPressItem() {
    this.setState(prevState => ({
      PressItem: [...prevState.PressItem, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        title: '',
        title_links: '',
        title_comment: '',
        title_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxPressItem = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let PressItem = [...this.state.PressItem];
    PressItem[i] = { ...PressItem[i], [name]: checked };
    await this.setState({ PressItem });
  }

  handleChangePressAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownPressAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.PressItem[index].title_links))

        if (name == 'title_links') {
          await this.setState({
            items: [...this.state.PressItem[index].title_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.PressItem[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'date_links') {
          await this.setState({
            items: [...this.state.PressItem[index].date_links.split(","), this.state.value],
            value: ""
          });
        }


        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.PressItem];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ PressItem: list });
      }
    }
  }

  handleDeletePressAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.PressItem];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ PressItem: list });
  }

  handleSubmitPressItem = (event) => {
    console.log("PressItem:", this.state.PressItem)
    event.preventDefault();
  }

  // Presentation
  handleChangePresentation(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let Presentation = [...this.state.Presentation];
    Presentation[i] = { ...Presentation[i], [name]: value };
    this.setState({ Presentation });
  }

  removeClickPresentation(i) {
    let Presentation = [...this.state.Presentation];
    Presentation.splice(i, 1);
    this.setState({ Presentation });
  }

  addClickPresentation() {
    this.setState(prevState => ({
      Presentation: [...prevState.Presentation, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        title: '',
        title_links: '',
        title_comment: '',
        title_status: '',
        type: '',
        type_links: '',
        type_comment: '',
        type_status: '',
        sponsor: '',
        sponsor_links: '',
        sponsor_comment: '',
        sponsor_status: '',
        location: '',
        location_links: '',
        location_comment: '',
        location_status: '',
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxPresentation = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let Presentation = [...this.state.Presentation];
    Presentation[i] = { ...Presentation[i], [name]: checked };
    await this.setState({ Presentation });
  }

  handleChangePresentationAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownPresentationAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.Presentation[index].title_links))

        if (name == 'title_links') {
          await this.setState({
            items: [...this.state.Presentation[index].title_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'type_links') {
          await this.setState({
            items: [...this.state.Presentation[index].type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sponsor_links') {
          await this.setState({
            items: [...this.state.Presentation[index].sponsor_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'location_links') {
          await this.setState({
            items: [...this.state.Presentation[index].location_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'date_links') {
          await this.setState({
            items: [...this.state.Presentation[index].date_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.Presentation];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ Presentation: list });
      }
    }
  }

  handleDeletePresentationAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.Presentation];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ Presentation: list });
  }

  handleSubmitPresentation = (event) => {
    console.log("Presentation:", this.state.Presentation)
    event.preventDefault();
  }

  // Publication Details
  handleChangePublicationDetails(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let PublicationDetails = [...this.state.PublicationDetails];
    PublicationDetails[i] = { ...PublicationDetails[i], [name]: value };
    this.setState({ PublicationDetails });
  }

  removeClickPublicationDetails(i) {
    let PublicationDetails = [...this.state.PublicationDetails];
    PublicationDetails.splice(i, 1);
    this.setState({ PublicationDetails });
  }

  addClickPublicationDetails() {
    this.setState(prevState => ({
      PublicationDetails: [...prevState.PublicationDetails, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        speciality_id: '',
        speciality_links: '',
        speciality_comment: '',
        speciality_status: '',
        sub_speciality: '',
        sub_speciality_links: '',
        sub_speciality_comment: '',
        sub_speciality_status: '',
        procedure_type: '',
        procedure_type_links: '',
        procedure_type_comment: '',
        procedure_type_status: '',
        journal: '',
        journal_links: '',
        journal_comment: '',
        journal_status: '',
        type_of_journal: '',
        type_of_journal_links: '',
        type_of_journal_comment: '',
        type_of_journal_status: '',
        publication_title: '',
        publication_title_links: '',
        publication_title_comment: '',
        publication_title_status: '',
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
        source_link: '',
        source_link_links: '',
        source_link_comment: '',
        source_link_status: '',
        position: '',
        position_links: '',
        position_comment: '',
        position_status: '',
        co_author: '',
        co_author_links: '',
        co_author_comment: '',
        co_author_status: '',
        key_words: '',
        key_words_links: '',
        key_words_comment: '',
        key_words_status: '',
        manual_weightage: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxPublicationDetails = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let PublicationDetails = [...this.state.PublicationDetails];
    PublicationDetails[i] = { ...PublicationDetails[i], [name]: checked };
    await this.setState({ PublicationDetails });
  }

  handleChangePublicationAddSource = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleKeyDownPublicationAddSource = async (evt, index, name) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      console.log('name', name)
      console.log('index', index)

      var value = this.state.value.trim();
      if (value !== '') {
        console.log("ite input : " + JSON.stringify(this.state.PublicationDetails[index].speciality_links))

        if (name == 'speciality_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'sub_speciality_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].sub_speciality_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'procedure_type_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].procedure_type_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'journal_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].journal_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'type_of_journal_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].type_of_journal_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'publication_title_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].publication_title_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'date_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].date_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'source_link_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].source_link_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'position_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].position_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'co_author_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].co_author_links.split(","), this.state.value],
            value: ""
          });
        }
        else if (name == 'key_words_links') {
          await this.setState({
            items: [...this.state.PublicationDetails[index].key_words_links.split(","), this.state.value],
            value: ""
          });
        }

        console.log("key d : " + JSON.stringify(this.state.items.toString()))

        let list = [...this.state.PublicationDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        //  console.log("list : "+JSON.stringify(list));
        this.setState({ PublicationDetails: list });
      }
    }
  }

  handleDeletePublicationAddSource = async (array, item, index, name) => {
    console.log('in del : ' + JSON.stringify(array));
    await this.setState({
      items: array.flat().filter((i) => i !== item)
    });
    let list = [...this.state.PublicationDetails];
    list[index] = { ...list[index], [name]: this.state.items.toString() };
    console.log("list : " + JSON.stringify(list));
    this.setState({ PublicationDetails: list });
  }

  handleSubmitPublicationDetails = (event) => {
    console.log("PublicationDetails:", this.state.PublicationDetails)
    event.preventDefault();
  }

  // Sunshine Payment
  handleChangeSunshinePayment(i, e) {
    console.log(i);
    console.log(e.target);
    const { name, value } = e.target;
    let SunshinePayment = [...this.state.SunshinePayment];
    SunshinePayment[i] = { ...SunshinePayment[i], [name]: value };
    this.setState({ SunshinePayment });
  }

  removeClickSunshinePayment(i) {
    let SunshinePayment = [...this.state.SunshinePayment];
    SunshinePayment.splice(i, 1);
    this.setState({ SunshinePayment });
  }

  addClickSunshinePayment() {
    this.setState(prevState => ({
      SunshinePayment: [...prevState.SunshinePayment, {
        id: '',
        kol_id: this.state.kol_unique_id,
        // kol_id: this.state.PersonalInformation.kol_unique_id,
        date: '',
        date_links: '',
        date_comment: '',
        date_status: '',
        type: '',
        type_links: '',
        type_comment: '',
        type_status: '',
        devices: '',
        devices_links: '',
        devices_comment: '',
        devices_status: '',
        location: '',
        location_links: '',
        location_comment: '',
        location_status: '',
        amount: '',
        amount_links: '',
        amount_comment: '',
        amount_status: '',
        payment_type: '',
        payment_type_links: '',
        payment_type_comment: '',
        payment_type_status: '',
        OL_address: '',
        OL_address_links: '',
        OL_address_comment: '',
        OL_address_status: '',
        speciality_type_id: '',
        speciality_type_links: '',
        speciality_type_comment: '',
        speciality_type_status: '',
        NPI: '',
        NPI_links: '',
        NPI_comment: '',
        NPI_status: '',
      }]
    }))
    this.setState({ items: [] })
  }

  handleChangeCheckBoxSunshinePayment = async (i, e) => {
    console.log(i);
    console.log(e.target);
    const { name, checked } = e.target;
    let SunshinePayment = [...this.state.SunshinePayment];
    SunshinePayment[i] = { ...SunshinePayment[i], [name]: checked };
    await this.setState({ SunshinePayment });
  }

  handleSubmitSunshinePayment = (event) => {
    console.log("SunshinePayment:", this.state.SunshinePayment)
    event.preventDefault();
  }

  // set procedure potential
  setProcedurePotential = async (event) => {
    // console.log(event.target.value);
    // await this.setState({ procedure_potential: event.target.value });
    let { PersonalInformation } = this.state;
    PersonalInformation.procedure_potential = event.target.value;
    await this.setState({ PersonalInformation });
  }

  // after Selecting KOL type from personal info
  afterSelectingKOL = () => {
    console.log("in function yeeeeeeeeeeee")
    console.log("personalinformation.koltype", this.state.PersonalInformation.kol_type)

    if (this.state.PersonalInformation.kol_type == 'Payers Community' ||
      this.state.PersonalInformation.kol_type == 'HTA Experts' ||
      this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
      this.state.PersonalInformation.kol_type == 'Advocacy Group'
      // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
    ) {
      this.setState({
        PressItem: [{
          kol_id: this.state.kol_unique_id,
          title: '',
          title_links: '',
          source_link: '',
          source_link_links: '',
          date: '',
          date_links: '',
        }],
        // Presentation
        Presentation: [{
          kol_id: this.state.kol_unique_id,
          title: '',
          title_links: '',
          type: '',
          type_links: '',
          sponsor: '',
          sponsor_links: '',
          location: '',
          location_links: '',
          date: '',
          date_links: '',
        }],
      })

      let { Biography } = this.state;
      Biography.licensed_regions = '';
      Biography.licensed_regions_links = '';
      Biography.work_phone = '';
      Biography.work_phone_links = '';
      Biography.work_email = '';
      Biography.work_email_links = '';
      Biography.twitter_followers = '';
      Biography.twitter_followers_links = '';
      Biography.biography = '';
      Biography.biography_links = '';
      this.setState({ Biography });

      console.log('Press Item after selectig kol different', this.state.PressItem)
    }

  }

  // after selection salutation
  afterSelectSalutation = () => {
    console.log("in function yeeeeeeeeeeee")
    console.log("personalinformation.koltype", this.state.PersonalInformation.kol_type)
    console.log("personalinformation.salutation", this.state.PersonalInformation.salutation)

    if (this.state.PersonalInformation.kol_type) {
      if (this.state.PersonalInformation.kol_type == 'Payers Community' ||
        this.state.PersonalInformation.kol_type == 'HTA Experts' ||
        this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
        this.state.PersonalInformation.kol_type == 'Advocacy Group'
        // this.state.PersonalInformation.kol_type == 'Clinical Commission Group' 
      ) {
        if (this.state.PersonalInformation.salutation == 'Mr.' ||
          this.state.PersonalInformation.salutation == 'Ms.' ||
          this.state.PersonalInformation.salutation == 'Mrs.' ||
          this.state.PersonalInformation.salutation == 'Prof.' ||
          this.state.PersonalInformation.salutation == 'Other') {
          let { PersonalInformation } = this.state;
          PersonalInformation.medical_schools = '';
          PersonalInformation.medical_registration_no = '';
          PersonalInformation.NPI_no = '';
          this.setState({ PersonalInformation });
        }
      }
    }
    console.log("personal information after selecting salutation", this.state.PersonalInformation)
  }



  SaveFunc = () => {

    this.setState({
      ShowProcedure: true,
    })

    

    console.log("biography array", this.state.Biography)
    console.log("resume sending to api", this.state.BiographyResume)
    console.log("photo sending to api", this.state.profile_photo)
    console.log("personal info", this.state.PersonalInformation)
    console.log('WorkDetails', this.state.WorkDetails)
    console.log("professional info", this.state.ProfessionalMemberships)
    console.log("Education", this.state.Education)
    console.log("AdvisoryBoard", this.state.AdvisoryBoard)
    console.log("AwardsHonours", this.state.AwardsHonours)
    console.log("BooksMonographs", this.state.BooksMonographs)
    console.log("Commitees", this.state.Commitees)
    console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
    console.log("EventsDetails", this.state.EventsDetails)
    console.log("Grants", this.state.Grants)
    console.log("Guidelines", this.state.Guidelines)
    console.log("HospitalAffiliations", this.state.HospitalAffiliations)
    console.log("Interactions", this.state.Interactions)
    console.log("PressItem", this.state.PressItem)
    console.log("Presentations", this.state.Presentation)
    console.log("PublicationDetails", this.state.PublicationDetails)
    console.log("SunshinePayment", this.state.SunshinePayment)
    // console.log("KOL TYPE",this.state.PersonalInformation.kol_type)

    // const isValid = this.validatesaveasdraft();
    // if (isValid){
    this.setState({
      ShowProcedure: true,
    })


    // const url = global.websiteUrl+'/service/Researcher/researcher';
    const url = global.websiteUrl+'/service/KOL/KOL';
    let formData = new FormData();
    formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
    formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
    formData.append('KOl_Identifier', JSON.stringify(this.state.KOl_Identifier));
    formData.append('profile_photo', this.state.profile_photo);
    formData.append('Biography', JSON.stringify(this.state.Biography));
    formData.append('resume', this.state.BiographyResume);
    formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
    formData.append('Education', JSON.stringify(this.state.Education));
    formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
    formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
    formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
    formData.append('Commitees', JSON.stringify(this.state.Commitees));
    formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
    formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
    formData.append('Grants', JSON.stringify(this.state.Grants));
    formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
    formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
    formData.append('Interactions', JSON.stringify(this.state.Interactions));
    formData.append('PressItem', JSON.stringify(this.state.PressItem));
    formData.append('Presentations', JSON.stringify(this.state.Presentation));
    formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
    formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
    
    //  formData.append('kol_status', "Rejected");
    
    
    
    formData.append('project_id', this.state.project_id);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var data = response.data;
        console.log("data", data);
        var status = response.data.status;
        console.log("status", status);
        this.setState({
          ShowProcedure: false,
        })

        
       // if (response.data.status == true) {
          if (response.status === 200) {
          
          Swal.fire({
            icon: 'success',
          //  text: response.data.message,
          text: 'Saved successful',
          
            
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
          
          
        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({
      // personal info
      specialityErr: '',
      koltypeErr: '',
      doctorfullnameErr: '',
      firstnameErr: '',
    })
    // }    

  }








  RejectFunc = () => {

    this.setState({
      ShowProcedure: true,
    })

    
    console.log("biography array", this.state.Biography)
    console.log("resume sending to api", this.state.BiographyResume)
    console.log("photo sending to api", this.state.profile_photo)
    console.log("personal info", this.state.PersonalInformation)
    console.log('WorkDetails', this.state.WorkDetails)
    console.log("professional info", this.state.ProfessionalMemberships)
    console.log("Education", this.state.Education)
    console.log("AdvisoryBoard", this.state.AdvisoryBoard)
    console.log("AwardsHonours", this.state.AwardsHonours)
    console.log("BooksMonographs", this.state.BooksMonographs)
    console.log("Commitees", this.state.Commitees)
    console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
    console.log("EventsDetails", this.state.EventsDetails)
    console.log("Grants", this.state.Grants)
    console.log("Guidelines", this.state.Guidelines)
    console.log("HospitalAffiliations", this.state.HospitalAffiliations)
    console.log("Interactions", this.state.Interactions)
    console.log("PressItem", this.state.PressItem)
    console.log("Presentations", this.state.Presentation)
    console.log("PublicationDetails", this.state.PublicationDetails)
    console.log("SunshinePayment", this.state.SunshinePayment)
    // console.log("KOL TYPE",this.state.PersonalInformation.kol_type)

    // const isValid = this.validatesaveasdraft();
    // if (isValid){
    this.setState({
      ShowProcedure: true,
    })


    // const url = global.websiteUrl+'/service/Researcher/researcher';
    const url = global.websiteUrl+'/service/KOL/KOL';
    let formData = new FormData();
    formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
    formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
    formData.append('KOl_Identifier', JSON.stringify(this.state.KOl_Identifier));
    formData.append('profile_photo', this.state.profile_photo);
    formData.append('Biography', JSON.stringify(this.state.Biography));
    formData.append('resume', this.state.BiographyResume);
    formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
    formData.append('Education', JSON.stringify(this.state.Education));
    formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
    formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
    formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
    formData.append('Commitees', JSON.stringify(this.state.Commitees));
    formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
    formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
    formData.append('Grants', JSON.stringify(this.state.Grants));
    formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
    formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
    formData.append('Interactions', JSON.stringify(this.state.Interactions));
    formData.append('PressItem', JSON.stringify(this.state.PressItem));
    formData.append('Presentations', JSON.stringify(this.state.Presentation));
    formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
    formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
    formData.append('kol_status', "Rejected");
    formData.append('project_id', this.state.project_id);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var data = response.data;
        console.log("data", data);
        var status = response.data.status;
        console.log("status", status);
        this.setState({
          ShowProcedure: false,
        })

        
       // if (response.data.status == true) {
          if (response.status === 200) {
          
          Swal.fire({
            icon: 'success',
            //text: response.data.message,           
            text: 'Rejected',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
              
           this.setState({ RedirectManagerKolList: true })
          
        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({
      // personal info
      specialityErr: '',
      koltypeErr: '',
      doctorfullnameErr: '',
      firstnameErr: '',
    })
    // }    

  }


  ApproveFunc = () => {
    // this.setState({
    //   PersonalInformation:{
    //     bio_pdf: '',
    //     profile_pdf: '',
    //   }
    // })
    console.log("biography array", this.state.Biography)
    console.log("resume", this.state.BiographyResume)
    console.log("photo", this.state.profile_photo)
    console.log("personal info", this.state.PersonalInformation)
    console.log('WorkDetails', this.state.WorkDetails)
    console.log("professional info", this.state.ProfessionalMemberships)
    console.log("Education", this.state.Education)
    console.log("AdvisoryBoard", this.state.AdvisoryBoard)
    console.log("AwardsHonours", this.state.AwardsHonours)
    console.log("BooksMonographs", this.state.BooksMonographs)
    console.log("Commitees", this.state.Commitees)
    console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
    console.log("EventsDetails", this.state.EventsDetails)
    console.log("Grants", this.state.Grants)
    console.log("Guidelines", this.state.Guidelines)
    console.log("HospitalAffiliations", this.state.HospitalAffiliations)
    console.log("Interactions", this.state.Interactions)
    console.log("PressItem", this.state.PressItem)
    console.log("Presentations", this.state.Presentation)
    console.log("PublicationDetails", this.state.PublicationDetails)
    console.log("SunshinePayment", this.state.SunshinePayment)

    console.log("check all in approve", this.state.salutationCheck,
    // this.state.doctorFullNameCheck,
    // this.state.firstNameCheck,
    // this.state.lastNameCheck,
    // this.state.suffixCheck,
    // this.state.experienceCheck,
    // this.state.brancdCheck,
    // this.state.procedureCheck,
    // this.state.specialityCheck,
    // this.state.subSpecialityCheck,
    // this.state.doctorContactCheck,
    // this.state.doctorEmailCheck,
    // this.state.addressCheck,

    // this.state.stateCheck,
    // this.state.counterCheck,
    // this.state.procedurePotentialCheck,
    // this.state.zipCheck,

    // // workditails
    // this.state.placeofWorkCheck,
    // this.state.designantionCheck,
    // this.state.departmentCheck,
    // this.state.fromYearWDCheck,
    // this.state.toYearWDCheck,

    // // Biography
    // this.state.areaInterestCheck,
    // this.state.biographyCheck,
    // this.state.organizationCheck,
    // this.state.positionCheck,

    // // Education
    // this.state.instituteNameCheck,
    // this.state.sourceLinkEducationCheck,
    // this.state.degreesCheck,
    // this.state.specialityEducationCheck,
    // this.state.specialityAdvisoryCheck,
    // this.state.subSpecialityAdvisoryCheck,
    // this.state.procedureTypeAdvisoryCheck,
    // this.state.companyCheck,
    // this.state.typeOfCompanyCheck,
    // this.state.roleCheck,

    // // Awards N Honours
    // this.state.yearAwardsCheck,
    // this.state.yearToAwardsCheck,
    // this.state.awardHonoursCheck,

    // // Books Monographs
    // this.state.yearBooksCheck,
    // this.state.titleCheck,
    // this.state.authorCheck,
    // this.state.publicationCheck,
    // this.state.commiteeCheck,
    // this.state.bodyOrgNameCommCheck,
    // this.state.roleCommiteeCheck,

    // // Clinical Trial Details
    // this.state.studyDetailsCheck,
    // this.state.sourceLinkClinicalCheck,
    // this.state.startDateCheck,
    // this.state.endDateCheck,
    // this.state.typeClinicalCheck,
    // this.state.phaseCheck,
    // this.state.roleClinicalCheck,
    // this.state.specilityClinicalCheck,
    // this.state.subSpecialityClinicalCheck,
    // this.state.procedureClinicalCheck,
    // this.state.studyLocationCheck,
    // this.state.coverageCheck,
    // this.state.eventTopicCheck,
    // this.state.sourceLinkEventCheck,
    // this.state.roleEventCheck,
    // this.state.specialityEventCheck,
    // this.state.subSpecialityEventCheck,
    // this.state.procedureEventCheck,
    // this.state.eventCheck,
    // this.state.eventTypeCheck,

    // // Grants
    // this.state.companyGrantsCheck,

    // // Guidelines
    // this.state.yearGuidelinesCheck,
    // this.state.comanyGuidelinesCheck,
    // this.state.titleGuidelinesCheck,
    // this.state.specialityGuidelinesCheck,
    // this.state.subSpecialityGuidelinesCheck,
    // this.state.procedureGuidelinesCheck,
    // this.state.organizationGuidelinesCheck,
    // this.state.typeOrgnizationCheck,
    // this.state.roleGuidelineCheck,

    // // Hospital Affiliations
    // this.state.hospitalCheck,
    // this.state.countryHospitalCheck,
    // this.state.roleHospitalCheck ,
    // this.state.fromYearHospitalCheck ,
    // this.state.toYearHospitalCheck ,

    // // Interactions
    // this.state.dateInteractionCheck,
    // this.state.timeCheck,
    // this.state.timeZoneInteractionCheck,
    // this.state.incentiveModeInteractionCheck,

    // // Press Item
    // this.state.titlePressCheck,
    // this.state.sourceLinkPressCheck,
    // this.state.datePressCheck,
    // this.state.quotePressCheck,
    
    // // Presentation
    // this.state.titlePresentation,
    // this.state.typePresentationCheck,
    // this.state.locationPresentationCheck,
    // this.state.eventNamePresentationCheck,

    // // Publication Details
    // this.state.journalCheck,
    // this.state.publicationTitleCheck,
    // this.state.datePublicationCheck,
    // this.state.sourceLinkPublicationCheck,
    // this.state.positionPublicationCheck,
    // this.state.coAutorCheck,
    // this.state.keyWordsCheck,
    // this.state.specialityPublicationCheck,
    // this.state.subSpecialityPublicationCheck,
    // this.state.procedurePublicationCheck,
    // this.state.typeOfJournelCheck,

    // // SunshinePayment
    // this.state.paymentTypeCheck,
    // this.state.specialityTypeCheck,

    )
    // console.log("KOL TYPE", this.state.KOLType); 

    const isValid = this.validateCheckAll();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      // const url = global.websiteUrl+'/service/Researcher/researcher';
      const url = global.websiteUrl+'/service/KOL/KOL';
      let formData = new FormData();
      formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
      formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
      formData.append('KOl_Identifier', JSON.stringify(this.state.KOl_Identifier));
      formData.append('profile_photo', this.state.profile_photo);
      formData.append('Biography', JSON.stringify(this.state.Biography));
      formData.append('resume', this.state.BiographyResume);
      formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
      formData.append('Education', JSON.stringify(this.state.Education));
      formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
      formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
      formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
      formData.append('Commitees', JSON.stringify(this.state.Commitees));
      formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
      formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
      formData.append('Grants', JSON.stringify(this.state.Grants));
      formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
      formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
      formData.append('Interactions', JSON.stringify(this.state.Interactions));
      formData.append('PressItem', JSON.stringify(this.state.PressItem));
      formData.append('Presentations', JSON.stringify(this.state.Presentation));
      formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
      formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
      formData.append('kol_status', "Approved");
      formData.append('project_id', this.state.project_id);

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var data = response.data;
          console.log("data", data);
          var status = response.data.status;
          console.log("status", status);
          this.setState({
            ShowProcedure: false,
          })
         // if (response.data.status == true) {
            if (response.status === 200 ) {

            Swal.fire({
              icon: 'success',
              //text: response.data.message,
              text: 'Approved',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            this.setState({ RedirectManagerKolList: true })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        salutationCheck: '',
        doctorFullNameCheck: '',
        firstNameCheck: '',
        // middaleNameCheck: '',
        lastNameCheck: '',
        suffixCheck: '',
        // qualificationChech: '',
        experienceCheck: '',
        // mediSchoolCheck: '',
        // mediRegiNoCheck: '',
        // NpiCheck: '',
        brancdCheck: '',
        procedureCheck: '',
        specialityCheck: '',
        subSpecialityCheck: '',
        doctorContactCheck: '',
        doctorEmailCheck: '',
        doctorEmail2Check:'',
        // linkedinProfileCheck: '',
        // twitterHandleCheck: '',
        // assistanceContactCheck: '',
        // assistaceEmailCheck: '',
        // websiteCheck: '',
        addressCheck: '',
        address2Check: '',
        // faxCheck: '',
        // stateCheck: '',
        counterCheck: '',
        procedurePotentialCheck: '',
        zipCheck: '',
        // contractCheck: '',
        // workditails
        placeofWorkCheck: '',
        designantionCheck: '',
        // departmentCheck: '',
        // fromYearWDCheck : '',
        // toYearWDCheck : '',
        // Biography
        // citizenshipCheck: '',
        // licensedCheck: '',
        areaInterestCheck: '',
        // languageCheck: '',
        // workPhoneCheck: '',
        // workEmailCheck: '',
        // linkedinFollowersCheck: '',
        // twitterFollowersCheck: '',
        biographyCheck: '',
        expertiseCheck: '',
        // resumeCheck: '',
        // Professional Membership
        organizationCheck: '',
        // organizationTypeCheck: '',
        // sourceLinkCheck: '',
        // locationCheck: '',
        positionCheck: '',
        // tenureCheck: '',
        // tenureToCheck: '',
        // organizationWebsiteCheck: '',
        // Education
        instituteNameCheck: '',
        // InstituteTypeCheck: '',
        sourceLinkEducationCheck: '',
        degreesCheck: '',
        specialityEducationCheck: '',
        // yearsAttendedCheck: '',
        // Advisory Board
        // yearCheck: '',
        // advisoryBoardCheck: '',
        // topicCheck: '',
        // specialityAdvisoryCheck: '',
        // subSpecialityAdvisoryCheck: '',
        // procedureTypeAdvisoryCheck: '',
        companyCheck: '',
        typeOfCompanyCheck: '',
        roleCheck: '',
        // manualWeigAdvBoardCheck: '',
        // Awards N Honours
        // yearAwardsCheck: '',
        // yearToAwardsCheck: '',
        awardHonoursCheck: '',
        // typesCheck: '',
        // Books Monographs
        yearBooksCheck: '',
        titleCheck: '',
        authorCheck: '',
        publicationCheck: '',
        // editionCheck: '',
        // pagesCheck: '',
        // editordCheck: '',
        // Commitees
        // yearCommiteeCheck: '',
        // commiteeCheck: '',
        bodyOrgNameCommCheck: '',
        roleCommiteeCheck: '',
        // Clinical Trial Details
        studyDetailsCheck: '',
        sourceLinkClinicalCheck: '',
        startDateCheck: '',
        endDateCheck: '',
        typeClinicalCheck: '',
        phaseCheck: '',
        roleClinicalCheck: '',
        // conditionCheck: '',
        // treatmentCheck: '',
        // primaryOutcomeCheck: '',
        // secondaryOutcomeCheck: '',
        // sponsorCheck: '',
        // collaboratorsCheck: '',
        // specilityClinicalCheck: '',
        // subSpecialityClinicalCheck: '',
        // procedureClinicalCheck: '',
        studyLocationCheck: '',
        coverageCheck: '',
        // manualWeigClinicalTrialCheck: '',
        // Event Details
        // eventDescCheck: '',
        eventTopicCheck: '',
        sourceLinkEventCheck: '',
        // conditionEventCheck: '',
        // interventationCheck: '',
        // dateCheck: '',
        // sessionTypeCheck: '',
        roleEventCheck: '',
        // eventSponsorCheck: '',
        // totalAttendeesCheck: '',
        // countryCheck: '',
        // specialityEventCheck: '',
        // subSpecialityEventCheck: '',
        // procedureEventCheck: '',
        eventCheck: '',
        eventTypeCheck: '',
        // manualWeigEventDetailsCheck: '',
        // Grants
        companyGrantsCheck: '',
        // titleGrantCheck: '',
        // yearGrantCheck: '',
        // amountCheck: '',
        // coInvestigatorCheck: '',
        // Guidelines
        yearGuidelinesCheck: '',
        comanyGuidelinesCheck: '',
        titleGuidelinesCheck: '',
        // specialityGuidelinesCheck: '',
        // subSpecialityGuidelinesCheck: '',
        // procedureGuidelinesCheck: '',
        organizationGuidelinesCheck: '',
        typeOrgnizationCheck: '',
        roleGuidelineCheck: '',
        GuidelinesaffiliationtypeGuidelinesCheck: '',
        // Hospital Affiliations
        hospitalCheck: '',
        // cityCheck: '',
        // stateHospitalCheck: '',
        countryHospitalCheck: '',
        roleHospitalCheck : '',
        // fromYearHospitalCheck : '',
        // toYearHospitalCheck : '',
        // Interactions
        dateInteractionCheck: '',
        // opinionLeaderCheck: '',
        // enteredByCheck: '',
        // productCheck: '',
        // typeInteractionCheck: '',
        // modeCheck: '',
        timeCheck: '',
        timeZoneInteractionCheck: '',
        incentiveModeInteractionCheck: '',
        // activityCheck: '',
        // commentsCheck: '',
        // Press Item
        titlePressCheck: '',
        sourceLinkPressCheck: '',
        datePressCheck: '',
        // quotePressCheck: '',
        
        // Presentation
        titlePresentation: '',
        typePresentationCheck: '',
        // sponsorPresentationCheck: '',
        locationPresentationCheck: '',
        eventNamePresentationCheck: '',
        // datePresentationCheck: '',
        // Publication Details
        journalCheck: '',
        publicationTitleCheck: '',
        datePublicationCheck: '',
        sourceLinkPublicationCheck: '',
        positionPublicationCheck: '',
        coAutorCheck: '',
        keyWordsCheck: '',
        // specialityPublicationCheck: '',
        // subSpecialityPublicationCheck: '',
        // procedurePublicationCheck: '',
        typeOfJournelCheck: '',
        // manualWeighPublicationCheck: '',
        // SunshinePayment
        // dateSunshineCheck: '',
        // typeSunshineCheck: '',
        // deviceCheck: '',
        // locationSunshineCheck: '',
        // amountSunshineCheck: '',
        paymentTypeCheck: '',
        // OLAddressCheck: '',
        // specialityTypeCheck: '',
        // npiSunshineCheck: '',
      });

    }
    else {
      {
        Swal.fire({
          icon: 'error',
          text: 'Please Check All Fields',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    }
  }

  BackToManagerKolList() {
    this.setState({ RedirectManagerKolList: true })
  }

  ProcedureDropdown = async () => {
    console.log("n procedure dropdown")
    await this.setState({ showProcedureSubSpecialityDropdown: true })
  }

  ProcedureDropdownAdvBoard = async () => {
    console.log("In procedure dropdown adb board")
    await this.setState({ showProcedureSubSpecialityDropdownAdvBoard: true })
  }

  ProcedureDropdownClinicalTrial = async () => {
    console.log("In procedure dropdown adb board")
    await this.setState({ showProcedureSubSpecialityDropdownClinicalTrial: true })
  }

  ProcedureDropdownEventDetails = async () => {
    console.log("In procedure dropdown Event Details")
    await this.setState({ showProcedureSubSpecialityDropdownEventDetails: true })
  }

  ProcedureDropdownGuidelines = async () => {
    console.log("In procedure dropdown Guidelines")
    await this.setState({ showProcedureSubSpecialityDropdownGuidelines: true })
  }

  ProcedureDropdownPublicationDetails = async () => {
    console.log("In procedure Publication Details")
    await this.setState({ showProcedureSubSpecialityDropdownPublicationDetails: true })
  }

  render() {

    if (this.state.RedirectManagerKolList) {
      return (<Redirect to={{
        pathname: '/ManagerKolList',
        state: {
          kol_unique_id: this.state.kol_unique_id,
          manager_name: this.state.manager_name,
          manager_id: this.state.manager_id,
          project_id: this.state.project_id,
          p_name: this.state.p_name,
        }
      }} />)
    }

    // <div>
    //                                                 //         <img src={this.state.profile_photo} />
    //                                                 //     </div>
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;

    if (this.state.profile_photo_for_edit !== 'NULL' && this.state.profile_photo_for_edit) {
      // console.log("in profile photo for edit")
      console.log("image is", this.state.profile_photo_for_edit)
      imagePreview = (<div> <img style={{ height: '150px', width: '100px' }} src={this.state.profile_photo_for_edit} /> </div>)
    }
    else
      if (imagePreviewUrl) {
        console.log("in profile photo original")
        imagePreview = (<img style={{ height: '150px', width: '100px' }} src={imagePreviewUrl} />);
      } else {
        // console.log("in profile photo not selected")
        imagePreview = (<div className="photo-box"></div>);
      }



    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    let Country_list = this.state.CountryListData;
    let OptionsCountry = Country_list.map((Country_list) =>
      <option key={Country_list.id} value={Country_list.id}>
        {Country_list.country_name}</option>
    );

    let state_list = this.state.StateData;
    let OptionsState = state_list.map((state_list) =>
      <option key={state_list.id} value={state_list.id}>
        {state_list.state_name}</option>
    );

    // let City_list = this.state.CityData;
    // let OptionsCity = City_list.map((City_list) =>
    //   <option key={City_list.id} value={City_list.id}>
    //     {City_list.city_name}</option>
    // );

    let state_list_hospital = this.state.StateDataForHospitalAffiliations;
    let OptionStateHospitalAff = state_list_hospital.map((state_list_hospital) =>
      <option key={state_list_hospital.id} value={state_list_hospital.id}>
        {state_list_hospital.state_name}</option>
    );

    // let city_list_hospital = this.state.CityDataForHospitalAffiliations;
    // let OptionCityHospitalAff = city_list_hospital.map((city_list_hospital) =>
    //   <option key={city_list_hospital.id} value={city_list_hospital.id}>
    //     {city_list_hospital.city_name}</option>
    // );

    let employee_list = this.state.EmployeeListData;
    let OptionsEmployee = employee_list.map((employee_list) =>
      <option key={employee_list.emp_id} value={employee_list.emp_id}>
        {employee_list.emp_name}</option>
    );

    let citizenship_list = this.state.CitizenshipdDrop;
    let OptionsCitizenship = citizenship_list.map((citizenship_list) =>
      <option key={citizenship_list.id} value={citizenship_list.id}>
        {citizenship_list.citizenship}</option>
    );

    return (
      <body>
        <div style={{marginTop:'15vh' }}>
          <div>
            {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
              <LoadingBar
                progress='100'
                // onLoaderFinished={() => setProgress(0)}
                //  progress={progress}
                height={3} color="#A90007" transitionTime={4000}
                loaderSpeed={4000} />
            </div>
              : null}
          </div>
          {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}

          <div>
            <div>
              <ManagerHeader ManagerName={this.state.manager_name} />
              <div class="container">
                <div class="content-wrapper">
                  <div class="screen-headingbox">
                    <div class="back-section">
                      {/* <a>
                        <Link to="/ResercherDashboard">
                            <img 
                                src={require('../Assets/Images/back-arrow.svg')} 
                            />
                            Back
                        </Link>
                        </a> */}
                      <a onClick={this.BackToManagerKolList.bind(this)}>
                        <img src={require('../Assets/Images/back-arrow.svg')} />Back</a>
                    </div>
                    <h2>Profile Details </h2>
                  </div>
                  <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                      <div class="sidebar sticky">
                        <ul class="cetas-leftnav">
                          <li>
                            <a onClick={this.PersonalInformationFun}>
                              {
                                (this.state.PersonalInformationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Personal Information</text>
                                    {
                                      (this.state.salutationCheck || this.state.doctorFullNameCheck || this.state.firstNameCheck || this.state.lastNameCheck || this.state.experienceCheck ||
                                        this.state.procedureCheck || this.state.specialityCheck || this.state.subSpecialityCheck ||
                                        this.state.doctorContactCheck || this.state.doctorEmailCheck || this.state.doctorEmail2Check ||
                                        this.state.addressCheck || this.state.address2Check || this.state.counterCheck || this.state.zipCheck ||
                                        this.state.placeofWorkCheck || this.state.designantionCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>

                                ) : (
                                  <div>
                                    <text>Personal Information</text>
                                    {
                                      (this.state.salutationCheck || this.state.doctorFullNameCheck || this.state.firstNameCheck || this.state.lastNameCheck || this.state.experienceCheck ||
                                        this.state.procedureCheck || this.state.specialityCheck || this.state.subSpecialityCheck ||
                                        this.state.doctorContactCheck || this.state.doctorEmailCheck || this.state.doctorEmail2Check ||
                                        this.state.addressCheck || this.state.address2Check || this.state.counterCheck || this.state.zipCheck ||
                                        this.state.placeofWorkCheck || this.state.designantionCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BiographyFun}>
                              {
                                (this.state.BiographyFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Biography</text>
                                    {
                                      (this.state.PersonalInformation.kol_type == 'KOL Community' ||
                                        this.state.PersonalInformation.kol_type == '') ? (
                                        (this.state.areaInterestCheck ||
                                          this.state.biographyCheck 
                                        ) ? (
                                          <span class="dot"></span>
                                        ) : (
                                          <div></div>
                                        )
                                      ) : (
                                        (this.state.areaInterestCheck ||
                                          this.state.biographyCheck 
                                        ) ? (
                                          <span class="dot"></span>
                                        ) : (
                                          <div></div>
                                        )
                                      )


                                    }
                                  </div>

                                ) : (
                                  <div><text>Biography</text>
                                    {
                                      (this.state.PersonalInformation.kol_type == 'KOL Community' || this.state.PersonalInformation.kol_type == '') ? (
                                        (this.state.areaInterestCheck ||
                                          this.state.biographyCheck
                                        ) ? (
                                          <span class="dot"></span>
                                        ) : (
                                          <div></div>
                                        )
                                      ) : (
                                        (this.state.areaInterestCheck ||
                                          this.state.biographyCheck
                                        ) ? (
                                          <span class="dot"></span>
                                        ) : (
                                          <div></div>
                                        )
                                      )


                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ProfessionalMembershipFun}>
                              {
                                (this.state.ProfessionalMembershipFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Professional Membership</text>
                                    {
                                      (this.state.organizationCheck ||
                                        this.state.positionCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Professional Membership</text>
                                    {
                                      (this.state.organizationCheck ||
                                        this.state.positionCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.EducationFun}>
                              {
                                (this.state.EducationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Education</text>
                                    {
                                      (this.state.instituteNameCheck || this.state.sourceLinkEducationCheck ||
                                        this.state.degreesCheck || this.state.specialityEducationCheck
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Education</text>
                                    {
                                      (this.state.instituteNameCheck || this.state.sourceLinkEducationCheck ||
                                        this.state.degreesCheck || this.state.specialityEducationCheck
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.AdvisoryBoardFun}>
                              {
                                (this.state.AdvisoryBoardFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Advisory Board</text>
                                    {
                                      ( this.state.companyCheck || this.state.typeOfCompanyCheck ||
                                        this.state.roleCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Advisory Board</text>
                                    {
                                      ( this.state.companyCheck || this.state.typeOfCompanyCheck ||
                                        this.state.roleCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.AwardsAndHonoursFun}>
                              {
                                (this.state.AwardsAndHonoursFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Awards & Honours</text>
                                    {
                                      ( this.state.awardHonoursCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Awards & Honours</text>
                                    {
                                      ( this.state.awardHonoursCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BooksAndMonographFun}>
                              {
                                (this.state.BooksAndMonographFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Books & Monographs</text>
                                    {
                                      (this.state.yearBooksCheck || this.state.titleCheck || this.state.authorCheck ||
                                        this.state.publicationCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Books & Monographs</text>
                                    {
                                      (this.state.yearBooksCheck || this.state.titleCheck || this.state.authorCheck ||
                                        this.state.publicationCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.CommiteesFun}>
                              {
                                (this.state.CommiteesFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Commitees</text>
                                    {
                                      (this.state.bodyOrgNameCommCheck || this.state.roleCommiteeCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Commitees</text>
                                    {
                                      (this.state.bodyOrgNameCommCheck || this.state.roleCommiteeCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ClinicalTrialDetailsFun}>
                              {
                                (this.state.ClinicalTrialDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Clinical Trial Details</text>
                                    {
                                      (this.state.studyDetailsCheck || this.state.sourceLinkClinicalCheck || this.state.startDateCheck || this.state.endDateCheck ||
                                        this.state.typeClinicalCheck ||
                                        this.state.phaseCheck || this.state.roleClinicalCheck ||
                                        this.state.studyLocationCheck || this.state.coverageCheck
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Clinical Trial Details</text>
                                    {
                                      (this.state.studyDetailsCheck || this.state.sourceLinkClinicalCheck || this.state.startDateCheck ||
                                        this.state.endDateCheck || this.state.typeClinicalCheck ||
                                        this.state.phaseCheck || this.state.roleClinicalCheck ||
                                        this.state.studyLocationCheck || this.state.coverageCheck 
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {
                            (this.state.PersonalInformation.kol_type == 'Hospital Procurement'
                            ) ? (
                              <div></div>
                            ) : (
                              <li>
                                <a onClick={this.EventsDetailsFun}>
                                  {
                                    (this.state.EventsDetailsFlag) ? (
                                      <div>
                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Events Details</text>
                                        {
                                          (this.state.eventTopicCheck || this.state.sourceLinkEventCheck ||
                                            this.state.roleEventCheck || this.state.eventCheck ||
                                            this.state.eventTypeCheck ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    ) : (
                                      <div>
                                        <text>Events Details</text>
                                        {
                                          (this.state.eventTopicCheck || this.state.sourceLinkEventCheck ||
                                            this.state.roleEventCheck || this.state.eventCheck ||
                                            this.state.eventTypeCheck ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    )
                                  }
                                </a>
                              </li>
                            )
                          }

                          <li>
                            <a onClick={this.GrantsFun}>
                              {
                                (this.state.GrantsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Grants</text>
                                    {
                                      (this.state.companyGrantsCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Grants</text>
                                    {
                                      (this.state.companyGrantsCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {
                            (this.state.PersonalInformation.kol_type == 'Hospital Procurement'
                            ) ? (
                              <div></div>
                            ) : (
                              <li>
                                <a onClick={this.GuidelinesFun}>
                                  {
                                    (this.state.GuidelinesFlag) ? (
                                      <div>
                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Guidelines</text>
                                        {
                                          (this.state.yearGuidelinesCheck || this.state.comanyGuidelinesCheck ||
                                            this.state.titleGuidelinesCheck || this.state.organizationGuidelinesCheck ||
                                            this.state.typeOrgnizationCheck || this.state.roleGuidelineCheck || this.state.GuidelinesaffiliationtypeGuidelineCheck  ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    ) : (
                                      <div>
                                        <text>Guidelines</text>
                                        {
                                          (this.state.yearGuidelinesCheck || this.state.comanyGuidelinesCheck ||
                                            this.state.titleGuidelinesCheck ||  this.state.organizationGuidelinesCheck ||
                                            this.state.typeOrgnizationCheck || this.state.roleGuidelineCheck || this.state.GuidelinesaffiliationtypeGuidelineCheck) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    )
                                  }
                                </a>
                              </li>
                            )
                          }

                          <li>
                            <a onClick={this.HospitalAffiliationsFun}>
                              {
                                (this.state.HospitalAffiliationsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Hospital Affiliations</text>
                                    {
                                      (this.state.hospitalCheck || this.state.countryHospitalCheck ||
                                        this.state.roleHospitalCheck 
                                        ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Hospital Affiliations</text>
                                    {
                                      (this.state.hospitalCheck || this.state.countryHospitalCheck || 
                                        this.state.roleHospitalCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.InteractionsFun}>
                              {
                                (this.state.InteractionsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Interactions</text>
                                    {
                                      (this.state.dateInteractionCheck || this.state.timeCheck ||
                                        this.state.timeZoneInteractionCheck || this.state.incentiveModeInteractionCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Interactions</text>
                                    {
                                      (this.state.dateInteractionCheck || this.state.timeCheck ||
                                        this.state.timeZoneInteractionCheck || this.state.incentiveModeInteractionCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )

                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {
                            (this.state.PersonalInformation.kol_type == 'Payers Community' ||
                              this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                              this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                              this.state.PersonalInformation.kol_type == 'Advocacy Group'
                              // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
                            ) ? (
                              <div></div>
                            ) : (
                              <li>
                                <a onClick={this.PressItemFun}>
                                  {
                                    (this.state.PressItemFlag) ? (
                                      <div>
                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Press Item</text>
                                        {
                                          (this.state.titlePressCheck || this.state.sourceLinkPressCheck || 
                                          this.state.datePressCheck  ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    ) : (
                                      <div>
                                        <text>Press Item</text>
                                        {
                                          (this.state.titlePressCheck || this.state.sourceLinkPressCheck || 
                                          this.state.datePressCheck  ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )

                                        }
                                      </div>
                                    )
                                  }
                                </a>
                              </li>
                            )
                          }

                          {
                            (this.state.PersonalInformation.kol_type == 'Payers Community' ||
                              this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                              this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                              this.state.PersonalInformation.kol_type == 'Advocacy Group'
                              // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'  
                            ) ? (
                              <div></div>
                            ) : (
                              <li>
                                <a onClick={this.PresentationsFun}>
                                  {
                                    (this.state.PresentationsFlag) ? (
                                      <div>
                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Presentations</text>
                                        {
                                          (this.state.titlePresentation || this.state.typePresentationCheck ||
                                            this.state.locationPresentationCheck || this.state.eventNamePresentationCheck
                                          ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )
                                        }
                                      </div>
                                    ) : (
                                      <div>
                                        <text>Presentations</text>
                                        {
                                          (this.state.titlePresentation || this.state.typePresentationCheck ||
                                            this.state.locationPresentationCheck || this.state.eventNamePresentationCheck 
                                          ) ? (
                                            <span class="dot"></span>
                                          ) : (
                                            <div></div>
                                          )
                                        }
                                      </div>
                                    )
                                  }
                                </a>
                              </li>
                            )
                          }

                          <li>
                            <a onClick={this.PublicationDetailsFun}>
                              {
                                (this.state.PublicationDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Publication Details</text>
                                    {
                                      (this.state.journalCheck || this.state.publicationTitleCheck ||
                                        this.state.datePublicationCheck || this.state.sourceLinkPublicationCheck ||
                                        this.state.positionPublicationCheck || this.state.coAutorCheck || 
                                        this.state.keyWordsCheck || this.state.typeOfJournelCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Publication Details</text>
                                    {
                                      (this.state.journalCheck || this.state.publicationTitleCheck ||
                                        this.state.datePublicationCheck || this.state.sourceLinkPublicationCheck ||
                                        this.state.positionPublicationCheck || this.state.coAutorCheck || 
                                        this.state.keyWordsCheck ||  this.state.typeOfJournelCheck ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.SunshinePaymentFun}>
                              {
                                (this.state.SunshinePaymentFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Sunshine Payment</text>
                                    {
                                      (
                                        this.state.paymentTypeCheck
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Sunshine Payment</text>
                                    {
                                      (
                                        this.state.paymentTypeCheck
                                      ) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ContractFunc}>
                              {
                                (this.state.ContractFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Contract</text>
                                    {/* {
                                                ( this.state.paymenttypeErr || this.state.specialitytypeSunshineErr ) ?(
                                                    <span class="dot"></span>
                                                ) : (
                                                    <div></div>
                                                )
                                            } */}
                                  </div>
                                ) : (
                                  <div>
                                    <text>Contract</text>
                                    {/* {
                                                ( this.state.paymenttypeErr || this.state.specialitytypeSunshineErr ) ?(
                                                    <span class="dot"></span>
                                                ) : (
                                                    <div></div>
                                                )
                                            } */}
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ProcedurePotentialFun}>
                              {
                                (this.state.ProcedurePotentialFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Procedure Potential</text>
                                    {
                                      (this.state.procedurePotentialCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                ) : (
                                  <div>
                                    <text>Procedure Potential</text>
                                    {
                                      (this.state.procedurePotentialCheck) ? (
                                        <span class="dot"></span>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li></li>
                        </ul>
                        <div style={{ marginTop: '50%' }}></div>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="scroll-box" style={{marginTop:'-100px' }}>
                        <a id="overall-details"></a>
                        {/* Personal information form */}
                        {
                          (this.state.PersonalInformationFlag == true) ? (
                            <section style={{ marginBottom: '30%' }}>
                              <h3 class="mt-30">Personal information </h3>
                              <span class="mandatory">Note: All * fields are mandatory </span>
                              <div class="scrollable-box">
                                <form>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        {/* <label for="exampleInputEmail1">Select KOL type </label>
                                                    <span class="mandatory">*</span>
                                                        <select class="form-control" 
                                                            id="exampleFormControlSelect1">
                                                            <option>Select Speciality</option>
                                                            <option>Researcher</option>
                                                            <option>Manager</option>
                                                            <option>Data entry</option>
                                                            <option>5</option>
                                                        </select> */}
                                        <label for="exampleFormControlTextarea1">
                                          Select KOL Type <span class="mandatory">*</span></label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          autofocus="true"
                                          name="kol_type"
                                          value={this.state.PersonalInformation.kol_type}
                                        // onChange={async(e) => {
                                        //     let { PersonalInformation } = this.state;
                                        //     PersonalInformation.kol_type = e.target.value;
                                        //     await this.setState({ PersonalInformation, });
                                        //     console.log("PersonalInformation.kol_type",this.state.PersonalInformation.kol_type)
                                        //     {this.afterSelectingKOL()}
                                        //     // below afterSelectSalutation call is just if user 1st selects the saalutation and then selects KOL
                                        //     {this.afterSelectSalutation()}
                                        // }}  
                                        >
                                            <option value="" >--Select KOL--</option>
                                                                            <option value="KOL Community" >HCP</option>
                                                                            <option value="Payers Community" >Payers</option>
                                                                            <option value="HTA Experts" >HTA Experts</option>
                                                                            <option value="Hospital Procurement" >Hospital Procurement Experts</option>
                                                                            <option value="Advocacy Group" >Advocacy Experts</option>
                                                                            <option value="MedTech Experts" >MedTech Experts</option>

                                        </Input>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{
                                    color: "red", fontSize: "12px",
                                    marginBottom: "5px",
                                  }}>
                                    {this.state.koltypeErr}</div>
                                  <hr />
                                  <div class="row">
                                    <div class="col-md-4">
                                      {imagePreview}
                                      {
                                        <div>
                                          <input type='file'
                                            id="myInput"
                                            accept="image/*"
                                            onChange={this.getPhoto} hidden />
                                          {/* <label htmlFor="myInput" 
                                                                    style={{color:'blue', fontSize: "15px",}}>
                                                                Upload Photo
                                                            </label> */}
                                        </div>
                                      }
                                    </div>

                                    <div class="col-md-3" style={{ marginLeft: '-8%' }}>
                                      <div class="form-group bottom-seperator pb-30">
                                        <label for="exampleFormControlTextarea1">SI. No.</label>
                                        {/* <input class="form-control" type="text" 
                                                        placeholder={this.state.SrNO} 
                                                        readonly="" /> */}
                                        <text className="form-control">{this.state.PersonalInformation.id}</text>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">
                                          Unique ID</label>
                                        {/* <input class="form-control" type="text" 
                                                                placeholder="UTFI8782" readonly="" /> */}
                                        {/* <text className="form-control">{this.state.PersonalInformation.kol_unique_id}</text> */}
                                        <text className="form-control">{this.state.kol_unique_id}</text>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="form-group">

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Salutation <span class="mandatory">*</span></label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          name="salutation"
                                          value={this.state.PersonalInformation.salutation}
                                        // onChange={async(e) => {
                                        //     let { PersonalInformation } = this.state;
                                        //     PersonalInformation.salutation = e.target.value;
                                        //     await this.setState({ PersonalInformation });
                                        //     {this.afterSelectSalutation()}
                                        // }}  
                                        >
                                          <option value="" >--Select Salutation--</option>
                                          <option value="Mr." >Mr.</option>
                                          <option value="Ms." >Ms.</option>
                                          <option value="Mrs." >Mrs.</option>
                                          <option value="Dr." >Dr.</option>
                                          <option value="Prof." >Prof.</option>
                                          <option value="Other" >Other</option>
                                        </Input>
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>
                                          {this.state.salutationErr}
                                        </div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="salutation_comment"
                                          value={this.state.PersonalInformation.salutation_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.salutation_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="salutation_status"
                                          checked={this.state.PersonalInformation.salutation_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.salutation_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.SalutationAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div   style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsSalutation.map((itemsSalutation,index) => (
                                                <div className="tag-item" key={itemsSalutation}>
                                                  {/* {itemsSalutation} */}
                                                  <a href={itemsSalutation} target="_blank">
                                                    <text>{index+1}.{itemsSalutation}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1" >
                                          Full Name <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input3" placeholder="Enter Full Name"
                                          name="doctor_full_name"
                                          value={this.state.PersonalInformation.doctor_full_name}
                                        />
                                        <div className="loginErrCreateProject">{this.state.doctorfullnameErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="doctor_full_name_comment"
                                          value={this.state.PersonalInformation.doctor_full_name_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_full_name_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="doctor_full_name_status"
                                          checked={this.state.PersonalInformation.doctor_full_name_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_full_name_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.DFullNameAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsDoctorFullName.map((itemsDoctorFullName,index) => (
                                                <div className="tag-item" key={itemsDoctorFullName}>
                                                  <a href={itemsDoctorFullName} target="_blank">
                                                    <text>{index+1}.{itemsDoctorFullName}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                      }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1" >
                                          First Name <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input5" placeholder="Enter First Name"
                                          name="first_name"
                                          value={this.state.PersonalInformation.first_name}
                                        />
                                        <div className="loginErrCreateProject">{this.state.firstnameErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="first_name_comment"
                                          value={this.state.PersonalInformation.first_name_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.first_name_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="first_name_status"
                                          checked={this.state.PersonalInformation.first_name_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.first_name_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.FirstNameAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsFirstName.map((itemsFirstName, index) => (
                                                <div className="tag-item" key={itemsFirstName}>
                                                  {/* {itemsFirstName} */}
                                                  <a href={itemsFirstName} target="_blank">
                                                    <text>{index+1}.{itemsFirstName}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                      }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Middle Name </label>
                                        <input type="text" class="form-control disabled"
                                          id="input5" placeholder="Enter Middle Name"
                                          name="middle_name"
                                          value={this.state.PersonalInformation.middle_name}
                                        />
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="middle_name_comment"
                                          value={this.state.PersonalInformation.middle_name_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.middle_name_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="middle_name_status"
                                          checked={this.state.PersonalInformation.middle_name_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.middle_name_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {
                                          (!this.state.MiddleNameAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsMiddleName.map((itemsMiddleName, index) => (
                                                <div className="tag-item" key={itemsMiddleName}>
                                                  {/* {itemsMiddleName} */}
                                                  <a href={itemsMiddleName} target="_blank">
                                                    <text>{index+1}.{itemsMiddleName}</text> <br/>
                                                  </a>

                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1" >
                                          Last Name <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input5" placeholder="Enter Last Name"
                                          name="last_name"
                                          value={this.state.PersonalInformation.last_name}
                                        />
                                        <div className="loginErrCreateProject">{this.state.lastnameErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="last_name_comment"
                                          value={this.state.PersonalInformation.last_name_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.last_name_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="last_name_status"
                                          checked={this.state.PersonalInformation.last_name_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.last_name_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.LastNameAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }} >
                                              {this.state.itemsLastName.map((itemsLastName, index) => (
                                                <div className="tag-item" key={itemsLastName}>
                                                  {/* {itemsLastName} */}
                                                  <a href={itemsLastName} target="_blank">
                                                    <text>{index+1}.{itemsLastName}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1" >
                                          Suffix <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input5" placeholder="Enter Suffix"
                                          name="suffix"
                                          value={this.state.PersonalInformation.suffix}
                                        />
                                        {/* <div className="loginErrCreateProject">{this.state.lastnameErr}</div> */}
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="suffix_comments"
                                          value={this.state.PersonalInformation.suffix_comments}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.suffix_comments = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="suffix_status"
                                          checked={this.state.PersonalInformation.suffix_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.suffix_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.SuffixAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsSuffix.map((itemsSuffix,index) => (
                                                <div className="tag-item" key={itemsSuffix}>
                                                  {/* {itemsLastName} */}
                                                  <a href={itemsSuffix} target="_blank">
                                                    <text>{index+1}.{itemsSuffix}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Clinical Experience <span class="mandatory">*</span></label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          name="experience"
                                          value={this.state.PersonalInformation.experience}
                                        >
                                          <option value="" >--Select Experience--</option>
                                          <option value="1 year" >1 year</option>
                                          <option value="2 years" >2 years</option>
                                          <option value="3 years" >3 years</option>
                                          <option value="4 years" >4 years</option>
                                          <option value="5 years" >5 years</option>
                                          <option value="6 years" >6 years</option>
                                          <option value="7 years" >7 years</option>
                                          <option value="8 years" >8 years</option>
                                          <option value="9 years" >9 years</option>
                                          <option value="10 years" >10 years</option>
                                          <option value="11 years" >11 years</option>
                                          <option value="12 years" >12 years</option>
                                          <option value="13 years" >13 years</option>
                                          <option value="14 years" >14 years</option>
                                          <option value="15 years" >15 years</option>
                                          <option value="16 years" >16 years</option>
                                          <option value="17 years" >17 years</option>
                                          <option value="18 years" >18 years</option>
                                          <option value="19 years" >19 years</option>
                                          <option value="20 years" >20 years</option>
                                          <option value="21 years" >21 years</option>
                                          <option value="22 years" >22 years</option>
                                          <option value="23 years" >23 years</option>
                                          <option value="24 years" >24 years</option>
                                          <option value="25+ years" >25+ years</option>
                                          <option value="N/A" >N/A</option>
                                        </Input>
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>
                                          {this.state.experienceErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="experience_comment"
                                          value={this.state.PersonalInformation.experience_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.experience_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="experience_status"
                                          checked={this.state.PersonalInformation.experience_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.experience_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.ExperienceAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsExperience.map((itemsExperience, index) => (
                                                <div className="tag-item" key={itemsExperience}>
                                                  {itemsExperience}
                                                  <a href={itemsExperience} target="_blank">
                                                    <text>{index+1}.{itemsExperience}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                      }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Qualification </label>
                                        <input type="text" class="form-control disabled"
                                          id="input6" placeholder="Enter Qualification "
                                          name="qualification"
                                          value={this.state.PersonalInformation.qualification}
                                        />
                                        {/* <div className="loginErrCreateProject">{this.state.qualificationErr}</div>                                            */}
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="qualification_comment"
                                          value={this.state.PersonalInformation.qualification_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.qualification_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="qualification_status"
                                          checked={this.state.PersonalInformation.qualification_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.qualification_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {
                                          (!this.state.QualificationAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsQualification.map((itemsQualification,index) => (
                                                <div className="tag-item" key={itemsQualification}>
                                                  {/* {itemsQualification} */}
                                                  <a href={itemsQualification} target="_blank">
                                                    <text>{index+1}.{itemsQualification}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    {/* {this.forMedicalInformation()} */}
                                    {
                                      (this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                                        this.state.PersonalInformation.kol_type == 'Payers Community' ||
                                        this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                                        this.state.PersonalInformation.kol_type == 'Advocacy Group'
                                        // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
                                      ) ? (
                                        (this.state.PersonalInformation.salutation == 'Dr.') ? (
                                          <div>

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                  {/* Medical Schools  */}
                                                  Alma Mater
                                                </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter Alma Mater "
                                                  name="medical_schools"
                                                  value={this.state.PersonalInformation.medical_schools}
                                                />
                                                {/* <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>                                           */}
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="medical_schools_comment"
                                                  value={this.state.PersonalInformation.medical_schools_comment}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_schools_comment = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="medical_schools_status"
                                                  checked={this.state.PersonalInformation.medical_schools_status}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_schools_status = e.target.checked;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.MedicalSchoolsAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {this.state.itemsMedicalSchool.map((itemsMedicalSchool,index) => (
                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                          {/* {itemsMedicalSchool} */}
                                                          <a href={itemsMedicalSchool} target="_blank">
                                                            <text>{index+1}.{itemsMedicalSchool}</text><br/>
                                                          </a>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )
                                              }

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                  License No. </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter License No. "
                                                  name="medical_registration_no"
                                                  value={this.state.PersonalInformation.medical_registration_no}
                                                />
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="medical_registration_no_comment"
                                                  value={this.state.PersonalInformation.medical_registration_no_comment}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_registration_no_comment = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="medical_registration_no_status"
                                                  checked={this.state.PersonalInformation.medical_registration_no_status}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_registration_no_status = e.target.checked;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.MedicalRegistrationNoAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo,index) => (
                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                          {/* {itemsMedicalRegisterNo} */}
                                                          <a href={itemsMedicalRegisterNo} target="_blank">
                                                            <text>{index+1}.{itemsMedicalRegisterNo}</text> <br/>
                                                          </a>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )
                                              }



                                            {/* {
                                                                ( this.state.PersonalInformation.country_id == '1' ) ? ( */}
                                            <div>

                                              <div class="row">
                                                <div class="col-md-5">
                                                  <label for="exampleFormControlTextarea1">
                                                    NPI No. </label>
                                                  <input type="text" maxlength="5" class="form-control disabled"
                                                    id="input7" placeholder="Enter NPI No. "
                                                    name="NPI_no"
                                                    value={this.state.PersonalInformation.NPI_no}
                                                  />
                                                  
                                                </div>

                                                <div class="col-md-6">
                                                  <label for="exampleFormControlTextarea1">
                                                    Comment</label>
                                                  <input type="text" class="form-control-addsource-Personal"
                                                    id="input3" placeholder="Enter Comment"
                                                    name="NPI_no_comment"
                                                    value={this.state.PersonalInformation.NPI_no_comment}
                                                    onChange={(e) => {
                                                      let { PersonalInformation } = this.state;
                                                      PersonalInformation.NPI_no_comment = e.target.value;
                                                      this.setState({ PersonalInformation });
                                                    }}
                                                  />
                                                </div>

                                                <div class="col-md-1">
                                                  <label for="exampleFormControlTextarea1">
                                                    Check</label>
                                                  <input type='checkbox'
                                                    class="form-control-addsource-Personal-ckeckbox"
                                                    // id="input3"
                                                    name="NPI_no_status"
                                                    checked={this.state.PersonalInformation.NPI_no_status}
                                                    onChange={(e) => {
                                                      let { PersonalInformation } = this.state;
                                                      PersonalInformation.NPI_no_status = e.target.checked;
                                                      this.setState({ PersonalInformation });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                                {
                                                    (!this.state.NPINoAddSource) ? (
                                                      <div></div>
                                                    ) : (
                                                      <div style={{ marginTop: '8%' }}>
                                                        {this.state.itemsNpiNo.map((itemsNpiNo,index) => (
                                                          <div className="tag-item" key={itemsNpiNo}>
                                                            {/* {itemsNpiNo} */}
                                                            <a href={itemsNpiNo} target="_blank">
                                                              <text>{index+1}.{itemsNpiNo}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))}
                                                      </div>
                                                    )
                                                }
                                              <hr />
                                            </div>
                                            {/* //     ): (
                                                            //         <div></div>
                                                            //     )
                                                            // } */}
                                          </div>
                                        ) :
                                          (
                                            <div></div>
                                          )

                                      ) :
                                        (
                                          <div>

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  {/* Medical Schools  */}
                                                  Alma Mater
                                                </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter Alma Mater"
                                                  name="medical_schools"
                                                  value={this.state.PersonalInformation.medical_schools}
                                                />
                                                <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="medical_schools_comment"
                                                  value={this.state.PersonalInformation.medical_schools_comment}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_schools_comment = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="medical_schools_status"
                                                  checked={this.state.PersonalInformation.medical_schools_status}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_schools_status = e.target.checked;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.MedicalSchoolsAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {this.state.itemsMedicalSchool.map((itemsMedicalSchool, index) => (
                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                          {/* {itemsMedicalSchool} */}
                                                          <a href={itemsMedicalSchool} target="_blank">
                                                            <text>{index+1}.{itemsMedicalSchool}</text><br/>
                                                          </a>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  License No. </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter License No. "
                                                  name="medical_registration_no"
                                                  value={this.state.PersonalInformation.medical_registration_no}
                                                />
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="medical_registration_no_comment"
                                                  value={this.state.PersonalInformation.medical_registration_no_comment}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_registration_no_comment = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="medical_registration_no_status"
                                                  checked={this.state.PersonalInformation.medical_registration_no_status}
                                                  onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.medical_registration_no_status = e.target.checked;
                                                    this.setState({ PersonalInformation });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.MedicalRegistrationNoAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo, index) => (
                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                          {/* {itemsMedicalRegisterNo} */}
                                                          <a href={itemsMedicalRegisterNo} target="_blank">
                                                            <text>{index+1}.{itemsMedicalRegisterNo}</text><br/>
                                                          </a>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )
                                                }
                                            <hr />


                                            {/* {
                                                            ( this.state.PersonalInformation.country_id == '1' ) ? ( */}
                                            <div>

                                              <div class="row">
                                                <div class="col-md-5">
                                                  <label for="exampleFormControlTextarea1" >
                                                    NPI No. </label>
                                                  <input type="text" maxlength="5" class="form-control disabled"
                                                    id="input7" placeholder="Enter NPI No. "
                                                    name="NPI_no"
                                                    value={this.state.PersonalInformation.NPI_no}
                                                  />
                                                 
                                                </div>

                                                <div class="col-md-6">
                                                  <label for="exampleFormControlTextarea1">
                                                    Comment</label>
                                                  <input type="text" class="form-control-addsource-Personal"
                                                    id="input3" placeholder="Enter Comment"
                                                    name="NPI_no_comment"
                                                    value={this.state.PersonalInformation.NPI_no_comment}
                                                    onChange={(e) => {
                                                      let { PersonalInformation } = this.state;
                                                      PersonalInformation.NPI_no_comment = e.target.value;
                                                      this.setState({ PersonalInformation });
                                                    }}
                                                  />
                                                </div>

                                                <div class="col-md-1">
                                                  <label for="exampleFormControlTextarea1">
                                                    Check</label>
                                                  <input type='checkbox'
                                                    class="form-control-addsource-Personal-ckeckbox"
                                                    // id="input3"
                                                    name="NPI_no_status"
                                                    checked={this.state.PersonalInformation.NPI_no_status}
                                                    onChange={(e) => {
                                                      let { PersonalInformation } = this.state;
                                                      PersonalInformation.NPI_no_status = e.target.checked;
                                                      this.setState({ PersonalInformation });
                                                    }}
                                                  />
                                                </div>


                                              </div>
                                                {
                                                    (!this.state.NPINoAddSource) ? (
                                                      <div></div>
                                                    ) : (
                                                      <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                        {this.state.itemsNpiNo.map((itemsNpiNo, index) => (
                                                          <div className="tag-item" key={itemsNpiNo}>
                                                            {/* {itemsNpiNo} */}
                                                            <a href={itemsNpiNo} target="_blank">
                                                              <text>{index+1}.{itemsNpiNo}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))}
                                                      </div>
                                                    )
                                                  }
                                              <hr />


                                            </div>
                                            {/* //     ): (
                                                        //         <div></div>
                                                        //     )
                                                        // } */}


                                          </div>
                                        )
                                    }

                                    {/* <label for="exampleFormControlTextarea1" style={{marginTop:'4%'}}>
                                               Brand </label>
                                            <input type="text" class="form-control" 
                                                id="input7" placeholder="Enter Brand " 
                                                name="brand"
                                                value={this.state.PersonalInformation.brand} 
                                                onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.brand = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                }}
                                            />
                                            <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.BrandAddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.BrandAddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                        {this.state.itemsBrand.map((itemsBrand) => (
                                                        <div className="tag-item" key={itemsBrand}>
                                                            {itemsBrand}
                                                            <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDeleteBrand(itemsBrand)}
                                                            >
                                                            &times;
                                                            </button>
                                                        </div>
                                                        ))}
                                                        <input
                                                            class="form-control-addsource-Personal" 
                                                            value={this.state.valuePersonalInfoAddSource}
                                                            placeholder="Type or paste source link and press `Enter`"
                                                            onKeyDown={this.handleKeyDownBrand}
                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                        />
                                                    </div>
                                                )
                                            } */}

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Speciality <span class="mandatory">*</span></label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          name="specialty_id"
                                          value={this.state.PersonalInformation.specialty_id}
                                        // onChange={async(e) => {
                                        //     let { PersonalInformation } = this.state;
                                        //     PersonalInformation.specialty_id = e.target.value;
                                        //     PersonalInformation.procedure_type_id = '';
                                        //     PersonalInformation.sub_specialty_id = '';
                                        //     await this.setState({ PersonalInformation });
                                        //     await this.setState({
                                        //         ProcedureType: [],
                                        //         SubSpecialityType: [],
                                        //         ProcedureTypeDefaultValues: [],
                                        //         SubSpecialityTypeDefaultValues: [],
                                        //         showProcedureSubSpecialityDropdown: false,
                                        //     })
                                        //     {this.getProcedureList()} 
                                        //     {this.getSubSpecialityList()}


                                        //     console.log('ProcedureType',this.state.ProcedureType)
                                        //     console.log('PersonalInformation.procedure_type_id',this.state.PersonalInformation)
                                        //     {this.ProcedureDropdown()}
                                        // }}                                                    
                                        >
                                          <option value="" >--Select Speciality--</option>
                                          {OptionsSpeciality}
                                        </Input>
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>
                                          {this.state.specialityErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="specialty_comment"
                                          value={this.state.PersonalInformation.specialty_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.specialty_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="specialty_status"
                                          checked={this.state.PersonalInformation.specialty_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.specialty_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.SpecialityAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemSpeciality.map((itemSpeciality,index) => (
                                                <div className="tag-item" key={itemSpeciality}>
                                                  {/* {itemSpeciality} */}
                                                  <a href={itemSpeciality} target="_blank">
                                                    <text>{index+1}.{itemSpeciality}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />




                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                        Secondary Speciality </label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          name="specialty2_id"
                                          value={this.state.PersonalInformation.specialty2_id}
                                                                                           
                                        >
                                          <option value="" >--Select Secondary Speciality--</option>
                                          {OptionsSpeciality}
                                        </Input>
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>
                                          {this.state.specialityErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="specialty2_comment"
                                          value={this.state.PersonalInformation.specialty2_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.specialty2_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="specialty2_status"
                                          checked={this.state.PersonalInformation.specialty2_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.specialty2_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.Speciality2AddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemSpeciality2.map((itemSpeciality2,index) => (
                                                <div className="tag-item" key={itemSpeciality2}>
                                                  {/* {itemSpeciality} */}
                                                  <a href={itemSpeciality2} target="_blank">
                                                    <text>{index+1}.{itemSpeciality2}</text> <br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />




                                    {/* Sub Speciality Dropdown starts here */}
                                    {
                                      // ( !this.state.PersonalInformation.specialty_id ) ? (
                                      (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                        <div></div>
                                      ) : (
                                        <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1" >
                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydata}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='id'
                                                textField='sub_speciality_type'
                                                defaultValue={this.state.SubSpecialityType}

                                              />
                                              <div style={{
                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                marginBottom: "5px",
                                              }}>{this.state.subspecialitytypeErr}</div>
                                             
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_specialty_comment"
                                                value={this.state.PersonalInformation.sub_specialty_comment}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.sub_specialty_comment = e.target.value;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_specialty_status"
                                                checked={this.state.PersonalInformation.sub_specialty_status}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.sub_specialty_status = e.target.checked;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>
                                          </div>
                                            {
                                                (!this.state.SubSpecialityTypeAddSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                    {this.state.itemsSubSpeciality.map((itemsSubSpeciality, index) => (
                                                      <div className="tag-item" key={itemsSubSpeciality}>
                                                        {/* {itemsSubSpeciality} */}
                                                        <a href={itemsSubSpeciality} target="_blank">
                                                          <text>{index+1}.{itemsSubSpeciality}</text> <br/>
                                                        </a>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )
                                              }
                                          <hr />

                                        </div>
                                      )
                                    }

                                    {/* Procedure Dropdown starts here */}
                                    {
                                      // ( !this.state.PersonalInformation.specialty_id ) ? (
                                      (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                        <div></div>
                                      ) : (
                                        <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1" >
                                                Procedure Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureData}
                                                placeholder="--Select Procedure Type--"
                                                valueField='id'
                                                textField='procedure_type'
                                                defaultValue={this.state.ProcedureType}

                                              />
                                              <div style={{
                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                marginBottom: "5px",
                                              }}>{this.state.proceduretypeErr}</div>
                                              
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={this.state.PersonalInformation.procedure_type_comment}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.procedure_type_comment = e.target.value;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={this.state.PersonalInformation.procedure_type_status}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.procedure_type_status = e.target.checked;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {
                                                (!this.state.ProcedureTypeAddSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                    {this.state.itemsProcedure.map((itemsProcedure,index) => (
                                                      <div className="tag-item" key={itemsProcedure}>
                                                        {/* {itemsProcedure} */}
                                                        <a href={itemsProcedure} target="_blank">
                                                          <text>{index+1}.{itemsProcedure}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )
                                              }
                                          <hr />

                                        </div>
                                      )
                                    }




<div class="row">
  <div class="col-md-5">
    <label for="exampleFormControlTextarea1">
    Expertise <span class="mandatory">*</span></label>
    <input type="text" class="form-control disabled"
      id="input7" placeholder="Enter Expertise"
      name="expertise"
      value={this.state.PersonalInformation.expertise}
    />
    <div className="loginErrCreateProject">{this.state.expertiseErr}</div>
    
  </div>

  <div class="col-md-6">
    <label for="exampleFormControlTextarea1">
      Comment</label>
    <input type="text" class="form-control-addsource-Personal"
      id="input3" placeholder="Enter Comment"
      name="expertise_comments"
      value={this.state.PersonalInformation.expertise_comments}
      onChange={(e) => {
        let { PersonalInformation } = this.state;
        PersonalInformation.expertise_comments = e.target.value;
        this.setState({ PersonalInformation });
      }}
    />
  </div>

  <div class="col-md-1">
    <label for="exampleFormControlTextarea1">
      Check</label>
    <input type='checkbox'
      class="form-control-addsource-Personal-ckeckbox"
      // id="input3"
      name="expertise_status"
      checked={this.state.PersonalInformation.expertise_status}
      onChange={(e) => {
        let { PersonalInformation } = this.state;
        PersonalInformation.expertise_status = e.target.checked;
        this.setState({ PersonalInformation });
      }}
    />
  </div>
</div>
  {
      (!this.state.expertiseAddSource) ? (
        <div></div>
      ) : (
        <div style={{ marginTop: '1%', marginBottom: '2%' }}>
          {this.state.itemsexpertise.map((itemsexpertise,index) => (
            <div className="tag-item" key={itemsexpertise}>
              {/* {itemsAreasOfInterest} */}
              <a href={itemsexpertise} target="_blank">
                <text>{index+1}.{itemsexpertise}</text><br/>
              </a>
            </div>
          ))}
        </div>
      )
    }


<hr />





                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Contact No. <span class="mandatory">*</span></label>
                                        <text class="form-control disabled" >
                                          {this.state.PersonalInformation.doctor_contact_no}
                                        </text>
                                        {/* <PhoneInput
                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "50px",
                                                            fontSize: "15px",
                                                            cursor: "notAllowed",
                                                            backgroundColor: "#efefef !important",
                                                        }}
                                                        containerStyle={{cursor: "notAllowed", background: "#efefef !important",}}
                                                        // disabled
                                                        width= "50%"
                                                        country={'in'}
                                                        value={this.state.PersonalInformation.doctor_contact_no}
                                                    /> */}
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>{this.state.doctorcontactnoErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="doctor_contact_comment"
                                          value={this.state.PersonalInformation.doctor_contact_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_contact_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="doctor_contact_status"
                                          checked={this.state.PersonalInformation.doctor_contact_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_contact_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.DoctorContactNoAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsDoctorContact.map((itemsDoctorContact,index) => (
                                                <div className="tag-item" key={itemsDoctorContact}>
                                                  {/* {itemsDoctorContact} */}
                                                  <a href={itemsDoctorContact} target="_blank">
                                                    <text>{index+1}.{itemsDoctorContact}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Email ID <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Email "
                                          name="doctor_email"
                                          value={this.state.PersonalInformation.doctor_email}
                                        />
                                        <div className="loginErrCreateProject">{this.state.doctoremailErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="doctor_email_comment"
                                          value={this.state.PersonalInformation.doctor_email_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_email_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                       <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="doctor_email_status"
                                          checked={this.state.PersonalInformation.doctor_email_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_email_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div> 

                                 


                                    </div>
                                      {
                                          (!this.state.DoctorEmailAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div  style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsDoctorEmail.map((itemsDoctorEmail,index) => (
                                                <div className="tag-item" key={itemsDoctorEmail}>
                                                  {/* {itemsDoctorEmail} */}
                                                  <a href={itemsDoctorEmail} target="_blank">
                                                    <text>{index+1}.{itemsDoctorEmail}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }



<hr />

<div class="row">
  <div class="col-md-5">
    <label for="exampleFormControlTextarea1">
    Secondary Email ID </label>
    <input type="text" class="form-control disabled"
      id="input7" placeholder="Secondary Email ID "
      name="doctor_email2"
      value={this.state.PersonalInformation.doctor_email2}
    />
    <div className="loginErrCreateProject">{this.state.doctoremail2Err}</div>
    
  </div>

  <div class="col-md-6">
    <label for="exampleFormControlTextarea1">
      Comment</label>
    <input type="text" class="form-control-addsource-Personal"
      id="input3" placeholder="Enter Comment"
      name="doctor_email2_comment"
      value={this.state.PersonalInformation.doctor_email2_comment}
      onChange={(e) => {
        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_email2_comment = e.target.value;
        this.setState({ PersonalInformation });
      }}
    />
  </div>
  
  <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                           id="input3"
                                          name="doctor_email2_status"
                                          checked={this.state.PersonalInformation.doctor_email2_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.doctor_email2_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                  </div>
</div>
  {
      (!this.state.DoctorEmail2AddSource) ? (
        <div></div>
      ) : (
        <div  style={{ marginTop: '1%', marginBottom: '2%' }}>
          {this.state.itemsDoctorEmail2.map((itemsDoctorEmail2,index) => (
            <div className="tag-item" key={itemsDoctorEmail2}>
              {/* {itemsDoctorEmail} */}
              <a href={itemsDoctorEmail2} target="_blank">
                <text>{index+1}.{itemsDoctorEmail2}</text><br/>
              </a>
            </div>
          ))}
        </div>
      )
    }


                                    <hr />


                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Fax </label>
                                        <text class="form-control disabled" >
                                          {this.state.PersonalInformation.fax}
                                        </text>
                                        {/* <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Fax"
                                          value={this.state.PersonalInformation.fax}
                                        /> */}
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="fax_comment"
                                          value={this.state.PersonalInformation.fax_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.fax_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="fax_status"
                                          checked={this.state.PersonalInformation.fax_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.fax_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.FaxAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsFax.map((itemsFax,index) => (
                                                <div className="tag-item" key={itemsFax}>
                                                  {/* {itemsFax} */}
                                                  <a href={itemsFax} target="_blank">
                                                    <text>{index+1}.{itemsFax}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          LinkedIn Profile </label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter LinkedIn Profile"
                                          name="linked_in_profile"
                                          value={this.state.PersonalInformation.linked_in_profile}
                                        />
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="linked_in_profile_comment"
                                          value={this.state.PersonalInformation.linked_in_profile_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.linked_in_profile_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="linked_in_profile_status"
                                          checked={this.state.PersonalInformation.linked_in_profile_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.linked_in_profile_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.LinkedInProfileAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsLinkedIn.map((itemsLinkedIn,index) => (
                                                <div className="tag-item" key={itemsLinkedIn}>
                                                  {/* {itemsLinkedIn} */}
                                                  <a href={itemsLinkedIn} target="_blank">
                                                    <text>{index+1}.{itemsLinkedIn}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Twitter Handle </label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Twitter Profile "
                                          name="twitter_handle"
                                          value={this.state.PersonalInformation.twitter_handle}
                                        />
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="twitter_handle_comment"
                                          value={this.state.PersonalInformation.twitter_handle_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.twitter_handle_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="twitter_handle_status"
                                          checked={this.state.PersonalInformation.twitter_handle_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.twitter_handle_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.TwitterHandleAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsTwitter.map((itemsTwitter,index) => (
                                                <div className="tag-item" key={itemsTwitter}>
                                                  {/* {itemsTwitter} */}
                                                  <a href={itemsTwitter} target="_blank">
                                                    <text>{index+1}.{itemsTwitter}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Assistant/Secretary Contact No. </label>
                                        <text class="form-control disabled" >
                                          {this.state.PersonalInformation.assistance_contact_no}
                                        </text>
                                        {/* <PhoneInput
                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "50px",
                                                            fontSize: "15px",

                                                        }}
                                                        className='disabled'
                                                        width= "50%"
                                                        country={'in'}
                                                        value={this.state.PersonalInformation.assistance_contact_no}
                                                        // onChange={(AssistanceContactNo) => {
                                                        //     let { PersonalInformation } = this.state;
                                                        //     PersonalInformation.assistance_contact_no = AssistanceContactNo;
                                                        //     this.setState({ PersonalInformation });
                                                        // }}
                                                    />  */}
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="assistance_contact_no_comment"
                                          value={this.state.PersonalInformation.assistance_contact_no_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.assistance_contact_no_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="assistance_contact_no_status"
                                          checked={this.state.PersonalInformation.assistance_contact_no_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.assistance_contact_no_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.AssistanceContactNoAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsAssistanceContact.map((itemsAssistanceContact,index) => (
                                                <div className="tag-item" key={itemsAssistanceContact}>
                                                  {/* {itemsAssistanceContact} */}
                                                  <a href={itemsAssistanceContact} target="_blank">
                                                    <text>{index+1}.{itemsAssistanceContact}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Assistant Email ID </label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Email "
                                          name="assistance_email"
                                          value={this.state.PersonalInformation.assistance_email}
                                        />
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="assistance_email_comment"
                                          value={this.state.PersonalInformation.assistance_email_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.assistance_email_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="assistance_email_status"
                                          checked={this.state.PersonalInformation.assistance_email_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.assistance_email_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {
                                          (!this.state.AssistanceEmailAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsAssistanceEmail.map((itemsAssistanceEmail,index) => (
                                                <div className="tag-item" key={itemsAssistanceEmail}>
                                                  {/* {itemsAssistanceEmail} */}
                                                  <a href={itemsAssistanceEmail} target="_blank">
                                                    <text>{index+1}.{itemsAssistanceEmail}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Website </label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Website "
                                          name="website"
                                          value={this.state.PersonalInformation.website}
                                        />
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="website_comment"
                                          value={this.state.PersonalInformation.website_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.website_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="website_status"
                                          checked={this.state.PersonalInformation.website_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.website_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.WebsiteAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsWebsite.map((itemsWebsite,index) => (
                                                <div className="tag-item" key={itemsWebsite}>
                                                  {/* {itemsWebsite} */}
                                                  <a href={itemsWebsite} target="_blank">
                                                    <text>{index+1}.{itemsWebsite}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />



                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Country <span class="mandatory">*</span></label>
                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                          style={{ height: '50px' }}
                                          name="country_id"
                                          value={this.state.PersonalInformation.country_id}
                                        // onChange={ async(e) => {
                                        //     let { PersonalInformation } = this.state;
                                        //     PersonalInformation.country_id = e.target.value;
                                        //     PersonalInformation.NPI_no = '';
                                        //     await this.setState({ PersonalInformation });
                                        //     {this.getStateList()}
                                        //     {this.getCityList()}
                                        // }}
                                        >
                                          <option value="" >--Select Country--</option>
                                          {OptionsCountry}
                                        </Input>
                                        <div style={{
                                          color: "red", fontSize: "12px", marginTop: "1%",
                                          marginBottom: "5px",
                                        }}>{this.state.countryErr}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="country_comment"
                                          value={this.state.PersonalInformation.country_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.country_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="country_status"
                                          checked={this.state.PersonalInformation.country_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.country_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.CountryAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsCountry.map((itemsCountry,index) => (
                                                <div className="tag-item" key={itemsCountry}>
                                                  {/* {itemsCountry} */}
                                                  <a href={itemsCountry} target="_blank">
                                                    <text>{index+1}.{itemsCountry}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />

                                    {/* State Dropdown starts here */}
                                    {
                                      (!this.state.PersonalInformation.country_id) ? (
                                        <div></div>
                                      ) : (
                                        <div>

                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1" >
                                                State 
                                                {/* <span class="mandatory">*</span> */}
                                              </label>
                                              <Input type="select" id="roleSelect" className="form-control disabled"
                                                style={{ height: '50px' }}
                                                value={this.state.PersonalInformation.state_id}
                                              // onChange={async(e) => {
                                              //     let { PersonalInformation } = this.state;
                                              //     PersonalInformation.state_id = e.target.value;
                                              //     await this.setState({ PersonalInformation });
                                              // }}
                                              >
                                                <option value="" >--Select State--</option>
                                                {OptionsState}
                                              </Input>
                                              <div style={{
                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                marginBottom: "5px",
                                              }}>{this.state.stateErr}</div>
                                              
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="state_comment"
                                                value={this.state.PersonalInformation.state_comment}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.state_comment = e.target.value;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="state_status"
                                                checked={this.state.PersonalInformation.state_status}
                                                onChange={(e) => {
                                                  let { PersonalInformation } = this.state;
                                                  PersonalInformation.state_status = e.target.checked;
                                                  this.setState({ PersonalInformation });
                                                }}
                                              />
                                            </div>
                                          </div>
                                            {
                                                (!this.state.StateAddSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                    {this.state.itemsState.map((itemsState,index) => (
                                                      <div className="tag-item" key={itemsState}>
                                                        {/* {itemsState} */}
                                                        <a href={itemsState} target="_blank">
                                                          <text>{index+1}.{itemsState}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )
                                              }
                                          <hr />

                                        </div>
                                      )
                                    }

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          City </label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter City"
                                          name="city"
                                          value={this.state.PersonalInformation.city}
                                        />                                      
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="city_comments"
                                          value={this.state.PersonalInformation.city_comments}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.city_comments = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="city_status"
                                          checked={this.state.PersonalInformation.city_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.city_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.CityPEAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsCity.map((itemsCity,index) => (
                                                <div className="tag-item" key={itemsCity}>
                                                  {/* {itemsCity} */}
                                                  <a href={itemsCity} target="_blank">
                                                    <text>{index+1}.{itemsCity}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }
                                    <hr />


                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Primary Work Address <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter First Address "
                                          name="address_1"
                                          value={this.state.PersonalInformation.address_1}
                                        />
                                        <div className="loginErrCreateProject">{this.state.address1Err}</div>
                                        
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="address_comment"
                                          value={this.state.PersonalInformation.address_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.address_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="address_status"
                                          checked={this.state.PersonalInformation.address_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.address_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                      {
                                          (!this.state.Address1AddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsAddress1.map((itemsAddress1,index) => (
                                                <div className="tag-item" key={itemsAddress1}>
                                                  {/* {itemsAddress1} */}
                                                  <a href={itemsAddress1} target="_blank">
                                                    <text>{index+1}.{itemsAddress1}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        }

                                     

                                    <hr />

                                    <div class="row">
                                    
                                    <div class="col-md-5">
                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                      Primary Work Address 2 </label>
                                    <input type="text" class="form-control disabled"
                                      id="input7" placeholder="Enter Second Address "
                                      name="address_2"
                                      value={this.state.PersonalInformation.address_2}
                                    />
                                    </div>
                                      <div className="loginErrCreateProject">{this.state.address2Err}</div>
                                        
                                     

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="address_2comment"
                                          value={this.state.PersonalInformation.address_2comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.address_2comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div> 
 
                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="address_2status"
                                          checked={this.state.PersonalInformation.address_2status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.address_2status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                      </div>
                                        {
                                          (!this.state.Address2AddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {this.state.itemsAddress2.map((itemsAddress2,index) => (
                                                <div className="tag-item" key={itemsAddress2}>
                                                  
                                                  <a href={itemsAddress2} target="_blank">
                                                    <text>{index+1}.{itemsAddress2}</text><br/>
                                                  </a>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        } 
                                    
                                    <hr />
                                    {/* <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.Address2AddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.Address2AddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                    <input type="text" class="form-control" 
                                                        id="input4" placeholder="Paste Source URL" 
                                                        value={this.state.Address2SourceUrl} 
                                                        onChange={(e) => {
                                                            let { Address2SourceUrl } = this.state;
                                                            Address2SourceUrl = e.target.value;
                                                            this.setState({ Address2SourceUrl });
                                                        }}/>
                                                    </div>
                                                )
                                            } */}

                                    <div class="row">
                                      <div class="col-md-5">
                                        <label for="exampleFormControlTextarea1">
                                          Zip Code <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control disabled"
                                          id="input7" placeholder="Enter Zip Code "
                                          name="zip_code"
                                          value={this.state.PersonalInformation.zip_code}
                                        />
                                        <div className="loginErrCreateProject">{this.state.zipcodeErr}</div>
                                      </div>

                                      <div class="col-md-6">
                                        <label for="exampleFormControlTextarea1">
                                          Comment</label>
                                        <input type="text" class="form-control-addsource-Personal"
                                          id="input3" placeholder="Enter Comment"
                                          name="zip_code_comment"
                                          value={this.state.PersonalInformation.zip_code_comment}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.zip_code_comment = e.target.value;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>

                                      <div class="col-md-1">
                                        <label for="exampleFormControlTextarea1">
                                          Check</label>
                                        <input type='checkbox'
                                          class="form-control-addsource-Personal-ckeckbox"
                                          // id="input3"
                                          name="zip_code_status"
                                          checked={this.state.PersonalInformation.zip_code_status}
                                          onChange={(e) => {
                                            let { PersonalInformation } = this.state;
                                            PersonalInformation.zip_code_status = e.target.checked;
                                            this.setState({ PersonalInformation });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <hr />




                                    {/* Work details */}
                                    {
                                      this.state.WorkDetails.map((el, i) => {

                                        var arrayCurruntPlaceOfWork = [];
                                        var arrayDesignation = [];
                                        var arrayDepartment = [];
                                        var arrayFromYear = [];
                                        var arrayToYear = [];

                                        if (el.current_place_of_work_links !== '') {
                                          arrayCurruntPlaceOfWork = el.current_place_of_work_links.split(',')
                                          arrayCurruntPlaceOfWork = arrayCurruntPlaceOfWork.splice(1)
                                        }
                                        if (el.designation_links !== '') {
                                          arrayDesignation = el.designation_links.split(',')
                                          arrayDesignation = arrayDesignation.splice(1)
                                        }
                                        if (el.department_links !== '') {
                                          arrayDepartment = el.department_links.split(',')
                                          arrayDepartment = arrayDepartment.splice(1)
                                        }
                                        if (el.from_year_links !== '') {
                                          arrayFromYear = el.from_year_links.split(',')
                                          arrayFromYear = arrayFromYear.splice(1)
                                        }
                                        if (el.to_year_links !== '') {
                                          arrayToYear = el.to_year_links.split(',')
                                          arrayToYear = arrayToYear.splice(1)
                                        }

                                        return (
                                          <div key={i}>
                                            {
                                              (i == 0) ? (
                                                <div>
                                                  <h3 class="mt-30">Work Details</h3>
                                                </div>
                                              ) : (
                                                <div>
                                                  <h3 class="mt-30">Work Details {i + 1}</h3>
                                                  {/* {this.state.WorkDetails.length !== 1 && 
                                                                        <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                                        onClick={this.removeClickWorkDetails.bind(this, i)}>x</a>
                                                                    } */}
                                                </div>
                                              )
                                            }

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  Current Place of Work <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter Current Place of Work"
                                                  name="current_place_of_work" value={el.current_place_of_work || ''}
                                                // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                />
                                                <div className="loginErrCreateProject">
                                                  {
                                                    (el.current_place_of_work != '') ? (<div></div>) : (this.state.placeofworkErr)
                                                  }
                                                </div>
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="current_place_of_work_comment"
                                                  value={el.current_place_of_work_comment}
                                                  onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="current_place_of_work_status"
                                                  checked={el.current_place_of_work_status}
                                                  onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.PlaceOfWorkAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {
                                                        arrayCurruntPlaceOfWork.flat().map((item,index) => (
                                                          <div class="tag-item" key={item}>
                                                            {item}
                                                            <a href={item} target="_blank">
                                                              <text>{index+1}.{item}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                  Designation <span class="mandatory">*</span></label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter Designation"
                                                  name="designation" value={el.designation || ''}
                                                // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                />
                                                <div className="loginErrCreateProject">
                                                  {
                                                    (el.designation != '') ? (<div></div>) : (this.state.designationErr)
                                                  }
                                                </div>
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="designation_comment"
                                                  value={el.designation_comment}
                                                  onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="designation_status"
                                                  checked={el.designation_status}
                                                  onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.DesignationAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {
                                                        arrayDesignation.flat().map((item,index) => (
                                                          <div class="tag-item" key={item}>
                                                            {/* {item} */}
                                                            <a href={item} target="_blank">
                                                              <text>{index+1}.{item}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  Department 
                                                  {/* <span class="mandatory">*</span> */}
                                                </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter Department"
                                                  name="department" value={el.department || ''}
                                                // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                />
                                                {/* <div className="loginErrCreateProject">
                                                  {
                                                    (el.department != '') ? (<div></div>) : (this.state.departmentErr)
                                                  }
                                                </div> */}
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="department_comment"
                                                  value={el.department_comment}
                                                 // value="Guddu"
                                                  onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="department_status"
                                                  checked={el.department_status}
                                                  onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                />
                                              </div>
                                            </div>
                                              {
                                                  (!this.state.DepartmentAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {
                                                        arrayDepartment.flat().map((item,index) => (
                                                          <div class="tag-item" key={item}>
                                                            {/* {item} */}
                                                            <a href={item} target="_blank">
                                                              <text>{index+1}.{item}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  From Year 
                                                  {/* <span class="mandatory">*</span> */}
                                                </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter From Year"
                                                  name="from_year" value={el.from_year || ''}
                                                // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                />
                                                {/* <div className="loginErrCreateProject">
                                                  {
                                                    (el.from_year != '') ? (<div></div>) : (this.state.departmentErr)
                                                  }
                                                </div> */}
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="from_year_comments"
                                                  value={el.from_year_comments}
                                                  onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="from_year_status"
                                                  checked={el.from_year_status}
                                                  onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                            </div>
                                              {
                                                  (!this.state.FromYearAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {
                                                        arrayFromYear.flat().map((item,index) => (
                                                          <div class="tag-item" key={item}>
                                                            {/* {item} */}
                                                            <a href={item} target="_blank">
                                                              <text>{index+1}.{item}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                            <div class="row">
                                              <div class="col-md-5">
                                                <label for="exampleFormControlTextarea1">
                                                  To Year 
                                                  {/* <span class="mandatory">*</span> */}
                                                </label>
                                                <input type="text" class="form-control disabled"
                                                  id="input7" placeholder="Enter To Year"
                                                  name="to_year" value={el.to_year || ''}
                                                // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                />
                                                {/* <div className="loginErrCreateProject">
                                                  {
                                                    (el.to_year != '') ? (<div></div>) : (this.state.departmentErr)
                                                  }
                                                </div> */}
                                                
                                              </div>

                                              <div class="col-md-6">
                                                <label for="exampleFormControlTextarea1">
                                                  Comment</label>
                                                <input type="text" class="form-control-addsource-Personal"
                                                  id="input3" placeholder="Enter Comment"
                                                  name="to_year_comments"
                                                  value={el.to_year_comments}
                                                  onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                              <div class="col-md-1">
                                                <label for="exampleFormControlTextarea1">
                                                  Check</label>
                                                <input type='checkbox'
                                                  class="form-control-addsource-Personal-ckeckbox"
                                                  // id="input3"
                                                  name="to_year_status"
                                                  checked={el.to_year_status}
                                                  onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                />
                                              </div>

                                            </div>
                                              {
                                                  (!this.state.ToYearAddSource) ? (
                                                    <div></div>
                                                  ) : (
                                                    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                      {
                                                        arrayToYear.flat().map((item,index) => (
                                                          <div class="tag-item" key={item}>
                                                            {/* {item} */}
                                                            <a href={item} target="_blank">
                                                              <text>{index+1}.{item}</text><br/>
                                                            </a>
                                                          </div>
                                                        ))
                                                      }
                                                    </div>
                                                  )
                                                }
                                            <hr />

                                           
                                            {/* Add More and remove */}
                                            {/* {this.state.WorkDetails.length - 1 === i && 
                                                        <a style={{marginTop:'5%', color:'#007bff'}}
                                                        onClick={this.addClickWorkDetails.bind(this)}>+ Add More Work Details</a>
                                                        } */}
                                          </div>
                                        )
                                      })
                                    }



                                            <label for="exampleFormControlTextarea1">
                                                            Source Link</label>
                                                            <input type="text" class="form-control-addsource-Personal"
                                                                id="input3" placeholder="Enter Source Link"
                                                                name="source_link"
                                                                value={this.state.PersonalInformation.source_link}
                                                                onChange={(e) => {
                                                                    // let { kol_comment } = this.state;
                                                                    // kol_comment = e.target.value;
                                                                    // this.setState({ kol_comment });

                                                                    let { PersonalInformation } = this.state;
                                                                    PersonalInformation.source_link = e.target.value;
                                                                    this.setState({ PersonalInformation });
                                                                }}
                                                            />


                                    {/* <label for="exampleFormControlTextarea1" style={{marginTop:'3%'}}>
                                               Last Updated Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control" 
                                                id="input7" placeholder="Choose Date " 
                                                name="last_updated_date"
                                                value={this.state.PersonalInformation.last_updated_date} 
                                                onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.last_updated_date = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                }}
                                            />
                                            <div className="loginErrCreateProject">{this.state.lastupdateddateErr}</div> */}

                                  </div>
                                </form>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Biography form start here*/}
                        {
                          (this.state.BiographyFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Biography</h3>
                              {/* <h5 class="mt-30">Check All</h5> */}
{/* 
                              <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.citizenship_status = e.target.checked;
                                              Biography.language_spoken_status = e.target.checked;
                                            
                                              Biography.linkedin_followers_status = e.target.checked;
                                              Biography.twitter_followers_status = e.target.checked;
                                              Biography.biography_status = e.target.checked;
                                              Biography.areas_of_interest_status = e.target.checked;
                                              Biography.expertise_status = e.target.checked;
                                              Biography.resume_status = e.target.checked;
                                            
                                              this.setState({ Biography });

                                             // alert('hi');


                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> */}
                                            
                              <form>
                                {
                                  (this.state.PersonalInformation.kol_type == 'KOL Community' ||
                                    this.state.PersonalInformation.kol_type == '') ? (
                                    <div>

                                      <div class="row">
                                        <div class="col-md-5">
                                          <div class="form-group">
                                            <label for="exampleFormControlTextarea1">
                                              Citizenship </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              autofocus="true"
                                              name="citizenship"
                                              value={this.state.Biography.citizenship}
                                            // onChange={(e) => {
                                            // let { Biography } = this.state;
                                            // Biography.citizenship = e.target.value;
                                            // this.setState({ Biography });
                                            // }}  
                                            >
                                              <option value="" >--Select Citizenship--</option>
                                              {OptionsCitizenship}
                                            </Input>
                                          </div>
                                          {/* <div className="loginErrCreateProject">{this.state.citizenshipErr}</div>                                            */}
                                         
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="citizenship_comment"
                                            value={this.state.Biography.citizenship_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.citizenship_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="citizenship_status"
                                            checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.citizenship_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.CitizenshipAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsCitizenship.map((itemsCitizenship,index) => (
                                                  <div className="tag-item" key={itemsCitizenship}>
                                                    {/* {itemsCitizenship} */}
                                                    <a href={itemsCitizenship} target="_blank">
                                                      <text>{index+1}.{itemsCitizenship}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />

                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Language Spoken </label>
                                          <Multiselect
                                            disabled
                                            data={this.state.Language}
                                            name="language_spoken"
                                            placeholder='--Select Lanuages--'
                                            // value= {el.areas_of_interest_links ||''}
                                            defaultValue={(this.state.Biography.language_spoken).split(",")}
                                            valueField='id'
                                            textField='language'
                                          />
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="language_spoken_comment"
                                            value={this.state.Biography.language_spoken_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.language_spoken_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="language_spoken_status"
                                            checked={this.state.Biography.language_spoken_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.language_spoken_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <hr />

                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            LinkedIn Followers</label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter LinkedIn Followers"
                                            value={this.state.Biography.linkedin_followers}
                                          />
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="linkedin_followers_comment"
                                            value={this.state.Biography.linkedin_followers_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.linkedin_followers_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="linkedin_followers_status"
                                            checked={this.state.Biography.linkedin_followers_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.linkedin_followers_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.LinkedInFollowersAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsLinkedInFolllowers.map((itemsLinkedInFolllowers,index) => (
                                                  <div className="tag-item" key={itemsLinkedInFolllowers}>
                                                    {/* {itemsLinkedInFolllowers} */}
                                                    <a href={itemsLinkedInFolllowers} target="_blank">
                                                      <text>{index+1}.{itemsLinkedInFolllowers}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />


                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Twitter Followers </label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter Twitter Followers"
                                            value={this.state.Biography.twitter_followers}
                                          />
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="twitter_followers_comment"
                                            value={this.state.Biography.twitter_followers_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.twitter_followers_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="twitter_followers_status"
                                            checked={this.state.Biography.twitter_followers_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.twitter_followers_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                      </div>
                                        {
                                            (!this.state.TwitterFollowersAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsTwitterFollowers.map((itemsTwitterFollowers,index) => (
                                                  <div className="tag-item" key={itemsTwitterFollowers}>
                                                    {/* {itemsTwitterFollowers} */}
                                                    <a href={itemsTwitterFollowers} target="_blank">
                                                      <text>{index+1}.{itemsTwitterFollowers}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />


                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Biography  <span class="mandatory">*</span></label>
                                          <textarea type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter Biography "
                                            value={this.state.Biography.biography}
                                          />
                                          <div className="loginErrCreateProject">{this.state.biographyErr}</div>
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="biography_comment"
                                            value={this.state.Biography.biography_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.biography_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="biography_status"
                                            checked={this.state.Biography.biography_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.biography_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.BiographyFieldAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsBiography.map((itemsBiography,index) => (
                                                  <div className="tag-item" key={itemsBiography}>
                                                    {/* {itemsBiography} */}
                                                    <a href={itemsBiography} target="_blank">
                                                      <text>{index+1}.{itemsBiography}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />


                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Areas of Interest <span class="mandatory">*</span></label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter Areas of Interest"
                                            name="areas_of_interest"
                                            value={this.state.Biography.areas_of_interest}
                                          />
                                          <div className="loginErrCreateProject">{this.state.areasofinterestErr}</div>
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="areas_of_interest_comment"
                                            value={this.state.Biography.areas_of_interest_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.areas_of_interest_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="areas_of_interest_status"
                                            checked={this.state.Biography.areas_of_interest_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.areas_of_interest_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.AreasofInterestAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsAreasOfInterest.map((itemsAreasOfInterest,index) => (
                                                  <div className="tag-item" key={itemsAreasOfInterest}>
                                                    {/* {itemsAreasOfInterest} */}
                                                    <a href={itemsAreasOfInterest} target="_blank">
                                                      <text>{index+1}.{itemsAreasOfInterest}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }



<hr />



                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="resume">
                                            Upload Resume </label>
                                          <div>
                                            {/* <input 
                                                        type='file' 
                                                        id="myInputResume"
                                                        // accept="image/*"
                                                        // onChange={this.onUploadResume} hidden 
                                                    /> */}
                                            {/* <label htmlFor="myInputResume" 
                                                            style={{color:'blue', fontSize: "15px", marginTop:'1.5%'}}>
                                                            Upload Resume
                                                    </label> */}
                                            {
                                              (this.state.BiographyResumeFileName) ? (
                                                <div class="form-control disabled" style={{ height: '60%' }}>
                                                  <a
                                                    href={this.state.BiographyResumeFileName} target="_blank">
                                                    <text>{this.state.FinalResumeName}</text>
                                                  </a>
                                                </div>

                                              ) : (
                                                <div class="form-control" style={{ height: '25%' }}>
                                                  No file Choosen
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="resume_comment"
                                            value={this.state.Biography.resume_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.resume_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="resume_status"
                                            checked={this.state.Biography.resume_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.resume_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <hr />

                                    </div>
                                  ) : (
                                    <div>

                                      <div class="row">
                                        <div class="col-md-5">
                                          <div class="form-group">
                                            <label for="exampleFormControlTextarea1">
                                              Citizenship </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              autofocus="true"
                                              name="citizenship"
                                              value={this.state.Biography.citizenship}
                                            // onChange={(e) => {
                                            // let { Biography } = this.state;
                                            // Biography.citizenship = e.target.value;
                                            // this.setState({ Biography });
                                            // }}  
                                            >
                                              <option value="" >--Select Citizenship--</option>
                                              {OptionsCitizenship}
                                            </Input>
                                          </div>
                                          <div className="loginErrCreateProject">{this.state.citizenshipErr}</div>
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="citizenship_comment"
                                            value={this.state.Biography.citizenship_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.citizenship_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="citizenship_status"
                                            checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.citizenship_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.CitizenshipAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{  marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsCitizenship.map((itemsCitizenship,index) => (
                                                  <div className="tag-item" key={itemsCitizenship}>
                                                    {/* {itemsCitizenship} */}
                                                    <a href={itemsCitizenship} target="_blank">
                                                      <text>{index+1}.{itemsCitizenship}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />

                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Language Spoken </label>
                                          <Multiselect
                                            disabled
                                            data={this.state.Language}
                                            name="language_spoken"
                                            placeholder='--Select Lanuages--'
                                            // value= {el.areas_of_interest_links ||''}
                                            defaultValue={(this.state.Biography.language_spoken).split(",")}
                                            valueField='id'
                                            textField='language'
                                          />
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="language_spoken_comment"
                                            value={this.state.Biography.language_spoken_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.language_spoken_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="language_spoken_status"
                                            checked={this.state.Biography.language_spoken_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.language_spoken_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <hr />


                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            LinkedIn Followers</label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter LinkedIn Followers"
                                            value={this.state.Biography.linkedin_followers}
                                          />
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="linkedin_followers_comment"
                                            value={this.state.Biography.linkedin_followers_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.linkedin_followers_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="linkedin_followers_status"
                                            checked={this.state.Biography.linkedin_followers_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.linkedin_followers_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.LinkedInFollowersAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsLinkedInFolllowers.map((itemsLinkedInFolllowers,index) => (
                                                  <div className="tag-item" key={itemsLinkedInFolllowers}>
                                                    {/* {itemsLinkedInFolllowers} */}
                                                    <a href={itemsLinkedInFolllowers} target="_blank">
                                                      <text>{index+1}.{itemsLinkedInFolllowers}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />



                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Biography  <span class="mandatory">*</span></label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter Biography "
                                            value={this.state.Biography.biography}
                                          />
                                          <div className="loginErrCreateProject">{this.state.biographyErr}</div>
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="biography_comment"
                                            value={this.state.Biography.biography_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.biography_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="biography_status"
                                            checked={this.state.Biography.biography_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.biography_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.BiographyFieldAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsBiography.map((itemsBiography,index) => (
                                                  <div className="tag-item" key={itemsBiography}>
                                                    {/* {itemsBiography} */}
                                                    <a href={itemsBiography} target="_blank">
                                                      <text>{index+1}.{itemsBiography}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }
                                      <hr />

                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="exampleFormControlTextarea1">
                                            Areas of Interest <span class="mandatory">*</span></label>
                                          <input type="text" class="form-control disabled"
                                            id="input7" placeholder="Enter Areas of Interest"
                                            name="areas_of_interest"
                                            value={this.state.Biography.areas_of_interest}
                                          />
                                          <div className="loginErrCreateProject">{this.state.areasofinterestErr}</div>
                                          
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="areas_of_interest_comment"
                                            value={this.state.Biography.areas_of_interest_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.areas_of_interest_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="areas_of_interest_status"
                                            checked={this.state.Biography.areas_of_interest_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.areas_of_interest_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                        {
                                            (!this.state.AreasofInterestAddSource) ? (
                                              <div></div>
                                            ) : (
                                              <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                {this.state.itemsAreasOfInterest.map((itemsAreasOfInterest,index) => (
                                                  <div className="tag-item" key={itemsAreasOfInterest}>
                                                    {/* {itemsAreasOfInterest} */}
                                                    <a href={itemsAreasOfInterest} target="_blank">
                                                      <text>{index+1}.{itemsAreasOfInterest}</text><br/>
                                                    </a>
                                                  </div>
                                                ))}
                                              </div>
                                            )
                                          }


                                      <hr />


                                      <div class="row">
                                        <div class="col-md-5">
                                          <label for="resume">
                                            Upload Resume </label>
                                          <div>
                                            {/* <input 
                                                        type='file' 
                                                        id="myInputResume"
                                                        // accept="image/*"
                                                        // onChange={this.onUploadResume} hidden 
                                                    /> */}
                                            {/* <label htmlFor="myInputResume" 
                                                            style={{color:'blue', fontSize: "15px", marginTop:'1.5%'}}>
                                                            Upload Resume
                                                    </label> */}
                                            {
                                              (this.state.BiographyResumeFileName) ? (
                                                <div class="form-control disabled" style={{ height: '60%' }}>
                                                  <a
                                                    href={this.state.BiographyResumeFileName} target="_blank">
                                                    <text>{this.state.FinalResumeName}</text>
                                                  </a>
                                                </div>

                                              ) : (
                                                <div class="form-control" style={{ height: '25%' }}>
                                                  No file Choosen
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>

                                        <div class="col-md-6">
                                          <label for="exampleFormControlTextarea1">
                                            Comment</label>
                                          <input type="text" class="form-control-addsource-Personal"
                                            id="input3" placeholder="Enter Comment"
                                            name="resume_comment"
                                            value={this.state.Biography.resume_comment}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.resume_comment = e.target.value;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>

                                        <div class="col-md-1">
                                          <label for="exampleFormControlTextarea1">
                                            Check</label>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                            name="resume_status"
                                            checked={this.state.Biography.resume_status}
                                            onChange={(e) => {
                                              let { Biography } = this.state;
                                              Biography.resume_status = e.target.checked;
                                              this.setState({ Biography });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <hr />

                                    </div>
                                  )
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Professional Membership form start here*/}
                        {
                          (this.state.ProfessionalMembershipFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Professional Membership</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { ProfessionalMemberships } = this.state;
                                              this.state.ProfessionalMemberships.map((el,i) => {                                             
                                                ProfessionalMemberships[i].organization_status = e.target.checked;
                                                ProfessionalMemberships[i].organization_type_status = e.target.checked;
                                                ProfessionalMemberships[i].location_status = e.target.checked;
                                                ProfessionalMemberships[i].position_status = e.target.checked;
                                                ProfessionalMemberships[i].tenure1_status = e.target.checked;
                                                ProfessionalMemberships[i].tenure_status = e.target.checked;
                                                ProfessionalMemberships[i].organization_website_status = e.target.checked;
                                                ProfessionalMemberships[i].department_status = e.target.checked;
                                                  
                                                this.setState({ ProfessionalMemberships });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


{/*                              


                              <div class='row' style={{marginLeft:'18px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91%' ,marginTop:'-50px' }}>                                   
 
                              <div class="form-group">
                                    <b>Check All</b>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                           // name="citizenship_status"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {

                                              let { ProfessionalMemberships } = this.state;
                                              this.state.ProfessionalMemberships.map((el,i) =>
                                              {

                                              ProfessionalMemberships[i].organization_status = e.target.checked;
                                              ProfessionalMemberships[i].organization_type_status = e.target.checked;
                                              ProfessionalMemberships[i].location_status = e.target.checked;
                                              ProfessionalMemberships[i].position_status = e.target.checked;
                                              ProfessionalMemberships[i].tenure1_status = e.target.checked;
                                              ProfessionalMemberships[i].tenure_status = e.target.checked;
                                              ProfessionalMemberships[i].organization_website_status = e.target.checked;
                                              ProfessionalMemberships[i].department_status = e.target.checked;
                                              
                                             }
                                              );
                                             
                                              this.setState({ ProfessionalMemberships });

                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div> */}



                              <form>
                                {
                                  this.state.ProfessionalMemberships.map((el, i) => {
                                    var arrayOrganization = [];
                                    var arrayOrganizationType = [];
                                    // var arraySourceLinkProff = [];
                                    var arrayLocation = [];
                                    var arrayPosition = [];
                                    var arrayTenure = [];
                                    var arrayOrganizationWebsite = [];
                                    var arrayDepartmentProfMember = [];

                                    if (el.organization_links !== '') {
                                      // arrayOrganization.push(el.organization_links)
                                      arrayOrganization = el.organization_links.split(',')
                                      arrayOrganization = arrayOrganization.splice(1)
                                      // console.log("arrayOrganization===",arrayOrganization)
                                    }
                                    if (el.organization_type_links !== '') {
                                      //    arrayOrganizationType.push(el.organization_type_links)
                                      arrayOrganizationType = el.organization_type_links.split(',')
                                      arrayOrganizationType = arrayOrganizationType.splice(1)
                                      // console.log("arrayOrganizationType===",arrayOrganizationType)
                                    }
                                    // if(el.source_link_links !== '')
                                    // {
                                    //     arraySourceLinkProff = el.source_link_links.split(',')
                                    //     arraySourceLinkProff =arraySourceLinkProff.splice(1)
                                    // }
                                    if (el.location_links !== '') {
                                      arrayLocation = el.location_links.split(',')
                                      arrayLocation = arrayLocation.splice(1)
                                    }
                                    if (el.position_links !== '') {
                                      arrayPosition = el.position_links.split(',')
                                      arrayPosition = arrayPosition.splice(1)
                                    }
                                    if (el.tenure_link !== '') {
                                      arrayTenure = el.tenure_link.split(',')
                                      arrayTenure = arrayTenure.splice(1)
                                    }
                                    if (el.organization_website_links !== '') {
                                      arrayOrganizationWebsite = el.organization_website_links.split(',')
                                      arrayOrganizationWebsite = arrayOrganizationWebsite.splice(1)
                                    }
                                    if (el.department_links !== '') {
                                      arrayDepartmentProfMember = el.department_links.split(',')
                                      arrayDepartmentProfMember = arrayDepartmentProfMember.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Professional Membership {i + 1}</h3>
                                              {/* {this.state.ProfessionalMemberships.length !== 1 && 
                                                    <a class="float-right" style={{marginTop:'-8.5%', color:'#007bff'}}
                                                    onClick={this.removeClickProfessionalMembership.bind(this, i)}>x</a> */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Organization  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Organization "
                                              name="organization" value={el.organization || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.organization != '') ? (<div></div>) : (this.state.organizationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="organization_comment"
                                              value={el.organization_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="organization_status"
                                              checked={el.organization_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.OrganizationAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayOrganization.flat().map((item, index) => (
                                                      <div class="tag-item" key={item}>
                                                        {console.log('item', item)}
                                                        {console.log('index', index)}
                                                        {/* {item} */}
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Organization Type</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Organization Type"
                                              name="organization_type" value={el.organization_type || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject">
                                                        {
                                                        (el.organization_type != '') ?( <div></div>) :(this.state.organizationtypeErr)
                                                        }
                                                    </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="organization_type_comment"
                                              value={el.organization_type_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="organization_type_status"
                                              checked={el.organization_type_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.OrganizationTypeAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayOrganizationType.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                    Source Link <span class="mandatory">*</span></label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Source Link" 
                                                        name="source_link" value={el.source_link ||''}
                                                        // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                                    />
                                                    {/* <div className="loginErrCreateProject">
                                                        {
                                                        (el.source_link != '') ?( <div></div>) :(this.state.sourcelinkErr)
                                                        }
                                                    </div> 
                                                    {
                                                        ( !this.state.SourceLinkAddSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                            arraySourceLinkProff.flat().map((item) => (
                                                                <div class="tag-item" key={item}>
                                                                    <a href={item} target = "_blank">
                                                                        <text>{item}</text>
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                        )
                                                    } */}
                                        {/* </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="source_link_comment" 
                                                        value={el.source_link_comment} 
                                                        onChange={this.handleChangeProfessionalMembership.bind(this, i)} 
                                                    />
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="source_link_status" 
                                                        checked={el.source_link_status}
                                                        onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                                    />
                                                </div>

                                            </div>
                                            <hr/> */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Location</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Location"
                                              name="location" value={el.location || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="location_comment"
                                              value={el.location_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="location_status"
                                              checked={el.location_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.LocationAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayLocation.flat().map((item,index) => (
                                                      <a href={item} target="_blank">
                                                        <text>{index+1}.{item}</text><br/>
                                                      </a>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Position <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Position"
                                              name="position" value={el.position || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.position != '') ? (<div></div>) : (this.state.positionErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="position_comment"
                                              value={el.position_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="position_status"
                                              checked={el.position_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.PositionAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPosition.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Tenure From</label>
                                            <text class="form-control disabled" >{el.tenure || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Tenure" 
                                                        name="tenure" value={el.tenure ||''}
                                                        // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                                    /> */}
                                            {/* <div className="loginErrCreateProject">
                                                        {
                                                        (el.tenure != '') ?( <div></div>) :(this.state.tenureErr)
                                                        }
                                                    </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="tenure_comment"
                                              value={el.tenure_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="tenure_status"
                                              checked={el.tenure_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TenureAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTenure.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Tenure To</label>
                                            <text class="form-control disabled" >{el.tenure1 || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Tenure" 
                                                        name="tenure1" value={el.tenure1 ||''}
                                                        // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                                    /> */}
                                            {/* <div className="loginErrCreateProject">
                                                        {
                                                        (el.tenure != '') ?( <div></div>) :(this.state.tenureErr)
                                                        }
                                                    </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="tenure1_comment"
                                              value={el.tenure1_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="tenure1_status"
                                              checked={el.tenure1_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TenureAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTenure.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Organization Website</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Organization Website"
                                              name="organization_website" value={el.organization_website || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="organization_website_comment"
                                              value={el.organization_website_comment}
                                              onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="organization_website_status"
                                              checked={el.organization_website_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.OrganizationWebsiteAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayOrganizationWebsite.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Department</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Department"
                                              name="department" value={el.department || ''}
                                            // onChange={this.handleChangeProfessionalMembership.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="department_comment"
                                              value={el.department_comment}
                                             //value="Aarna"
                                             onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="department_status"
                                              checked={el.department_status}
                                              onChange={this.handleChangeCheckBoxProfessionalMem.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.DepartmentProfMemberAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDepartmentProfMember.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* Add More and remove */}
                                        {/* {this.state.ProfessionalMemberships.length !== 1 && 
                                                <a class="float-right" style={{marginTop:'-2%', color:'#007bff'}}
                                                onClick={this.removeClickProfessionalMembership.bind(this, i)}>- Remove</a>
                                            } */}
                                        {/* {this.state.ProfessionalMemberships.length - 1 === i && 
                                                <a  style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickProfessionalMembership.bind(this)}>+ Add More Professional Membership</a>
                                            } */}

                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitProfessional.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Education form start here */}
                        {
                          (this.state.EducationFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Highest Education</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Education } = this.state;
                                              this.state.Education.map((el,i) => {                                             
                                              
                                                Education[i].institute_name_status = e.target.checked;
                                                Education[i].institute_type_status = e.target.checked;
                                                Education[i].source_link_status = e.target.checked;
                                                Education[i].degrees_status = e.target.checked;
                                                Education[i].speciality_status = e.target.checked;
                                                Education[i].years_attended_staus = e.target.checked;
                                                 
                                                this.setState({ Education });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}

{/*                                                          
                              <div class='row' style={{marginLeft:'18px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91%' ,marginTop:'-50px' }}>                                   
 
                              <div class="form-group">
                                    <b>Check All</b>
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                           // name="citizenship_status"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {

                                              let { Education } = this.state;
                                              this.state.Education.map((el,i) =>
                                              {

                                                Education[i].institute_name_status = e.target.checked;
                                                Education[i].institute_type_status = e.target.checked;
                                                Education[i].source_link_status = e.target.checked;
                                                Education[i].degrees_status = e.target.checked;
                                                Education[i].speciality_status = e.target.checked;
                                                Education[i].years_attended_staus = e.target.checked;
                                               
                                              
                                             }
                                              );
                                             
                                              this.setState({ Education });

                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div> */}


                              <form>
                                {
                                  this.state.Education.map((el, i) => {
                                    var arrayInstituteName = [];
                                    var arrayInstituteType = [];
                                    var arraySourceLinksEducation = [];
                                    var arrayDegrees = [];
                                    var arraySpecialityEducation = [];
                                    var arrayYearsAttended = [];

                                    if (el.institute_name_links !== '') {
                                      arrayInstituteName = el.institute_name_links.split(',')
                                      arrayInstituteName = arrayInstituteName.splice(1)
                                    }
                                    if (el.institute_type_links !== '') {
                                      arrayInstituteType = el.institute_type_links.split(',')
                                      arrayInstituteType = arrayInstituteType.splice(1)
                                    }
                                    if (el.source_link_links !== '') {
                                      arraySourceLinksEducation = el.source_link_links.split(',')
                                      arraySourceLinksEducation = arraySourceLinksEducation.splice(1)
                                    }
                                    if (el.degrees_links !== '') {
                                      arrayDegrees = el.degrees_links.split(',')
                                      arrayDegrees = arrayDegrees.splice(1)
                                    }
                                    if (el.speciality_links !== '') {
                                      arraySpecialityEducation = el.speciality_links.split(',')
                                      arraySpecialityEducation = arraySpecialityEducation.splice(1)
                                    }
                                    if (el.years_attended_links !== '') {
                                      arrayYearsAttended = el.years_attended_links.split(',')
                                      arrayYearsAttended = arrayYearsAttended.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Education {i + 1}</h3>
                                              {/* {this.state.Education.length !== 1 && 
                                                    <a class="float-right" style={{marginTop:'-8.5%', color:'#007bff'}}
                                                    onClick={this.removeClickEducation.bind(this, i)}>x</a>
                                                    } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Alma Mater  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Alma Mater"
                                              name="institute_name" value={el.institute_name || ''}
                                            // onChange={this.handleChangeEducation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.institute_name != '') ? (<div></div>) : (this.state.institutenameErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="institute_name_comment"
                                              value={el.institute_name_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="institute_name_status"
                                              checked={el.institute_name_status}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.InstituteNameAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayInstituteName.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Institute Type </label>
                                            {/* <input type="text" class="form-control disabled" 
                                                    id="input7" placeholder="Enter Institute Type" 
                                                    name="institute_type" value={el.institute_type ||''}
                                                    // onChange={this.handleChangeEducation.bind(this, i)}   
                                                /> */}
                                            <Input type="select" id="itSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="institute_type"
                                              value={el.institute_type || ''}
                                            // onChange={this.handleChangeEducation.bind(this, i)} 
                                            >
                                              <option value="" >--Select Institute Type--</option>
                                              <option value="Public" >Public</option>
                                              <option value="Private" >Private</option>
                                            </Input>
                                            {/* <div className="loginErrCreateProject">
                                                {
                                                (el.institute_type != '') ?( <div></div>) :(this.state.institutetypeErr)
                                                }
                                                </div>  */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              // id="input3" 
                                              placeholder="Enter Comment"
                                              name="institute_type_comment"
                                              value={el.institute_type_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="institute_type_status"
                                              checked={el.institute_type_status}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.InstituteTypeAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayInstituteType.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Institution Link  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Source Link"
                                              name="source_link" value={el.source_link || ''}
                                            // onChange={this.handleChangeEducation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkEducationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              // id="input3" 
                                              placeholder="Enter Comment"
                                              name="source_link_comment"
                                              value={el.source_link_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="source_link_status"
                                              checked={el.source_link_status}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.SourceLinkEducationAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySourceLinksEducation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Degrees  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Degree"
                                              name="degrees" value={el.degrees || ''}
                                            // onChange={this.handleChangeEducation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.degrees != '') ? (<div></div>) : (this.state.degreesErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              // id="input3" 
                                              placeholder="Enter Comment"
                                              name="degrees_comment"
                                              value={el.degrees_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="degrees_status"
                                              checked={el.degrees_status}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DegreesAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDegrees.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality  
                                            </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Speciality"
                                              name="speciality_id" value={el.speciality_id || ''}
                                              // onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                            {/* <Multiselect
                                              disabled
                                              data={this.state.SpecialityData}
                                              valueField='id'
                                              textField='speciality_type'
                                              defaultValue={(el.speciality_id != '') ? (el.speciality_id.split(",")) : ([])}
                                            /> */}
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityEducationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SpecialityEducationAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySpecialityEducation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Years Attended </label>
                                            <br />
                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                              From </label>
                                            <text class="form-control disabled" >{el.years_attended_from || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                    // id="input7" 
                                                    placeholder="From" 
                                                    name="years_attended_from" value={el.years_attended_from ||''}
                                                    // onChange={this.handleChangeEducation.bind(this, i)}   
                                                /> */}
                                            {/* <div className="loginErrCreateProject">
                                                {
                                                (el.years_attended_from != '') ?( <div></div>) :(this.state.yearsattendedfromErr )
                                                }
                                                </div> */}
                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                              To  </label>
                                            <text class="form-control disabled" >{el.years_attended_to || ''}</text>
                                            {/* <input type="date" class="form-contro disabled" 
                                                    id="input7" placeholder="To" 
                                                    name="years_attended_to" value={el.years_attended_to ||''}
                                                    // onChange={this.handleChangeEducation.bind(this, i)}   
                                                /> */}
                                            {/* <div className="loginErrCreateProject">
                                                {
                                                (el.years_attended_to != '') ?( <div></div>) :(this.state.yearsattendedtoErr  )
                                                }
                                                </div>   */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '10%' }}>
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="years_attended_comment"
                                              value={el.years_attended_comment}
                                              onChange={this.handleChangeEducation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1" style={{ marginTop: '5%' }}>
                                            <label for="exampleFormControlTextarea1" >
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="years_attended_staus"
                                              checked={el.years_attended_staus}
                                              onChange={this.handleChangeCheckBoxEducation.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.YearsAttendedAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearsAttended.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />
                                        {/* Add More and remove */}
                                        {/* {this.state.Education.length !== 1 && 
                                            <a class="float-right" style={{marginTop:'-2%', color:'#007bff'}}
                                            onClick={this.removeClickEducation.bind(this, i)}>- Remove</a>
                                        } */}
                                        {/* {this.state.Education.length - 1 === i && 
                                            <a style={{marginTop:'5%', color:'#007bff'}}
                                            onClick={this.addClickEducation.bind(this)}>+ Add More Education</a>
                                        } */}

                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitEducation.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Advisory Board form */}
                        {
                          (this.state.AdvisoryBoardFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Advisory Board 1</h3>
                             
                              {/* <div class='row' style={{marginLeft:'25px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >                                
                              Check All
                                          <input type='checkbox'
                                            class="form-control-addsource-Personal-ckeckbox"
                                            // id="input3"
                                           // name="citizenship_status"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { AdvisoryBoard } = this.state;

                                              this.state.AdvisoryBoard.map((el,i)=>
                                              {

                                              AdvisoryBoard[i].year_status = e.target.checked;
                                              AdvisoryBoard[i].to_year_status = e.target.checked;
                                              AdvisoryBoard[i].company_status = e.target.checked;
                                              AdvisoryBoard[i].type_of_company_status = e.target.checked;
                                              AdvisoryBoard[i].advisory_board_status = e.target.checked;
                                              AdvisoryBoard[i].topic_status = e.target.checked;
                                              AdvisoryBoard[i].role_status = e.target.checked;
                                             
                                              })
                                              
                                            
                                              this.setState({ AdvisoryBoard });

                                             // alert('hi');


                                            }}
                                          />
                                        </div>
</div>
</div> */}
{/* 
<div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { AdvisoryBoard } = this.state;
                                              this.state.AdvisoryBoard.map((el,i) => {                                             
                                                AdvisoryBoard[i].year_status = e.target.checked;
                                                AdvisoryBoard[i].to_year_status = e.target.checked;
                                                AdvisoryBoard[i].company_status = e.target.checked;
                                                AdvisoryBoard[i].type_of_company_status = e.target.checked;
                                                AdvisoryBoard[i].advisory_board_status = e.target.checked;
                                                AdvisoryBoard[i].topic_status = e.target.checked;
                                                AdvisoryBoard[i].role_status = e.target.checked;
                                                
                                                this.setState({ AdvisoryBoard });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}

                              <form>
                                {
                                  this.state.AdvisoryBoard.map((el, i) => {
                                    // var arraySpecialityAdvBoard = [];
                                    // var arraySubSpecialityAdvBoard = [];
                                    // var arrayProcedureAdvBoard = [];
                                    var arrayYearAdvBoard = [];
                                    var arrayToYearAdvBoard = [];
                                    var arrayCompany = [];
                                    var arrayTypeofCompany = [];
                                    var arrayAdvisoryBoard = [];
                                    var arrayTopic = [];
                                    var arrayRole = [];
                                    var arrayaffiliationtype = [];
                                    var arraycommitteestatus = [];
                                    


                                    // if (el.speciality_links !== '' && el.speciality_links !== null) {
                                    //   arraySpecialityAdvBoard = el.speciality_links.split(',')
                                    //   arraySpecialityAdvBoard = arraySpecialityAdvBoard.splice(1)
                                    // }
                                    // if (el.sub_speciality_links !== '' && el.sub_speciality_links !== null) {
                                    //   arraySubSpecialityAdvBoard = el.sub_speciality_links.split(',')
                                    //   arraySubSpecialityAdvBoard = arraySubSpecialityAdvBoard.splice(1)
                                    // }
                                    // if (el.procedure_type_links !== '' && el.procedure_type_links !== null) {
                                    //   arrayProcedureAdvBoard = el.procedure_type_links.split(',')
                                    //   arrayProcedureAdvBoard = arrayProcedureAdvBoard.splice(1)
                                    // }
                                    if (el.year_links !== '' && el.year_links !== null) {
                                      arrayYearAdvBoard = el.year_links.split(',')
                                      arrayYearAdvBoard = arrayYearAdvBoard.splice(1)
                                    }
                                    if (el.to_year_links !== '' && el.to_year_links !== null) {
                                      arrayToYearAdvBoard = el.to_year_links.split(',')
                                      arrayToYearAdvBoard = arrayToYearAdvBoard.splice(1)
                                    }
                                    if (el.company_links !== '' && el.company_links !== null) {
                                      arrayCompany = el.company_links.split(',')
                                      arrayCompany = arrayCompany.splice(1)
                                    }
                                    if (el.type_of_company_links !== '' && el.type_of_company_links !== null) {
                                      arrayTypeofCompany = el.type_of_company_links.split(',')
                                      arrayTypeofCompany = arrayTypeofCompany.splice(1)
                                    }
                                    if (el.advisory_board_links !== '' && el.advisory_board_links !== null) {
                                      arrayAdvisoryBoard = el.advisory_board_links.split(',')
                                      arrayAdvisoryBoard = arrayAdvisoryBoard.splice(1)
                                    }
                                    if (el.topic_links !== '' && el.topic_links !== null) {
                                      arrayTopic = el.topic_links.split(',')
                                      arrayTopic = arrayTopic.splice(1)
                                    }
                                    if (el.role_links !== '' && el.role_links !== null) {
                                      arrayRole = el.role_links.split(',')
                                      arrayRole = arrayRole.splice(1)
                                    }

                                    if (el.committeestatus_links !== '' && el.committeestatus_links !== null) {
                                      arraycommitteestatus = el.committeestatus_links.split(',')
                                      arraycommitteestatus = arraycommitteestatus.splice(1)
                                    }

                                    
                                    if (el.affiliationtype_links !== '' && el.affiliationtype_links !== null) {
                                      arrayaffiliationtype = el.affiliationtype_links.split(',')
                                      arrayaffiliationtype = arrayaffiliationtype.splice(1)
                                    }

                                    
                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Advisory Board {i + 1}</h3>
                                              {/* {this.state.AdvisoryBoard.length !== 1 && 
                                                        <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                        onClick={this.removeClickAdvisoryBoard.bind(this, i)}>x</a>
                                                    } */}
                                            </div>
                                          )
                                        }

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_id"
                                              value={el.speciality_id || ''}
                                            >
                                              <option value="" >--Select Speciality--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityAdvBoardErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialityAdvBoardAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialityAdvBoard.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        {/* Sub Specilality dropdown starts here */}
                                        {/* {
                                            ( this.state.showProcedureSubSpecialityDropdownAdvBoard == false ) ? (
                                            <div></div>
                                            ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Sub Speciality Type </label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydataForAdvBoard}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='sub_speciality_type'
                                                textField='sub_speciality_type'
                                                defaultValue={(el.sub_specialty_id != '') ? (el.sub_specialty_id.split(",")) : ([])}

                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.sub_specialty_id != '') ? (<div></div>) : (this.state.subspecialityAdvBoardErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.SubSpecialityAdvBoardAddSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arraySubSpecialityAdvBoard.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_speciality_comment"
                                                value={el.sub_speciality_comment}
                                                onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_speciality_status"
                                                checked={el.sub_speciality_status}
                                                onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}

                                        {/* )
                                        } */}

                                        {/* Procedure Dropdown starts here */}
                                        {/* {
                                            ( this.state.showProcedureSubSpecialityDropdownAdvBoard == false ) ? (
                                            <div></div>
                                            ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Procedure Type </label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureDataForAdvBoard}
                                                placeholder="--Select Procedure Type--"
                                                valueField='procedure_type'
                                                textField='procedure_type'
                                                defaultValue={(el.procedure_type_id != '') ? (el.procedure_type_id.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.procedure_type_id != '') ? (<div></div>) : (this.state.procedureAdvBoardErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.ProcedureAdvBoardAddSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arrayProcedureAdvBoard.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={el.procedure_type_comment}
                                                onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={el.procedure_type_status}
                                                onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}

                                        {/* )
                                        } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              From Year </label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="text" class="form-control disabled" 
                                                    autofocus="true"
                                                    id="input7" placeholder="Enter Year" 
                                                    name="year" value={el.year ||''}
                                                    // onChange={this.handleChangeAdvisoryBoard.bind(this, i)}   
                                                /> */}
                                            {/* <Datetime class="form-control disabled" 
                                                    name="year" 
                                                    placeholder="Enter Last Name"
                                                    initialViewMode ='years'
                                                    timeFormat =''
                                                    closeOnSelect = 'true'
                                                    value ={(moment('12-2-'+el.year).format("YYYY")== 'Invalid date')?('Select Year'):
                                                    (moment('12-2-'+el.year).format("YYYY"))}
                                                    // value={el.year}
                                                    dateFormat="YYYY" 
                                                    // onChange={(date)=> this.handleInputChangeYearAdvBoard(date, i,'year')}
                                                /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.YearAddSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearAdvBoard.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                             To Year </label>
                                            <text class="form-control disabled" >{el.to_year || ''}</text>

                                            {/* <div className="loginErrCreateProject" >
                                              {
                                                (el.to_year != '') ? (<div></div>) : (this.state.yearErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="to_year_comment"
                                              value={el.to_year_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="to_year_status"
                                              checked={el.to_year_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.ToYearAdvBoardAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayToYearAdvBoard.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Company/Org  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Company/Org"
                                              name="company" value={el.company || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.company != '') ? (<div></div>) : (this.state.companyAdvBoardErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="company_comment"
                                              value={el.company_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="company_status"
                                              checked={el.company_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                        {
                                          (!this.state.CompanyOrgAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayCompany.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type Of Company/Org  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="type_of_company"
                                              value={el.type_of_company || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
                                            >
                                              <option value="" >--Select Type Of Company--</option>
                                              <option value="Global" >Global</option>
                                              <option value="National" >National</option>
                                              <option value="Regional" >Regional</option>
                                              <option value="Local" >Local</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.type_of_company != '') ? (<div></div>) : (this.state.typeofCompanyErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_of_company_comment"
                                              value={el.type_of_company_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_of_company_status"
                                              checked={el.type_of_company_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                        {
                                          (!this.state.TypeOfCompanyOrgAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayTypeofCompany.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Advisory Board </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Advisory Board"
                                              name="advisory_board" value={el.advisory_board || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                                    {
                                                    (el.advisory_board != '') ?( <div></div>) :(this.state.advisoryboardErr )
                                                    }
                                                </div>  */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="advisory_board_comment"
                                              value={el.advisory_board_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="advisory_board_status"
                                              checked={el.advisory_board_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.AdvisoryBoardSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayAdvisoryBoard.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Topic </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Topic"
                                              name="topic" value={el.topic || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.topic != '') ? (<div></div>) : (this.state.topicErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="topic_comment"
                                              value={el.topic_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="topic_status"
                                              checked={el.topic_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.TopicSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayTopic.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="role_id"
                                              value={el.role_id || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
                                            >
                                              <option value="" >--Select Role--</option>
                                              <option value="Board Member" >Board Member</option>
                                              <option value="Advisory Committee" >Advisory Committee</option>
                                              <option value="Chair" >Chair</option>
                                              <option value="Co-Chair" >Co-Chair</option>
                                              <option value="Member" >Member</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.role_id != '') ? (<div></div>) : (this.state.roleAdvBoardErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comment"
                                              value={el.role_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.RoleAdvBoardAddSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayRole.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

<div class="row">
  <div class="col-md-5">
    <label for="exampleFormControlTextarea1">
    Committee Status  <span class="mandatory">*</span></label>
    <Input type="select" id="roleSelect" className="form-control disabled"
      style={{ height: '50px' }}
      name="committeestatus"
      value={el.committeestatus || ''}
    // onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
    >
      <option value="" >--Select Committee Status--</option>
      <option value="Current" >Current</option>
      <option value="Unknown" >Unknown</option>
      <option value="Past" >Past</option>
      
    </Input>
    <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
      {
        (el.committeestatus != '') ? (<div></div>) : (this.state.committeestatusAdvBoardErr)
      }
    </div>
    
  </div>

  <div class="col-md-6">
    <label for="exampleFormControlTextarea1">
      Comment</label>
    <input type="text" class="form-control-addsource-Personal"
      id="input3" placeholder="Enter Comment"
      name="committeestatus_comment"
      value={el.committeestatus_comment}
      onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
    />
  </div>

  <div class="col-md-1">
    <label for="exampleFormControlTextarea1">
      Check</label>
    <input type='checkbox'
      class="form-control-addsource-Personal-ckeckbox"
      // id="input3"
      name="committeestatus_status"
      checked={el.committeestatus_status}
      onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
    />
  </div>
</div>
{
  (!this.state.committeestatusAdvBoardAddSource) ? (
    <div></div>
  ) : (
    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
      {
        arraycommitteestatus.flat().map((item,index) => (
          <div class="tag-item" key={item}>
            <a href={item} target="_blank">
              <text>{index+1}.{item}</text><br/>
            </a>
          </div>
        ))
      }
    </div>
  )
}
<hr />






          

<div class="row">
  <div class="col-md-5">
    <label for="exampleFormControlTextarea1">
    Affiliation Type  <span class="mandatory">*</span></label>
    <Input type="select" id="roleSelect" className="form-control disabled"
      style={{ height: '50px' }}
      name="affiliationtype"
      value={el.affiliationtype || ''}
    // onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
    >
      <option value="" >--Select Affiliation Type--</option>
      <option value="Pharma Companies" >Pharma Companies</option>
      <option value="Medtech Companies" >Medtech Companies</option>
      <option value="Professional Associations" >Professional Associations</option>
      <option value="Government Institutions" >Government Institutions</option>
     
    </Input>
    <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
      {
        (el.affiliationtype != '') ? (<div></div>) : (this.state.affiliationtypeAdvBoardErr)
      }
    </div>
    
  </div>

  <div class="col-md-6">
    <label for="exampleFormControlTextarea1">
      Comment</label>
    <input type="text" class="form-control-addsource-Personal"
      id="input3" placeholder="Enter Comment"
      name="affiliationtype_comment"
      value={el.affiliationtype_comment}
      onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
    />
  </div>

  <div class="col-md-1">
    <label for="exampleFormControlTextarea1">
      Check</label>
    <input type='checkbox'
      class="form-control-addsource-Personal-ckeckbox"
      // id="input3"
      name="affiliationtype_status"
      checked={el.affiliationtype_status}
      onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
    />
  </div>
</div>
{
  (!this.state.affiliationtypeAdvBoardAddSource) ? (
    <div></div>
  ) : (
    <div style={{ marginTop: '1%', marginBottom: '2%' }}>
      {
        arrayaffiliationtype.flat().map((item,index) => (
          <div class="tag-item" key={item}>
            <a href={item} target="_blank">
              <text>{index+1}.{item}</text><br/>
            </a>
          </div>
        ))
      }
    </div>
  )
}
<hr />



                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="manual_weightage"
                                              value={el.manual_weightage || ''}
                                            // onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
                                            >
                                              <option value="" >--Select Manual weightage--</option>
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.manual_weightage !== '') ?
                                                  (<div></div>) : (this.state.manualAdvBoardErr)
                                              }
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1" >
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="manual_weightage_comment"
                                              value={el.manual_weightage_comment}
                                              onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="manual_weightage_status"
                                              checked={el.manual_weightage_status}
                                              onChange={this.handleChangeCheckBoxAdvisoryBoard.bind(this, i)}
                                            />
                                          </div>

                                        </div> */}

                                        <hr />

                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                          Manual weightage  <span class="mandatory">*</span></label>
                                        <Input type="select"  id="roleSelect"  className="form-control" 
                                                style={{height: '50px'}}
                                                name="manual_weightage"
                                                value={el.manual_weightage ||''}
                                                onChange={this.handleChangeAdvisoryBoard.bind(this, i)} 
                                            >
                                                <option value = "" >--Select Manual weightage--</option>
                                                <option value = "1" >1</option>
                                                <option value = "2" >2</option>
                                                <option value = "3" >3</option>
                                                <option value = "4" >4</option>
                                                <option value = "5" >5</option>
                                        </Input>
                                        <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                            {
                                            (el.manual_weightage != '') ?( <div></div>) :(this.state.manualAdvBoardErr )
                                            }
                                        </div>
                                                                                                              
                                    <hr/>                                         */}
                                        {/* Add More */}

                                        {/* {this.state.AdvisoryBoard.length - 1 === i && 
                                        <a style={{marginTop:'5%', color:'#007bff'}}
                                        onClick={this.addClickAdvisoryBoard.bind(this)}>+ Add More Advisory Board</a>
                                    } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitAdvisoryBoard.bind(this)}/> */}

                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Awards & Honours form */}
                        {
                          (this.state.AwardsAndHonoursFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Awards & Honours 1</h3>

                              {/* <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { AwardsHonours } = this.state;

                                             this.state.AwardsHonours.map((el,i) => 
                                              {
                                               AwardsHonours[i].year_status = e.target.checked;
                                              AwardsHonours[i].to_year_status = e.target.checked;
                                            
                                              AwardsHonours[i].awards_honours_status = e.target.checked;
                                              AwardsHonours[i].Awarding_Organisation_status = e.target.checked;
                                              AwardsHonours[i].types_status = e.target.checked;
                                              }

                                              );
                                              this.setState({ AwardsHonours });

                                             // alert('hi');


                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> 
                              */}
{/* 
<div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { AwardsHonours } = this.state;
                                              this.state.AwardsHonours.map((el,i) => {                                             
                                                AwardsHonours[i].year_status = e.target.checked;
                                                AwardsHonours[i].to_year_status = e.target.checked;
                                              
                                                AwardsHonours[i].awards_honours_status = e.target.checked;
                                                AwardsHonours[i].Awarding_Organisation_status = e.target.checked;
                                                AwardsHonours[i].types_status = e.target.checked;
                                                  
                                                this.setState({ AwardsHonours });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.AwardsHonours.map((el, i) => {
                                    var arrayYearAwards = [];
                                    var arrayToYearAwards = [];
                                    var arrayAwardsnHonours = [];
                                    var arrayAwardingOrganisation = [];

                                    var arrayTypesAwards = [];

                                    if (el.year_links !== '') {
                                      arrayYearAwards = el.year_links.split(',')
                                      arrayYearAwards = arrayYearAwards.splice(1)
                                    }
                                    if (el.to_year_links !== '') {
                                      arrayToYearAwards = el.to_year_links.split(',')
                                      arrayToYearAwards = arrayToYearAwards.splice(1)
                                    }
                                    if (el.awards_honours_links !== '') {
                                      arrayAwardsnHonours = el.awards_honours_links.split(',')
                                      arrayAwardsnHonours = arrayAwardsnHonours.splice(1)
                                    }

                                    if (el.Awarding_Organisation_links !== '') {
                                      arrayAwardingOrganisation = el.Awarding_Organisation_links.split(',')
                                      arrayAwardingOrganisation = arrayAwardingOrganisation.splice(1)
                                    }
                                   
                                    if (el.types_links !== '') {
                                      arrayTypesAwards = el.types_links.split(',')
                                      arrayTypesAwards = arrayTypesAwards.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Awards & Honours {i + 1}</h3>
                                              {/* {this.state.AwardsHonours.length !== 1 && 
                                                        <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                        onClick={this.removeClickAwardsHonours.bind(this, i)}>x</a>
                                                    } */}

                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                             From Year  
                                              {/* <span class="mandatory">*</span> */}
                                            </label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                    autofocus="true"
                                                    id="input7" placeholder="Enter Year" 
                                                    name="year" value={el.year ||''}
                                                    // onChange={this.handleChangeAwardsHonours.bind(this, i)}   
                                                /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearAwardsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxAwardsHonours.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.YearAwardSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearAwards.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                             To Year  
                                              {/* <span class="mandatory">*</span> */}
                                            </label>
                                            <text class="form-control disabled" >{el.to_year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                    autofocus="true"
                                                    id="input7" placeholder="Enter Year" 
                                                    name="year" value={el.year ||''}
                                                    // onChange={this.handleChangeAwardsHonours.bind(this, i)}   
                                                /> */}
                                            {/* <div className="loginErrCreateProject" >
                                              {
                                                (el.to_year != '') ? (<div></div>) : (this.state.yearAwardsErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="to_year_comments"
                                              value={el.to_year_comments}
                                              onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="to_year_status"
                                              checked={el.to_year_status}
                                              onChange={this.handleChangeCheckBoxAwardsHonours.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.ToYearAwardSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayToYearAwards.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Awards & Honours  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Awards And Honours"
                                              name="awards_honours" value={el.awards_honours || ''}
                                            // onChange={this.handleChangeAwardsHonours.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.awards_honours != '') ? (<div></div>) : (this.state.awardsnhonourErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="awards_honours_commen"
                                              value={el.awards_honours_commen}
                                              onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="awards_honours_status"
                                              checked={el.awards_honours_status}
                                              onChange={this.handleChangeCheckBoxAwardsHonours.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.AwardsHonoursSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayAwardsnHonours.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />



                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                            Awarding Organisation  <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Awarding Organisation"
                                              name="Awarding_Organisation" value={el.Awarding_Organisation || ''}
                                            // onChange={this.handleChangeAwardsHonours.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.Awarding_Organisation != '') ? (<div></div>) : (this.state.awardsnhonourErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="Awarding_Organisation_commen"
                                              value={el.Awarding_Organisation_commen}
                                              onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="Awarding_Organisation_status"
                                              checked={el.Awarding_Organisation_status}
                                              onChange={this.handleChangeCheckBoxAwardsHonours.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.AwardingOrganisationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayAwardingOrganisation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />






















                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Types of Awards & Honours  </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Types of Awards & Honours"
                                              name="types" value={el.types || ''}
                                            // onChange={this.handleChangeAwardsHonours.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="types_comment"
                                              value={el.types_comment}
                                              onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="types_status"
                                              checked={el.types_status}
                                              onChange={this.handleChangeCheckBoxAwardsHonours.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TypesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypesAwards.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />
                                        {/* <hr/>                                       
                                        {/* Add More and remove */}

                                        {/* {this.state.AwardsHonours.length - 1 === i && 
                                            <a style={{marginTop:'5%', color:'#007bff'}}
                                            onClick={this.addClickAwardsHonours.bind(this)}>+ Add More Awards & Honours</a>
                                        } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitAwardsHonours.bind(this)}/> */}
                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Books and Monographs form */}
                        {
                          (this.state.BooksAndMonographFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Books & Monographs</h3>


                              {/* <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { BooksMonographs } = this.state;

                                              this.state.BooksMonographs.map((el,i) => {

                                             
                                              BooksMonographs[i].year_status = e.target.checked;
                                              BooksMonographs[i].title_status = e.target.checked;
                                            
                                              BooksMonographs[i].author_status = e.target.checked;
                                              BooksMonographs[i].publication_status = e.target.checked;
                                              BooksMonographs[i].edition_status = e.target.checked;
                                              BooksMonographs[i].pages_status = e.target.checked;
                                              BooksMonographs[i].editors_status = e.target.checked;
                                              
                                              this.setState({ BooksMonographs });

                                             // alert('hi');
                                            }
                                              
                                            )

                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> 
                              */}

{/* <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { BooksMonographs } = this.state;
                                              this.state.BooksMonographs.map((el,i) => {                                             
                                                
                                                
                                              BooksMonographs[i].year_status = e.target.checked;
                                              BooksMonographs[i].title_status = e.target.checked;
                                            
                                              BooksMonographs[i].author_status = e.target.checked;
                                              BooksMonographs[i].publication_status = e.target.checked;
                                              BooksMonographs[i].edition_status = e.target.checked;
                                              BooksMonographs[i].pages_status = e.target.checked;
                                              BooksMonographs[i].editors_status = e.target.checked;
                                              
                                                this.setState({ BooksMonographs });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.BooksMonographs.map((el, i) => {
                                    var arrayYearBooks = [];
                                    var arrayTitleBooks = [];
                                    var arrayAuthor = [];
                                    var arrayPublicationBooks = [];
                                    var arrayEdition = [];
                                    var arrayPages = [];
                                    var arrayEditors = []

                                    if (el.year_links !== '') {
                                      arrayYearBooks = el.year_links.split(',')
                                      arrayYearBooks = arrayYearBooks.splice(1)
                                    }
                                    if (el.title_links !== '') {
                                      arrayTitleBooks = el.title_links.split(',')
                                      arrayTitleBooks = arrayTitleBooks.splice(1)
                                    }
                                    if (el.author_links !== '') {
                                      arrayAuthor = el.author_links.split(',')
                                      arrayAuthor = arrayAuthor.splice(1)
                                    }
                                    if (el.publication_links !== '') {
                                      arrayPublicationBooks = el.publication_links.split(',')
                                      arrayPublicationBooks = arrayPublicationBooks.splice(1)
                                    }
                                    if (el.edition_links !== '') {
                                      arrayEdition = el.edition_links.split(',')
                                      arrayEdition = arrayEdition.splice(1)
                                    }
                                    if (el.pages_links !== '') {
                                      arrayPages = el.pages_links.split(',')
                                      arrayPages = arrayPages.splice(1)
                                    }
                                    if (el.editors_links !== '') {
                                      arrayEditors = el.editors_links.split(',')
                                      arrayEditors = arrayEditors.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Books & Monographs {i + 1}</h3>
                                              {/* {this.state.BooksMonographs.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickBooksMonographs.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Year <span class="mandatory">*</span></label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        autofocus="true"
                                                        id="input7" placeholder="Enter Year" 
                                                        name="year" value={el.year ||''}
                                                        // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                                    /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearBooksErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                              (!this.state.YearBooksSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearBooks.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Title <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Title"
                                              name="title" value={el.title || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.title != '') ? (<div></div>) : (this.state.titleErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="title_comment"
                                              value={el.title_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="title_status"
                                              checked={el.title_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.titleSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTitleBooks.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Author <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Author"
                                              name="author" value={el.author || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.author != '') ? (<div></div>) : (this.state.authorErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="author_comment"
                                              value={el.author_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="author_status"
                                              checked={el.author_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.AuthorSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayAuthor.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Publication <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Publication"
                                              name="publication" value={el.publication || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.publication != '') ? (<div></div>) : (this.state.publicationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="publication_comment"
                                              value={el.publication_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="publication_status"
                                              checked={el.publication_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.PublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPublicationBooks.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Edition </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Edition"
                                              name="edition" value={el.edition || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="edition_comment"
                                              value={el.edition_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="edition_status"
                                              checked={el.edition_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.EditionSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayEdition.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Pages </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Pages"
                                              name="pages" value={el.pages || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="pages_comment"
                                              value={el.pages_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="pages_status"
                                              checked={el.pages_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.PagesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPages.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Editors </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Editors"
                                              name="editors" value={el.editors || ''}
                                            // onChange={this.handleChangeBooksMonographs.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.editors != '') ? (<div></div>) : (this.state.editorsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="editors_comment"
                                              value={el.editors_comment}
                                              onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="editors_status"
                                              checked={el.editors_status}
                                              onChange={this.handleChangeCheckBoxBooksMonographs.bind(this, i)}
                                            />
                                          </div>
                                          {/* <div style={{marginBottom: '20%'}}> </div> */}
                                        </div>
                                          {
                                              (!this.state.EditorsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayEditors.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />
                                        {/* <hr/>
                                            {/* Add More and remove */}
                                        {/* {this.state.BooksMonographs.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickBooksMonographs.bind(this)}>+ Add More Books & Monographs</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitBooksMonographs.bind(this)}/> */}

                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Commitees form */}
                        {
                          (this.state.CommiteesFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Commitees 1</h3>


                              {/* <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Commitees } = this.state;

                                              this.state.Commitees.map((el,i) => {

                                             
                                                Commitees[i].year_status = e.target.checked;
                                                Commitees[i].to_year_status = e.target.checked;
                                            
                                                Commitees[i].body_org_name_status = e.target.checked;
                                                Commitees[i].committee_status = e.target.checked;
                                                Commitees[i].role_status = e.target.checked;
                                               
                                              this.setState({ Commitees });

                                             // alert('hi');
                                            }
                                              
                                            )

                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> 
                              */}

{/* <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Commitees } = this.state;
                                              this.state.Commitees.map((el,i) => {                                             

                                                Commitees[i].year_status = e.target.checked;
                                                Commitees[i].to_year_status = e.target.checked;
                                            
                                                Commitees[i].body_org_name_status = e.target.checked;
                                                Commitees[i].committee_status = e.target.checked;
                                                Commitees[i].role_status = e.target.checked;

                                                this.setState({ Commitees });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}




                              <form>
                                {
                                  this.state.Commitees.map((el, i) => {
                                    var arrayYearComitees = [];
                                    var arrayToYearComitees = [];
                                    var arrayBodyOrgName = [];
                                    var arrayCommeitees = [];
                                    var arrayRoleCommitees = [];

                                    if (el.year_links !== '') {
                                      arrayYearComitees = el.year_links.split(',')
                                      arrayYearComitees = arrayYearComitees.splice(1)
                                    }
                                    if (el.to_year_links !== '') {
                                      arrayToYearComitees = el.to_year_links.split(',')
                                      arrayToYearComitees = arrayToYearComitees.splice(1)
                                    }
                                    if (el.body_org_name_links !== '') {
                                      arrayBodyOrgName = el.body_org_name_links.split(',')
                                      arrayBodyOrgName = arrayBodyOrgName.splice(1)
                                    }
                                    if (el.committee_links !== '') {
                                      arrayCommeitees = el.committee_links.split(',')
                                      arrayCommeitees = arrayCommeitees.splice(1)
                                    }
                                    if (el.role_links !== '') {
                                      arrayRoleCommitees = el.role_links.split(',')
                                      arrayRoleCommitees = arrayRoleCommitees.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Commitees {i + 1}</h3>
                                              {/* {this.state.Commitees.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickCommitees.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              From Year </label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        autofocus="true"
                                                        id="input7" placeholder="Enter Publication" 
                                                        name="year" value={el.year ||''}
                                                        // onChange={this.handleChangeCommitees.bind(this, i)}   
                                                    /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearCommiteeErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeCommitees.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxCommitees.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.YearCommiteesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearComitees.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              To Year </label>
                                            <text class="form-control disabled" >{el.to_year || ''}</text>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="to_year_comments"
                                              value={el.to_year_comments}
                                              onChange={this.handleChangeCommitees.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="to_year_status"
                                              checked={el.to_year_status}
                                              onChange={this.handleChangeCheckBoxCommitees.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.ToYearCommiteesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayToYearComitees.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Body/Org Name <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Body/Org Name"
                                              name="body_org_name" value={el.body_org_name || ''}
                                            // onChange={this.handleChangeCommitees.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                              {
                                                (el.body_org_name != '') ? (<div></div>) : (this.state.commiteeErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="body_org_name_comments"
                                              value={el.body_org_name_comments}
                                              onChange={this.handleChangeCommitees.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="body_org_name_status"
                                              checked={el.body_org_name_status}
                                              onChange={this.handleChangeCheckBoxCommitees.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.bodyOrgNameSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayBodyOrgName.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Commitee </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Commitee"
                                              name="committee" value={el.committee || ''}
                                            // onChange={this.handleChangeCommitees.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.committee != '') ? (<div></div>) : (this.state.commiteeErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="committee_comment"
                                              value={el.committee_comment}
                                              onChange={this.handleChangeCommitees.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="committee_status"
                                              checked={el.committee_status}
                                              onChange={this.handleChangeCheckBoxCommitees.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CommiteeSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCommeitees.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role <span class="mandatory">*</span></label>
                                              <input type="text" class="form-control disabled"
                                                id="input7" placeholder="Enter Role"
                                                name="role_id" value={el.role_id || ''}
                                                // onChange={this.handleChangeCommitees.bind(this, i)}
                                              />
                                            {/* <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="role_id"
                                              value={el.role_id || ''}
                                            // onChange={this.handleChangeCommitees.bind(this, i)}
                                            >
                                              <option value="" >--Select Role--</option>
                                              <option value="President" >President</option>
                                              <option value="Chairman" >Chairman</option>
                                              <option value="Secretary" >Secretary</option>
                                              <option value="Treasurer" >Treasurer</option>
                                              <option value="Marketing and Promotion Officer" >Marketing and Promotion Officer</option>
                                              <option value="Health and Safety Officer" >Health and Safety Officer</option>
                                              <option value="Volunteer Coordinator" >Volunteer Coordinator</option>
                                              <option value="Other" >Other</option>
                                            </Input> */}
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.role_id != '') ? (<div></div>) : (this.state.roleErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comment"
                                              value={el.role_comment}
                                              onChange={this.handleChangeCommitees.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxCommitees.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.RoleSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayRoleCommitees.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />
                                        {/* Add More and remove */}
                                        {/* {this.state.Commitees.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickCommitees.bind(this)}>+ Add More Commitees</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitCommitees.bind(this)}/> */}
                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Clinical Trial Details form */}
                        {
                          (this.state.ClinicalTrialDetailsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Clinical Trial Details 1</h3>

                              {/* <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { ClinicalTrialDetails } = this.state;

                                              this.state.ClinicalTrialDetails.map((el,i) => {

                                             
                                                ClinicalTrialDetails[i].study_details_status = e.target.checked;
                                                ClinicalTrialDetails[i].source_link_status = e.target.checked;
                                            
                                                ClinicalTrialDetails[i].start_date_status = e.target.checked;
                                                ClinicalTrialDetails[i].end_date_status = e.target.checked;
                                                ClinicalTrialDetails[i].type_status = e.target.checked;
                                                ClinicalTrialDetails[i].phase_status = e.target.checked;

                                                ClinicalTrialDetails[i].role_status = e.target.checked;
                                                ClinicalTrialDetails[i].coverage_status = e.target.checked;
                                                ClinicalTrialDetails[i].no_of_study_locations_status = e.target.checked;

                                                ClinicalTrialDetails[i].conditions_status = e.target.checked;
                                                ClinicalTrialDetails[i].treatment_intervention_status = e.target.checked;
                                                ClinicalTrialDetails[i].primary_outcomes_status = e.target.checked;


                                                ClinicalTrialDetails[i].secondary_outcomes_status = e.target.checked;
                                                ClinicalTrialDetails[i].sponsors_status = e.target.checked;
                                                ClinicalTrialDetails[i].collaborators_status = e.target.checked;

                                              this.setState({ ClinicalTrialDetails });

                                             // alert('hi');
                                            }
                                              
                                            )

                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> 
                        */}

{/* 
<div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { ClinicalTrialDetails } = this.state;
                                              this.state.ClinicalTrialDetails.map((el,i) => {                                             
                                                ClinicalTrialDetails[i].study_details_status = e.target.checked;
                                                ClinicalTrialDetails[i].source_link_status = e.target.checked;
                                            
                                                ClinicalTrialDetails[i].start_date_status = e.target.checked;
                                                ClinicalTrialDetails[i].end_date_status = e.target.checked;
                                                ClinicalTrialDetails[i].type_status = e.target.checked;
                                                ClinicalTrialDetails[i].phase_status = e.target.checked;

                                                ClinicalTrialDetails[i].role_status = e.target.checked;
                                                ClinicalTrialDetails[i].coverage_status = e.target.checked;
                                                ClinicalTrialDetails[i].no_of_study_locations_status = e.target.checked;

                                                ClinicalTrialDetails[i].conditions_status = e.target.checked;
                                                ClinicalTrialDetails[i].treatment_intervention_status = e.target.checked;
                                                ClinicalTrialDetails[i].primary_outcomes_status = e.target.checked;


                                                ClinicalTrialDetails[i].secondary_outcomes_status = e.target.checked;
                                                ClinicalTrialDetails[i].sponsors_status = e.target.checked;
                                                ClinicalTrialDetails[i].collaborators_status = e.target.checked;

                                                this.setState({ ClinicalTrialDetails });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}

                              <form>
                                {
                                  this.state.ClinicalTrialDetails.map((el, i) => {
                                    // var arraySpecialityClinical = [];
                                    // var arraySubSpecialityClinical = [];
                                    // var arrayProcedureClinical = [];
                                    var arrayStudyDetails = [];
                                    var arraySourceLinkClinical = [];
                                    var arrayStartDateClinical = [];
                                    var arrayEndDateClinical = [];
                                    var arrayTypeClinical = [];
                                    var arrayPhaseClinical = [];
                                    var arrayRoleClinical = [];
                                    var arrayCoverage = [];
                                    var arrayNoofStudy = [];
                                    var arrayCondition = [];
                                    var arrayTreatmentInv = [];
                                    var arrayPrimaryOutcome = [];
                                    var arraySecondaryOutcome = [];
                                    var arraySponser = [];
                                    var arrayCollaboraters = [];

                                    // if (el.speciality_links !== '') {
                                    //   arraySpecialityClinical = el.speciality_links.split(',')
                                    //   arraySpecialityClinical = arraySpecialityClinical.splice(1)
                                    // }
                                    // if (el.sub_speciality_links !== '') {
                                    //   arraySubSpecialityClinical = el.sub_speciality_links.split(',')
                                    //   arraySubSpecialityClinical = arraySubSpecialityClinical.splice(1)
                                    // }
                                    // if (el.procedure_type_links !== '') {
                                    //   arrayProcedureClinical = el.procedure_type_links.split(',')
                                    //   arrayProcedureClinical = arrayProcedureClinical.splice(1)
                                    // }
                                    if (el.study_details_links !== '') {
                                      arrayStudyDetails = el.study_details_links.split(',')
                                      arrayStudyDetails = arrayStudyDetails.splice(1)
                                    }
                                    if (el.source_link_links !== '') {
                                      arraySourceLinkClinical = el.source_link_links.split(',')
                                      arraySourceLinkClinical = arraySourceLinkClinical.splice(1)
                                    }
                                    if (el.start_date_links !== '') {
                                      arrayStartDateClinical = el.start_date_links.split(',')
                                      arrayStartDateClinical = arrayStartDateClinical.splice(1)
                                    }
                                    if (el.end_date_links !== '') {
                                      arrayEndDateClinical = el.end_date_links.split(',')
                                      arrayEndDateClinical = arrayEndDateClinical.splice(1)
                                    }
                                    if (el.type_links !== '') {
                                      arrayTypeClinical = el.type_links.split(',')
                                      arrayTypeClinical = arrayTypeClinical.splice(1)
                                    }
                                    if (el.phase_links !== '') {
                                      arrayPhaseClinical = el.phase_links.split(',')
                                      arrayPhaseClinical = arrayPhaseClinical.splice(1)
                                    }
                                    if (el.role_links !== '') {
                                      arrayRoleClinical = el.role_links.split(',')
                                      arrayRoleClinical = arrayRoleClinical.splice(1)
                                    }
                                    if (el.coverage_links !== '') {
                                      arrayCoverage = el.coverage_links.split(',')
                                      arrayCoverage = arrayCoverage.splice(1)
                                    }
                                    if (el.no_of_study_locations_links !== '') {
                                      arrayNoofStudy = el.no_of_study_locations_links.split(',')
                                      arrayNoofStudy = arrayNoofStudy.splice(1)
                                    }
                                    if (el.conditions_links !== '') {
                                      arrayCondition = el.conditions_links.split(',')
                                      arrayCondition = arrayCondition.splice(1)
                                    }
                                    if (el.treatment_intervention_links !== '') {
                                      arrayTreatmentInv = el.treatment_intervention_links.split(',')
                                      arrayTreatmentInv = arrayTreatmentInv.splice(1)
                                    }
                                    if (el.primary_outcomes_links !== '') {
                                      arrayPrimaryOutcome = el.primary_outcomes_links.split(',')
                                      arrayPrimaryOutcome = arrayPrimaryOutcome.splice(1)
                                    }
                                    if (el.secondary_outcomes_links !== '') {
                                      arraySecondaryOutcome = el.secondary_outcomes_links.split(',')
                                      arraySecondaryOutcome = arraySecondaryOutcome.splice(1)
                                    }
                                    if (el.sponsors_links !== '') {
                                      arraySponser = el.sponsors_links.split(',')
                                      arraySponser = arraySponser.splice(1)
                                    }
                                    if (el.collaborators_links !== '') {
                                      arrayCollaboraters = el.collaborators_links.split(',')
                                      arrayCollaboraters = arrayCollaboraters.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Clinical Trial Details {i + 1}</h3>
                                              {/* {this.state.ClinicalTrialDetails.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickClinicalTrialDetails.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_id"
                                              value={el.speciality_id || ''}
                                            >
                                              <option value="" >--Select Speciality--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityClinicalErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialityClinicalSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialityClinical.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        {/* Sub Specilality dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownClinicalTrial == false ) ? (
                                                <div></div>
                                                ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydataClinicalTrial}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='sub_speciality_type'
                                                textField='sub_speciality_type'
                                                // defaultValue={(el.sub_speciality).split(",")}
                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityClinicalErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.SubSpecialityClinicalSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arraySubSpecialityClinical.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_speciality_comment"
                                                value={el.sub_speciality_comment}
                                                onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_speciality_status"
                                                checked={el.sub_speciality_status}
                                                onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}
                                        {/* )
                                            } */}

                                        {/* Procedure Dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownClinicalTrial == false ) ? (
                                                <div></div>
                                                ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Procedure Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureDataForClinicalTrial}
                                                placeholder="--Select Procedure Type--"
                                                valueField='procedure_type'
                                                textField='procedure_type'
                                                // defaultValue={(el.procedure_type).split(",")}
                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.procedure_type != '') ? (<div></div>) : (this.state.procedureClinicalErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.ProcedureClinicalSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arrayProcedureClinical.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={el.procedure_type_comment}
                                                onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={el.procedure_type_status}
                                                onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}

                                        {/* )
                                            } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Study Details <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Study Details"
                                              name="study_details" value={el.study_details || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.study_details != '') ? (<div></div>) : (this.state.studydetailsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="study_details_comment"
                                              value={el.study_details_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="study_details_status"
                                              checked={el.study_details_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.StudyDetailsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayStudyDetails.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Source Link <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Source Link"
                                              name="source_link" value={el.source_link || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkClinicalErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="source_link_comment"
                                              value={el.source_link_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="source_link_status"
                                              checked={el.source_link_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SourceLinkClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySourceLinkClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Start Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter Start Date"
                                              name="start_date" value={el.start_date || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.start_date != '') ? (<div></div>) : (this.state.startdateErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="start_date_comment"
                                              value={el.start_date_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="start_date_status"
                                              checked={el.start_date_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.StartDateClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayStartDateClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              End Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter End Date"
                                              name="end_date" value={el.end_date || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.end_date != '') ? (<div></div>) : (this.state.enddateErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="end_date_comment"
                                              value={el.end_date_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="end_date_status"
                                              checked={el.end_date_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.EndDateClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayEndDateClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="type"
                                              value={el.type || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Type--</option>
                                              <option value="Interventional" >Interventional</option>
                                              <option value="Observational" >Observational</option>
                                              {/* <option value = "Pilot Study" >Pilot Study</option>
                                                        <option value = "Feasibility Study" >Feasibility Study</option>
                                                        <option value = "Prevnetion Trials" >Prevnetion Trials</option>
                                                        <option value = "Screening Trials" >Screening Trials</option>
                                                        <option value = "Treatment Trials" >Treatment Trials</option>
                                                        <option value = "Multi Arm Trials" >Multi Arm Trials</option>
                                                        <option value = "Multi Stage Trials" >Multi Stage Trials</option>
                                                        <option value = "Cohort Studies" >Cohort Studies</option>
                                                        <option value = "Case Control Studies" >Case Control Studies</option>
                                                        <option value = "Cross Sectional Studies" >Cross Sectional Studies</option>   */}
                                            </Input>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_comment"
                                              value={el.type_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_status"
                                              checked={el.type_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.TypeSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypeClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Phase <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="phase"
                                              value={el.phase || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Phase--</option>
                                              <option value="NA" >NA</option>
                                              <option value="Phase I" >Phase I</option>
                                              <option value="Phase II" >Phase II</option>
                                              <option value="Phase III" >Phase III</option>
                                              <option value="Phase IV" >Phase IV</option>
                                              <option value="Phase I/II" >Phase I/II</option>
                                              <option value="Phase II/III" >Phase II/III</option>
                                              <option value="Phase III/IV" >Phase III/IV</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.phase != '') ? (<div></div>) : (this.state.phaseErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="phase_comment"
                                              value={el.phase_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="phase_status"
                                              checked={el.phase_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.PhaseSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPhaseClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="role_id"
                                              value={el.role_id || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            >
                                              {/* <option value="" >--Select Role--</option>
                                              <option value="Investigator" >Investigator</option>
                                              <option value="Principal Investigator/Author" >Principal Investigator/Author</option>
                                              <option value="No Role" >No Role</option> */}
                                              <option value="" >--Select Role--</option>
                                              <option value="Study Chair" >Study Chair</option>
                                              <option value="Study Director" >Study Director</option>
                                              <option value="Investigator" >Investigator</option>
                                              <option value="Principal Investigator/Author" >Principal Investigator/Author</option>
                                              <option value="No Role" >No Role</option>

                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.role_id != '') ? (<div></div>) : (this.state.roleClinicalErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comment"
                                              value={el.role_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.RoleClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayRoleClinical.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Coverage <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="coverage"
                                              value={el.coverage || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Coverage--</option>
                                              <option value="Global" >Global</option>
                                              <option value="Regional" >Regional</option>
                                              <option value="National" >National</option>
                                              <option value="Local" >Local</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.coverage != '') ? (<div></div>) : (this.state.coverageErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="coverage_comment"
                                              value={el.coverage_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="coverage_status"
                                              checked={el.coverage_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CoverageClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCoverage.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              No. of Study Locations <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="no_of_study_locations"
                                              value={el.no_of_study_locations || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select No of Study Locations--</option>
                                              <option value="1" >1</option>
                                              <option value="10" >1-10</option>
                                              <option value="50" >11-50</option>
                                              <option value="10000" >more than 50</option>
                                              {/* <option value="1" >1</option>
                                              <option value="10" >10</option>
                                              <option value="50" >50</option>
                                              <option value="100000" >100000</option> */}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.no_of_study_locations != '') ? (<div></div>) : (this.state.noStudyLocationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="no_of_study_locations_comment"
                                              value={el.no_of_study_locations_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="no_of_study_locations_status"
                                              checked={el.no_of_study_locations_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.NoStudyLocationClinicalTrialSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayNoofStudy.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Conditions</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Conditions"
                                              name="conditions" value={el.conditions || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="conditions_comment"
                                              value={el.conditions_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="conditions_status"
                                              checked={el.conditions_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.conditionsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCondition.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Treatment/Intervention</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Treatment/Intervention"
                                              name="treatment_intervention" value={el.treatment_intervention || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="treatment_intervention_comment"
                                              value={el.treatment_intervention_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="treatment_intervention_status"
                                              checked={el.treatment_intervention_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TreatmentInterventionSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTreatmentInv.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Primary Outcome</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Primary Outcome"
                                              name="primary_outcomes" value={el.primary_outcomes || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                           
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="primary_outcomes_comment"
                                              value={el.primary_outcomes_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="primary_outcomes_status"
                                              checked={el.primary_outcomes_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.PrimaryOutcomeSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPrimaryOutcome.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Secondary Outcomes </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Secondary Outcomes"
                                              name="secondary_outcomes" value={el.secondary_outcomes || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="secondary_outcomes_comment"
                                              value={el.secondary_outcomes_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="secondary_outcomes_status"
                                              checked={el.secondary_outcomes_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SecondaryOutcomesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySecondaryOutcome.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Sponsor </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Sponsor"
                                              name="sponsors" value={el.sponsors || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="sponsors_comment"
                                              value={el.sponsors_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="sponsors_status"
                                              checked={el.sponsors_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SponsorSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySponser.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Collaborators </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Collaborators"
                                              name="collaborators" value={el.collaborators || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="collaborators_comment"
                                              value={el.collaborators_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="collaborators_status"
                                              checked={el.collaborators_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CollaboratorsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCollaboraters.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="manual_weightage"
                                              value={el.manual_weightage || ''}
                                            // onChange={this.handleChangeClinicalTrialDetails.bind(this, i)} 
                                            >
                                              <option value="" >--Select Manual weightage--</option>
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.manual_weightage != '') ? (<div></div>) : (this.state.manualClinicaldErr)
                                              }
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="manual_weightage_comment"
                                              value={el.manual_weightage_comment}
                                              onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="manual_weightage_status"
                                              checked={el.manual_weightage_status}
                                              onChange={this.handleChangeCheckBoxClinicalTrialDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div> */}
                                        {/* <hr /> */}

                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                            Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select"  id="roleSelect"  className="form-control" 
                                                    style={{height: '50px'}}
                                                    name="manual_weightage"
                                                    value={el.manual_weightage ||''}
                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)} 
                                                >
                                                    <option value = "" >--Select Manual weightage--</option>
                                                    <option value = "1" >1</option>
                                                    <option value = "2" >2</option>
                                                    <option value = "3" >3</option>
                                                    <option value = "4" >4</option>
                                                    <option value = "5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                {
                                                (el.manual_weightage != '') ?( <div></div>) :(this.state.manualClinicaldErr )
                                                }
                                            </div>

                                            <hr/> */}
                                        {/* Add More and remove */}

                                        {/* {this.state.ClinicalTrialDetails.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickClinicalTrialDetails.bind(this)}>+ Add More Clinical Trial Details</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitClinicalTrialDetails.bind(this)}/> */}

                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Event Details form */}
                        {
                          (this.state.EventsDetailsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Event Details</h3>


                              {/* <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >
                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            
                                            
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { EventsDetails } = this.state;

                                              this.state.EventsDetails.map((el,i) => {

                                             
                                                EventsDetails[i].events_status = e.target.checked;
                                                EventsDetails[i].event_type_status = e.target.checked;
                                            
                                                EventsDetails[i].event_desc_status = e.target.checked;
                                                EventsDetails[i].event_topic_status = e.target.checked;
                                                EventsDetails[i].source_link_status = e.target.checked;
                                                EventsDetails[i].conditions_status = e.target.checked;

                                                EventsDetails[i].intervention_status = e.target.checked;
                                                EventsDetails[i].date_status = e.target.checked;
                                                EventsDetails[i].session_type_status = e.target.checked;

                                                EventsDetails[i].role_status = e.target.checked;
                                                EventsDetails[i].event_sponsor_status = e.target.checked;
                                                EventsDetails[i].total_event_attendees_status = e.target.checked;


                                                EventsDetails[i].country_status = e.target.checked;
                                                
                                              this.setState({ EventsDetails });

                                             // alert('hi');
                                            }
                                              
                                            )

                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div> 
                        */}
{/* 
<div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { EventsDetails } = this.state;
                                              this.state.EventsDetails.map((el,i) => {                                             
                                                EventsDetails[i].events_status = e.target.checked;
                                                EventsDetails[i].event_type_status = e.target.checked;
                                            
                                                EventsDetails[i].event_desc_status = e.target.checked;
                                                EventsDetails[i].event_topic_status = e.target.checked;
                                                EventsDetails[i].source_link_status = e.target.checked;
                                                EventsDetails[i].conditions_status = e.target.checked;

                                                EventsDetails[i].intervention_status = e.target.checked;
                                                EventsDetails[i].date_status = e.target.checked;
                                                EventsDetails[i].session_type_status = e.target.checked;

                                                EventsDetails[i].role_status = e.target.checked;
                                                EventsDetails[i].event_sponsor_status = e.target.checked;
                                                EventsDetails[i].total_event_attendees_status = e.target.checked;


                                                EventsDetails[i].country_status = e.target.checked;
                                                this.setState({ EventsDetails });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.EventsDetails.map((el, i) => {
                                    // var arraySpecialityEvent = [];
                                    // var arraySuSpecialityEvent = [];
                                    // var arrayProcedureEvent = [];
                                    var arrayEventName = [];
                                    var arrayEventType = [];
                                    var arrayEventDescription = [];
                                    var arrayEventTopic = [];
                                    var arraySourceEvent = [];
                                    var arrayConditions = [];
                                    var arrayInterventation = [];
                                    var arrayDateEvent = [];
                                    var arraySessionType = [];
                                    var arrayRoleEvent = [];
                                    var arrayEventSponser = [];
                                    var arrayTotalEventAttendies = [];
                                    var arrayCountryEvent = [];

                                    // if (el.speciality_links !== '') {
                                    //   arraySpecialityEvent = el.speciality_links.split(',')
                                    //   arraySpecialityEvent = arraySpecialityEvent.splice(1)
                                    // }
                                    // if (el.sub_speciality_links !== '') {
                                    //   arraySuSpecialityEvent = el.sub_speciality_links.split(',')
                                    //   arraySuSpecialityEvent = arraySuSpecialityEvent.splice(1)
                                    // }
                                    // if (el.procedure_type_links !== '') {
                                    //   arrayProcedureEvent = el.procedure_type_links.split(',')
                                    //   arrayProcedureEvent = arrayProcedureEvent.splice(1)
                                    // }
                                    if (el.events_links !== '') {
                                      arrayEventName = el.events_links.split(',')
                                      arrayEventName = arrayEventName.splice(1)
                                    }
                                    if (el.event_type_links !== '') {
                                      arrayEventType = el.event_type_links.split(',')
                                      arrayEventType = arrayEventType.splice(1)
                                    }
                                    if (el.event_desc_links !== '') {
                                      arrayEventDescription = el.event_desc_links.split(',')
                                      arrayEventDescription = arrayEventDescription.splice(1)
                                    }
                                    if (el.event_topic_links !== '') {
                                      arrayEventTopic = el.event_topic_links.split(',')
                                      arrayEventTopic = arrayEventTopic.splice(1)
                                    }
                                    if (el.source_link_links !== '') {
                                      arraySourceEvent = el.source_link_links.split(',')
                                      arraySourceEvent = arraySourceEvent.splice(1)
                                    }
                                    if (el.conditions_links !== '') {
                                      arrayConditions = el.conditions_links.split(',')
                                      arrayConditions = arrayConditions.splice(1)
                                    }
                                    if (el.intervention_links !== '') {
                                      arrayInterventation = el.intervention_links.split(',')
                                      arrayInterventation = arrayInterventation.splice(1)
                                    }
                                    if (el.date_links !== '') {
                                      arrayDateEvent = el.date_links.split(',')
                                      arrayDateEvent = arrayDateEvent.splice(1)
                                    }
                                    if (el.session_type_links !== '') {
                                      arraySessionType = el.session_type_links.split(',')
                                      arraySessionType = arraySessionType.splice(1)
                                    }
                                    if (el.role_links !== '') {
                                      arrayRoleEvent = el.role_links.split(',')
                                      arrayRoleEvent = arrayRoleEvent.splice(1)
                                    }
                                    if (el.event_sponsor_links !== '') {
                                      arrayEventSponser = el.event_sponsor_links.split(',')
                                      arrayEventSponser = arrayEventSponser.splice(1)
                                    }
                                    if (el.total_event_attendees_links !== '') {
                                      arrayTotalEventAttendies = el.total_event_attendees_links.split(',')
                                      arrayTotalEventAttendies = arrayTotalEventAttendies.splice(1)
                                    }
                                    if (el.country_links !== '') {
                                      arrayCountryEvent = el.country_links.split(',')
                                      arrayCountryEvent = arrayCountryEvent.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Event Details {i + 1}</h3>
                                              {/* {this.state.EventsDetails.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickEventsDetails.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_id"
                                              value={el.speciality_id || ''}
                                            >
                                              <option value="" >--Select Speciality--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityEventErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialityEventSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialityEvent.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        {/* Sub Specilality dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownEventDetails == false ) ? (
                                                <div></div>
                                                ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydataEventDetails}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='sub_speciality_type'
                                                textField='sub_speciality_type'
                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityEventErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.SubSpecialityEventSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arraySuSpecialityEvent.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_speciality_comment"
                                                value={el.sub_speciality_comment}
                                                onChange={this.handleChangeEventsDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_speciality_status"
                                                checked={el.sub_speciality_status}
                                                onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}

                                        {/* )
                                            } */}

                                        {/* Procedure Dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownEventDetails == false ) ? (
                                                <div></div>
                                                ) : ( */}

                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Procedure Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureDataForEventDetails}
                                                placeholder="--Select Procedure Type--"
                                                valueField='procedure_type'
                                                textField='procedure_type'
                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.procedure_type != '') ? (<div></div>) : (this.state.procedureEventErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.ProceduereEventSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arrayProcedureEvent.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={el.procedure_type_comment}
                                                onChange={this.handleChangeEventsDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={el.procedure_type_status}
                                                onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}

                                        {/* )
                                            } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Events/Conference Name <span class="mandatory">*</span> </label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Event Name"
                                              name="events" value={el.events || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.events != '') ? (<div></div>) : (this.state.eventNameErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="events_comment"
                                              value={el.events_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="events_status"
                                              checked={el.events_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.EventNameSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayEventName.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Event Type <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="event_type"
                                              value={el.event_type || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            >
                                              {/* <option value="" >--Select Event Type--</option>
                                              <option value="International" >International</option>
                                              <option value="National" >National</option>
                                              <option value="Local" >Local</option> */}
                                              <option value="" >--Select Event Type--</option>
                                              <option value="Global" >Global</option>
                                              <option value="Regional" >Regional</option>
                                              <option value="National" >National</option>
                                              <option value="Local" >Local</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.event_type != '') ? (<div></div>) : (this.state.eventTypeErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="event_type_comment"
                                              value={el.event_type_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="event_type_status"
                                              checked={el.event_type_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.EventTypeEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayEventType.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Event Description </label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Event Description"
                                              name="event_desc" value={el.event_desc || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                                        {
                                                            (el.event_desc != '') ?( <div></div>) :(this.state.eventdescriptionErr )
                                                        }
                                                    </div>  */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="event_desc_comment"
                                              value={el.event_desc_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="event_desc_status"
                                              checked={el.event_desc_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.EventDescriptionSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayEventDescription.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Event Topic <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Event Topic"
                                              name="event_topic" value={el.event_topic || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.event_topic != '') ? (<div></div>) : (this.state.eventtopicErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="event_topic_comment"
                                              value={el.event_topic_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="event_topic_status"
                                              checked={el.event_topic_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.EventTopicSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayEventTopic.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Source link <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Source link"
                                              name="source_link" value={el.source_link || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkEventErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="source_link_comment"
                                              value={el.source_link_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="source_link_status"
                                              checked={el.source_link_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.SourcelinEventkSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arraySourceEvent.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Conditions </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Conditions"
                                              name="conditions" value={el.conditions || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="conditions_comment"
                                              value={el.conditions_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="conditions_status"
                                              checked={el.conditions_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.ConditionsEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayConditions.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Intervention </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Intervention"
                                              name="intervention" value={el.intervention || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="intervention_comment"
                                              value={el.intervention_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="intervention_status"
                                              checked={el.intervention_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.InterventionEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayInterventation.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date </label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.DateEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayDateEvent.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Session Type </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Session Type"
                                              name="session_type" value={el.session_type || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                                        {
                                                            (el.session_type != '') ?( <div></div>) :(this.state.sessiontypeErr )
                                                        }
                                                    </div>   */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="session_type_comment"
                                              value={el.session_type_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="session_type_status"
                                              checked={el.session_type_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.SessionTypeSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arraySessionType.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="role_id"
                                              value={el.role_id || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            >
                                              {/* <option value="" >--Select Role--</option>
                                              <option value="Chairperson" >Chairperson</option>
                                              <option value="Regular" >Regular</option>
                                              <option value="Keynote Speaker" >Keynote Speaker</option> */}
                                              <option value="" >--Select Role--</option>
                                              <option value="Chairperson" >Event Chairperson</option>
                                              <option value="Moderator/Session Chair" >Moderator/Session Chair</option>
                                              <option value="Regular" >Regular</option>
                                              <option value="Keynote Speaker" >Keynote Speaker</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.role_id != '') ? (<div></div>) : (this.state.roleEventsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comment"
                                              value={el.role_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.RoleEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayRoleEvent.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Event Sponsor </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Event Sponsor"
                                              name="event_sponsor" value={el.event_sponsor || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="event_sponsor_comment"
                                              value={el.event_sponsor_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="event_sponsor_status"
                                              checked={el.event_sponsor_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.EventSponsorSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayEventSponser.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1" >
                                              Total Event Attendees </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="total_event_attendees"
                                              value={el.total_event_attendees || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Total Event Attendees--</option>
                                              <option value="0-10" >0-10</option>
                                              <option value="10-20" >10-20</option>
                                              <option value="20-30" >20-30</option>
                                              <option value="30-40" >30-40</option>
                                              <option value="40-50" >40-50</option>
                                              <option value="50+" >50+</option>
                                              <option value="0" >Not Available</option>
                                            </Input>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="total_event_attendees_comment"
                                              value={el.total_event_attendees_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="total_event_attendees_status"
                                              checked={el.total_event_attendees_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.TotalEventAttendeesSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayTotalEventAttendies.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Country</label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="country_id"
                                              value={el.country_id || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Country--</option>
                                              {OptionsCountry}
                                            </Input>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="country_comment"
                                              value={el.country_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="country_status"
                                              checked={el.country_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                          (!this.state.CountryEventsSource) ? (
                                            <div></div>
                                          ) : (
                                            <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                              {
                                                arrayCountryEvent.flat().map((item,index) => (
                                                  <div class="tag-item" key={item}>
                                                    <a href={item} target="_blank">
                                                      <text>{index+1}.{item}</text><br/>
                                                    </a>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          )
                                        }
                                        <hr />

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="manual_weightage"
                                              value={el.manual_weightage || ''}
                                            // onChange={this.handleChangeEventsDetails.bind(this, i)} 
                                            >
                                              <option value="" >--Select Manual weightage--</option>
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.manual_weightage != '') ? (<div></div>) : (this.state.manualAdvEventDetails)
                                              }
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="manual_weightage_comment"
                                              value={el.manual_weightage_comment}
                                              onChange={this.handleChangeEventsDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="manual_weightage_status"
                                              checked={el.manual_weightage_status}
                                              onChange={this.handleChangeCheckBoxEventDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                                Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select"  id="roleSelect"  className="form-control" 
                                                    style={{height: '50px'}}
                                                    name="manual_weightage"
                                                    value={el.manual_weightage ||''}
                                                    onChange={this.handleChangeEventsDetails.bind(this, i)} 
                                                >
                                                    <option value = "" >--Select Manual weightage--</option>
                                                    <option value = "1" >1</option>
                                                    <option value = "2" >2</option>
                                                    <option value = "3" >3</option>
                                                    <option value = "4" >4</option>
                                                    <option value = "5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                {
                                                (el.manual_weightage != '') ?( <div></div>) :(this.state.manualAdvEventDetails )
                                                }
                                            </div>
                                            <hr/> */}
                                        {/* Add More and remove */}

                                        {/* {this.state.EventsDetails.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickEventsDetails.bind(this)}>+ Add More Event Details</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitEventsDetails.bind(this)}/> */}
                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Grants form */}
                        {
                          (this.state.GrantsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Grants 1</h3>



                              {/* <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Grants } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                Grants[i].company_status = e.target.checked;
                                                Grants[i].title_status = e.target.checked;                                            
                                                Grants[i].year_status = e.target.checked;
                                                Grants[i].to_year_status = e.target.checked;
                                                Grants[i].amount_status = e.target.checked;
                                                Grants[i].co_investigator_status = e.target.checked;
                                              this.setState({ Grants });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}




{/* 
                              <div class='row' style={{marginLeft:'15px'}}>     
                              <div class="col-md-5_not" style={{background:'transparent',  float:'right',  marginLeft:'91.5%' ,marginTop:'-40px' }}>                                   
                              <div class="form-group" >                                
                                        <div ><b>Check All</b>
                                          <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Grants } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                Grants[i].company_status = e.target.checked;
                                                Grants[i].title_status = e.target.checked;                                            
                                                Grants[i].year_status = e.target.checked;
                                                Grants[i].to_year_status = e.target.checked;
                                                Grants[i].amount_status = e.target.checked;
                                                Grants[i].co_investigator_status = e.target.checked;
                                              this.setState({ Grants });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                          </div>
                                    </div>
                                    </div>
                                  </div>  */}
                       



                              <form>
                                {
                                  this.state.Grants.map((el, i) => {
                                    var arrayCompanyGrants = [];
                                    var arrayTitleGrants = [];
                                    var arrayYearGrants = [];
                                    var arrayToYearGrants = [];
                                    var arrayAmountGrants = [];
                                    var arrayCoInvestigator = [];

                                    if (el.company_links !== '') {
                                      arrayCompanyGrants = el.company_links.split(',')
                                      arrayCompanyGrants = arrayCompanyGrants.splice(1)
                                    }
                                    if (el.title_links !== '') {
                                      arrayTitleGrants = el.title_links.split(',')
                                      arrayTitleGrants = arrayTitleGrants.splice(1)
                                    }
                                    if (el.year_links !== '') {
                                      arrayYearGrants = el.year_links.split(',')
                                      arrayYearGrants = arrayYearGrants.splice(1)
                                    }
                                    if (el.to_year_links !== '') {
                                      arrayToYearGrants = el.to_year_links.split(',')
                                      arrayToYearGrants = arrayToYearGrants.splice(1)
                                    }
                                    if (el.amount_links !== '') {
                                      arrayAmountGrants = el.amount_links.split(',')
                                      arrayAmountGrants = arrayAmountGrants.splice(1)
                                    }
                                    if (el.co_investigator_links !== '') {
                                      arrayCoInvestigator = el.co_investigator_links.split(',')
                                      arrayCoInvestigator = arrayCoInvestigator.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Grants {i + 1}</h3>
                                              {/* {this.state.Grants.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickGrants.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Company/Organization <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Company/Organization"
                                              name="company" value={el.company || ''}
                                            // onChange={this.handleChangeGrants.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.company != '') ? (<div></div>) : (this.state.companyErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="company_comment"
                                              value={el.company_comment}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="company_status"
                                              checked={el.company_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CompanySource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCompanyGrants.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Title 
                                              {/* <span class="mandatory">*</span> */}
                                            </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Title"
                                              name="title" value={el.title || ''}
                                            // onChange={this.handleChangeGrants.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.title != '') ? (<div></div>) : (this.state.titleGrantsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="title_comment"
                                              value={el.title_comment}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="title_status"
                                              checked={el.title_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TitleGrantsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTitleGrants.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              From Year 
                                              {/* <span class="mandatory">*</span> */}
                                            </label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Year" 
                                                        name="year" value={el.year ||''}
                                                        // onChange={this.handleChangeGrants.bind(this, i)}   
                                                    /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearGrantsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.YearGrantsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearGrants.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              To Year 
                                            </label>
                                            <text class="form-control disabled" >{el.to_year || ''}</text>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="to_year_comments"
                                              value={el.to_year_comments}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="to_year_status"
                                              checked={el.to_year_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.ToYearGrantsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayToYearGrants.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Amount </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Amount"
                                              name="amount" value={el.amount || ''}
                                            // onChange={this.handleChangeGrants.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="amount_comment"
                                              value={el.amount_comment}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="amount_status"
                                              checked={el.amount_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.AmountGrantsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayAmountGrants.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Co-Investigator </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Co-Investigator"
                                              name="co_investigator" value={el.co_investigator || ''}
                                            // onChange={this.handleChangeGrants.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="co_investigator_comment"
                                              value={el.co_investigator_comment}
                                              onChange={this.handleChangeGrants.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="co_investigator_status"
                                              checked={el.co_investigator_status}
                                              onChange={this.handleChangeCheckBoxGrants.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CoInvestigatorSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCoInvestigator.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />
                                        {/* Add More and remove */}
                                        {/* {this.state.Grants.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickGrants.bind(this)}>+ Add More Grants</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitGrants.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Guidlines form */}
                        {
                          (this.state.GuidelinesFlag == true) ? (
                            <section style={{ marginBottom: '10%' }}>
                              <h3 class="mt-30">Guidelines 1</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Guidelines } = this.state;
                                              this.state.Guidelines.map((el,i) => {                                             
                                                Guidelines[i].year_status = e.target.checked;
                                                Guidelines[i].organization_status = e.target.checked;                                            
                                                Guidelines[i].type_of_organization_status = e.target.checked;
                                                Guidelines[i].title_status = e.target.checked;
                                                Guidelines[i].role_status = e.target.checked;
                                                this.setState({ Guidelines });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}
                              <form>
                                {
                                  this.state.Guidelines.map((el, i) => {
                                    // var arraySpecialityGuidelines = [];
                                    // var arraysunSpecialityGuidelines = [];
                                    // var arrayProcedureGuidelines = [];
                                    var arrayYearGuidelines = [];
                                    var arrayOrganizatoinGuidelines = [];
                                    var arrayTypeofOrganizationGuidlines = [];
                                    var arrayTitleGuidelines = [];
                                    var arrayRoleGuideline = [];
                                    var arrayGuidelinesaffiliationtypeGuideline = [];

                                    // if (el.speciality_links !== '') {
                                    //   arraySpecialityGuidelines = el.speciality_links.split(',')
                                    //   arraySpecialityGuidelines = arraySpecialityGuidelines.splice(1)
                                    // }
                                    // if (el.sub_speciality_links !== '') {
                                    //   arraysunSpecialityGuidelines = el.sub_speciality_links.split(',')
                                    //   arraysunSpecialityGuidelines = arraysunSpecialityGuidelines.splice(1)
                                    // }
                                    // if (el.procedure_type_links !== '') {
                                    //   arrayProcedureGuidelines = el.procedure_type_links.split(',')
                                    //   arrayProcedureGuidelines = arrayProcedureGuidelines.splice(1)
                                    // }
                                    if (el.year_links !== '') {
                                      arrayYearGuidelines = el.year_links.split(',')
                                      arrayYearGuidelines = arrayYearGuidelines.splice(1)
                                    }
                                    if (el.organization_links !== '') {
                                      arrayOrganizatoinGuidelines = el.organization_links.split(',')
                                      arrayOrganizatoinGuidelines = arrayOrganizatoinGuidelines.splice(1)
                                    }
                                    if (el.type_of_organization_links !== '') {
                                      arrayTypeofOrganizationGuidlines = el.type_of_organization_links.split(',')
                                      arrayTypeofOrganizationGuidlines = arrayTypeofOrganizationGuidlines.splice(1)
                                    }
                                    if (el.title_links !== '') {
                                      arrayTitleGuidelines = el.title_links.split(',')
                                      arrayTitleGuidelines = arrayTitleGuidelines.splice(1)
                                    }
                                    if (el.role_links !== '') {
                                      arrayRoleGuideline = el.role_links.split(',')
                                      arrayRoleGuideline = arrayRoleGuideline.splice(1)
                                    }
                                    if (el.Guidelinesaffiliationtype_links !== '') {
                                      arrayGuidelinesaffiliationtypeGuideline = el.Guidelinesaffiliationtype_links.split(',')
                                      arrayGuidelinesaffiliationtypeGuideline = arrayGuidelinesaffiliationtypeGuideline.splice(1)
                                    }


                                    
                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Guidelines {i + 1}</h3>
                                              {/* {this.state.Guidelines.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickGuidelines.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_id"
                                              value={el.speciality_id || ''}
                                            >
                                              <option value="" >--Select Speciality--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityGuidelinesErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialityGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialityGuidelines.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        {/* Sub Specilality dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownGuidelines == false ) ? (
                                                <div></div>
                                                ) : ( */}
                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydataGuidelines}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='sub_speciality_type'
                                                textField='sub_speciality_type'
                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityGuidelinesErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.SubSpecialityGuidlinesSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arraysunSpecialityGuidelines.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_speciality_comment"
                                                value={el.sub_speciality_comment}
                                                onChange={this.handleChangeGuidelines.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_speciality_status"
                                                checked={el.sub_speciality_status}
                                                onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}
                                        {/* )
                                            } */}

                                        {/* Procedure Dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownGuidelines == false ) ? (
                                                <div></div>
                                                ) : ( */}
                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Procedure Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureDataForGuidelines}
                                                placeholder="--Select Procedure Type--"
                                                valueField='procedure_type'
                                                textField='procedure_type'
                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.procedure_type != '') ? (<div></div>) : (this.state.procedureGuidelinesErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.ProcedureGuidlinesSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arrayProcedureGuidelines.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={el.procedure_type_comment}
                                                onChange={this.handleChangeGuidelines.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={el.procedure_type_status}
                                                onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}
                                        {/* )
                                            } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Year <span class="mandatory">*</span></label>
                                            <text class="form-control disabled" >{el.year || ''}</text>
                                            {/* <input type="date" class="form-control disabled" 
                                                        autofocus="true"
                                                        id="input7" placeholder="Enter Year" 
                                                        name="year" value={el.year ||''}
                                                        // onChange={this.handleChangeGuidelines.bind(this, i)}   
                                                    /> */}
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.year != '') ? (<div></div>) : (this.state.yearGuidelinesErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="year_comment"
                                              value={el.year_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="year_status"
                                              checked={el.year_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.YearGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayYearGuidelines.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Organization <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter organization"
                                              name="organization" value={el.organization || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.organization != '') ? (<div></div>) : (this.state.companyGuidelinesErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="organization_comment"
                                              value={el.organization_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="organization_status"
                                              checked={el.organization_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.OrganizationGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayOrganizatoinGuidelines.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type Of Organization<span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="type_of_organization"
                                              value={el.type_of_organization || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)}
                                            >
                                              <option value="" >--Select Role--</option>
                                              <option value="Global" >Global</option>
                                              <option value="Regional" >Regional</option>
                                              <option value="National" >National</option>
                                              <option value="Local" >Local</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.type_of_organization != '') ? (<div></div>) : (this.state.typeOfCompanyGuidelinesErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_of_organization_comment"
                                              value={el.type_of_organization_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_of_organization_status"
                                              checked={el.type_of_organization_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TypeOfOrganizationGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypeofOrganizationGuidlines.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Title <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Title"
                                              name="title" value={el.title || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.title != '') ? (<div></div>) : (this.state.titleGuidelinesErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="title_comment"
                                              value={el.title_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="title_status"
                                              checked={el.title_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TitleGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTitleGuidelines.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        {/* <div style={{marginBottom:'20%'}}></div> */}
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Role"
                                              name="role" value={el.role || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)}   
                                            />
                                             {/* <Input type="select" id="roleSelect" className="form-control"
                                              style={{ height: '50px' }}
                                              name="role"
                                              value={el.role || ''}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            >
                                              <option value="" >--Select Role--</option>
                                              <option value="Author" >Author</option>
                                              <option value="Committee Chair" >Committee Chair</option>
                                              <option value="Committee Member" >Committee Member</option>
                                            </Input> */}
                                            {/* <div className="loginErrCreateProject">
                                              {
                                                (el.role != '') ? (<div></div>) : (this.state.titleGuidelinesErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comments"
                                              value={el.role_comments}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {/* <div style={{marginBottom:'20%'}}></div> */}
                                          {
                                              (!this.state.RoleGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayRoleGuideline.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />




                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                            Affiliation Type <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Affiliation Type"
                                              name="Guidelinesaffiliationtype" value={el.Guidelinesaffiliationtype || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="Guidelinesaffiliationtype_comment"
                                              value={el.Guidelinesaffiliationtype_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="Guidelinesaffiliationtype_status"
                                              checked={el.Guidelinesaffiliationtype_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {/* <div style={{marginBottom:'20%'}}></div> */}
                                          {
                                              (!this.state.GuidelinesaffiliationtypeGuidlinesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayGuidelinesaffiliationtypeGuideline.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />





                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="manual_weightage"
                                              value={el.manual_weightage || ''}
                                            // onChange={this.handleChangeGuidelines.bind(this, i)} 
                                            >
                                              <option value="" >--Select Manual weightage--</option>
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.manual_weightage != '') ? (<div></div>) : (this.state.manualGuidelines)
                                              }
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="manual_weightage_comment"
                                              value={el.manual_weightage_comment}
                                              onChange={this.handleChangeGuidelines.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="manual_weightage_status"
                                              checked={el.manual_weightage_status}
                                              onChange={this.handleChangeCheckBoxGuidelines.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}
                                        <div style={{marginBottom:'20%'}}></div>
                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                            Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select"  id="roleSelect"  className="form-control" 
                                                    style={{height: '50px'}}
                                                    name="manual_weightage"
                                                    value={el.manual_weightage ||''}
                                                    onChange={this.handleChangeGuidelines.bind(this, i)} 
                                                >
                                                    <option value = "" >--Select Manual weightage--</option>
                                                    <option value = "1" >1</option>
                                                    <option value = "2" >2</option>
                                                    <option value = "3" >3</option>
                                                    <option value = "4" >4</option>
                                                    <option value = "5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                {
                                                (el.manual_weightage != '') ?( <div></div>) :(this.state.manualGuidelines )
                                                }
                                            </div>
                                            <hr/> */}
                                        {/* Add More and remove */}
                                        {/* {this.state.Guidelines.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickGuidelines.bind(this)}>+ Add More Guidelines</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitGuidelines.bind(this)}/> */}
                                      </div>
                                    )
                                  })

                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Hospital Affiliations form */}
                        {
                          (this.state.HospitalAffiliationsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Hospital Affiliations 1</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { HospitalAffiliations } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                HospitalAffiliations[i].hospital_status = e.target.checked;
                                                HospitalAffiliations[i].role_status = e.target.checked;                                            
                                                HospitalAffiliations[i].from_year_status = e.target.checked;
                                                HospitalAffiliations[i].to_year_status = e.target.checked;
                                                HospitalAffiliations[i].country_status = e.target.checked;

                                                HospitalAffiliations[i].state_status = e.target.checked;
                                                HospitalAffiliations[i].city_status = e.target.checked;
                                                HospitalAffiliations[i].department_status = e.target.checked;
                                                
                                                this.setState({ HospitalAffiliations });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.HospitalAffiliations.map((el, i) => {
                                    var arrayHospital = [];
                                    var arryRoleHospital = [];
                                    var arrayDepartmentHospital = [];
                                    var arryFromYearHospital = [];
                                    var arryToYearHospital = [];
                                    var arrayCityHospital = [];
                                    var arrayStateHospital = [];
                                    var arrayCountryHospital = [];

                                    if (el.hospital_links !== '') {
                                      arrayHospital = el.hospital_links.split(',')
                                      arrayHospital = arrayHospital.splice(1)
                                    }
                                    if (el.role_links !== '') {
                                      arryRoleHospital = el.role_links.split(',')
                                      arryRoleHospital = arryRoleHospital.splice(1)
                                    }
                                    if (el.department_links !== '') {
                                      arrayDepartmentHospital = el.department_links.split(',')
                                      arrayDepartmentHospital = arrayDepartmentHospital.splice(1)
                                    }
                                    if (el.from_year_links !== '') {
                                      arryFromYearHospital = el.from_year_links.split(',')
                                      arryFromYearHospital = arryFromYearHospital.splice(1)
                                    }
                                    if (el.to_year_links !== '') {
                                      arryToYearHospital = el.to_year_links.split(',')
                                      arryToYearHospital = arryToYearHospital.splice(1)
                                    }
                                    if (el.city_links !== '') {
                                      arrayCityHospital = el.city_links.split(',')
                                      arrayCityHospital = arrayCityHospital.splice(1)
                                    }
                                    if (el.state_links !== '') {
                                      arrayStateHospital = el.state_links.split(',')
                                      arrayStateHospital = arrayStateHospital.splice(1)
                                    }
                                    if (el.country_links !== '') {
                                      arrayCountryHospital = el.country_links.split(',')
                                      arrayCountryHospital = arrayCountryHospital.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Hospital Affiliations {i + 1}</h3>
                                              {/* {this.state.HospitalAffiliations.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickHospitalAffiliations.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Hospitals <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Hospitals"
                                              name="hospital" value={el.hospital || ''}
                                            // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.hospital != '') ? (<div></div>) : (this.state.hospitalErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="hospital_comment"
                                              value={el.hospital_comment}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="hospital_status"
                                              checked={el.hospital_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.HospitalSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Role <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Role"
                                              name="role" value={el.role || ''}
                                            // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject">
                                              {
                                                (el.role != '') ? (<div></div>) : (this.state.hospitalErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="role_comments"
                                              value={el.role_comments}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="role_status"
                                              checked={el.role_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.HospitalRoleSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arryRoleHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              From Year 
                                                {/* <span class="mandatory">*</span> */}
                                              </label>
                                              <text class="form-control disabled" >{el.from_year || ''}</text>
                                            {/* <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Role"
                                              name="role" value={el.role || ''}
                                             // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}   
                                            /> */}
                                            {/* <div className="loginErrCreateProject">
                                              {
                                                (el.role != '') ? (<div></div>) : (this.state.hospitalErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="from_year_comments"
                                              value={el.from_year_comments}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="from_year_status"
                                              checked={el.from_year_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                         {
                                              (!this.state.HospitalFromYearSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arryFromYearHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              To Year 
                                                {/* <span class="mandatory">*</span> */}
                                              </label>
                                              <text class="form-control disabled" >{el.to_year || ''}</text>
                                            {/* <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Role"
                                              name="role" value={el.role || ''}
                                             // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}   
                                            /> */}
                                            {/* <div className="loginErrCreateProject">
                                              {
                                                (el.role != '') ? (<div></div>) : (this.state.hospitalErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="to_year_comments"
                                              value={el.to_year_comments}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="to_year_status"
                                              checked={el.to_year_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.HospitalToYearSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}> 
                                                  {
                                                    arryToYearHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Country <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="country_id"
                                              value={el.country_id || ''}
                                            // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            >
                                              <option value="" >--Select Country--</option>
                                              {OptionsCountry}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.country_id != '') ? (<div></div>) : (this.state.countryHospitalErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="country_comment"
                                              value={el.country_comment}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="country_status"
                                              checked={el.country_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CountryHospitalAffSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCountryHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              State</label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="state_id"
                                              value={el.state_id || ''}
                                            // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            >
                                              <option value="" >--Select State--</option>
                                              {OptionStateHospitalAff}
                                            </Input>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="state_comment"
                                              value={el.state_comment}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="state_status"
                                              checked={el.state_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.StateHospitalAffSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayStateHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              City</label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter City"
                                              name="city_id" value={el.city_id || ''}
                                            />
                                            {/* <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="city_id"
                                              value={el.city_id || ''}
                                            // onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            >
                                              <option value="" >--Select City--</option>
                                              {OptionCityHospitalAff}
                                            </Input> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="city_comment"
                                              value={el.city_comment}
                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="city_status"
                                              checked={el.city_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.CityHospitalAffSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCityHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Department </label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Department"
                                              name="department" value={el.department || ''}
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="department_comment"
                                              value={el.department_comment}
                                             //value="Jyoti"

                                              onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="department_status"
                                              checked={el.department_status}
                                              onChange={this.handleChangeCheckBoxHospitalAffiliations.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DepartmentHospitalSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDepartmentHospital.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* Add More and remove */}
                                        {/* {this.state.HospitalAffiliations.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickHospitalAffiliations.bind(this)}>+ Add More Hospital Affiliations</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitHospitalAffiliations.bind(this)}/> */}

                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Interactions form */}
                        {
                          (this.state.InteractionsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Interactions 1</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Interactions } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                Interactions[i].date_status = e.target.checked;
                                                Interactions[i].time_status = e.target.checked;                                            
                                                Interactions[i].timezone_status = e.target.checked;
                                                Interactions[i].incentive_mode_status = e.target.checked;
                                                
                                                this.setState({ Interactions });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.Interactions.map((el, i) => {
                                    var arrayDateInteractions = [];
                                    var arrayOpinionLeader = [];
                                    var arrayEnteredBy = [];
                                    var arrayProduct = [];
                                    var arrayTypeInteractions = [];
                                    var arrayMode = [];
                                    var arrayTime = [];
                                    var arrayActivityTool = [];
                                    var arrayCommentsInteractions = [];

                                    if (el.date_links !== '') {
                                      arrayDateInteractions = el.date_links.split(',')
                                      arrayDateInteractions = arrayDateInteractions.splice(1)
                                    }
                                    // if(el.opinion_leader_links !== '')
                                    // {
                                    //     arrayOpinionLeader = el.opinion_leader_links.split(',')
                                    //     arrayOpinionLeader = arrayOpinionLeader.splice(1)
                                    // } 
                                    // if(el.entered_by_links !== '')
                                    // {
                                    //     arrayEnteredBy = el.entered_by_links.split(',')
                                    //     arrayEnteredBy = arrayEnteredBy.splice(1)
                                    // } 
                                    // if(el.product_links !== '')
                                    // {
                                    //     arrayProduct = el.product_links.split(',')
                                    //     arrayProduct = arrayProduct.splice(1)
                                    // } 
                                    // if(el.type_links !== '')
                                    // {
                                    //     arrayTypeInteractions = el.type_links.split(',')
                                    //     arrayTypeInteractions = arrayTypeInteractions.splice(1)
                                    // } 
                                    // if(el.mode_links !== '')
                                    // {
                                    //     arrayMode = el.mode_links.split(',')
                                    //     arrayMode = arrayMode.splice(1)
                                    // } 
                                    if (el.time_links !== '') {
                                      arrayTime = el.time_links.split(',')
                                      arrayTime = arrayTime.splice(1)
                                    }
                                    // if(el.activity_or_tool_used_links !== '')
                                    // {
                                    //     arrayActivityTool = el.activity_or_tool_used_links.split(',')
                                    //     arrayActivityTool = arrayActivityTool.splice(1)
                                    // } 
                                    // if(el.comments_links !== '')
                                    // {
                                    //     arrayCommentsInteractions = el.comments_links.split(',')
                                    //     arrayCommentsInteractions = arrayCommentsInteractions.splice(1)
                                    // } 

                                    return (
                                      <div key={i}>
                                        {/* {
                                                ( i == 0 )?(
                                                    <div></div>
                                                ):(
                                                    <div>
                                                        <h3 class="mt-30">Interactions {i+1}</h3>
                                                        {/* {this.state.Interactions.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickInteractions.bind(this, i)}>x</a>
                                                        } */}
                                        {/* </div>
                                                )
                                            } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangeInteractions.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.date != '') ? (<div></div>) : (this.state.dateInteractionErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangeInteractions.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DateInteractionsSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDateInteractions.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Time <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Time"
                                              name="time" value={el.time || ''}
                                            // onChange={this.handleChangeInteractions.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.time != '') ? (<div></div>) : (this.state.timeInteractionErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="time_comment"
                                              value={el.time_comment}
                                              onChange={this.handleChangeInteractions.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="time_status"
                                              checked={el.time_status}
                                              onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TimeInteractionSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTime.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Time Zone <span class="mandatory">*</span></label>
                                            <Input type="select" id="timezone" className="form-control"
                                              style={{ height: '50px' }}
                                              name="timezone"
                                              value={el.timezone || ''}
                                            // onChange={this.handleChangeInteractions.bind(this, i)}
                                            >
                                              <option value="" >--Select Time Zone--</option>
                                              <option value="" >Greenwich Mean Time</option>
                                              <option value="" >Universal Coordinated Time</option>
                                              <option value="+01:00" >European Central Time</option>
                                              <option value="+02:00" >Eastern European Time</option>
                                              <option value="+02:00" >(Arabic) Egypt Standard Time</option>
                                              <option value="+03:00" >Eastern African Time</option>
                                              <option value="+03:30" >Middle East Time</option>
                                              <option value="+04:00" >Near East Time</option>
                                              <option value="+05:00" >Pakistan Lahore Time</option>
                                              <option value="+05:30" >India Standard Time</option>
                                              <option value="+06:00" >Bangladesh Standard Time</option>
                                              <option value="+07:00" >Vietnam Standard Time</option>
                                              <option value="+08:00" >China Taiwan Time</option>
                                              <option value="+09:00" >Japan Standard Time</option>
                                              <option value="+09:30" >Australia Central Time</option>
                                              <option value="+10:00" >Australia Eastern Time</option>
                                              <option value="+11:00" >Solomon Standard Time</option>
                                              <option value="+12:00" >New Zealand Standard Time</option>
                                              <option value="-11:00" >Midway Islands Time</option>
                                              <option value="-10:00" >Hawaii Standard Time</option>
                                              <option value="-09:00" >Alaska Standard Time</option>
                                              <option value="-08:00" >Pacific Standard Time</option>
                                              <option value="-07:00" >Phoenix Standard Time</option>
                                              <option value="-07:00" >Mountain Standard Time</option>
                                              <option value="-06:00" >Central Standard Time</option>
                                              <option value="-05:00" >Eastern Standard Time</option>
                                              <option value="-05:00" >Indiana Eastern Standard Time</option>
                                              <option value="-04:00" >Puerto Rico and US Virgin Islands Time</option>
                                              <option value="-03:30" >Canada Newfoundland Time</option>
                                              <option value="-03:00" >Argentina Standard Time</option>
                                              <option value="-03:00" >Brazil Eastern Time</option>
                                              <option value="-01:00" >Central African Time</option>
                                            </Input>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="timezone_comment"
                                              value={el.timezone_comment}
                                              onChange={this.handleChangeInteractions.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="timezone_status"
                                              checked={el.timezone_status}
                                              onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                              Incentive Mode <span class="mandatory">*</span></label>
                                            <Input type="select" id="incentive_mode" className="form-control"
                                              style={{ height: '50px' }}
                                              name="incentive_mode"
                                              value={el.incentive_mode || ''}
                                            // onChange={this.handleChangeInteractions.bind(this, i)}
                                            >
                                              <option value="" >--Select Incentive Mode--</option>
                                              <option value="Voucher" >Voucher</option>
                                              <option value="Bank Transfer" >Bank Transfer</option>
                                              <option value="Cheque" >Cheque</option>
                                            </Input>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="incentive_mode_comment"
                                              value={el.incentive_mode_comment}
                                              onChange={this.handleChangeInteractions.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="incentive_mode_status"
                                              checked={el.incentive_mode_status}
                                              onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr />

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Opinion Leader <span class="mandatory">*</span></label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Opinion Leader" 
                                                        name="opinion_leader" value={el.opinion_leader ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}   
                                                    />
                                                    <div className="loginErrCreateProject" >
                                                        {
                                                            (el.opinion_leader != '') ?( <div></div>) :(this.state.opinionleaderErr )
                                                        }
                                                    </div>  
                                                    {
                                                        ( !this.state.OpinionLeaderSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayOpinionLeader.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="opinion_leader_comment" 
                                                        value={el.opinion_leader_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="opinion_leader_status" 
                                                        checked={el.opinion_leader_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Entered By<span class="mandatory">*</span></label>
                                                    <Input type="select"  id="roleSelect"  className="form-control disabled" 
                                                        style={{height: '50px'}}
                                                        name="entered_by"
                                                        value={el.entered_by ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}
                                                    >
                                                        <option value = "" >--Select Employee--</option>
                                                        {OptionsEmployee}
                                                    </Input>
                                                    <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                        {
                                                            (el.entered_by != '') ?( <div></div>) :(this.state.enteredbyErr )
                                                        }
                                                    </div>  
                                                    {
                                                        ( !this.state.EnteredBySource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                    {
                                                        arrayEnteredBy.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }  
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="entered_by_comment" 
                                                        value={el.entered_by_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="entered_by_status" 
                                                        checked={el.entered_by_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Product/Topic<span class="mandatory">*</span></label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Product/Topic" 
                                                        name="product" value={el.product ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}   
                                                    />
                                                    <div className="loginErrCreateProject" >
                                                        {
                                                            (el.product != '') ?( <div></div>) :(this.state.producttopicErr )
                                                        }
                                                    </div>
                                                    {
                                                        ( !this.state.ProductTopicSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayProduct.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="product_comment" 
                                                        value={el.product_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="product_status" 
                                                        checked={el.product_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Type <span class="mandatory">*</span></label>
                                                    <Input type="select"  id="roleSelect"  className="form-control disabled" 
                                                        style={{height: '50px'}}
                                                        name="type"
                                                        value={el.type ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}
                                                    >
                                                        <option value = "" >--Select Type--</option>
                                                        <option value = "Face to Face" >Face to Face</option>
                                                        <option value = "Web Conference" >Web Conference</option>
                                                        <option value = "Tele Conference" >Tele Conference</option>
                                                        <option value = "Email" >Email</option>
                                                        
                                                    </Input>
                                                    <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                        {
                                                            (el.type != '') ?( <div></div>) :(this.state.typeInteractionErr )
                                                        }
                                                    </div>
                                                    {
                                                        ( !this.state.TypeInteractionSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayTypeInteractions.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="type_comment" 
                                                        value={el.type_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="type_status" 
                                                        checked={el.type_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Mode </label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Mode" 
                                                        name="mode" value={el.mode ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}   
                                                    />
                                                    {/* <div className="loginErrCreateProject" >
                                                        {
                                                            (el.mode != '') ?( <div></div>) :(this.state.modeInteractionErr )
                                                        }
                                                    </div>  */}
                                        {/* {
                                                        ( !this.state.ModeInteractionSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayMode.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                     <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="mode_comment" 
                                                        value={el.mode_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="mode_status" 
                                                        checked={el.mode_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}



                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Activity/Tool Used </label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Activity/Tool Used" 
                                                        name="activity_or_tool_used" value={el.activity_or_tool_used ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}   
                                                    />  
                                                    { */}
                                        {/* ( !this.state.ActivityToolUsedSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayActivityTool.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }    
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="activity_or_tool_used_comment" 
                                                        value={el.activity_or_tool_used_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="activity_or_tool_used_status" 
                                                        checked={el.activity_or_tool_used_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* <div class="row">
                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comments/Keywords </label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Comments/Keywords" 
                                                        name="comments" value={el.comments ||''}
                                                        // onChange={this.handleChangeInteractions.bind(this, i)}   
                                                    />  
                                                    {
                                                        ( !this.state.CommentsKeywordsSource) ? (
                                                            <div></div>
                                                        ) : (
                                                        <div>
                                                        {
                                                        arrayCommentsInteractions.flat().map((item) => (
                                                            <div class="tag-item" key={item}>
                                                                <a href={item} target = "_blank">
                                                                    <text>{item}</text>
                                                                </a>
                                                            </div>
                                                        ))
                                                        }
                                                        </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="comments_comment" 
                                                        value={el.comments_comment} 
                                                        onChange={this.handleChangeInteractions.bind(this, i)} 
                                                    /> 
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        // id="input3"
                                                        name="comments_status" 
                                                        checked={el.comments_status}
                                                        onChange={this.handleChangeCheckBoxInteractions.bind(this, i)}
                                                    /> 
                                                </div>
                                            </div>
                                            <hr/> */}

                                        {/* Add More and remove */}
                                        {/* {this.state.Interactions.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickInteractions.bind(this)}>+ Add More Interactions</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitInteractions.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Press Item form */}
                        {
                          (this.state.PressItemFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Press Item 1</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { PressItem } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                PressItem[i].title_status = e.target.checked;
                                                PressItem[i].quote_status = e.target.checked;                                            
                                                PressItem[i].source_link_status = e.target.checked;
                                                PressItem[i].date_status = e.target.checked;
                                                
                                                this.setState({ PressItem });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}

                              <form>
                                {
                                  this.state.PressItem.map((el, i) => {
                                    var arrayTitlePress = [];
                                    var arrayQuotePress = [];
                                    var arraySourcePress = [];
                                    var arrayDatePress = [];

                                    if (el.title_links !== '') {
                                      arrayTitlePress = el.title_links.split(',')
                                      arrayTitlePress = arrayTitlePress.splice(1)
                                    }
                                    if (el.quote_links !== '') {
                                      arrayQuotePress = el.quote_links.split(',')
                                      arrayQuotePress = arrayQuotePress.splice(1)
                                    }
                                    if (el.source_link_links !== '') {
                                      arraySourcePress = el.source_link_links.split(',')
                                      arraySourcePress = arraySourcePress.splice(1)
                                    }
                                    if (el.date_links !== '') {
                                      arrayDatePress = el.date_links.split(',')
                                      arrayDatePress = arrayDatePress.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Press Item {i + 1}</h3>
                                              {/* {this.state.PressItem.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickPressItem.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Title <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Title"
                                              name="title" value={el.title || ''}
                                            // onChange={this.handleChangePressItem.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.title != '') ? (<div></div>) : (this.state.titlePressErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="title_comment"
                                              value={el.title_comment}
                                              onChange={this.handleChangePressItem.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="title_status"
                                              checked={el.title_status}
                                              onChange={this.handleChangeCheckBoxPressItem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TitlePressItemSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTitlePress.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Quote 
                                              {/* <span class="mandatory">*</span> */}
                                            </label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Quote"
                                              name="quote" value={el.quote || ''}
                                            // onChange={this.handleChangePressItem.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                              {
                                                (el.quote != '') ? (<div></div>) : (this.state.titlePressErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="quote_comments"
                                              value={el.quote_comments}
                                              onChange={this.handleChangePressItem.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="quote_status"
                                              checked={el.quote_status}
                                              onChange={this.handleChangeCheckBoxPressItem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.QuotePressItemSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayQuotePress.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Press Item Link <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Press Item Link"
                                              name="source_link" value={el.source_link || ''}
                                            // onChange={this.handleChangePressItem.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkPressErr)
                                              }
                                            </div>
                                           
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="source_link_comment"
                                              value={el.source_link_comment}
                                              onChange={this.handleChangePressItem.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="source_link_status"
                                              checked={el.source_link_status}
                                              onChange={this.handleChangeCheckBoxPressItem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SourceLinkPressItemSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySourcePress.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangePressItem.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.date != '') ? (<div></div>) : (this.state.datePressErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangePressItem.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxPressItem.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DatePressItemSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDatePress.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* Add More and remove */}
                                        {/* {this.state.PressItem.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickPressItem.bind(this)}>+ Add More Press Item</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitPressItem.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Presentations form */}
                        {
                          (this.state.PresentationsFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Presentations 1</h3>
{/* 
                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { Presentation } = this.state;
                                              this.state.Grants.map((el,i) => {                                             
                                                Presentation[i].title_status = e.target.checked;
                                                Presentation[i].event_name_status = e.target.checked;                                            
                                                Presentation[i].type_status = e.target.checked;
                                                Presentation[i].sponsor_status = e.target.checked;
                                                Presentation[i].location_status = e.target.checked;

                                                Presentation[i].date_status = e.target.checked;
                                                
                                                this.setState({ Presentation });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div> */}


                              <form>
                                {
                                  this.state.Presentation.map((el, i) => {
                                    var arrayTitlePresentation = [];
                                    var arrayEventNamePresentation = [];
                                    var arrayTypePresentation = [];
                                    var arraySponserPresentation = [];
                                    var arrayLocationPresentation = [];
                                    var arrayDatePresentation = [];

                                    if (el.title_links !== '') {
                                      arrayTitlePresentation = el.title_links.split(',')
                                      arrayTitlePresentation = arrayTitlePresentation.splice(1)
                                    }
                                    if (el.event_name_links !== '') {
                                      arrayEventNamePresentation = el.event_name_links.split(',')
                                      arrayEventNamePresentation = arrayEventNamePresentation.splice(1)
                                    }
                                    if (el.type_links !== '') {
                                      arrayTypePresentation = el.type_links.split(',')
                                      arrayTypePresentation = arrayTypePresentation.splice(1)
                                    }
                                    if (el.sponsor_links !== '') {
                                      arraySponserPresentation = el.sponsor_links.split(',')
                                      arraySponserPresentation = arraySponserPresentation.splice(1)
                                    }
                                    if (el.location_links !== '') {
                                      arrayLocationPresentation = el.location_links.split(',')
                                      arrayLocationPresentation = arrayLocationPresentation.splice(1)
                                    }
                                    if (el.date_links !== '') {
                                      arrayDatePresentation = el.date_links.split(',')
                                      arrayDatePresentation = arrayDatePresentation.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Presentations {i + 1}</h3>
                                              {/* {this.state.Presentation.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickPresentation.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Title <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Title"
                                              name="title" value={el.title || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.title != '') ? (<div></div>) : (this.state.titlePresentationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="title_comment"
                                              value={el.title_comment}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="title_status"
                                              checked={el.title_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TitlePresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTitlePresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Event Name <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Event Name"
                                              name="event_name" value={el.event_name || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                              {
                                                (el.event_name != '') ? (<div></div>) : (this.state.titlePresentationErr)
                                              }
                                            </div> */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="event_name_comments"
                                              value={el.event_name_comments}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="event_name_status"
                                              checked={el.event_name_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.EventPresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayEventNamePresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Type"
                                              name="type" value={el.type || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.type != '') ? (<div></div>) : (this.state.typePresentationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_comment"
                                              value={el.type_comment}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_status"
                                              checked={el.type_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TypePresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypePresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Sponsor</label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Sponsor"
                                              name="sponsor" value={el.sponsor || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                                        {
                                                            (el.sponsor != '') ?( <div></div>) :(this.state.sponsorPresentationErr )
                                                        }
                                                    </div>   */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="sponsor_comment"
                                              value={el.sponsor_comment}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="sponsor_status"
                                              checked={el.sponsor_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        {
                                              (!this.state.SponsorPresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySponserPresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Location <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Location"
                                              name="location" value={el.location || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.location != '') ? (<div></div>) : (this.state.locationPresentationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="location_comment"
                                              value={el.location_comment}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="location_status"
                                              checked={el.location_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.LocationPresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayLocationPresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date  </label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangePresentation.bind(this, i)}   
                                            />
                                            {/* <div className="loginErrCreateProject" >
                                                        {
                                                            (el.date != '') ?( <div></div>) :(this.state.datePresentationErr )
                                                        }
                                                    </div>  */}
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangePresentation.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxPresentation.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DatePresentationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDatePresentation.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* Add More and remove */}
                                        {/* {this.state.Presentation.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickPresentation.bind(this)}>+ Add More Presentations</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitPresentation.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Publication Details form */}
                        {
                          (this.state.PublicationDetailsFlag == true) ? (
                            <section style={{ marginBottom: '10%' }}>
                              <h3 class="mt-30">Publication Details </h3>

                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { PublicationDetails } = this.state;
                                              this.state.PublicationDetails.map((el,i) => {                                             
                                                PublicationDetails[i].journal_status = e.target.checked;
                                                PublicationDetails[i].type_of_journal_status = e.target.checked;                                            
                                                PublicationDetails[i].publication_title_status = e.target.checked;
                                                PublicationDetails[i].date_status = e.target.checked;
                                                PublicationDetails[i].source_link_status = e.target.checked;

                                                PublicationDetails[i].position_status = e.target.checked;
                                                PublicationDetails[i].co_author_status = e.target.checked;
                                                PublicationDetails[i].key_words_status = e.target.checked;
                                                
                                                this.setState({ PublicationDetails });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div>



                              <form>
                                {
                                  this.state.PublicationDetails.map((el, i) => {
                                    // var arraySpecialityPublication = [];
                                    // var arraySubSpecialityPublication = [];
                                    // var arrayProcedurePublication = [];
                                    var arrayJournel = [];
                                    var arrayTypeOfJournel = [];
                                    var arrayPublicationTitle = [];
                                    var arrayDatePublication = [];
                                    var arraySourcePublication = [];
                                    var arrayPositionPublication = [];
                                    var arrayCoAuthor = [];
                                    var arrayKeyWords = [];

                                    // if (el.speciality_links !== '') {
                                    //   arraySpecialityPublication = el.speciality_links.split(',')
                                    //   arraySpecialityPublication = arraySpecialityPublication.splice(1)
                                    // }
                                    // if (el.sub_speciality_links !== '') {
                                    //   arraySubSpecialityPublication = el.sub_speciality_links.split(',')
                                    //   arraySubSpecialityPublication = arraySubSpecialityPublication.splice(1)
                                    // }
                                    // if (el.procedure_type_links !== '') {
                                    //   arrayProcedurePublication = el.procedure_type_links.split(',')
                                    //   arrayProcedurePublication = arrayProcedurePublication.splice(1)
                                    // }
                                    if (el.journal_links !== '') {
                                      arrayJournel = el.journal_links.split(',')
                                      arrayJournel = arrayJournel.splice(1)
                                    }
                                    if (el.type_of_journal_links !== '') {
                                      arrayTypeOfJournel = el.type_of_journal_links.split(',')
                                      arrayTypeOfJournel = arrayTypeOfJournel.splice(1)
                                    }
                                    if (el.publication_title_links !== '') {
                                      arrayPublicationTitle = el.publication_title_links.split(',')
                                      arrayPublicationTitle = arrayPublicationTitle.splice(1)
                                    }
                                    if (el.date_links !== '') {
                                      arrayDatePublication = el.date_links.split(',')
                                      arrayDatePublication = arrayDatePublication.splice(1)
                                    }
                                    if (el.source_link_links !== '') {
                                      arraySourcePublication = el.source_link_links.split(',')
                                      arraySourcePublication = arraySourcePublication.splice(1)
                                    }
                                    if (el.position_links !== '') {
                                      arrayPositionPublication = el.position_links.split(',')
                                      arrayPositionPublication = arrayPositionPublication.splice(1)
                                    }
                                    if (el.co_author_links !== '') {
                                      arrayCoAuthor = el.co_author_links.split(',')
                                      arrayCoAuthor = arrayCoAuthor.splice(1)
                                    }
                                    if (el.key_words_links !== '') {
                                      arrayKeyWords = el.key_words_links.split(',')
                                      arrayKeyWords = arrayKeyWords.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Publication Details {i + 1}</h3>
                                              {/* {this.state.PublicationDetails.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickPublicationDetails.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_id"
                                              value={el.speciality_id || ''}
                                            >
                                              <option value="" >--Select Speciality--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_id != '') ? (<div></div>) : (this.state.specialityPublicationErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialityPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialityPublication.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_comment"
                                              value={el.speciality_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_status"
                                              checked={el.speciality_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                        <hr /> */}

                                        {/* Sub Specilality dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownPublicationDetails == false ) ? (
                                                <div></div>
                                                ) : ( */}
                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.SubSpecialitydataPublicationDetails}
                                                placeholder="--Select Sub Speciality--"
                                                valueField='sub_speciality_type'
                                                textField='sub_speciality_type'
                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityPublicationErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.SubSpecialityPublicationSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arraySubSpecialityPublication.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="sub_speciality_comment"
                                                value={el.sub_speciality_comment}
                                                onChange={this.handleChangePublicationDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="sub_speciality_status"
                                                checked={el.sub_speciality_status}
                                                onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}
                                        {/* )
                                            } */}

                                        {/* Procedure Dropdown starts here */}
                                        {/* {
                                                ( this.state.showProcedureSubSpecialityDropdownPublicationDetails == false ) ? (
                                                <div></div>
                                                ) : ( */}
                                        {/* <div>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <label for="exampleFormControlTextarea1">
                                                Procedure Type <span class="mandatory">*</span></label>
                                              <Multiselect
                                                disabled
                                                data={this.state.ProcedureDataForPublicationDetails}
                                                placeholder="--Select Procedure Type--"
                                                valueField='procedure_type'
                                                textField='procedure_type'
                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                              />
                                              <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                {
                                                  (el.procedure_type != '') ? (<div></div>) : (this.state.procedurePublicationErr)
                                                }
                                              </div>
                                              {
                                                (!this.state.ProcedurePublicationSource) ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    {
                                                      arrayProcedurePublication.flat().map((item) => (
                                                        <div class="tag-item" key={item}>
                                                          <a href={item} target="_blank">
                                                            <text>{item}</text>
                                                          </a>
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>

                                            <div class="col-md-6">
                                              <label for="exampleFormControlTextarea1">
                                                Comment</label>
                                              <input type="text" class="form-control-addsource-Personal"
                                                id="input3" placeholder="Enter Comment"
                                                name="procedure_type_comment"
                                                value={el.procedure_type_comment}
                                                onChange={this.handleChangePublicationDetails.bind(this, i)}
                                              />
                                            </div>

                                            <div class="col-md-1">
                                              <label for="exampleFormControlTextarea1">
                                                Check</label>
                                              <input type='checkbox'
                                                class="form-control-addsource-Personal-ckeckbox"
                                                // id="input3"
                                                name="procedure_type_status"
                                                checked={el.procedure_type_status}
                                                onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                        </div> */}
                                        {/* )
                                            } */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Journal <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Journal"
                                              name="journal" value={el.journal || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.journal != '') ? (<div></div>) : (this.state.journalErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="journal_comment"
                                              value={el.journal_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="journal_status"
                                              checked={el.journal_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.JournalSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayJournel.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type of Journal/Publication <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="type_of_journal"
                                              value={el.type_of_journal || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Type of Journal/Publication--</option>
                                              <option value="Global" >Global</option>
                                              <option value="National" >National</option>
                                              <option value="Regional" >Regional</option>
                                              <option value="Local" >Local</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.type_of_journal != '') ? (<div></div>) : (this.state.typeOfJournelErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_of_journal_comment"
                                              value={el.type_of_journal_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_of_journal_status"
                                              checked={el.type_of_journal_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TypeOfJournelPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypeOfJournel.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Publication Title <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Publication Title"
                                              name="publication_title" value={el.publication_title || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.publication_title != '') ? (<div></div>) : (this.state.publicationtitleErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="publication_title_comment"
                                              value={el.publication_title_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="publication_title_status"
                                              checked={el.publication_title_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                         {
                                              (!this.state.PublicationTitleSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPublicationTitle.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control disabled"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.date != '') ? (<div></div>) : (this.state.datePublicationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DatePublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDatePublication.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Source Link <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Source Link"
                                              name="source_link" value={el.source_link || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkPublicationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="source_link_comment"
                                              value={el.source_link_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="source_link_status"
                                              checked={el.source_link_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.SourceLinkPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arraySourcePublication.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Position <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="position"
                                              value={el.position || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            >
                                              <option value="" >--Select Position--</option>
                                              <option value="First Author" >First Author</option>
                                              <option value="Second Author" >Second Author</option>
                                              <option value="Last Author" >Last Author</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.position != '') ? (<div></div>) : (this.state.positionPublicationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="position_comment"
                                              value={el.position_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="position_status"
                                              checked={el.position_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.PositionPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPositionPublication.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Co-Author <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Co-Author"
                                              name="co_author" value={el.co_author || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject" >
                                              {
                                                (el.co_author != '') ? (<div></div>) : (this.state.coauthorPublicationErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="co_author_comment"
                                              value={el.co_author_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="co_author_status"
                                              checked={el.co_author_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.CoAuthorPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayCoAuthor.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Key Words <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Key Words"
                                              name="key_words" value={el.key_words || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)}   
                                            />
                                            <div className="loginErrCreateProject">
                                              {
                                                (el.key_words != '') ? (<div></div>) : (this.state.keywordsErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="key_words_comment"
                                              value={el.key_words_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="key_words_status"
                                              checked={el.key_words_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                          {
                                              (!this.state.KeyWordsPublicationSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayKeyWords.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="manual_weightage"
                                              value={el.manual_weightage || ''}
                                            // onChange={this.handleChangePublicationDetails.bind(this, i)} 
                                            >
                                              <option value="" >--Select Manual weightage--</option>
                                              <option value="1" >1</option>
                                              <option value="2" >2</option>
                                              <option value="3" >3</option>
                                              <option value="4" >4</option>
                                              <option value="5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.manual_weightage != '') ? (<div></div>) : (this.state.manualPublication)
                                              }
                                            </div>
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="manual_weightage_comment"
                                              value={el.manual_weightage_comment}
                                              onChange={this.handleChangePublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="manual_weightage_status"
                                              checked={el.manual_weightage_status}
                                              onChange={this.handleChangeCheckBoxPublicationDetails.bind(this, i)}
                                            />
                                          </div>

                                        </div>
                                        <hr /> */}
                                         <div style={{marginTop:'20%'}}></div> 
                                        {/* <div style={{marginTop:'30%'}}></div>                                          */}

                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                            Manual weightage  <span class="mandatory">*</span></label>
                                            <Input type="select"  id="roleSelect"  className="form-control" 
                                                    style={{height: '50px'}}
                                                    name="manual_weightage"
                                                    value={el.manual_weightage ||''}
                                                    onChange={this.handleChangePublicationDetails.bind(this, i)} 
                                                >
                                                    <option value = "" >--Select Manual weightage--</option>
                                                    <option value = "1" >1</option>
                                                    <option value = "2" >2</option>
                                                    <option value = "3" >3</option>
                                                    <option value = "4" >4</option>
                                                    <option value = "5" >5</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                {
                                                (el.manual_weightage != '') ?( <div></div>) :(this.state.manualPublication )
                                                }
                                            </div>
                                            <hr/> */}
                                        {/* Add More and remove */}
                                        {/* {this.state.PublicationDetails.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickPublicationDetails.bind(this)}>+ Add More Publication Details</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitPublicationDetails.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Sunshine Payment form */}
                        {
                          (this.state.SunshinePaymentFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Sunshine Payment </h3>

                              <div class="row">
                                <div class="col-md-2">
                                <input type='checkbox'
                                           class="form-control-addsource-Personal-ckeckbox"
                                            //checked={this.state.Biography.citizenship_status}
                                            onChange={(e) => {
                                              let { SunshinePayment } = this.state;
                                              this.state.SunshinePayment.map((el,i) => {                                             
                                                SunshinePayment[i].date_status = e.target.checked;
                                                SunshinePayment[i].type_status = e.target.checked;                                            
                                                SunshinePayment[i].devices_status = e.target.checked;
                                                SunshinePayment[i].location_status = e.target.checked;
                                                SunshinePayment[i].amount_status = e.target.checked;

                                                SunshinePayment[i].payment_type_status= e.target.checked;
                                                SunshinePayment[i].OL_address_status = e.target.checked;
                                                SunshinePayment[i].NPI_status = e.target.checked;
                                                
                                                this.setState({ SunshinePayment });                                             
                                            }                                              
                                            )

                                            }}
                                          />
                                </div>
                                <div class="col-md-6">
                                  <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                    Check All 
                                  </label>
                                </div>
                              </div>


                              <form>
                                {
                                  this.state.SunshinePayment.map((el, i) => {
                                    var arrayDateSunshine = [];
                                    var arrayTypeSunshine = [];
                                    var arrayDevices = [];
                                    var arrayLocationSunshine = [];
                                    var arrayAmountSunshine = [];
                                    var arrayPaymentType = [];
                                    var arrayOLAddress = [];
                                    // var arraySpecialitySunshine = [];
                                    var arrayNPILinks = [];

                                    if (el.date_links !== '') {
                                      arrayDateSunshine = el.date_links.split(',')
                                      arrayDateSunshine = arrayDateSunshine.splice(1)
                                    }
                                    if (el.type_links !== '') {
                                      arrayTypeSunshine = el.type_links.split(',')
                                      arrayTypeSunshine = arrayTypeSunshine.splice(1)
                                    }
                                    if (el.devices_links !== '') {
                                      arrayDevices = el.devices_links.split(',')
                                      arrayDevices = arrayDevices.splice(1)
                                    }
                                    if (el.location_links !== '') {
                                      arrayLocationSunshine = el.location_links.split(',')
                                      arrayLocationSunshine = arrayLocationSunshine.splice(1)
                                    }
                                    if (el.amount_links !== '') {
                                      arrayAmountSunshine = el.amount_links.split(',')
                                      arrayAmountSunshine = arrayAmountSunshine.splice(1)
                                    }
                                    if (el.payment_type_links !== '') {
                                      arrayPaymentType = el.payment_type_links.split(',')
                                      arrayPaymentType = arrayPaymentType.splice(1)
                                    }
                                    if (el.OL_address_links !== '') {
                                      arrayOLAddress = el.OL_address_links.split(',')
                                      arrayOLAddress = arrayOLAddress.splice(1)
                                    }
                                    // if (el.speciality_type_links !== '') {
                                    //   arraySpecialitySunshine = el.speciality_type_links.split(',')
                                    //   arraySpecialitySunshine = arraySpecialitySunshine.splice(1)
                                    // }
                                    if (el.NPI_links !== '') {
                                      arrayNPILinks = el.NPI_links.split(',')
                                      arrayNPILinks = arrayNPILinks.splice(1)
                                    }

                                    return (
                                      <div key={i}>
                                        {
                                          (i == 0) ? (
                                            <div></div>
                                          ) : (
                                            <div>
                                              <h3 class="mt-30">Sunshine Payment {i + 1}</h3>
                                              {/* {this.state.SunshinePayment.length !== 1 && 
                                                            <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                            onClick={this.removeClickSunshinePayment.bind(this, i)}>x</a>
                                                        } */}
                                            </div>
                                          )
                                        }

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Date </label>
                                            <input type="date" class="form-control disabled"
                                              autofocus="true"
                                              id="input7" placeholder="Enter Date"
                                              name="date" value={el.date || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="date_comment"
                                              value={el.date_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="date_status"
                                              checked={el.date_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DateSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDateSunshine.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Type</label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="type"
                                              value={el.type || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            >
                                              <option value="" >--Select Type--</option>
                                              <option value="Consulting Fees" >Consulting Fees</option>
                                              <option value="Advisory Board" >Advisory Board</option>
                                              <option value="Honararia" >Honararia</option>
                                              <option value="Gifts" >Gifts </option>
                                              <option value="Entairnment" >Entairnment</option>
                                              <option value="Food and Beverage" >Food and Beverage</option>
                                              <option value="Travel and Accomodation" >Travel and Accomodation</option>
                                              <option value="Educational Grants" >Educational Grants</option>
                                              <option value="Research Grant" >Research Grant</option>
                                              <option value="Charitable Contribution" >Charitable Contribution</option>
                                              <option value="Payment to third party" >Payment to third party</option>
                                              <option value="Royalty/Licence" >Royalty/Licence</option>
                                              <option value="Speaker/Faculty for CE" >Speaker/Faculty for CE</option>
                                              <option value="Onership/Investment Interesrt" >Onership/Investment Interesrt</option>
                                              <option value="Other Services" >Other Services</option>
                                            </Input>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="type_comment"
                                              value={el.type_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="type_status"
                                              checked={el.type_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.TypeSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayTypeSunshine.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Drug/Devices </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Drug/Devices"
                                              name="devices" value={el.devices || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="devices_comment"
                                              value={el.devices_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="devices_status"
                                              checked={el.devices_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.DrugDevicesSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayDevices.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Location </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Location"
                                              name="location" value={el.location || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="location_comment"
                                              value={el.location_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="location_status"
                                              checked={el.location_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.LocationSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayLocationSunshine.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Amount </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Amount"
                                              name="amount" value={el.amount || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="amount_comment"
                                              value={el.amount_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="amount_status"
                                              checked={el.amount_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.AmountSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayAmountSunshine.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Payment Type  <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="payment_type"
                                              value={el.payment_type || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            >
                                              <option value="" >--Select Type--</option>
                                              <option value="Cash or cash equivalent" >Cash or cash equivalent</option>
                                              <option value="Bank Transfer" >Bank Transfer</option>
                                              <option value="Debit Card" >Debit Card</option>
                                              <option value="Credit Card" >Credit Card</option>
                                              <option value="PayPal" >PayPal</option>
                                              <option value="In-kind items and services" >In-kind items and services</option>
                                              <option value="Any other ownership interest" >Any other ownership interest</option>
                                              <option value="Stock, stock option, or any other ownership interest" >Stock, stock option, or any other ownership interest</option>
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.payment_type != '') ? (<div></div>) : (this.state.paymenttypeErr)
                                              }
                                            </div>
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="payment_type_comment"
                                              value={el.payment_type_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="payment_type_status"
                                              checked={el.payment_type_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.PaymentTypeSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayPaymentType.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                            Company Name </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter Company Name"
                                              name="OL_address" value={el.OL_address || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="OL_address_comment"
                                              value={el.OL_address_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="OL_address_status"
                                              checked={el.OL_address_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.OLAddressSunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayOLAddress.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              Speciality Type  <span class="mandatory">*</span> </label>
                                            <Input type="select" id="roleSelect" className="form-control disabled"
                                              style={{ height: '50px' }}
                                              name="speciality_type_id"
                                              value={el.speciality_type_id || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            >
                                              <option value="" >--Select Type--</option>
                                              {OptionsSpeciality}
                                            </Input>
                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                              {
                                                (el.speciality_type_id != '') ? (<div></div>) : (this.state.specialitytypeSunshineErr)
                                              }
                                            </div>
                                            {
                                              (!this.state.SpecialitySunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div>
                                                  {
                                                    arraySpecialitySunshine.flat().map((item) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{item}</text>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="speciality_type_comment"
                                              value={el.speciality_type_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="speciality_type_status"
                                              checked={el.speciality_type_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                        <hr /> */}

                                        <div class="row">
                                          <div class="col-md-5">
                                            <label for="exampleFormControlTextarea1">
                                              NPI# </label>
                                            <input type="text" class="form-control disabled"
                                              id="input7" placeholder="Enter NPI"
                                              name="NPI" value={el.NPI || ''}
                                            // onChange={this.handleChangeSunshinePayment.bind(this, i)}   
                                            />
                                            
                                          </div>

                                          <div class="col-md-6">
                                            <label for="exampleFormControlTextarea1">
                                              Comment</label>
                                            <input type="text" class="form-control-addsource-Personal"
                                              id="input3" placeholder="Enter Comment"
                                              name="NPI_comment"
                                              value={el.NPI_comment}
                                              onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                            />
                                          </div>

                                          <div class="col-md-1">
                                            <label for="exampleFormControlTextarea1">
                                              Check</label>
                                            <input type='checkbox'
                                              class="form-control-addsource-Personal-ckeckbox"
                                              // id="input3"
                                              name="NPI_status"
                                              checked={el.NPI_status}
                                              onChange={this.handleChangeCheckBoxSunshinePayment.bind(this, i)}
                                            />
                                          </div>
                                        </div>
                                          {
                                              (!this.state.NPISunshineSource) ? (
                                                <div></div>
                                              ) : (
                                                <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                                  {
                                                    arrayNPILinks.flat().map((item,index) => (
                                                      <div class="tag-item" key={item}>
                                                        <a href={item} target="_blank">
                                                          <text>{index+1}.{item}</text><br/>
                                                        </a>
                                                      </div>
                                                    ))
                                                  }
                                                </div>
                                              )
                                            }
                                        <hr />

                                        {/* Add More and remove */}
                                        {/* {this.state.SunshinePayment.length - 1 === i && 
                                                <a style={{marginTop:'5%', color:'#007bff'}}
                                                onClick={this.addClickSunshinePayment.bind(this)}>+ Add More Sunshine Payment</a>
                                            } */}
                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmitSunshinePayment.bind(this)}/> */}
                                      </div>
                                    )
                                  })
                                }
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Contract Form */}
                        {
                          (this.state.ContractFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Contract</h3>
                              <form>
                                <div class="row">
                                  <div class="col-md-5">
                                    <label for="exampleFormControlTextarea1">
                                      Contract </label>
                                    <input type="text" class="form-control disabled"
                                      id="input7" placeholder="Enter Contract "
                                      name="contract"
                                      value={this.state.PersonalInformation.contract}
                                    />
                                    {
                                      (this.state.PersonalInformation.contract_file == '') ? (
                                        <div></div>
                                      ) : (
                                        <div style={{ marginTop: '8%' }}>
                                          {/* {this.state.PersonalInformation.contract_file}   */}
                                          <a href={this.state.PersonalInformation.contract_file} target="_blank">
                                            <text>{this.state.PersonalInformation.contract_file}</text>
                                          </a>
                                        </div>
                                      )
                                    }
                                  </div>

                                  <div class="col-md-6">
                                    <label for="exampleFormControlTextarea1">
                                      Comment</label>
                                    <input type="text" class="form-control-addsource-Personal"
                                      id="input3" placeholder="Enter Comment"
                                      name="contract_comment"
                                      value={this.state.PersonalInformation.contract_comment}
                                      onChange={(e) => {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.contract_comment = e.target.value;
                                        this.setState({ PersonalInformation });
                                      }}
                                    />
                                  </div>

                                  <div class="col-md-1">
                                    <label for="exampleFormControlTextarea1">
                                      Check</label>
                                    <input type='checkbox'
                                      class="form-control-addsource-Personal-ckeckbox"
                                      // id="input3"
                                      name="contract_status"
                                      checked={this.state.PersonalInformation.contract_status}
                                      onChange={(e) => {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.contract_status = e.target.checked;
                                        this.setState({ PersonalInformation });
                                      }}
                                    />
                                  </div>
                                </div>
                                <hr />
                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Procedure Potential form */}
                        {
                          (this.state.ProcedurePotentialFlag == true) ? (
                            <section style={{ marginBottom: '50%' }}>
                              <h3 class="mt-30">Procedure Potential</h3>
                              <div className="loginErrCreateProject">{this.state.procedurepotentialErr}</div>
                              <form>

                                <div class="row">
                                  {/* <div class="col-md-5">
                                        <div 
                                            // onChange={this.setProcedurePotential.bind(this)} 
                                            style={{marginTop:'5%'}}
                                        >
                                        <label> <input type="radio" value="High" autofocus="true"
                                            defaultChecked checked = {this.state.PersonalInformation.procedure_potential === 'High'}
                                            name="ProcedurePotential"/> High
                                            </label>
                                        <label> <input type="radio" value="Medium"
                                            defaultChecked checked = {this.state.PersonalInformation.procedure_potential === 'Medium'}
                                            name="ProcedurePotential"/> Medium
                                        </label>
                                        <label> <input type="radio" value="Low" 
                                            defaultChecked checked = {this.state.PersonalInformation.procedure_potential === 'Low'}
                                            name="ProcedurePotential"/> Low
                                        </label>
                                    </div>   */}
                                  <div class="col-md-5">
                                    <div
                                      // onChange={this.setProcedurePotential.bind(this)} 
                                      style={{ marginTop: '5%' }}
                                    >
                                      <input type="radio" value="High" autofocus="true"
                                        defaultChecked checked={this.state.PersonalInformation.procedure_potential === 'High'}
                                        name="ProcedurePotential" />
                                      <label>High
                                      </label>
                                      <br></br>
                                      <input type="radio" value="Medium"
                                        defaultChecked checked={this.state.PersonalInformation.procedure_potential === 'Medium'}
                                        name="ProcedurePotential" />
                                      <label> Medium
                                      </label>
                                      <br></br>
                                      <input type="radio" value="Low"
                                        defaultChecked checked={this.state.PersonalInformation.procedure_potential === 'Low'}
                                        name="ProcedurePotential" />
                                      <label>Low
                                      </label>
                                    </div>
                                    
                                  </div>

                                  <div class="col-md-6">
                                    <label for="exampleFormControlTextarea1">
                                      Comment</label>
                                    <input type="text" class="form-control-addsource-Personal"
                                      id="input3" placeholder="Enter Comment"
                                      name="procedure_potential_comment"
                                      value={this.state.PersonalInformation.procedure_potential_comment}
                                      onChange={(e) => {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.procedure_potential_comment = e.target.value;
                                        this.setState({ PersonalInformation });
                                      }}
                                    />
                                  </div>

                                  <div class="col-md-1">
                                    <label for="exampleFormControlTextarea1">
                                      Check</label>
                                    <input type='checkbox'
                                      class="form-control-addsource-Personal-ckeckbox"
                                      // id="input3"
                                      name="procedure_potential_status"
                                      checked={this.state.PersonalInformation.procedure_potential_status}
                                      onChange={(e) => {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.procedure_potential_status = e.target.checked;
                                        this.setState({ PersonalInformation });
                                      }}
                                    />
                                  </div>
                                </div>
                                  {
                                      (!this.state.ProcedurePotentialSource) ? (
                                        <div></div>
                                      ) : (
                                        <div style={{ marginTop: '1%', marginBottom: '2%' }}>
                                          {this.state.itemsProcedurePotential.map((itemsProcedurePotential,index) => (
                                            <div className="tag-item" key={itemsProcedurePotential}>
                                              {/* {itemsProcedurePotential} */}
                                              <a href={itemsProcedurePotential} target="_blank">
                                                <text>{index+1}.{itemsProcedurePotential}</text><br/>
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      )
                                    }
                                <hr />

                              </form>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="action-btn-container">
                <div class="container">
                  <div class="col-md-7 offset-md-3">
                    
                  <button class="cetas-button" onClick={this.SaveFunc}>Save</button>
                    <button class="cetas-button" onClick={this.RejectFunc}>Reject</button>
                    <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                      onClick={this.ApproveFunc}>Approve</button>

                  </div>
                </div>
              </div>
              <footer class="footer-fixed">
                <div class="cetas-footer">
                  <div class="container">
                    <p>All rights reserved. www.cetashealthcare.com</p>
                  </div>

                </div>
              </footer>
            </div>
          </div>
        </div>
      </body>
    );

  }
}

export default ManagerQC;